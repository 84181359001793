import { Functionalities } from "./app-functionalities";

export const environment = {
    production: true,
    baseUrl: "https://app4.maptycs.com/api/v2",
    token: "dtjumtnq22q9rlc0u5nlt17ntehabpnh",
    whitelist: ["app4.maptycs.com"],
    authToken: "5bqnpgtvlfoajcjav5t9dnhevp8j4f72",
    logo: "logo-light@3x.png",
    whiteLogo: "login-logo.svg",
    pageTitle: "", // if null it will be overriden from AppComponent
    customFavicon: "", // if null or empty it will be ignored
    agGridLicense:
        "Using_this_AG_Grid_Enterprise_key_( AG-042233 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Maptycs )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Maptycs )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Maptycs )_need_to_be_licensed___( Maptycs )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 16 July 2024 )____[v2]_MTcyMTA4NDQwMDAwMA==693dda2803784657c26ae417944bd116",
    wpLogin: "https://support.maptycs.com/?token=",
    wpLogout: "https://support.maptycs.com/wp-login.php?action=logout"
};

// here we can put only disabled features
export const Config: { name: Functionalities; enabled: boolean }[] = [];

export function isEnabled(functionalityName: Functionalities): boolean {
    let component = Config.find((f) => {
        return f.name === functionalityName;
    });
    if (component) {
        return component.enabled;
    }
    return true;
}
