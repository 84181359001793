import {Injectable} from '@angular/core';
import {DatapointFilter} from '../../model/datapoint/filter/datapoint-filter';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {MapShapeEvent} from '../../model/map/map-shape-event';
import {GeometryFilterShape, Type} from '../../model/geometry/geometry-filter-shape';
import {DatapointsPageStateService} from './datapoints-page-state.service';
import {DatapointFilterField} from '../../model/datapoint/filter/datapoint-filter-field';
import {FilterBarItem} from '../../model/datapoint/draft/table/filter-bar-item';
import {DatasetField} from '../../model/dataset/field/dataset-field';
import {Dataset} from '../../model/dataset/dataset';
import {DatasetFieldSpecificType} from '../../model/dataset/dataset-field-specific.type';
import {take} from 'rxjs/operators';
import {DatasetFieldStatistics} from '../../model/analytics/dataset-field-statistics';
import {DatapointsAggregateService} from '../../data-access-layer/datapoints/datapoints-aggregate.service';
import {DatasetFieldType} from '../../model/dataset/dataset-field-type';
import {formatNumber} from '@angular/common';
import {ComputationUtils} from '../../core/utils/computation-utils';
import {DistanceUnit} from '../../constants';
import {DateUtils} from '../../core/utils/date-utils';
import {TessadataNriFields} from 'src/app/core/tessadata/tessadata-nri-fields';
import {NotifService} from '../../core/notification/notif.service';
import {FilteringErrorCodes} from './filtering-error-codes';

const NUMBER_INPUT_LENGTH = 16;
const TEXT_INPUT_LENGTH = 100;

@Injectable()
export class DatapointsFilterService {

    private activeFilter: DatapointFilter;
    private filterBarItems: FilterBarItem[] = [];
    private geoRegionsIds: number[] = [];
    private readonly activeGroups: number[];
    private readonly updateFilterSubject: Subject<DatapointFilter> = new Subject();
    private readonly updateFilterBarItemsSubject: Subject<FilterBarItem[]> = new Subject();
    private readonly fetchStatisticsReadySubject: Subject<void> = new Subject<void>();
    private readonly zoomupdatedfromFilter: Subject<void> = new Subject<void>();
    private savedColumnsSubject = new BehaviorSubject<string[]>([]);
    savedColumnsArray$ = this.savedColumnsSubject.asObservable();

    constructor(
        private readonly datapointPageStateService: DatapointsPageStateService,
        private readonly datapointsAggregateService: DatapointsAggregateService,
        private readonly notifService: NotifService
    ) {
    }

    getActiveFilter(): DatapointFilter {
        return this.activeFilter;
    }

    onFilterChange(): Observable<DatapointFilter> {
        return this.updateFilterSubject.asObservable();
    }

    onFilterBarItemsChange(): Observable<FilterBarItem[]> {
        return this.updateFilterBarItemsSubject.asObservable();
    }

    onFetchStatisticsReady(): Observable<void> {
        return this.fetchStatisticsReadySubject.asObservable();
    }

    initFilter(filter: DatapointFilter) {
        this.activeFilter = filter;
    }

    /**
     * This will re-compose the filter and emit the change.
     */
    updateFilter() {
        let datasetID = this.datapointPageStateService.getActiveDataset().id;
        let filter: DatapointFilter = {
            datasetID: datasetID,
            groups: this.datapointPageStateService.activeGroups,
            fields: [],
            links: [],
            geometryFilter: this.activeFilter.geometryFilter
        };
        this.filterBarItems.forEach((filterBarItem: FilterBarItem) => {
            this.populateFilterBarItemDisplayValues(filterBarItem);
            //
            if (filterBarItem.dataset.id === datasetID) {
                filter.fields.push(this.createFilterFieldObject(filterBarItem));
            } else {
                if (!(filter.links.find(link => link.datasetID === filterBarItem.dataset.id))) {
                    filter.links.push({datasetID: filterBarItem.dataset.id, fields: [], linkFields: [], links: []});
                }
                let linkFilter = filter.links.find(link => link.datasetID === filterBarItem.dataset.id);
                if (filterBarItem.id === 'distance') {
                    linkFilter.linkFields.push(this.createFilterFieldObject(filterBarItem));
                } else {
                    linkFilter.fields.push(this.createFilterFieldObject(filterBarItem));
                }
            }
        });
        this.activeFilter = filter;
        this.emitFilterUpdate();
    }

     populateFilterBarItemDisplayValues(filterBarItem: FilterBarItem) {
        filterBarItem.displayedSearchValue = this.constructFilterDisplayValue(filterBarItem);
        let datasetFieldName = filterBarItem.datasetField.displayName == null || filterBarItem.datasetField.displayName == undefined ? filterBarItem.datasetField.name : filterBarItem.datasetField.displayName;
        if (filterBarItem.displayedSearchValue) {
            filterBarItem.displayedTitle = `${datasetFieldName}(${filterBarItem.dataset.name}) - ${filterBarItem.displayedSearchValue}`;
        } else {
            filterBarItem.displayedTitle = `${datasetFieldName}(${filterBarItem.dataset.name}) : Filter is not active`;
        }
    }

    updateShapes(event: MapShapeEvent) {
        let shapes: GeometryFilterShape[] = [];
        event.circles.forEach(circle => {
            shapes.push({type: Type.CIRCLE, center: {x: circle.longitude, y: circle.latitude}, radius: circle.radius});
        });
        event.rectangles.forEach(rectangle => {
            shapes.push({
                type: Type.BOUNDS,
                bottom: rectangle.south,
                left: rectangle.west,
                top: rectangle.north,
                right: rectangle.east
            });
        });
        event.polygons.forEach(polygon => {
            shapes.push({
                type: Type.POLYGON,
                coordinates: [].concat.apply([], polygon.polygon.getPath().getArray().map(edge => [edge.lng(), edge.lat()]))
            });
        });
        if (this.activeFilter.geometryFilter) {
            this.activeFilter.geometryFilter.unionShapes = shapes;
        } else {
            this.activeFilter.geometryFilter = {unionShapes: shapes, intersectionShapes: [], regionIds: this.geoRegionsIds};
        }
        this.emitFilterUpdate();
    }

    applyFilterForGeoRegions(regions) {
        this.geoRegionsIds = [];
        regions.forEach((region) => {
            this.geoRegionsIds.push(region.regionId);
        });
        if (this.activeFilter.geometryFilter) {
            this.activeFilter.geometryFilter.regionIds = this.geoRegionsIds;
        } else {
            this.activeFilter.geometryFilter = {intersectionShapes: [] , regionIds: this.geoRegionsIds};
        }
        this.emitFilterUpdate();
    }

    applyFilterBar() {
        let datasetID = this.datapointPageStateService.getActiveDataset().id;
        this.activeFilter = this.updateFilterBarItemsOnActiveFilter(datasetID, this.filterBarItems);
        this.emitFilterBarUpdate();
        this.emitFilterUpdate();
    }

    updateFilterBarItemsOnActiveFilter(datasetID: string, filterBarItems: FilterBarItem[]): DatapointFilter {
        let filter: DatapointFilter = {
            datasetID: this.activeFilter.datasetID,
            geometryFilter: this.activeFilter.geometryFilter,
            groups: this.activeFilter.groups,
            joinType: this.activeFilter.joinType,
            fields: [],
            linkFields: [],
            links: []
        };

        return this.populateFilterWithFilterBarItems(filterBarItems, datasetID, filter);
    }

    constructNewFilterObjectFromFilterBarItems(datasetID: string, filterBarItems: FilterBarItem[]): DatapointFilter {
        let filter: DatapointFilter = {
            datasetID: datasetID,
            groups: [],
            fields: [],
            links: [],
        };
        this.populateFilterWithFilterBarItems(filterBarItems, datasetID, filter);
        return filter;
    }

    private populateFilterWithFilterBarItems(filterBarItems: FilterBarItem[], datasetID: string, filter: DatapointFilter) {
        filterBarItems.forEach((filterBarItem) => {
            let fbi = filterBarItems.find((val) => val.id === filterBarItem.id);
            let filterField = this.createFilterFieldObject(filterBarItem);
            if (filterBarItem.dataset.id === datasetID) {
                filter.fields.push(filterField);
            } else {
                let existingLink = filter.links.find(link => link.datasetID === filterBarItem.dataset.id);
                if (!existingLink) {
                    existingLink = {datasetID: filterBarItem.dataset.id, fields: [], linkFields: [], links: []};
                    filter.links.push(existingLink);
                }
                if (filterField.id === 'distance') {
                    existingLink.linkFields.push(filterField);
                } else {
                    existingLink.fields.push(filterField);
                }

            }
        });
        return filter;
    }

    clearFilterBar() {
        this.filterBarItems = [];
        if (this.activeFilter) {
            this.activeFilter.fields = [];
            this.activeFilter.links = [];
        }
        this.emitFilterUpdate();
    }

    createFilterFieldObject(filterBarItem: FilterBarItem): DatapointFilterField {
        let textValues = [];
        let statistics = filterBarItem.statisticValues;
        try {
            for (let prop in statistics) {
                if (statistics.hasOwnProperty(prop) && statistics[prop]) {
                    textValues.push(prop);
                }
            }
        } catch (err) {
        }

        let maxNumberValue = filterBarItem.isDistanceItem ? ComputationUtils.getDistanceInMeters(filterBarItem.maxNumberValue, filterBarItem.distanceUnit) : filterBarItem.maxNumberValue;
        let minNumberValue = filterBarItem.isDistanceItem ? ComputationUtils.getDistanceInMeters(filterBarItem.minNumberValue, filterBarItem.distanceUnit) : filterBarItem.minNumberValue || 0;

        let field: DatapointFilterField = {
            id: filterBarItem.datasetField.id,
            maxDateValue: filterBarItem.maxDateValue ? new Date(filterBarItem.maxDateValue).getTime() : null,
            minDateValue: filterBarItem.minDateValue ? new Date(filterBarItem.minDateValue).getTime() : null,
            maxNumberValue: maxNumberValue,
            minNumberValue: minNumberValue,
            searchValue: filterBarItem.searchValue || null,
            textValues: textValues,
            // this is only for workspace item, to keep track of the distance unit. do not use it elsewhere
            distanceUnit: filterBarItem.isDistanceItem ? DistanceUnit.KM : null //filterBarItem.distanceUnit : null
        };
        return field;
    }

    private removeFilterField(datasetID: string, fieldID: string): void {
        if (this.activeFilter.datasetID === datasetID) { // simple field
            let indexToDelete = this.activeFilter.fields.findIndex(field => field.id === fieldID);
            this.activeFilter.fields.splice(indexToDelete, 1);
        } else { // link field
            this.activeFilter.links.forEach(link => {
                if (link.datasetID === datasetID) {
                    let indexToDelete = link.fields.findIndex(field => field.id === fieldID);
                    link.fields.splice(indexToDelete, 1);
                    if (link.fields.length === 0) {
                        this.activeFilter.links.splice(this.activeFilter.links.indexOf(link), 1);
                    }
                }
            });
        }
        this.emitFilterUpdate();
    }

    updateGroups(groupIDs: number[]) {
        this.activeFilter.groups = groupIDs;
        this.emitFilterUpdate();
    }

    emitFilterUpdate() {
        this.updateFilterSubject.next(this.activeFilter);
    }

    emitFilterBarUpdate() {
        this.updateFilterBarItemsSubject.next(this.filterBarItems);
    }


    createFilterBarItem(datasetField: DatasetField, dataset?: Dataset, isDistanceItem?: boolean): FilterBarItem {
        datasetField = this.setCustomFilterName(datasetField);
        let datasetFieldName = datasetField.displayName == null || datasetField.displayName == undefined ? datasetField.name : datasetField.displayName;
        return {
            id: datasetField.id,
            datasetField: datasetField,
            dataset: dataset,
            statistics: {
                values: [],
                maxValue: null,
                minValue: null,
                averageValue: null,
                id: null,
                stdevValue: null
            },
            maxNumberValue: null,
            minNumberValue: null,
            minDateValue: null,
            maxDateValue: null,
            searchValue: '',
            statisticValues: null,
            filteredStatisticValues: null,
            isDistanceItem: isDistanceItem,
            displayedTitle: `${datasetFieldName}(${dataset.name}) : Filter is not active`,
            baseType: datasetField.baseType
        };
    }

    applyOverlayDistance(dataset: Dataset, distance: number, distanceUnit: DistanceUnit) {
        let existingDistanceField = this.filterBarItems.findIndex(item => item.dataset.id === dataset.id && item.datasetField.id === 'distance');
        if (!distance) { // we need to remove the field and maybe disabled the other filters related to this dataset
            return;
        }
        if (existingDistanceField > -1) { // we need to update the distances
            this.filterBarItems[existingDistanceField].maxNumberValue = distance;
            this.filterBarItems[existingDistanceField].minNumberValue = 0;
            this.filterBarItems[existingDistanceField].distanceUnit = distanceUnit;
        } else { // we need to add the new item in list
            let distanceField: DatasetField = {
                id: 'distance',
                name: 'Distance',
                type: DatasetFieldSpecificType.NUMBER_FIELD,
                baseType: DatasetFieldType.NUMBER
            };
            let filterBarItem = this.createFilterBarItem(distanceField, dataset, true);
            filterBarItem.maxNumberValue = distance;
            filterBarItem.minNumberValue = 0;
            filterBarItem.distanceUnit = distanceUnit;
            filterBarItem.displayedSearchValue = `< ${distance} ${distanceUnit}`;
            this.filterBarItems.push(filterBarItem);
            this.emitFilterBarUpdate();
            this.applyFilterBar();
        }
    }

    addFilterBarItem(datasetField: DatasetField, dataset?: Dataset, isDistanceItem?: boolean, selectedStatisticValues?: string[], params?: any): FilterBarItem {
        let filterBarItem = this.createFilterBarItem(datasetField, dataset, isDistanceItem);
        this.filterBarItems.push(filterBarItem);
        if (!selectedStatisticValues) {
            selectedStatisticValues = [];
        }
        filterBarItem = this.bindTableViewFilterValue(datasetField, filterBarItem, params);
        if (!datasetField.isHighCardinality && !isDistanceItem) {
            let groups = this.activeFilter.datasetID === dataset.id ? this.datapointPageStateService.activeGroups : [];
            this.fetchFilterBarItemStatistics(filterBarItem, dataset, datasetField, groups, selectedStatisticValues);
        }
        this.emitFilterBarUpdate();
        return filterBarItem;
    }

    addFilterBarItemForTableView(datasetFields: DatasetField[], dataset?: Dataset, params?: any) {
        let groups = this.activeFilter.datasetID === dataset.id ? this.datapointPageStateService.activeGroups : [];
        let collectFilterBarItem = []; 
        let datapointsFieldStatisticsResults = this.datapointsAggregateService.prepareDatapointsFieldStatistics(datasetFields, dataset, groups);
        datapointsFieldStatisticsResults.then(results => {
            results.forEach(result => {
                const datasetField = datasetFields.find(element => element.id === result.id || element.name === result.id);
                let fieldValue = params.get(datasetField.id);
                let filterBarItem = this.createFilterBarItem(datasetField, dataset, false);
                collectFilterBarItem.push(filterBarItem);
                if (!fieldValue.textValues) {
                    fieldValue.textValues = [];
                }
                if (!datasetField.isHighCardinality) { 
                    const valueListExists = result.values && result.values.length > 0;
                try {
                    // this circleData block is to remove null values, bug from a backend workaround
                    result.values = result.values.filter(value => value !== null);
                } catch (err) {

                }

                let {averageValue, maxValue, minValue, values} = result;
                result.maxDateValue = new Date(maxValue);
                result.minDateValue = new Date(minValue);
                if (valueListExists) {
                    let statisticValues = [];
                    values.forEach((value: string) => {
                        statisticValues.push({selected: false, value: value});
                    });
                    filterBarItem.statisticValues = {};
                    filterBarItem.filteredStatisticValues = values;
                    const lowercaseArray: string[] = fieldValue.textValues.map((element) => element.toLowerCase());
                    values.forEach((value => {
                        if (lowercaseArray.includes(value.toLowerCase())) {
                            filterBarItem.statisticValues[value] = true;
                        } else {
                            filterBarItem.statisticValues[value] = false;
                        }

                    }));
                }
                filterBarItem.statistics = result;
                if (datasetField.baseType == DatasetFieldType.NUMBER) {
                    filterBarItem.minNumberValue = fieldValue.minNumberValue;
                    filterBarItem.maxNumberValue = fieldValue.maxNumberValue;
                }
                filterBarItem.displayedSearchValue = this.constructFilterDisplayValue(filterBarItem);

                this.fetchStatisticsReadySubject.next();
                }
            })
            this.filterBarItems = collectFilterBarItem;    
            this.applyFilterBar();
        })
    }

    removeFilterBarItem(datasetId: string, fieldId: string) {
        this.filterBarItems = this.filterBarItems.filter((item) => {
            return !(item.dataset.id === datasetId && item.datasetField.id === fieldId);
        });
        this.applyFilterBar();
    }

    fetchFilterBarItemStatisticss(filterBarItem: FilterBarItem, dataset: Dataset, datasetField: DatasetField, groups: number[], findValue: any) {
        this.datapointsAggregateService.getDatapointsFieldStatistics(dataset.id, datasetField.id, {
            datasetID: dataset.id,
            groups: groups
        })
            .pipe(take(1))
            .subscribe((statistics: DatasetFieldStatistics) => {
                const valueListExists = statistics.values && statistics.values.length > 0;
                try {
                    // this circleData block is to remove null values, bug from a backend workaround
                    statistics.values = statistics.values.filter(value => value !== null);
                } catch (err) {

                }

                let {averageValue, maxValue, minValue, values} = statistics;
                statistics.maxDateValue = new Date(maxValue);
                statistics.minDateValue = new Date(minValue);
                if (valueListExists) {
                    let statisticValues = [];
                    values.forEach((value: string) => {
                        statisticValues.push({selected: false, value: value});
                    });
                    filterBarItem.statisticValues = {};
                    filterBarItem.filteredStatisticValues = values;
                    const lowercaseArray: string[] = findValue.textValues.map((element) => element.toLowerCase());
                    values.forEach((value => {
                        if (lowercaseArray.includes(value.toLowerCase())) {
                            filterBarItem.statisticValues[value] = true;
                        } else {
                            filterBarItem.statisticValues[value] = false;
                        }

                    }));
                }
                filterBarItem.statistics = statistics;
                if (datasetField.baseType == DatasetFieldType.NUMBER) {
                    filterBarItem.minNumberValue = findValue.minNumberValue;
                    filterBarItem.maxNumberValue = findValue.maxNumberValue;
                }
                filterBarItem.displayedSearchValue = this.constructFilterDisplayValue(filterBarItem);

                this.fetchStatisticsReadySubject.next();
            });
    }

    fetchFilterBarItemStatistics(filterBarItem: FilterBarItem, dataset: Dataset, datasetField: DatasetField, groups: number[], selectedStatisticValues: string[]) {
        this.datapointsAggregateService.getDatapointsFieldStatistics(dataset.id, datasetField.id, {
            datasetID: dataset.id,
            groups: groups
        })
            .pipe(take(1))
            .subscribe((statistics: DatasetFieldStatistics) => {
                const valueListExists = statistics.values && statistics.values.length > 0;
                try {
                    // this circleData block is to remove null values, bug from a backend workaround
                    statistics.values = statistics.values.filter(value => value !== null);
                } catch (err) {

                }

                let {averageValue, maxValue, minValue, values} = statistics;
                statistics.maxDateValue = new Date(maxValue);
                statistics.minDateValue = new Date(minValue);
                if (valueListExists) {
                    let statisticValues = [];
                    values.forEach((value: string) => {
                        statisticValues.push({selected: false, value: value});
                    });
                    filterBarItem.statisticValues = {};
                    filterBarItem.filteredStatisticValues = values;
                    const lowercaseArray: string[] = selectedStatisticValues.map((element) => element.toLowerCase());
                    values.forEach((value => {
                        if (lowercaseArray.includes(value.toLowerCase())) {
                            filterBarItem.statisticValues[value] = true;
                        } else {
                            filterBarItem.statisticValues[value] = false;
                        }

                    }));
                }
                filterBarItem.statistics = statistics;
                filterBarItem.displayedSearchValue = this.constructFilterDisplayValue(filterBarItem);

                this.fetchStatisticsReadySubject.next();
            });
    }

    constructFilterDisplayValue(filterBarItem: FilterBarItem): string {

        if (filterBarItem.statisticValues) {
            let vals = [];
            let tempVals = [];
            for (const [key, value] of Object.entries(filterBarItem.statisticValues)) {
                if (value && !tempVals.includes(key.toLowerCase())) {
                    vals.push(key);
                    tempVals.push(key.toLowerCase());
                }
            }
            return '' + vals.join();
        } else if (filterBarItem.searchValue) {
            return filterBarItem.searchValue;
        } else if (filterBarItem.minDateValue || filterBarItem.maxDateValue) {
            if (!filterBarItem.minDateValue) {
                return 'Until ' + DateUtils.parseDate(filterBarItem.maxDateValue);
            }
            if (!filterBarItem.maxDateValue) {
                return 'From ' + DateUtils.parseDate(filterBarItem.minDateValue);
            }
            return DateUtils.parseDate(filterBarItem.minDateValue) + ' - ' + DateUtils.parseDate(filterBarItem.maxDateValue);
        } else if (filterBarItem.minNumberValue || filterBarItem.maxNumberValue) {
            if (filterBarItem.id === 'distance') {
                let distanceUnit = filterBarItem.distanceUnit;
                if (!filterBarItem.maxNumberValue) {
                    return ' > ' + formatNumber(filterBarItem.minNumberValue, 'en') + ' ' + distanceUnit;
                }
                if (!filterBarItem.minNumberValue) {
                    return ' < ' + formatNumber(filterBarItem.maxNumberValue, 'en') + ' ' + distanceUnit;
                }
                return formatNumber(filterBarItem.minNumberValue, 'en') + ' ' + distanceUnit + ' - ' + formatNumber(filterBarItem.maxNumberValue / 1000, 'en') + 'km';
            }
            if (!filterBarItem.maxNumberValue) {
                return ' > ' + formatNumber(filterBarItem.minNumberValue, 'en');
            }
            if (!filterBarItem.minNumberValue) {
                return ' < ' + formatNumber(filterBarItem.maxNumberValue, 'en');
            }
            return formatNumber(filterBarItem.minNumberValue, 'en') + ' - ' + formatNumber(filterBarItem.maxNumberValue, 'en');

        }
        return null;
    }

    emitzoomFromFiter(zoomRegions) {
        this.zoomupdatedfromFilter.next(zoomRegions);
    }

    onzoomRegionChange(): Observable<any> {
        return this.zoomupdatedfromFilter.asObservable();
    }

    setCustomFilterName(datasetField) {
        let nameSplitValue = datasetField.name.split(" ");
        if ([TessadataNriFields.SUMMARY_SOVI_RATING, TessadataNriFields.SUMMARY_RESL_RATING].includes(datasetField.id)) {
            datasetField.name = nameSplitValue[1] + " " + nameSplitValue[2] + " Rating";
        } else if (datasetField.id == TessadataNriFields.SUMMARY_EAL_RATING) {
            datasetField.name = nameSplitValue[1] + " Rating";
        } else if (datasetField.id  == TessadataNriFields.SUMMARY_RISK_RATING) {
            datasetField.name = "Overall " + nameSplitValue[1];
        }
        return datasetField;
    }

    isFilterBarExist(activeField: DatasetField) {
        return this.filterBarItems.find(element => element.id == activeField.id) == undefined ? true : false;
    }

    bindTextValuetoGlobalFilter(filterValues, activeField: DatasetField, params?: any) {
        const filterBarItem = this.filterBarItems.find(element => element.id === activeField.id);
        if (filterBarItem.baseType == DatasetFieldType.TEXT && !activeField.isHighCardinality) {
            let statistics = filterBarItem.statisticValues;
            try {
                for (let prop in statistics) {
                    if (statistics.hasOwnProperty(prop)) {
                        statistics[prop] = filterValues.includes(prop.toLowerCase());

                    }
                }
                filterBarItem.statisticValues = statistics;
            } catch (err) {
            }
        } else if (filterBarItem.baseType == DatasetFieldType.TEXT && activeField.isHighCardinality) {
            filterBarItem.searchValue = params.searchValue;
        }  else if (filterBarItem.baseType == DatasetFieldType.NUMBER) {
            filterBarItem.minNumberValue = filterValues.minNumberValue == undefined ? null : filterValues.minNumberValue;
            filterBarItem.maxNumberValue = filterValues.maxNumberValue == undefined ? null : filterValues.maxNumberValue;
        } else if (filterBarItem.baseType == DatasetFieldType.DATE_TIME) {
            filterBarItem.minDateValue = filterValues.minDateValue;
            filterBarItem.maxDateValue = filterValues.maxDateValue;
        }
        this.filterBarItems.forEach(element => {
            if (element.id == filterBarItem.id) {
                element = filterBarItem;
            }
        });
        filterBarItem.displayedSearchValue = this.constructFilterDisplayValue(filterBarItem);
        this.emitFilterBarUpdate();
    }

    resetAllFilterBarItems(fieldIds: string[]) {
        if (this.filterBarItems.length) {
            if (fieldIds.length) {
                this.filterBarItems.forEach(element => {
                    if (fieldIds.includes(element.id) || element.id === 'distance') {
                        element = this.resetFilterBarItemsValues(element);
                    }
                });
                this.activeFilter.fields = [];
            } else {
                this.filterBarItems.forEach(element => {
                    element = this.resetFilterBarItemsValues(element);
                });
            }
        }
    }

    resetFilterBarItemsValues(element: FilterBarItem, resetFilterFields: boolean = false) {
        if (element.datasetField.isDisplayedInFilter && element.datasetField.baseType === DatasetFieldType.TEXT && !element.datasetField.isHighCardinality) {
            for (const key in element.statisticValues) {
                if (Object.prototype.hasOwnProperty.call(element.statisticValues, key)) {
                    element.statisticValues[key] = false;
                }
            }
        } else if (element.datasetField.isDisplayedInFilter && element.datasetField.baseType === DatasetFieldType.TEXT && element.datasetField.isHighCardinality) {
            element.searchValue = '';
        } else if (element.datasetField.isDisplayedInFilter && element.datasetField.baseType === DatasetFieldType.NUMBER) {
            element.maxNumberValue = null;
            element.minNumberValue = null;
            element.statistics.minValue = null;
            element.statistics.maxValue = null;
            if (resetFilterFields) {
                this.activeFilter.fields.forEach(sub_element => {
                    if (element.id == sub_element.id) {
                        sub_element.minNumberValue = null;
                        sub_element.maxNumberValue = null;
                    }
                })
            }
            
        } else if (element.datasetField.isDisplayedInFilter && element.datasetField.baseType === DatasetFieldType.DATE_TIME) {
            element.maxDateValue = null;
            element.minDateValue = null;
        }
        element.displayedSearchValue = this.constructFilterDisplayValue(element);
        return element;
    }

    resetSelectedFilterBarItem(fieldIds: string[], isRowGroupCall: boolean, filterModelKeys: string[]) {
        if (this.filterBarItems.length) {
            this.filterBarItems.forEach(element => {
                if (isRowGroupCall && (fieldIds.length && fieldIds.includes(element.id))) {
                    return;
                } else {
                    const resetFilterFields = filterModelKeys.length && filterModelKeys.includes(element.id) ? false : true;
                    element = this.resetFilterBarItemsValues(element, resetFilterFields);
                }
            });
        }
    }

    bindTableViewFilterValue(datasetField: DatasetField, filterBarItem: FilterBarItem, params?: any) {
        if (params !== undefined && Object.keys(params).length) {
            if (datasetField.baseType == DatasetFieldType.NUMBER) {
                filterBarItem.minNumberValue = params.minNumberValue == undefined ? null : params.minNumberValue;
                filterBarItem.maxNumberValue = params.maxNumberValue == undefined ? null : params.maxNumberValue;
            } else if (datasetField.baseType == DatasetFieldType.DATE_TIME) {
                filterBarItem.minDateValue = params.minDateValue;
                filterBarItem.maxDateValue = params.maxDateValue;
                filterBarItem.displayedSearchValue = this.constructFilterDisplayValue(filterBarItem);
            } else if (datasetField.baseType == DatasetFieldType.TEXT && datasetField.isHighCardinality) {
                filterBarItem.searchValue = params.searchValue;
                filterBarItem.displayedSearchValue = this.constructFilterDisplayValue(filterBarItem);
            }
        }
        return filterBarItem;
    }

    getFilterBarItemIds() {
        return this.filterBarItems.map(x => x.id);
    }

//    form validators
    validatorsForFiltersInputs(filterItem: FilterBarItem) {
        if(filterItem.datasetField.baseType === DatasetFieldType.NUMBER) {
            if (filterItem.maxNumberValue === null && filterItem.minNumberValue === null) {
                filterItem.maxNumberValue = filterItem.statistics.maxValue || null;
            }
            if ((filterItem.maxNumberValue !== null && filterItem.minNumberValue !== null) && filterItem.minNumberValue > filterItem.maxNumberValue) {
                return FilteringErrorCodes.MIN_GREATER_THAN_MAX;
            }
            if (filterItem.minNumberValue !== null) {
                let minValue = filterItem.minNumberValue.toString(10);
                if (minValue.length > NUMBER_INPUT_LENGTH) {
                    return FilteringErrorCodes.EXCEED_MIN_ALLOWED;
                }
            }
            if (filterItem.maxNumberValue !== null) {
                let maxValue = filterItem.maxNumberValue.toString(10);
                if (maxValue.length > NUMBER_INPUT_LENGTH) {
                    return FilteringErrorCodes.EXCEED_MAX_ALLOWED;
                }
            }
        }
        if (filterItem.datasetField.baseType === DatasetFieldType.TEXT) {
            if (filterItem.searchValue && filterItem.searchValue.length > TEXT_INPUT_LENGTH) {
                return FilteringErrorCodes.EXCEED_LENGTH_CHARTS;
            }
        }
    }
    
    updateArray(newArray: string[]): void {
        this.savedColumnsSubject.next(newArray);
    }
}
