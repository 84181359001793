// tslint:disable:max-file-line-count
import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "../../data-access-layer/account/account.service";
import { Account } from "../../model/account/account";
import { Dataset } from "../../model/dataset/dataset";
import { Constants, DatapointsViewMode, DatasetsNames, DistanceUnit } from "../../constants";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../../auth/auth.service";
import { RenderingOptions } from "../../model/dataset/rendering/rendering-options";
import {
    DatapointConverterOptions,
    DatapointConverterType,
    Grouping,
} from "../../model/dataset/rendering/datapoint-converter-options";
import { VisualizationType } from "../../model/dataset/rendering/visualization-options";
import { DatapointFilter } from "../../model/datapoint/filter/datapoint-filter";
import { DatapointProjection } from "../../model/datapoint/projection/datapoint-projection";
import { DatasetField } from "../../model/dataset/field/dataset-field";
import { DatasetFieldType } from "../../model/dataset/dataset-field-type";
import { DatapointsAggregateService } from "../../data-access-layer/datapoints/datapoints-aggregate.service";
import { OverlaysService } from "../../data-access-layer/global-overlays/overlays.service";
import { ActivateOverlayEvent } from "../../model/events/activate-overlay-event";
import { DatasetUtils } from "../../core/utils/dataset-utils";
import { MapStateService } from "../../map/map-state.service";
import {
    defaultMapStatus,
    defaultTileSize,
} from "../../core/map/map.constants";
import { GroupPanelComponent } from "../group-panel/group-panel.component";
import { DatapointSort, SortOrder } from "../../model/filter/draft-filter-sort";
import { SortDirection } from "@angular/material/sort";
import { LinkDatasetService } from "../../data-access-layer/dataset/link-dataset.service";
import { DatasetService } from "../../data-access-layer/dataset/dataset.service";
import { PanelComponent } from "../../shared/panel/panel.component";
import { DatapointsPageStateService } from "./datapoints-page-state.service";
import { DatapointFilterObject } from "../../model/datapoint/datapoint-filter-object";
import { DatasetGeometryType } from "../../model/dataset/dataset-geometry-type";
import { ColorScaleType } from "../../model/dataset/rendering/color-scale-type";
import { SidePanelComponent } from "../../core/side-panel/side-panel.component";
import { MapDrawType } from "../../model/map/map-draw-type";
// import { MAT_INPUT_VALUE_ACCESSOR } from "@angular/material";
import { MatDialog } from "@angular/material/dialog";
import { DatasetDownloadComponent } from "../dataset-download/dataset-download.component";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DatapointsFilterService } from "./datapoints-filter.service";
import { MapShape } from "../../model/map/map-shape";
import { FilterBarItem } from "../../model/datapoint/draft/table/filter-bar-item";
import { DatasetFieldStatistics } from "../../model/analytics/dataset-field-statistics";
import { MapStateActionEnum } from "../../model/map/map-state-action.enum";
import { AggregateGroupRequest } from "../../model/datapoint/report/aggregate-group-request";
import { ReportRequest } from "../../model/datapoint/report/report-request";
import {
    MapThematicOverlayService,
    ThematicMapConfig,
} from "../../map/map-thematic-overlay.service";
import {
    NR_THEMATIC_MAP_LEVELS,
    thematicMapOverlayRenderingOptions,
} from "../../core/map/map-thematic-overlay.constants";
import { forkJoin, from, Subject, Subscription } from "rxjs";
import { IntervalOption } from "../../model/dataset/rendering/interval-option";
import { AttachmentUtils } from "../../core/utils/attachment-utils";
import { DownloadService } from "../../data-access-layer/download/download.service";
import { Location } from "@angular/common";
import { DatapointsOverlaysComponent } from "./datapoints-overlay/datapoints-overlays.component";
import { NotifService } from "../../core/notification/notif.service";
import { Type } from "../../model/geometry/geometry-filter-shape";
import { ActivateImageOverlayEvent } from "../../model/events/activate-image-overlay-event";
import { ImageOverlay } from "../../model/overlay/external/image-overlay";
import { UserStateService } from "../../auth/user-state-service";
import { DatapointsProfilePanelComponent } from "./datapoints-location-profile/datapoints-profile-panel.component";
import { environment, isEnabled } from "../../../environments/environment";
import {
    DatapointsOverlayFilterService,
    DatapointsOverlayFilterStore,
} from "./datapoints-overlay-filter/datapoints-overlay-filter.service";
import { DatapointFilterField } from "../../model/datapoint/filter/datapoint-filter-field";
import { MatInputCommifiedDirective } from "../../core/directives/separatopr-directive.directive";
import { DownloadDatapointRequest } from "../../model/download/download-datapoint-request";
import { MapDetails } from "../../map/map/info-box/map-info-box.component";
import { MapsAPILoader } from "@agm/core";
import { MapLocation } from "../../model/map/map-location";
import { WorkspaceItemDialogComponent } from "../workspace-item/projection/workspace-item-dialog.component";
import { WorkspaceItemType } from "../../model/workspace/workspace-item-type";
import { WorkspaceItemService } from "../../data-access-layer/workspace-item/workspace-item.service";
import { WorkspaceItem } from "../../model/workspace/workspace-item";
import { WorkspaceItemAccess } from "../../model/workspace/workspace-item-access";
import { DialogComponent } from "../../shared/dialog/dialog.component";
import { DialogModel } from "../../model/dialog/dialog-model";
import { debounceTime, mergeMap, take } from "rxjs/operators";
import { DatasetFieldSpecificType } from "../../model/dataset/dataset-field-specific.type";
import { ComputationUtils } from "../../core/utils/computation-utils";
import { LegendInfo } from "../../model/legend/legend-info";
import { LegendUtils } from "../../core/utils/legend-utils";
import { GradientDetails } from "../../model/legend/gradient-details";
import { MapInteractionMode } from "./map-interaction-mode";
import { TessadataService } from "../../data-access-layer/tessadata.service";
import { Functionalities } from "../../../environments/app-functionalities";
import { OverlaysConstants } from "../../overlays/overlays.constants";
import { DatasetFieldFloatingOption } from "../../model/dataset/dataset-field-floating-option";
import { ObjectUtils } from "../../core/utils/object-utils";
import { TessadataDatasetsByCountry } from "../../core/tessadata/tessadata-datasets-by-country";
import { TessadataDataset } from "../../core/tessadata/tessadata-dataset";
import { DatasetFieldScope } from "../../model/dataset/dataset-field-scope";
import { DatapointsService } from "../../data-access-layer/datapoints/datapoints.service";
import { MapComponent } from "../../map/map/map.component";
import { Point } from "../../model/geometry/point";
import { UpdateDatapointRequest } from "../../model/datapoint/update-datapoint-request";
import { DatapointAggregateFieldType } from "../../model/datapoint/report/datapoint-aggregate-field-type";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { TessadataDatasetType } from "../../core/tessadata/tessadata-dataset-type";
import { TessadataDatasetStructure } from "../../core/tessadata/tessadata-dataset-structure";
import { ClusteringService } from "../clustering/clustering.service";
import { Datapoint } from "../../model/datapoint/datapoint";
import { ClusterDatapoint } from "../clustering/cluster-datapoint";
import { AddressLocationProfileComponent } from "./address-location-profile/address-location-profile.component";
import { MaptycsApplication } from "src/app/model/account/maptycs-application";
import { SidePanelService } from "../../shared/services/side-panel.service";
import { SidePanels } from "../../shared/services/side-panel.helper";
import { DataPointsServiceState } from "../../shared/services/datapoints-service-state";
import { ClusteringRequest } from "../clustering/clustering-request";
import { GeographicalRegionsService } from "src/app/geographical-regions/geographical-regions.service";
import {
    isNull,
    isNullOrUndefined,
    isUndefined,
    sortArrayByName,
} from "src/app/core/utils/util-master";
import { TessadataNriFields } from "src/app/core/tessadata/tessadata-nri-fields";
import { AgDatapointTableComponent } from "./ag-datapoint-table/ag-datapoint-table.component";
import { DatapointsOverlayFilterComponent } from "./datapoints-overlay-filter/datapoints-overlay-filter.component";
import { FilteringErrorCodes } from "./filtering-error-codes";
import { Crisis24Alert } from "src/app/model/datapoint/crisis24-alert";
import { DatapointField } from "src/app/model/datapoint/datapoint-field";
import { TreeStructureUtils } from "src/app/core/utils/tree-structure-utils";
import { TreeStructure } from "src/app/model/menu/tree-structure";
import { Datapoints } from "./datapoints";
import { DraftsUploadComponent } from "../drafts/drafts-upload/drafts-upload.component";
import { LocationProfile } from "src/app/model/datapoint/location-profile/location-profile";
import { MatMenu } from "@angular/material/menu";
import { ReportPanelConfig } from "src/app/model/report/report-panel-config";
import { ReportService } from "src/app/shared/services/renewal-report-service";
import { DownloadFileType } from "src/app/model/download/download-file-type";
import { REPORT_PANEL_ID } from "./reports/reports.constants";
declare var google: any;

// Constants

const DEFAULT_SORT: any = {
    fields: [],
    datasetID: null,
    links: [],
    linkFields: [],
    // geocodingAccuracyOrder: SortOrder.ASCENDANT,
    // statusOrder: SortOrder.ASCENDANT
};
const LIMIT = 1000;
const DEFAULT_SKIP = 0;
const DIRECTION_MAP = { asc: SortOrder.ASCENDANT, desc: SortOrder.DESCENDANT };

type AnalitycsComponentType = {
    dataset: Dataset;
    nriFields: any;
    _climateOverlays: any;
};

type EventNotificationComponentType = {
    account: Account;
    dataset: Dataset;
    groupsIds: number[];
};

type DatapointsClusterComponentType = {
    filter: DatapointFilter;
    dataset: Dataset;
    initSettings: ClusteringRequest;
};

type UpdateDatapointComponentType = {
    dataset: Dataset;
};

type crises24DatapointsAgTableComponentType = {
    datapointID: string;
    dataset: Dataset;
    location?: Point;
    version: number;
    radius?: number;
    isListCall?: boolean;
};

export interface MatSortChange {
    active: string;
    direction: SortDirection;
}

interface OutputItem {
    datasetID: string;
    fields: string[];
  }

/**
 * This is the main component of the page with datapoints. It contains header, footer, groupNodes and overlays panels, the map component, and the table component.
 */
@Component({
    selector: "map-datapoints",
    templateUrl: "./datapoints.component.html",
    styleUrls: ["./datapoints.component.scss"],
    // providers: [
    //     {
    //         provide: MAT_INPUT_VALUE_ACCESSOR,
    //         useExisting: MatInputCommifiedDirective,
    //     },
    // ],
})
export class DatapointsComponent implements OnInit, OnDestroy {
    DatasetsNames = DatasetsNames;
    MaptycsApplication = MaptycsApplication;
    isAccountAdmin: boolean;

    filterAttributes: Object = {
        filterAccountDatasets: {},
        tessadataGroups: {},
        tessadataEnabled: false,
        tessadataFieldsByDataset: {},
        overlayComponentEnabled: false,
        filterAccountOverlays: {},
    };
    isFilterAttributesPrepared: boolean = false;

    filterTreeStrcuture = new TreeStructureUtils();
    treeControl = this.filterTreeStrcuture.getTreeControl();
    dataSource = this.filterTreeStrcuture.getDataSource();

    externalDataTreeStrcuture = new TreeStructureUtils();
    externalDatatreeControl = this.externalDataTreeStrcuture.getTreeControl();
    externalDatadataSource = this.externalDataTreeStrcuture.getDataSource();
    externalData: boolean = false;

    showSaveFilterTreeStrcuture = new TreeStructureUtils();
    saveFilterTreeControl = this.showSaveFilterTreeStrcuture.getTreeControl();
    saveFilterDataSource = this.showSaveFilterTreeStrcuture.getDataSource();

    datapointObject = new Datapoints();
    externalTensorFlightData: any;
    currentSelectedProfile: string | null = null;
    locationProfile = new LocationProfile();
    showDatasetList: boolean = false;
    showReportList: boolean = false;
    reports: WorkspaceItem[] = [];
    isAnalyticsDisabled: boolean = true;

    constructor(
        private readonly geoRegionsService: GeographicalRegionsService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly mapStateService: MapStateService,
        private readonly accountService: AccountService,
        private readonly datasetService: DatasetService,
        private readonly linkDatasetService: LinkDatasetService,
        private readonly datapointsServiceState: DataPointsServiceState,
        private readonly overlayService: OverlaysService,
        private readonly authService: AuthService,
        private readonly clusteringService: ClusteringService,
        private readonly datapointsService: DatapointsService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly datapointAggregateService: DatapointsAggregateService,
        private readonly datapointsOverlayFilterService: DatapointsOverlayFilterService,
        private readonly cd: ChangeDetectorRef,
        private readonly mapThematicOverlayService: MapThematicOverlayService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly http: HttpClient,
        public readonly dialog: MatDialog,
        private readonly formBuilder: UntypedFormBuilder,
        public readonly downloadService: DownloadService,
        private readonly tessadataService: TessadataService,
        public readonly notifService: NotifService,
        private readonly location: Location,
        private readonly sidePanelService: SidePanelService,
        readonly userStateService: UserStateService,
        private readonly workspaceItemsService: WorkspaceItemService,
        private readonly mapsAPILoader: MapsAPILoader,
        private readonly reportService: ReportService
    ) {
        this.filterFieldSearchFilter = (field: DatasetField) => {
            return field.name
                .toLowerCase()
                .includes(this.filterFieldSearchString.toLowerCase());
        };

        // Projection search
        this.projectionDatasetSearchFilter = (dataset: Dataset) => {
            return dataset.name
                .toLowerCase()
                .includes(this.projectionDatasetSearchString.toLowerCase());
        };

        this.projectionFieldSearchFilter = (field: DatasetField) => {
            return field.name
                .toLowerCase()
                .includes(this.projectionFieldSearchString.toLowerCase());
        };

        // workspaceItem search
        this.workspaceItemSearchFilter = (workspaceItem: WorkspaceItem) => {
            return workspaceItem.name
                .toLowerCase()
                .includes(this.workspaceItemSearchString.toLowerCase());
        };
    }

    analyticsPanel: ComponentRef<SidePanelComponent>;
    notificationsPanel: ComponentRef<SidePanelComponent>;
    updateDatapointPanel: ComponentRef<SidePanelComponent>;
    clusterPanel: ComponentRef<SidePanelComponent>;
    addressLocationPanel: ComponentRef<SidePanelComponent>;
    crises24DatapointsAgTablePanel: ComponentRef<SidePanelComponent>;
    reportPanel: ComponentRef<SidePanelComponent>;

    @ViewChild("mapComponent") mapComponent: MapComponent;
    @ViewChild("groupsPanel") groupsPanel: GroupPanelComponent;
    @ViewChild("pagePanel") pagePanel: PanelComponent;
    @ViewChild("tableComponent") tableComponent: AgDatapointTableComponent;
    @ViewChild("locationProfilePanel")
    locationProfilePanel: DatapointsProfilePanelComponent;
    @ViewChild("addressLocationProfile")
    addressLocationProfile: AddressLocationProfileComponent;
    @ViewChild("filterMenu") filterMenu: any;
    @ViewChild("datapointsOverlaysComponent")
    datapointsOverlaysComponent: DatapointsOverlaysComponent;
    @ViewChild("workspaceItemDialog", { static: true })
    workspaceIemDialog: WorkspaceItemDialogComponent;
    @ViewChild("thematicMapToggleElement") thematicMapToggleElement: any;
    // @ViewChild('datasetMenuAll', { static: false }) datasetMenuAllCheckBox: MatCheckbox;
    // @ViewChild('externalMenuAll', { static: false }) externalMenuAllCheckBox: MatCheckbox;
    // @ViewChild('nriMenuAll', { static: false }) nriMenuAllCheckBox: MatCheckbox;
    @ViewChild("overlaysFilter")
    overlaysFilterComponent: DatapointsOverlayFilterComponent;
    @ViewChild("uploadComponent") uploadComponent: DraftsUploadComponent;
    @ViewChild('switchDatasetMenu') switchDatasetMenu: MatMenu;

    analyticsComponentEnabled = isEnabled(Functionalities.ANALYTICS);
    groupsComponentEnabled = isEnabled(Functionalities.GROUPS);
    overlayComponentEnabled = isEnabled(Functionalities.OVERLAYS);
    thematicMapFeatureEnabled = isEnabled(Functionalities.THEMATICMAP);
    draftsEnabled = isEnabled(Functionalities.DRAFTS);
    eventNotificationsEnabled = isEnabled(Functionalities.NOTIFICATIONS);
    downloadDatapointsEnabled = isEnabled(Functionalities.DOWNLOAD_DATAPOINTS);
    alterDatapointsEnabled = isEnabled(Functionalities.ALTER_DATAPOINTS);
    tessadataEnabled = isEnabled(Functionalities.TESSADATA_DATA);

    account: Account;
    dataset: Dataset;
    linkedAccountOverlays: Dataset[] = [];
    linkedAccountDatasets: Dataset[] = [];
    accountOverlays: Dataset[];
    accountExternalOverlays: Dataset[];
    fieldsByIdsAndDataset: Map<string, Map<string, DatasetField>> = new Map();
    filterAccountDatasets: Dataset[];
    tableVilterAccountDatasets: Dataset[];

    filterAccountOverlays = [];
    sortedFilterAccountOverlays: any = [];
    parentOverlay: any;

    isMapView = false;
    shouldHaveBackgroundColor = false;

    filter: DatapointFilter;
    projection: DatapointProjection;
    renderingOptions: RenderingOptions;
    clusteringSettings: ClusteringRequest;
    isClusteringActivated: boolean;

    filterBarItems: FilterBarItem[] = []; // it can contain not populated fields yet, and they have suggestions
    datapointFilterObject: DatapointFilterObject;
    mapReady = false;
    groupsReady = false;
    limit: number = LIMIT;
    sort: DatapointSort = Object.assign({}, DEFAULT_SORT);
    skip = 0;
    mapDatapointInfo?: MapDetails;
    selectedLocationClone: Point; // we make a clone to be able to undo the changes
    overlaysFilterDistance = {};

    mapInteractionMode: MapInteractionMode;
    isThematicMapActive = false;
    activeThematicMapConfig: ThematicMapConfig;
    datasetFloatingFields: DatasetField[] = [];
    datapointsCount = 0;
    floatingFieldsValuesByFields: Map<DatasetField, number>;

    legends: LegendInfo[] = [];
    externalDataMenuItems: TessadataDatasetsByCountry[] = [];
    externalPOIMenuItems: TessadataDataset[] = [];
    externalPOIDatasetsStructures: TessadataDatasetStructure[] = [];

    private readonly subscriptions: Subscription = new Subscription();

    // projection and filter search strings for input
    projectionFieldSearchString = "";
    projectionDatasetSearchString = "";
    filterFieldSearchString = "";
    filterStatisticValuesString = "";
    workspaceItemSearchString = "";

    filterFieldSearchFilter: (field: DatasetField) => boolean;
    projectionFieldSearchFilter: (field: DatasetField) => boolean;
    projectionDatasetSearchFilter: (dataset: Dataset) => boolean;
    workspaceItemSearchFilter: (workspaceItem: WorkspaceItem) => boolean;

    selectedOverlay!: Dataset;
    datasetIsDisabled = false;

    notifPanelIsOpen = false;
    searchIsActive = false;

    searchResultLocation: MapLocation;
    defaultDistanceUnit: DistanceUnit = DistanceUnit.KM;

    workspaceItemsForTableProjection: WorkspaceItem[] = [];
    workspaceItemsForFilter: WorkspaceItem[] = [];
    workspaceItemsForFormulas: WorkspaceItem[] = [];

    tableColumnsExcludedOverlayGroups: number[];

    hasWritePermission = false;
    moveLocationModeEnabled = false; // when we start to move a datapoint on the map
    displayCoordinatesConfirmation = false; // when we move the point on the map

    tessadataFieldsByDataset = {}; // {externalFields: [], nriFields: []}
    tessadataFieldsForview = {}; /*copy tessadata obj for View feature*/
    tessadataGroups: any;
    thematicMapFormula: WorkspaceItem;
    thematicMapMetric: DatasetField;
    thematicMapTotal: number;

    thematicMapGrouping: string = Grouping.SUM;
    selectedDatapointForEdit: Datapoint;
    private openThematicMapOnPageLoad: boolean;
    private dataIsReadySubject = new Subject<any>();
    private accountDataIsReady: boolean;

    customLegendsEnabled: boolean = false;
    legendLimitForm: UntypedFormGroup;
    statisticsFiltersFetched = false;
    formulasMenuOpen: boolean = false;
    workspaceItemsProjectionMenuOpen: boolean = false;


    trackByIndexFunction = (index: number): number => index;

    private static computeImageOverlayUrl(
        url: string,
        zoom: number,
        x: number,
        y: number,
        apiKey?: string,
        id?: string,
        offset?: string
    ) {
        return url
            .replace("overlayId", id)
            .replace("zoom", zoom.toString())
            .replace("coordX", x.toString())
            .replace("coordY", y.toString())
            .replace("offset", offset)
            .replace("apiKey", apiKey);
    }

    private static setFieldStatistics(
        colorizationField: DatasetField,
        renderingOptions: RenderingOptions,
        statistics: DatasetFieldStatistics
    ) {
        // we need to not allow falsy values exists into the statistics values array - will mess the legends colorization
        if (statistics.values) {
            statistics.values = statistics.values.filter((item) =>
                Boolean(item)
            );
        }
        switch (DatasetUtils.getDatasetFieldType(colorizationField)) {
            case DatasetFieldType.NUMBER:
                renderingOptions.converterOptions.type =
                    DatapointConverterType.NUMBER_FIELD;
                renderingOptions.converterOptions.maxNumber =
                    statistics.maxValue;
                renderingOptions.converterOptions.minNumber =
                    statistics.minValue;
                break;
            case DatasetFieldType.TEXT:
                renderingOptions.converterOptions.type =
                    DatapointConverterType.TEXT_FIELD;
                renderingOptions.converterOptions.values = statistics.values;
                break;
            case DatasetFieldType.DATE_TIME:
                renderingOptions.converterOptions.type =
                    DatapointConverterType.DATE_FIELD;
                renderingOptions.converterOptions.maxDate = statistics.maxValue;
                renderingOptions.converterOptions.minDate = statistics.minValue;
                break;
        }
    }

    private static setFieldStatisticsForInterval(
        colorizationField: DatasetField,
        renderingOptions: RenderingOptions
    ) {
        // min, max are 0 because they are not needed for interval scale type
        switch (DatasetUtils.getDatasetFieldType(colorizationField)) {
            case DatasetFieldType.NUMBER:
                renderingOptions.converterOptions.type =
                    DatapointConverterType.NUMBER_FIELD;
                renderingOptions.converterOptions.maxNumber = 0;
                renderingOptions.converterOptions.minNumber = 0;
                break;
            case DatasetFieldType.TEXT:
                renderingOptions.converterOptions.type =
                    DatapointConverterType.TEXT_FIELD;
                renderingOptions.converterOptions.values = [];
                break;
            case DatasetFieldType.DATE_TIME:
                renderingOptions.converterOptions.type =
                    DatapointConverterType.DATE_FIELD;
                renderingOptions.converterOptions.maxDate = 0;
                renderingOptions.converterOptions.minDate = 0;
                break;
        }
    }

    ngOnInit(): void {
        // implementing custom legends -
        this.legendLimitForm = this.formBuilder.group({
            forGreen: ["", Validators.required],
            forYellow: ["", Validators.required],
            forOrange: ["", Validators.required],
        });

        this.datapointsFilterService.clearFilterBar();
        this.datapointsPageStateService.reset();
        this.mapThematicOverlayService.reset();

        let datasetId = this.route.snapshot.paramMap.get("datasetId");
        let accountId = +this.route.snapshot.paramMap.get("accountId");
        let viewMode = this.route.snapshot.paramMap.get(
            Constants.VIEW_MODE_PARAM_NAME
        );
        this.isAccountAdmin = this.userStateService.isAccountAdmin(accountId);

        this.subscriptions.add(
            this.overlayService
                .getOverlayGroupsByTag(
                    OverlaysConstants.HIDE_GROUP_FROM_TABLE_COLUMNS_TAG
                )
                .subscribe(
                    (overlayGroups) =>
                    (this.tableColumnsExcludedOverlayGroups =
                        overlayGroups.map((group) => group.id))
                )
        );
        this.subscriptions.add(
            this.clusteringService.onClusterSelected().subscribe((cluster) => {
                if (this.clusterPanel) {
                    this.clusterPanel.instance.showPanel();
                } else {
                    this.sidePanelService.setRootViewContainerRef(
                        this.viewContainerRef
                    );
                    this.clusterPanel =
                        this.sidePanelService.open<DatapointsClusterComponentType>(
                            SidePanels.CLUSTER_PANEL,
                            {
                                width: 459,
                                id: "clusterPanel",
                                panelTitle: "Cluster",
                                panelIcon: "fa-atom",
                            },
                            {
                                dataset: this.dataset,
                                filter: this.filter,
                                initSettings: this.clusteringSettings,
                            }
                        );

                    this.datapointsServiceState.onDataPointSelected$.subscribe(
                        (clusterPoint: ClusterDatapoint) =>
                            this.openLocationProfile(clusterPoint)
                    );
                    this.datapointsServiceState.onApplySettings$.subscribe(
                        (settings: ClusteringRequest) => {
                            this.clusteringSettings = settings;
                            if (!this.clusteringSettings.openSidePanel)
                                this.closeClusterPanel();
                        }
                    );

                    this.datapointsServiceState.onCancelClustering$.subscribe(
                        () => {
                            this.clusteringSettings = undefined;
                            this.closeClusterPanel();
                        }
                    );
                }
            })
        );

        this.subscriptions.add(
            this.clusteringService
                .isClusteringActivatedEmitter()
                .subscribe((value) => {
                    this.isClusteringActivated = value;
                })
        );

        this.subscriptions.add(
            this.tessadataService
                .fetchDatasets(TessadataDatasetType.LOCATION_DATA, accountId)
                .subscribe((datasets) => {
                    const temp_hide_datasets_ids = ["25", "26"];
                    datasets.forEach((element) => {
                        element.datasets = element.datasets.filter(
                            (child_element) =>
                                !temp_hide_datasets_ids.includes(
                                    child_element.datasetId
                                )
                        );
                    });
                    this.externalDataMenuItems = datasets;

                    this.externalDatadataSource.data = datasets.map(item => ({
                        name: item.countryName,
                        children: item.datasets.map(dataset => { return {name: dataset.datasetLabel, datasetId: dataset.datasetId} }),
                    }));
                })
        );

        this.subscriptions.add(
            this.tessadataService
                .fetchDatasets(TessadataDatasetType.POINT_OF_INTEREST, accountId)
                .subscribe((datasets) => {
                    this.externalPOIMenuItems = [].concat.apply(
                        [],
                        datasets.map((ds) => ds.datasets)
                    );

                    var datasetsIds = this.externalPOIMenuItems.map(
                        (ds) => ds.datasetId
                    );

                    this.tessadataService
                        .fetchDatasetsStructures(datasetId,datasetsIds)
                        .subscribe(
                            (structures) => {
                                structures.forEach((structure) =>
                                    structure.fields.push({
                                        fieldId: "distance",
                                        fieldLabel: "Distance",
                                        fieldType: "NUMBER",
                                    })
                                );
                                this.externalPOIDatasetsStructures = structures;
                            },
                            (error) =>
                                console.log("Cannot fetch tessadata structures")
                        );
                })
        );

        this.subscriptions.add(
            this.datapointsPageStateService
                .onMapInteractionChange()
                .subscribe((newStatus) => {
                    this.mapInteractionMode = newStatus.mode;
                    this.isThematicMapActive =
                        newStatus.mode ===
                        MapInteractionMode.THEMATIC_MAP_ACTIVE;
                })
        );
        this.datapointsPageStateService.activeAccountId = accountId;
        this.hasWritePermission =
            this.userStateService.hasWriteAccess(accountId);

        this.mapsAPILoader.load().then(() => {
            this.subscriptions.add(
                this.accountService
                    .findAccount(accountId)
                    .subscribe((account) => {
                        this.account = account;
                        this.datapointsPageStateService.activeAccount = account;
                        this.account.datasets.forEach((dataset) => {
                            let isLocations =
                                dataset.geometryType ===
                                DatasetGeometryType.POINT;
                            if (dataset.id === datasetId) {
                                this.isMapView =
                                    (!viewMode ||
                                        viewMode.toUpperCase() ===
                                        DatapointsViewMode.MAP) &&
                                    dataset.geometryType !==
                                    DatasetGeometryType.NONE;
                                if (!this.isMapView) {
                                    this.fallBackToTableView(
                                        dataset.id,
                                        account.id
                                    );
                                }
                            }

                            if (isLocations) {
                                this.tessadataFieldsByDataset[dataset.id] = {
                                    externalFields: [],
                                    nriFields: [],
                                    tensorflightFields: [],
                                    munichreFields: []
                                };
                                this.tessadataFieldsForview[dataset.id] = {
                                    externalFields: [],
                                    nriFields: [],
                                    tensorflightFields: [],
                                    munichreFields: []
                                };
                            }

                            dataset.fields.forEach((field) => {
                                field.isDisplayedInFilter = true;
                                field.isDisplayedInProjection = true;
                                if (isLocations && field.isGenerated) {
                                    if (
                                        field.scope ===
                                        DatasetFieldScope.EXTERNAL
                                    ) {
                                        this.tessadataFieldsByDataset[
                                            dataset.id
                                        ].externalFields.push(field);
                                        this.tessadataFieldsForview[
                                            dataset.id
                                        ].externalFields.push(field);
                                    } else if (
                                        field.scope ===
                                        DatasetFieldScope.TENSORFLIGHT
                                    ) {
                                        this.tessadataFieldsByDataset[
                                            dataset.id
                                        ].tensorflightFields.push(field);
                                        this.tessadataFieldsForview[
                                            dataset.id
                                        ].tensorflightFields.push(field);
                                    } else if (
                                        field.scope ===
                                        DatasetFieldScope.MUNICHRE
                                    ) {
                                        this.tessadataFieldsByDataset[
                                            dataset.id
                                        ].munichreFields.push(field);
                                        this.tessadataFieldsForview[
                                            dataset.id
                                        ].munichreFields.push(field);
                                    } else if (
                                        field.scope === DatasetFieldScope.NRI
                                    ) {
                                        this.tessadataFieldsByDataset[
                                            dataset.id
                                        ].nriFields.push(field);
                                        this.tessadataFieldsForview[
                                            dataset.id
                                        ].nriFields.push(field);
                                    }
                                }
                            });

                            if (isLocations) {
                                this.tessadataFieldsByDataset[
                                    dataset.id
                                ].externalFields = this.sortFields(
                                    this.tessadataFieldsByDataset[dataset.id]
                                        .externalFields
                                );
                                this.tessadataFieldsByDataset[
                                    dataset.id
                                ].tensorflightFields = this.sortFields(
                                    this.tessadataFieldsByDataset[dataset.id]
                                        .tensorflightFields
                                );
                                this.tessadataFieldsByDataset[
                                    dataset.id
                                ].nriFields = this.sortFields(
                                    this.tessadataFieldsByDataset[dataset.id]
                                        .nriFields
                                );
                                this.tessadataFieldsForview[
                                    dataset.id
                                ].externalFields = this.sortFields(
                                    this.tessadataFieldsForview[dataset.id]
                                        .externalFields
                                );
                                this.tessadataFieldsForview[
                                    dataset.id
                                ].tensorflightFields = this.sortFields(
                                    this.tessadataFieldsForview[dataset.id]
                                        .tensorflightFields
                                );
                                this.tessadataFieldsForview[
                                    dataset.id
                                ].nriFields = this.sortFields(
                                    this.tessadataFieldsForview[dataset.id]
                                        .nriFields
                                );
                            }

                            // Sort External Fields By Alphabetical Name
                            if (
                                this.tessadataFieldsByDataset[dataset.id] &&
                                (this.tessadataFieldsByDataset[dataset.id]
                                    .externalFields.length > 0 || this.tessadataFieldsByDataset[dataset.id]
                                    .tensorflightFields.length > 0 || this.tessadataFieldsByDataset[dataset.id]
                                    .munichreFields.length > 0)
                            ) {
                                this.tessadataGroups = dataset.fields
                                    .filter(
                                        (item) =>
                                            [DatasetFieldScope.EXTERNAL, DatasetFieldScope.TENSORFLIGHT, DatasetFieldScope.MUNICHRE].includes(item.scope)
                                    )
                                    .reduce(
                                        (groups, item) => ({
                                            ...groups,
                                            [item.tags[0]]: [
                                                ...(groups[item.tags[0]] || []),
                                                item,
                                            ],
                                        }),
                                        []
                                    );

                                for (const key in this.tessadataGroups) {
                                    if (
                                        Object.prototype.hasOwnProperty.call(
                                            this.tessadataGroups,
                                            key
                                        )
                                    ) {
                                        this.tessadataGroups[key] =
                                            this.sortFields(
                                                this.tessadataGroups[key]
                                            );
                                    }
                                }
                            }

                            if (
                                this.tessadataFieldsByDataset[dataset.id] &&
                                this.tessadataFieldsByDataset[dataset.id]
                                    .nriFields.length > 0
                            ) {
                                this.tessadataFieldsByDataset[
                                    dataset.id
                                ].nriFields = this.groupByNriFields(
                                    this.tessadataFieldsByDataset[dataset.id]
                                        .nriFields
                                );
                            }
                            dataset.isDisplayedInFilter = true;
                            dataset.isDisplayedInProjection = true;
                            this.datapointsPageStateService.storeDataset(
                                dataset
                            );
                            this.fieldsByIdsAndDataset.set(
                                dataset.id,
                                new Map<string, DatasetField>(
                                    dataset.fields.map((field) => [
                                        field.id,
                                        field,
                                    ])
                                )
                            );
                        });
                        this.datapointsPageStateService.accountDatasets =
                            this.account.datasets;
                        let selectedDataset =
                            this.datapointsPageStateService.getDataset(
                                datasetId
                            ) ||
                            this.datapointsPageStateService.getDataset(
                                account.datasets[0].id
                            ); // we shouldn't work on this
                        this.dataset = ObjectUtils.clone(selectedDataset);
                        this.datapointsPageStateService.setActiveDataset(
                            selectedDataset
                        ); // we save a different object in the state

                        this.sort.datasetID = this.dataset.id;
                        this.initContext(this.dataset);
                        this.datapointFilterObject =
                            this.constructFilterObject();
                        this.datasetFloatingFields = this.dataset.fields.filter(
                            (field) => field.isFloating
                        );
                        this.thematicMapMetric = this.datasetFloatingFields[0];
                        if (this.thematicMapMetric) {
                            this.thematicMapMetric.selectedForTM = true;
                        } else {
                            console.log(
                                "The dataset does not have any floating field"
                            );
                        }
                        // Get assigned Tessadata and rest all overlay to account and merged responce
                        this.subscriptions.add(
                            forkJoin(
                                this.overlayService.getAllAccountOverlays(
                                    this.account.id
                                ),
                                this.overlayService.getAccountTessadataOverlays(
                                    this.account.id
                                )
                            ).subscribe(
                                ([
                                    accountGlobalOverlay,
                                    accountExternalOverlay,
                                ]) => {
                                    // Added id name and groupid as to match Global Oject
                                    accountExternalOverlay.map((item) => {
                                        item.id = item.datasetId;
                                        item.name = item.datasetLabel;
                                        item.overlayGroupId =
                                            item.externalGroupId;
                                    });
                                    const overlays = [
                                        ...accountGlobalOverlay,
                                        ...accountExternalOverlay,
                                    ];
                                    this.accountOverlays = overlays;
                                    this.datapointsPageStateService.accountOverlays =
                                        overlays;
                                    overlays.forEach((overlay) => {
                                        overlay.isDisplayedInFilter = true;
                                        overlay.isDisplayedInProjection = true;
                                        this.fieldsByIdsAndDataset.set(
                                            overlay.id,
                                            new Map<string, DatasetField>(
                                                overlay.fields.map((field) => [
                                                    field.id,
                                                    field,
                                                ])
                                            )
                                        );
                                        this.datapointsPageStateService.storeDataset(
                                            overlay
                                        );
                                        overlay.fields.forEach((field) => {
                                            field.isDisplayedInFilter = true;
                                            field.isDisplayedInProjection =
                                                true;
                                        });
                                    });
                                    this.dataIsReadySubject.next({
                                        dataType: "account",
                                    });
                                }
                            )
                        );

                        this.subscriptions.add(
                            this.linkDatasetService
                                .getLinkedAccountApplications(this.dataset.id)
                                .subscribe((linkedDatasets) => {
                                    this.linkedAccountDatasets = [
                                        selectedDataset,
                                        ...linkedDatasets,
                                    ];
                                    this.linkedAccountDatasets[0].fields.forEach(
                                        (field) => {
                                            if (
                                                field.id === "created_on" ||
                                                field.id === "updated_on" ||
                                                field.id === "id"
                                            ) {
                                                field.isProjected = false;
                                            } else {
                                                field.isProjected = true;
                                            }
                                        }
                                    );
                                    this.datapointsPageStateService.setLinkedAccountDatasets(
                                        linkedDatasets
                                    );
                                    this.filterAccountDatasets =
                                        ObjectUtils.clone([selectedDataset]);
                                    this.tableVilterAccountDatasets =
                                        ObjectUtils.clone([selectedDataset]);
                                    if (this.filterAccountDatasets) {
                                        this.filterAccountDatasets.forEach(
                                            (dataset) => {
                                                let tempField =
                                                    dataset.fields[
                                                    dataset
                                                        .mainSummaryFieldIndex
                                                    ];
                                                dataset.fields =
                                                    this.sortFields(
                                                        dataset.fields
                                                    );
                                                if (
                                                    this
                                                        .datapointsPageStateService
                                                        .activeDatasetsOnMap
                                                        .length > 0
                                                ) {
                                                    this.datapointsPageStateService.activeDatasetsOnMap[0].mainSummaryFieldIndex =
                                                        dataset.fields.indexOf(
                                                            tempField
                                                        );
                                                }
                                                dataset.mainSummaryFieldIndex =
                                                    dataset.fields.indexOf(
                                                        tempField
                                                    );
                                            }
                                        );
                                    }
                                    if (this.linkedAccountDatasets) {
                                        this.linkedAccountDatasets.forEach(
                                            (dataset) => {
                                                dataset.fields =
                                                    this.sortFields(
                                                        dataset.fields
                                                    );
                                            }
                                        );
                                    }
                                    this.datapointsPageStateService.setFilterAccountDatasets(
                                        this.filterAccountDatasets
                                    );
                                    this.subscriptions.add(
                                        this.linkDatasetService
                                            .linkedGroupOverlays(
                                                this.dataset.id
                                            )
                                            .subscribe((res) => {
                                                if (res) {
                                                    const information =
                                                        res.filter(
                                                            (element) =>
                                                                element.group.name.toLowerCase() ==
                                                                "information"
                                                        );
                                                    res.forEach((element) => {
                                                        if (
                                                            element.children
                                                                .length
                                                        ) {
                                                            element.children.sort(
                                                                (
                                                                    item1,
                                                                    item2
                                                                ) => {
                                                                    if (
                                                                        item1 &&
                                                                        item1
                                                                            .group
                                                                            .name &&
                                                                        item2 &&
                                                                        item2
                                                                            .group
                                                                            .name
                                                                    ) {
                                                                        return item1.group.name
                                                                            .trim()
                                                                            .toLowerCase()
                                                                            .localeCompare(
                                                                                item2.group.name
                                                                                    .trim()
                                                                                    .toLowerCase()
                                                                            );
                                                                    }
                                                                }
                                                            );
                                                        }
                                                    });
                                                    res.sort((a, b) =>
                                                        a.group.name >
                                                            b.group.name
                                                            ? -1
                                                            : 1
                                                    );
                                                    let index = res.findIndex(
                                                        (group) =>
                                                            group.group.name.toLowerCase() ===
                                                            "information"
                                                    );
                                                    if (index !== -1) {
                                                        res.splice(index, 1);
                                                    }
                                                    this.filterAccountOverlays =
                                                        res;

                                                    this.isAnalyticsDisabled = false;

                                                    // Hide Private Overlays - if exists - for a not superadmin user until BE code for this will be implemented
                                                    if (
                                                        !this.userStateService
                                                            .isSuperadmin
                                                    ) {
                                                        let index =
                                                            this.filterAccountOverlays.findIndex(
                                                                (group) =>
                                                                    group.group
                                                                        .name ===
                                                                    "Private Overlays"
                                                            );
                                                        if (index !== -1) {
                                                            this.filterAccountOverlays.splice(
                                                                index,
                                                                1
                                                            );
                                                        }
                                                    }
                                                    this.filterAccountOverlays =
                                                        !isUndefined(
                                                            information
                                                        )
                                                            ? [
                                                                ...this
                                                                    .filterAccountOverlays,
                                                                ...information,
                                                            ]
                                                            : this
                                                                .filterAccountOverlays;

                                                    this.filterAccountOverlays =
                                                        this.prepareFilterAcccountOverlays(
                                                            this
                                                                .filterAccountOverlays
                                                        );

                                                    const climateFilterAccountOverlays = this.prepareClimateOverlays();

                                                    this.sortedFilterAccountOverlays = [];
                                                    if ([MaptycsApplication.CLAIMS, MaptycsApplication.POLICIES].includes(this.dataset.application)) {
                                                        this.dataSource.data = [
                                                            ...this.datapointObject.prepareDataset(this.filterAccountDatasets, this.filterAccountDatasets[0])
                                                        ];
                                                        this.filterAttributes = {
                                                            A: this.tableVilterAccountDatasets,
                                                            B: [], C: [], D: [], E: []
                                                        };
                                                    } else {
                                                        const overlays = [
                                                            ...this.datapointObject.prepareTesadata(this.tessadataGroups, this.filterAccountDatasets[0]),
                                                            ...this.datapointObject.prepareNRIFields(
                                                                this
                                                                    .filterAccountDatasets,
                                                                this
                                                                    .tessadataFieldsByDataset,
                                                                this
                                                                    .tessadataEnabled,
                                                                this.filterAccountDatasets[0]),
                                                            ...this.datapointObject.prepareClimateData(climateFilterAccountOverlays, false)
                                                        ];

                                                        this.dataSource.data = [...this.datapointObject.prepareDataset(this.filterAccountDatasets, this.filterAccountDatasets[0]),
                                                        ...sortArrayByName(overlays)];

                                                        this.filterAttributes = {
                                                            A: this
                                                                .tableVilterAccountDatasets,
                                                            B: this.tessadataGroups,
                                                            C: [
                                                                this
                                                                    .tessadataEnabled,
                                                                this
                                                                    .tessadataFieldsByDataset,
                                                            ],
                                                            E: [] /*F: this.overlayComponentEnabled*/,
                                                        };
                                                    }
                                                    // this.dataSource.data = this.datapointObject.filterAndDelete(this.dataSource.data);
                                                }
                                                this.isFilterAttributesPrepared = true;
                                            })
                                    );
                                    this.subscriptions.add(
                                        this.linkDatasetService
                                            .getLinkedOverlays(this.dataset.id)
                                            .subscribe((linkedOverlays) => {
                                                this.linkedAccountOverlays =
                                                    linkedOverlays;
                                                this.datapointsPageStateService.setLinkedAccountOverlays(
                                                    linkedOverlays
                                                );
                                                this.initializeDatasetFilterSelectedState();
                                            })
                                    );
                                })
                        );

                        this.fetchWorkspaceItems();
                    })
            );
        });

        this.subscriptions.add(
            this.datapointsFilterService
                .onFilterChange()
                .pipe(debounceTime(200))
                .subscribe((newFilter) => {
                    this.filter = newFilter;
                    if (
                        this.filter.geometryFilter &&
                        this.filter.geometryFilter.unionShapes &&
                        this.filter.geometryFilter.unionShapes.length &&
                        !this.datapointsOverlaysComponent.datasetChecked
                    ) {
                        this.datapointsOverlaysComponent.datasetChecked = true;
                    }

                    this.fetchDatapoints();
                    if (this.datasetIsDisabled) {
                        this.disableOverlay(this.dataset.id);
                    }
                    if (this.isThematicMapActive) {
                        this.mapThematicOverlayService.setActive(
                            this.mapThematicOverlayService.currentConfig
                        );
                    }
                })
        );

        this.subscriptions.add(
            this.datapointsFilterService
                .onFilterBarItemsChange()
                .subscribe((filterBarItems) => {
                    this.filterBarItems = filterBarItems;
                    // this.applyFilters();
                })
        );

        let previousOverlay: string | null = null;

        this.subscriptions.add(
            this.mapThematicOverlayService
                .getActive()
                .subscribe((overlaySettings) => {
                    // here we get an empty element if we have to disable thematic map. this is not ideal
                    this.activeThematicMapConfig = overlaySettings;
                    this.removeThematicMapLegends();
                    if (previousOverlay) {
                        this.disableOverlay(previousOverlay);
                    }
                    if (!this.activeThematicMapConfig.dataset) {
                        return; // we have an empty object. we have to remove everything
                    }

                    let formula = this.thematicMapFormula
                        ? JSON.parse(this.thematicMapFormula.data).formula
                        : null;
                    let metricId = this.thematicMapMetric
                        ? this.thematicMapMetric.id
                        : null;
                    let tempFilter = ObjectUtils.clone(
                        this.filter
                    );
                    if (this.isThematicMapActive && this.mapThematicOverlayService.currentConfig.parentDatasetId !== null) {
                        const field: DatapointFilterField[] = [{id: 'link_' + this.mapThematicOverlayService.currentConfig.parentDatasetId, maxDateValue: null, minDateValue: null,maxNumberValue: null, minNumberValue: 0, searchValue: null, textValues:[this.mapThematicOverlayService.currentConfig.geometryID.geometryID], distanceUnit: null}];
                        tempFilter.fields = tempFilter.fields.length > 0 ? [...tempFilter.fields, ...field] : field;
                    }
                    let filter: DatapointFilter = JSON.parse(
                        JSON.stringify(tempFilter)
                    );
                    if (overlaySettings.filter) {
                        filter.links.push(overlaySettings.filter);
                    }

                    if (metricId === null && formula === null) {
                        this.notifService.error(
                            "Thematic Map View must have a selected Value field or formula"
                        );
                        return;
                    }

                    if (this.thematicMapGrouping === "") {
                        this.notifService.error(
                            "Please select a Grouping option for a selected Value field or formula"
                        );
                        return;
                    }

                    let thematicMapIntervals;

                    this.subscriptions.add(
                        this.datapointAggregateService
                            .getDatapointsAggregateMinMaxForLinkedDatasets(
                                this.dataset.id,
                                this.activeThematicMapConfig.dataset.id,
                                this.thematicMapGrouping,
                                metricId,
                                formula,
                                filter
                            )
                            .subscribe((aggregate) => {
                                thematicMapIntervals =
                                    this.mapThematicOverlayService.getThematicMapIntervals(
                                        aggregate.min,
                                        aggregate.max,
                                        aggregate.total
                                    );
                                this.addLegend(
                                    this.activeThematicMapConfig.dataset.id,
                                    thematicMapIntervals,
                                    true,
                                    "Thematic Map"
                                );
                                this.thematicMapTotal = aggregate.total;

                                let converterType = this.thematicMapFormula
                                    ? DatapointConverterType.FORMULA
                                    : thematicMapOverlayRenderingOptions
                                        .converterOptions.type;
                                let accountApplicationFieldId: any = null;
                                if (this.thematicMapMetric) {
                                    accountApplicationFieldId =
                                        this.thematicMapMetric.id;
                                } else if (this.thematicMapFormula) {
                                    let currentLevelDataset =
                                        this.mapThematicOverlayService
                                            .currentConfig.dataset;
                                    let currentLevelDatasetThematicField =
                                        currentLevelDataset.fields.find(
                                            (field) =>
                                                field.id ===
                                                currentLevelDataset
                                                    .thematicMapSettings
                                                    .mainThematicFieldId
                                        );
                                    let breakdownFields =
                                        this.getBreakdownFields(
                                            currentLevelDataset,
                                            currentLevelDatasetThematicField
                                        );
                                    const field = !isUndefined(
                                        breakdownFields[
                                            breakdownFields.length - 1
                                        ].get(this.dataset.id)
                                    )
                                        ? breakdownFields[
                                            breakdownFields.length - 1
                                        ].get(this.dataset.id)[0].id
                                        : null;
                                    accountApplicationFieldId = field;
                                }

                                let event = {
                                    active: this.isThematicMapActive,
                                    datasetID: overlaySettings.dataset.id,
                                    renderingOptions: {
                                        converterOptions: {
                                            ...thematicMapOverlayRenderingOptions.converterOptions,
                                            accountApplicationGroups:
                                                this.datapointsPageStateService
                                                    .activeGroups,
                                            accountApplicationDatasetId:
                                                this.dataset.id,
                                            datasetID:
                                                overlaySettings.dataset.id,
                                            accountApplicationFieldId:
                                                accountApplicationFieldId,
                                            formula: formula,
                                            grouping:
                                                Grouping[
                                                this.thematicMapGrouping
                                                ],
                                            type: converterType,
                                        },
                                        datasetStylingOptions: {
                                            ...overlaySettings.stylingOptions,
                                            intervalOptions:
                                                thematicMapIntervals,
                                        },
                                        visualizationOptions:
                                            thematicMapOverlayRenderingOptions.visualizationOptions,
                                    },
                                    filter: overlaySettings.filter,
                                };
                                this.onToggleOverlay(event);
                                if (this.isThematicMapActive) {
                                    this.getFloatingFieldsValues();
                                }
                                previousOverlay = overlaySettings.dataset.id;
                                if (
                                    this.thematicMapGrouping ===
                                    this.groupingEnum.COUNT
                                ) {
                                    this.thematicMapMetric = undefined;
                                }
                            })
                    );
                    this.updateAggregateChartPanel();
                })
        );

        this.openThematicMapOnPageLoad = history.state.openThematicMap;
        this.dataIsReadySubject.subscribe((a) => {
            if (a.dataType === "account") {
                this.accountDataIsReady = true;
            }
            if (a.dataType === "groups") {
                this.groupsReady = true;
            }
            if (this.openThematicMapOnPageLoad) {
                if (this.accountDataIsReady && this.groupsReady) {
                    this.toggleThematicMap(true);
                    this.toggleAggregateChartPanel();
                }
            }
        });

        this.getReports(datasetId, accountId);

    }

    prepareFilterAcccountOverlays(overlays) {
        overlays.forEach((element, key) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
                return;
            } else if (element.children.length > 0) {
                this.recursiveFilterAccountOverlay(element);
            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            }
        });
        return overlays;
    }

    recursiveFilterAccountOverlay(element) {
        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (
                    sub_element.children.length <= 0 &&
                    sub_element.overlays.length <= 0
                ) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(
                        (params) => !groupIds.includes(params.group.id)
                    );
                    this.recursiveFilterAccountOverlay(sub_element);
                    return;
                }
            });

            if (groupIds.length) {
                element.children = element.children.filter(
                    (params) => !groupIds.includes(params.group.id)
                );
                this.recursiveFilterAccountOverlay(this.parentOverlay);
            }
        }
    }

    prepareViewFilterAccountOverlays(overlays, emitUpdate: boolean = true) {
        let groupIds = [];
        // overlays = overlays.filter((overlay) => overlay.group.name !== 'Climate');
        overlays.forEach((element, key) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
                return;
            } else if (element.children.length > 0) {
                this.filterAccountOverlaysCallback(element);
            }

            if (!isUndefined(element.overlays) && element.overlays.length > 0) {
                const overlays = element.overlays.filter(
                    (overlay) => overlay.geoWorkspace == "ipcc"
                );
                element.overlays = overlays;
                if (element.overlays.length > 0) {
                    element.overlays.forEach((sub_element, sub_element_key) => {
                        const filterValue = sub_element.fields.filter(
                            (field_element) =>
                                field_element.displayInDropdowns == true
                        );
                        sub_element.fields = [];
                        if (filterValue.length) {
                            filterValue.forEach(element => {
                                element.isProjected = false;
                                this.addProjectionField(sub_element, element);
                            });
                            sub_element.fields = filterValue;
                        } else {
                            element.overlays.splice(sub_element_key);
                        }
                    });
                }
            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                groupIds.push(element.group.id);
            }
        });
        overlays = overlays.filter(overlay => !groupIds.includes(overlay.group.id));
        if (emitUpdate)
        {
            this.datapointFilterObject = this.constructFilterObject();
            this.datapointsFilterService.updateFilter();
        }
        return overlays;
    }

    filterAccountOverlaysCallback(element) {
        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (
                    sub_element.children.length <= 0 &&
                    sub_element.overlays.length <= 0
                ) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(
                        (params) => !groupIds.includes(params.group.id)
                    );
                    this.filterAccountOverlaysCallback(sub_element);
                    return;
                }

                if (sub_element.overlays.length) {
                    const overlays = sub_element.overlays.filter(
                        (overlay) => overlay.geoWorkspace == "ipcc"
                    );
                    sub_element.overlays = overlays;
                    if (sub_element.overlays.length > 0) {
                        sub_element.overlays.forEach((overlay, sub_element_key) => {
                            const filterValue = overlay.fields.filter(field_element => field_element.displayInDropdowns == true);
                            overlay.fields = [];
                            if (filterValue.length) {
                                filterValue.forEach(element => {
                                    element.isProjected = false;
                                    this.addProjectionField(overlay, element);
                                });
                                overlay.fields = filterValue;
                            } else {
                                sub_element.overlays.splice(sub_element_key);
                            }
                    });
                    }
                }

                if (
                    sub_element.children.length <= 0 &&
                    sub_element.overlays <= 0
                ) {
                    groupIds.push(sub_element.group.id);
                }
            });

            if (groupIds.length) {
                element.children = element.children.filter(
                    (params) => !groupIds.includes(params.group.id)
                );
                this.filterAccountOverlaysCallback(this.parentOverlay);
            }
        }
    }

    sortFields(fields: DatasetField[]) {
        fields.sort((item1, item2) => {
            if (item1 && item1.name && item2 && item2.name) {
                return item1.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(item2.name.trim().toLowerCase());
            }
        });
        return fields;
    }

    get datasetFieldScope() {
        return DatasetFieldScope;
    }

    selectThematicMapFormula(item: WorkspaceItem, $event: MatCheckboxChange) {
        this.mapThematicOverlayService.emitCustomizedLegends([]);
        this.customLegendsEnabled = false;
        this.legendLimitForm.reset();
        if ($event.checked) {
            this.thematicMapFormula = item;
            this.workspaceItemsForFormulas.forEach((f) => {
                f.selectedForTM = f.id === item.id;
            });
            this.thematicMapMetric = undefined;
            this.dataset.fields.forEach((f) => (f.selectedForTM = false));
        } else {
            this.thematicMapFormula = undefined;
        }
        if (this.isThematicMapActive) {
            this.mapThematicOverlayService.setActive(
                this.mapThematicOverlayService.currentConfig
            );
        }
    }

    confirmCoordinatesChange() {
        this.moveLocationModeEnabled = false;
        const request: UpdateDatapointRequest = {
            id: this.mapDatapointInfo.datapointID,
            fields: [],
            location: this.mapDatapointInfo.location,
            version: this.mapDatapointInfo.version,
        };
        this.datapointsService
            .updateDatapoint(
                this.dataset.id,
                this.mapDatapointInfo.datapointID,
                request
            )
            .subscribe((success) => {
                this.notifService.success("The datapoint location was updated");
                this.mapStateService.updateOverlay(
                    this.dataset.id,
                    this.generatePortfolioLayer(true)
                );
                this.selectedLocationClone = ObjectUtils.clone(
                    this.mapDatapointInfo.location
                );
            });
    }

    cancelCoordinatesChange() {
        this.moveLocationModeEnabled = false;
        this.mapDatapointInfo.location = ObjectUtils.clone(
            this.selectedLocationClone
        );
    }

    private addLegend(
        datasetId: string,
        intervalOptions: IntervalOption[],
        isThematicMapLegend: boolean,
        datasetName?: string,
        fieldLabel?: string,
        gradientDetails?: GradientDetails
    ) {
        this.removeLegend(datasetId);
        this.legends.push({
            datasetId: datasetId,
            intervals: intervalOptions,
            datasetName: datasetName,
            fieldLabel: fieldLabel,
            isForThematicMap: isThematicMapLegend,
            gradientDetails: gradientDetails,
        });
        if (!isThematicMapLegend) {
            this.shouldHaveBackgroundColor = true;
        }
    }

    fetchExternalData(dataset: TessadataDataset, event, refresh: boolean) {
        if (event) {
            let selectedDatapointIds =
                this.tableComponent.getSelectedDatapointIds();
            if (
                selectedDatapointIds.length > 0 ||
                this.tableComponent.selectAll
            ) {
                if (refresh === true) {
                    const dialogRef = this.dialog.open(DialogComponent, {
                        data: new DialogModel("Confirm Action", `Please confirm you want to fetch again all the data for the selected locations.`),
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if(result === true) {
                            this.tableComponent.fetchExternalData(
                                [dataset.datasetId],
                                refresh,
                                selectedDatapointIds
                            );
                        }
                        else {
                            // Do nothing. just close it.
                        }
                    });

                }
                else {
                    this.tableComponent.fetchExternalData(
                        [dataset.datasetId],
                        refresh,
                        selectedDatapointIds
                    );
                }
            }
         else {
            dataset.checked = false;
            this.notifService.error(
                "Please select one or more datapoints and try again"
            );
        }
    }
}


    openLocationProfile(datapoint: ClusterDatapoint) {
        this.mapDatapointInfo = {
            location: datapoint.location,
            version: datapoint.version,
            datapointID: datapoint.id,
            dataset: this.dataset,
            refresh: false,
        };
        this.locationProfilePanel.onExpandedClick(true);
    }

    removeLegend(datasetId: string) {
        let searchElement = this.legends.find(
            (legend) => legend.datasetId === datasetId
        );
        if (searchElement) {
            let index = this.legends.indexOf(searchElement);
            this.legends.splice(index, 1);
        }
        if (this.legends.length === 1 && this.legends[0].isForThematicMap) {
            this.shouldHaveBackgroundColor = false;
        }
    }

    private removeThematicMapLegends() {
        this.legends = this.legends.filter(
            (legend) => !legend.isForThematicMap
        );
    }

    constructFilterObject(): DatapointFilterObject {
        const { limit, skip, sort, projection, filter } = this;
        let filterObject: DatapointFilterObject = {
            projection,
            filter,
            limit,
            skip,
            sort,
        };
        return filterObject;
    }

    getDefaultFilterObject(): DatapointFilterObject {
        let projection = {
            datasetID: this.dataset.id,
            fields: this.dataset.fields
                .filter((field) => field.isSummary)
                .map((field) => field.id),
            links: [],
        };

        let filter = {
            datasetID: this.dataset.id,
            groups: this.getSelectedGroupsIds() || [],
            fields: [],
            links: [],
        };
        let sort = Object.assign({}, DEFAULT_SORT);

        sort.datasetID = this.dataset.id;
        let filterObject: DatapointFilterObject = {
            projection,
            filter,
            limit: LIMIT,
            skip: DEFAULT_SKIP,
            sort,
        };
        return filterObject;
    }

    onMapReady() {
        if (this.groupsReady) {
            let activeOverlays: Dataset[] = ObjectUtils.clone(
                this.datapointsPageStateService.activeDatasetsOnMap
            ); // JSON.parse(JSON.stringify(this.datapointsPageStateService.activeDatasetsOnMap));

            if (
                !activeOverlays.find(
                    (dataset) => dataset.id === this.dataset.id
                )
            ) {
                this.dataset.isSelected = false;
            }

            let overlaysRenderingOptions: Map<string, RenderingOptions> =
                this.datapointsOverlaysComponent.getOverlaysRenderingOptions();

            this.datapointsPageStateService.activeDatasetsOnMap = []
            activeOverlays.forEach((dataset, i ) => {
                let event: ActivateOverlayEvent = {
                    active: true,
                    datasetID: dataset.id,
                    renderingOptions: overlaysRenderingOptions.get(dataset.id),
                };
                // we have to remove the overlay from the active overlays list otherwise it will not be enabled since it appears active
                // let indexInActiveOverlays =
                //     this.datapointsPageStateService.activeDatasetsOnMap.indexOf(
                //         dataset
                //     );
                // this.datapointsPageStateService.activeDatasetsOnMap.splice(
                //     indexInActiveOverlays,
                //     1
                // );
                this.enableOverlay(dataset.id, event);
            });

            this.initializeShapesFromActiveFilter();
            this.initializeZoomFromActiveFilter();
        }
        this.mapReady = true;
    }

    private fetchWorkspaceItems() {
        let previousFormulaSelection = {};
        this.workspaceItemsForFormulas.forEach((item) => {
            previousFormulaSelection[item.id] = item.isProjected;
        });
        this.workspaceItemsForFormulas = [];
        this.workspaceItemsForTableProjection = [];
        this.workspaceItemsForFilter = [];

        this.subscriptions.add(
            this.workspaceItemsService
                .getAllWorkspaceItemsForUser(this.dataset.id)
                .subscribe((items) => {
                    items.forEach((item) => {
                        switch (item.type) {
                            case WorkspaceItemType.FILTER:
                                this.workspaceItemsForFilter.push(item);
                                break;
                            case WorkspaceItemType.TABLE_PROJECTION:
                                this.workspaceItemsForTableProjection.push(
                                    item
                                );
                                break;
                            case WorkspaceItemType.ANALYTICS:
                                break;
                            case WorkspaceItemType.FORMULA:
                                item.isProjected =
                                    previousFormulaSelection[item.id]; // we don't want to lose old projections
                                this.workspaceItemsForFormulas.push(item);
                                break;
                        }
                        if (
                            this.userStateService.isSuperadmin ||
                            this.userStateService.isAccountAdmin(
                                this.account.id
                            )
                        ) {
                            item.isDeletable = true;
                        } else {
                            if (item.access === WorkspaceItemAccess.MEMBER) {
                                item.isDeletable = true;
                            }
                        }
                    });
                    this.saveFilterDataSource.data = [
                        {
                            name: "Open",
                            children: this.workspaceItemsForFilter,
                        },
                    ];
                })
        );
    }

    private initializeShapesFromActiveFilter() {
        let geometryFilter =
            this.datapointsFilterService.getActiveFilter().geometryFilter;
        this.initializeShapesFromFilter(geometryFilter);
    }

    private initializeShapesFromFilter(geometryFilter) {
        if (geometryFilter && geometryFilter.unionShapes) {
            geometryFilter.unionShapes.forEach((shape) => {
                switch (shape.type) {
                    case Type.POLYGON:
                        let polygon = new google.maps.Polygon();
                        let paths: google.maps.LatLng[] = [];
                        let i = 0;
                        while (i < shape.coordinates.length) {
                            paths.push(
                                new google.maps.LatLng(
                                    shape.coordinates[i + 1],
                                    shape.coordinates[i]
                                )
                            );
                            i += 2;
                        }
                        polygon.setPath(paths);
                        this.mapStateService.insertShape(
                            polygon,
                            google.maps.drawing.OverlayType.POLYGON
                        );

                        break;
                    case Type.CIRCLE:
                        let circle = new google.maps.Circle();
                        circle.setCenter(
                            new google.maps.LatLng(
                                shape.center.y,
                                shape.center.x
                            )
                        );
                        circle.setRadius(shape.radius);
                        this.mapStateService.insertShape(
                            circle,
                            google.maps.drawing.OverlayType.CIRCLE
                        );

                        break;
                    case Type.BOUNDS:
                        let rectangle = new google.maps.Rectangle();
                        const bounds = {
                            south: shape.bottom,
                            west: shape.left,
                            north: shape.top,
                            east: shape.right,
                        };
                        rectangle.setBounds(bounds);
                        this.mapStateService.insertShape(
                            rectangle,
                            google.maps.drawing.OverlayType.RECTANGLE
                        );

                        break;
                }
            });
        }
    }

    onGroupsReady(groupIDs: number[]) {
        this.filter.groups = groupIDs;
        if (
            groupIDs.length > 0 &&
            this.datapointsOverlaysComponent &&
            this.datapointsOverlaysComponent.isDatasetChecked
        ) {
            this.fetchDatapoints();
            if (!this.groupsReady) {
                this.dataIsReadySubject.next({ dataType: "groups" });
            }
        } else {
            this.dataIsReadySubject.next({ dataType: "groups" });
        }
    }

    initContext(dataset: Dataset) {
        let converterOptions: DatapointConverterOptions = {
            type: DatapointConverterType.NONE,
            datasetID: dataset.id,
            fieldID: this.dataset.stylingOptions
                ? this.dataset.stylingOptions.colorizationFieldIndex
                    ? this.dataset.fields[
                        this.dataset.stylingOptions.colorizationFieldIndex
                    ].id
                    : null
                : null,
        };
        this.renderingOptions = {
            datasetStylingOptions: this.dataset.stylingOptions,
            converterOptions: converterOptions,
            visualizationOptions: { type: VisualizationType.DEFAULT },
        };

        this.filter = {
            datasetID: dataset.id,
            groups: this.getSelectedGroupsIds() || [],
            fields: [],
            links: [],
        };

        this.datapointsFilterService.initFilter(this.filter);

        let projectedFields = dataset.fields.filter(
            (field) =>
                field.id !== "created_on" &&
                field.id !== "updated_on" &&
                field.id !== "id"
        );
        this.projection = {
            datasetID: dataset.id,
            fields: projectedFields.map((field) => field.id),
            links: [],
        };
    }

    getSelectedGroupsIds() {
        return this.groupsPanel.selectedGroupsIds;
    }

    getAllGroupsIds() {
        return this.groupsPanel.groupsIds;
    }

    get groupingOptions() {
        return Object.keys(Grouping);
    }

    get groupingEnum() {
        return Grouping;
    }

    fetchDatapoints() {
        if (this.isMapView) {
            this.updatePortfolioLayer();
        } else {
            this.datapointFilterObject = this.constructFilterObject();
            this.tableComponent.fetchDatapoints();
        }
    }

    onFilterMenuFieldClick(
        event: boolean,
        dataset: Dataset,
        field: DatasetField
    ) {
        // it is not clear why this in in here
        if (field.datasetId) {
            field.id = field.datasetId;
            field.name = field.datasetLabel;
        }
        //
        if (event) {
            this.datapointsFilterService.addFilterBarItem(field, dataset);
        } else {
            // need to remove the filter filterBarItem
            this.datapointsFilterService.removeFilterBarItem(
                dataset.id,
                field.id
            );
        }
    }

    dynamicFilterMenuEmitter(_event) {
        if (_event.isDistanceFilterCall) {
            this.applyDistanceFilter(
                _event.dataset,
                _event.distance,
                _event.distanceUnit
            );
        } else {
            this.onFilterMenuFieldClick(
                _event.event,
                _event.dataset,
                _event.field
            );
        }
    }

    applyFilters() {
        let isInvalidFilter = "";
        for (const filter of this.filterBarItems) {
            isInvalidFilter =
                this.datapointsFilterService.validatorsForFiltersInputs(filter);
            if (isInvalidFilter) {
                break;
            }
        }
        switch (isInvalidFilter) {
            case FilteringErrorCodes.MIN_GREATER_THAN_MAX:
                this.notifService.error(
                    "Error. Min cannot be greater then Max."
                );
                return;
            case FilteringErrorCodes.EXCEED_MIN_ALLOWED:
                this.notifService.error(
                    "Error. Exceeded the maximum allowed Min length."
                );
                return;
            case FilteringErrorCodes.EXCEED_MAX_ALLOWED:
                this.notifService.error(
                    "Error. Exceeded the maximum allowed Max length."
                );
                return;
            case FilteringErrorCodes.EXCEED_LENGTH_CHARTS:
                this.notifService.error(
                    `Error. Exceed the maximum limit of characters.`
                );
                return;
        }
        this.datapointsFilterService.updateFilter();
        this.datapointFilterObject = this.constructFilterObject();
    }

    applyOverlayFilters(
        selected: DatapointsOverlayFilterStore,
        onlyPrepapredFilter: boolean = false
    ) {
        const overlay = this.datapointsPageStateService.getDataset(
            selected.datasetID
        );
        if (!onlyPrepapredFilter) {
            this.datapointsFilterService
                .onFetchStatisticsReady()
                .subscribe((ready) => {
                    this.datapointsOverlayFilterService.updateFilters(
                        selected.datasetID,
                        selected.filters
                    );
                });
        }
        const fields: DatapointFilterField[] = selected.filters.map((item) => {
            let textValues = [];
            let statistics = item.statisticValues;
            try {
                for (let prop in statistics) {
                    if (statistics.hasOwnProperty(prop) && statistics[prop]) {
                        textValues.push(prop);
                    }
                }
            } catch (err) {}
            const minDateValue = item.minDateValue
                ? new Date(item.minDateValue).getTime()
                : undefined;
            const maxDateValue = item.maxDateValue
                ? new Date(item.maxDateValue).getTime()
                : undefined;
            return {
                id: item.id,
                minNumberValue: item.minNumberValue,
                maxNumberValue: item.maxNumberValue,
                minDateValue,
                maxDateValue,
                searchValue: item.searchValue || null,
                textValues: textValues,
            };
        });
        const event: ActivateOverlayEvent = {
            active: true,
            datasetID: selected.datasetID,
            renderingOptions: this.datapointsOverlaysComponent
                .getOverlaysRenderingOptions()
                .get(selected.datasetID),
            filter: { fields, datasetID: selected.datasetID },
        };
        if (onlyPrepapredFilter) {
            return { fields, datasetID: selected.datasetID };
        } else {
            this.mapStateService.updateOverlay(
                selected.datasetID,
                this.generateOverlayLayer(overlay, event)
            );
        }
    }

    navigateToDrafts(): void {
        this.router.navigate([
            `private/account/${this.account.id}/dataset/${this.dataset.id}/drafts`,
        ]);
    }

    switchView(viewMode: string): void {
        this.datapointsFilterService.updateArray([]);
        if (viewMode.toUpperCase() === DatapointsViewMode.DASHBOARD) {
            this.router.navigate([
                `private/account/${this.account.id}/dataset/${this.dataset.id}/${viewMode}`,
            ]);
        } else {
            this.projection.formulas = [];
            this.tessadataFieldsByDataset[this.dataset.id].nriFields =
                this.groupByNriFields(
                    this.tessadataFieldsForview[this.dataset.id].nriFields
                );
            this.datapointsPageStateService.updateMapMode(defaultMapStatus);

            const currentViewMode = this.isMapView
                ? DatapointsViewMode.MAP
                : DatapointsViewMode.TABLE;
            if (viewMode.toUpperCase() === currentViewMode) {
                return;
            }
            this.location.replaceState(
                `private/account/${this.account.id}/dataset/${this.dataset.id}/datapoints/${viewMode}`
            );

            this.isMapView = !this.isMapView;

            if (!this.isMapView) {
                this.legends = [];

                this.closeAllPanels();
            } else {
                // active clustering on switching view
                if (this.isClusteringActivated === true) {
                    this.clusteringService.enableClusteringMode(
                        this.clusteringSettings
                    );
                }

                if (this.analyticsPanel && this.analyticsPanel.instance) {
                    this.analyticsPanel.instance.hidePanel();
                }
                if (this.notificationsPanel && this.notificationsPanel.instance) {
                    this.notificationsPanel.instance.hidePanel();
                }

                if(this.reportPanel.instance){
                    this.reportPanel.instance.hidePanel()
                }
            }
        }
    }

    closeAllPanels(): void {
        this.locationProfilePanel.onExpandedClick(false);
        const panelsToClose = [
            this.analyticsPanel,
            this.notificationsPanel,
            this.clusterPanel,
            this.addressLocationPanel,
            this.crises24DatapointsAgTablePanel,
            this.reportPanel
        ];
        panelsToClose.forEach(panel => {
            if (panel && panel.instance) {
                panel.instance.hidePanel();
            }
        });
    }

    fallBackToTableView(datasetID, accountID): void {
        // this.router.navigate([`private/account/${accountID}/dataset/${datasetID}/datapoints/table`]);
    }

    switchApplication(datasetId: string): void {
        const viewMode = this.route.snapshot.paramMap.get(
            Constants.VIEW_MODE_PARAM_NAME
        );

        if (datasetId === this.dataset.id) {
            return;
        }

        this.router.navigate(
            [
                `private/account/${this.account.id}/dataset/${datasetId}/datapoints/${viewMode}`,
            ],
            { replaceUrl: true }
        );
    }

    onToggleOverlay(event: ActivateOverlayEvent) {
        if (event.active) {
            // should activate overlay
            this.enableOverlay(event.datasetID, event);
            if (event.datasetID === this.dataset.id) {
                this.datasetIsDisabled = false;
            } else {
                this.datapointsOverlayFilterService.addOverlay(event.datasetID);
            }
            if (
                this.selectedOverlay &&
                event.datasetID === this.selectedOverlay.id
            ) {
                this.datapointsOverlayFilterService.selectFilter(
                    event.datasetID
                );
            }
        } else {
            // should remove overlay
            this.disableOverlay(event.datasetID);
            this.datapointsOverlayFilterService.removeOverlay(event.datasetID);
            if (
                this.selectedOverlay &&
                event.datasetID === this.selectedOverlay.id
            ) {
                this.datapointsOverlayFilterService.selectFilter();
            }
            if (event.datasetID === this.dataset.id) {
                this.datasetIsDisabled = true;
            }
            this.closeCrisis24Panel(event.datasetID);
        }
    }

    updatePortfolioLayer(): void {
        let scaleType = this.renderingOptions.datasetStylingOptions.type;
        if (
            this.renderingOptions.datasetStylingOptions &&
            this.renderingOptions.datasetStylingOptions
                .colorizationFieldIndex !== null
        ) {
            let colorizationField =
                this.dataset.fields[
                this.renderingOptions.datasetStylingOptions
                    .colorizationFieldIndex
                ];
            this.renderingOptions.converterOptions.datasetID = this.dataset.id;
            this.renderingOptions.converterOptions.fieldID =
                colorizationField.id;

            if (
                scaleType === ColorScaleType.FIXED ||
                scaleType === ColorScaleType.GRADIENT
            ) {
                this.subscriptions.add(
                    this.datapointAggregateService
                        .getDatapointsFieldStatistics(
                            this.dataset.id,
                            colorizationField.id,
                            {
                                datasetID: this.dataset.id,
                                groups: this.getSelectedGroupsIds(),
                            }
                        )
                        .subscribe((statistics) => {
                            DatapointsComponent.setFieldStatistics(
                                colorizationField,
                                this.renderingOptions,
                                statistics
                            );
                            const field = this.dataset.fields.find(
                                (field) => field.id === colorizationField.id
                            );
                            const name =
                                field.displayName === null ||
                                    field.displayName === undefined
                                    ? field.name
                                    : field.displayName;
                            let layer = this.generatePortfolioLayer();
                            this.addLegend(
                                this.dataset.id,
                                LegendUtils.createIntervalsForLegend(
                                    this.renderingOptions,
                                    this.dataset.isGeoserver
                                ),
                                false,
                                this.dataset.name,
                                name,
                                LegendUtils.createGradientForLegend(
                                    this.renderingOptions,
                                    this.dataset.isGeoserver
                                )
                            );
                            this.mapStateService.updateOverlay(
                                layer.name,
                                layer
                            );
                        })
                );
            } else if (scaleType === ColorScaleType.INTERVAL) {
                // it is constant or interval. we don't need min and max
                DatapointsComponent.setFieldStatisticsForInterval(
                    colorizationField,
                    this.renderingOptions
                );
                let layer = this.generatePortfolioLayer();
                const field = this.dataset.fields.find(
                    (field) => field.id === colorizationField.id
                );
                const name =
                    field.displayName === null ||
                        field.displayName === undefined
                        ? field.name
                        : field.displayName;
                this.addLegend(
                    this.dataset.id,
                    LegendUtils.createIntervalsForLegend(
                        this.renderingOptions,
                        this.dataset.isGeoserver
                    ),
                    false,
                    this.dataset.name,
                    name,
                    LegendUtils.createGradientForLegend(
                        this.renderingOptions,
                        this.dataset.isGeoserver
                    )
                );
                this.mapStateService.updateOverlay(layer.name, layer);
            } else {
                let layer = this.generatePortfolioLayer();
                this.mapStateService.updateOverlay(layer.name, layer);
            }
        } else {
            let layer = this.generatePortfolioLayer();
            this.mapStateService.updateOverlay(layer.name, layer);
        }
    }

    private generatePortfolioLayer(avoidCache?: boolean) {
        let token = this.authService.getToken();
        let opacity =
            this.renderingOptions.datasetStylingOptions.backgroundOpacity || 1;
        // so that the the BE requests for tiles do not get triggered only for opacity
        let renderingOptionsCopy: RenderingOptions = JSON.parse(
            JSON.stringify(this.renderingOptions)
        );
        renderingOptionsCopy.datasetStylingOptions.backgroundOpacity =
            undefined;

        let projectionCopy: DatapointProjection = JSON.parse(
            JSON.stringify(this.projection)
        );

        projectionCopy.fields = ["id"];
        if (
            this.renderingOptions.converterOptions &&
            this.renderingOptions.converterOptions.fieldID
        ) {
            projectionCopy.fields.push(
                this.renderingOptions.converterOptions.fieldID
            );
        }
        projectionCopy.links = [];

        let renderingOptionsJson = encodeURIComponent(
            JSON.stringify(this.renderingOptions)
        );
        let filterJson = JSON.stringify(this.filter);
        let projectionJson = encodeURIComponent(JSON.stringify(projectionCopy));

        let tileUrl = (coord, zoom) =>
            `${environment.baseUrl}/render/dataset/${this.dataset.id}/tile/${coord.x}/${coord.y}/${zoom}?token=${token}&filter=${filterJson}&projection=` +
            `${projectionJson}&renderingOptions=${renderingOptionsJson}` +
            (avoidCache ? `&v=${Math.random()}` : "");
        let layer = new google.maps.ImageMapType({
            getTileUrl: tileUrl,
            minZoom: 3,
            maxZoom: 15,
            name: this.dataset.id,
            opacity: opacity,
            tileSize: new google.maps.Size(
                defaultTileSize.width,
                defaultTileSize.height
            ),
        });
        return layer;
    }

    enableOverlay(datasetID: string, event: ActivateOverlayEvent) {
        let overlay = this.datapointsPageStateService.getDataset(datasetID);
        this.insertOverlay(
            overlay,
            event,
            MapStateActionEnum.INSERT_OVERLAY
        ).then((resolve: any) => {});
    }

    disableOverlay(datasetID: string) {
        this.removeLegend(datasetID);
        let existingOverlayIndex =
            this.datapointsPageStateService.activeDatasetsOnMap.findIndex(
                (activeOverlay) => activeOverlay.id === datasetID
            );
        if (existingOverlayIndex >= 0) {
            this.mapStateService.removeOverlay(datasetID);
        }
        if (this.datapointsPageStateService.activeDatasetsOnMap.length < 1) {
        }
    }

    prepareProjectionByRenderingOptions(
        datasetID: string,
        event: ActivateOverlayEvent
    ): DatapointProjection {
        if (event.projection) {
            return event.projection;
        }

        let projection: DatapointProjection = { datasetID };

        if (
            event.renderingOptions.visualizationOptions.type ===
            VisualizationType.THEMATIC_MAP
        ) {
            projection.geometryPrecision = 15;
        }

        let linkProjection: DatapointProjection;
        let fields: string[] = [];

        let haveLinkColorization =
            datasetID !== event.renderingOptions.converterOptions.datasetID;

        if (haveLinkColorization) {
            linkProjection = {
                datasetID: event.renderingOptions.converterOptions.datasetID,
            };
        }
        switch (event.renderingOptions.datasetStylingOptions.type) {
            case ColorScaleType.CONSTANT:
            case ColorScaleType.FIXED:
            case ColorScaleType.GRADIENT:
            case ColorScaleType.INTERVAL:
                if (event.renderingOptions.converterOptions.fieldID) {
                    fields = [event.renderingOptions.converterOptions.fieldID];
                }
        }
        if (haveLinkColorization) {
            linkProjection.fields = fields;
            projection.links = [linkProjection];
        } else {
            projection.fields = fields;
        }
        return projection;
    }

    generateOverlayLayer(overlay: Dataset, event: ActivateOverlayEvent) {
        let opacity =
            event.renderingOptions.datasetStylingOptions.backgroundOpacity || 1;
        // so that the the BE requests for tiles do not get triggered only for opacity
        let renderingOptionsCopy: RenderingOptions = JSON.parse(
            JSON.stringify(event.renderingOptions)
        );
        renderingOptionsCopy.datasetStylingOptions.backgroundOpacity =
            undefined;

        let filter = this.prepareFilter(overlay, event);
        let projection: DatapointProjection =
            this.prepareProjectionByRenderingOptions(overlay.id, event);
        let projectionJson = JSON.stringify(projection);
        let renderingOptionsJson = encodeURIComponent(
            JSON.stringify(renderingOptionsCopy)
        );
        let token = this.authService.getToken();
        let urlSuffix;
        let filterJson;
        let linkedApplicationFilter; //for thematic map only
        if (
            overlay.thematicMapSettings &&
            overlay.thematicMapSettings.isThematicMapDataset
        ) {
            urlSuffix = "thematic-tile";
            if (this.mapThematicOverlayService.currentConfig.parentDatasetId !== null) {
                filter.datasetID = this.dataset.id;
                const field: DatapointFilterField[] = [{id: 'link_' + this.mapThematicOverlayService.currentConfig.parentDatasetId, maxDateValue: null, minDateValue: null,maxNumberValue: null, minNumberValue: 0, searchValue: null, textValues:[this.mapThematicOverlayService.currentConfig.geometryID.geometryID], distanceUnit: null}];
                filter.fields = filter.fields.length > 0 ? [...filter.fields, ...field] : field;
            }
            let parentFilter = this.mapThematicOverlayService.currentConfig.parentFilter;
            filterJson = JSON.stringify(filter) + `&parentFilter=` + JSON.stringify(parentFilter);
            linkedApplicationFilter = JSON.stringify(this.filter);
        } else {
            urlSuffix = "tile";
            filterJson = JSON.stringify(filter);
        }

        let tileUrl = (coord, zoom) =>
            `${environment.baseUrl}/render/dataset/${overlayId}/${urlSuffix}/${coord.x}/${coord.y}/${zoom}?token=${token}&filter=` +
            `${filterJson}&projection=${projectionJson}&renderingOptions=${renderingOptionsJson}&linkedApplicationFilter=${linkedApplicationFilter}`;

        if (overlay.name == "GEE Climate Change") {
            tileUrl = function (coord, zoom) {
                var url =
                    "https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/4a9bb75df22b107ef53ef99dbd390e89-415b54946cb9482a753272d03e01dde6/tiles/{z}/{x}/{y}"
                        .replace("{x}", coord.x)
                        .replace("{y}", coord.y)
                        .replace("{z}", zoom);
                return url;
            };
        }

        let overlayId = overlay.id;
        let layer = new google.maps.ImageMapType({
            getTileUrl: tileUrl,
            minZoom: 1,
            maxZoom: 15,
            opacity: opacity,
            name: overlay.id,
            tileSize: new google.maps.Size(
                defaultTileSize.width,
                defaultTileSize.height
            ),
        });
        return layer;
    }

    private prepareFilter(overlay: Dataset, event: ActivateOverlayEvent) {
        if (event.filter) {
            return event.filter;
        }

        let filter: DatapointFilter;
        if (overlay.id === this.dataset.id) {
            filter = this.filter;
        } else if (
            overlay.id !== this.dataset.id &&
            this.datapointsOverlayFilterService.getOverlayFilter(overlay.id)
        ) {
            filter = this.applyOverlayFilters(
                this.datapointsOverlayFilterService.getOverlayFilter(
                    overlay.id
                ),
                true
            );
        } else {
            filter = { datasetID: overlay.id };
        }
        return filter;
    }

    onUpdateOverlaySettings(event: ActivateOverlayEvent) {
        let renderingOptions = event.renderingOptions;
        let datasetID = event.datasetID;
        let overlay = this.datapointsPageStateService.getDataset(datasetID);

        if (this.dataset.id === datasetID) {
            this.renderingOptions = renderingOptions;
        }
        this.insertOverlay(
            overlay,
            event,
            MapStateActionEnum.UPDATE_OVERLAY
        ).then((resolve: any) => {
            this.overlaysFilterComponent.resetFilters();
        });
    }

    onSelectedOverlay(event: Dataset) {
        this.selectedOverlay = event;
        if (this.selectedOverlay.isSelected) {
            this.datapointsOverlayFilterService.selectFilter(
                this.selectedOverlay.id
            );
        } else {
            this.datapointsOverlayFilterService.selectFilter();
        }
    }

    drawShapes(shape: MapShape, type: string): void {
        const drawingType =
            type === MapDrawType.MEASURE
                ? MapDrawType.MEASURE
                : MapDrawType.FILTER;

        switch (shape) {
            case MapShape.CIRCLE:
                this.mapStateService.setDrawingMode(
                    google.maps.drawing.OverlayType.CIRCLE,
                    drawingType
                );
                break;
            case MapShape.RECTANGLE:
                this.mapStateService.setDrawingMode(
                    google.maps.drawing.OverlayType.RECTANGLE,
                    drawingType
                );
                break;
            case MapShape.POLYGON:
                this.mapStateService.setDrawingMode(
                    google.maps.drawing.OverlayType.POLYGON,
                    drawingType
                );
                break;
            case MapShape.POLYLINE:
                this.mapStateService.setDrawingMode(
                    google.maps.drawing.OverlayType.POLYLINE,
                    drawingType
                );
                break;
        }
    }

    removeShapes(type: string): void {
        const drawingType =
            type === MapDrawType.MEASURE
                ? MapDrawType.MEASURE
                : MapDrawType.FILTER;
        this.mapStateService.clearShapes(drawingType);
    }

    ngOnDestroy(): void {
        if (this.analyticsPanel) {
            this.analyticsPanel.instance.closePanel();
            this.analyticsPanel = null;
        }

        if (this.addressLocationPanel) {
            this.addressLocationPanel.instance.closePanel();
            this.addressLocationPanel = null;
        }

        if (this.updateDatapointPanel) {
            this.updateDatapointPanel.instance.closePanel();
            this.updateDatapointPanel = null;
        }

        if (this.clusterPanel) {
            this.clusterPanel.instance.closePanel();
            this.clusterPanel = null;
        }

        if (this.notificationsPanel) {
            this.notificationsPanel.instance.closePanel();
            this.notificationsPanel = null;
        }

        if (this.crises24DatapointsAgTablePanel) {
            this.crises24DatapointsAgTablePanel.instance.closePanel();
            this.crises24DatapointsAgTablePanel = null;
        }

        this.datapointsPageStateService.reset();
        this.mapThematicOverlayService.reset();
        this.subscriptions.unsubscribe();
    }

    open($event: string) {}

    onUpdated() {
        this.updateDatapointPanel.instance.hidePanel();
        this.fetchDatapoints();
    }

    deleteSelected() {
        this.tableComponent.deleteSelected();
    }

    filterStatisticValues(id: string, $event: any) {
        const filterValue = $event.target.value;
        const filterBarItem = this.filterBarItems.find(
            (currentFilterBarItem) => {
                return currentFilterBarItem.id === id;
            }
        );
        const index = this.filterBarItems.indexOf(filterBarItem);
        this.filterBarItems[index].filteredStatisticValues =
            this.filterBarItems[index].statistics.values.filter((value) => {
                return value.toLowerCase().includes(filterValue.toLowerCase());
            });
    }

    removeFilterBarItem(filterBarItem: FilterBarItem) {
        filterBarItem.datasetField.selected = false;
        this.bindFilterIds({ fieldIds: [filterBarItem.datasetField.id] }, true);
        this.datapointsFilterService.removeFilterBarItem(
            filterBarItem.dataset.id,
            filterBarItem.datasetField.id
        );
        this.statisticsFiltersFetched = false;
    }

    removeFilterBar() {
        this.initializeDatasetFilterSelectedState();
        // Filter
        this.reinitializeDatasetFilterSelectedState();
        this.filterBarItems = [];
        this.datapointsFilterService.clearFilterBar();
        this.statisticsFiltersFetched = false;
        this.bindFilterIds({ fieldIds: [] }, true);
    }

    initializeDatasetFilterSelectedState(): void {
        this.linkedAccountOverlays.forEach((dataset) => {
            dataset.fields.forEach((field) => {
                field.selected = false;
            });
        });

        this.linkedAccountDatasets.forEach((dataset) => {
            dataset.fields.forEach((field) => {
                field.selected = false;
            });
        });
    }

    reinitializeDatasetFilterSelectedState(): void {
        this.filterAccountOverlays.forEach((dataset) => {
            dataset.overlays.forEach((overlay) => {
                overlay.fields.forEach((field) => {
                    field.selected = false;
                });
            });
        });

        this.filterAccountDatasets.forEach((dataset) => {
            dataset.fields.forEach((field) => {
                field.selected = false;
            });
        });
    }

    onFormulaProjectionChange(formulaItem: WorkspaceItem) {
        if (formulaItem.isProjected) {
            if (!this.projection.formulas) {
                this.projection.formulas = [formulaItem];
            } else {
                this.projection.formulas.push(formulaItem);
            }
        } else {
            // we need to remove it
            this.findItemInListAndDelete(formulaItem, this.projection.formulas);
        }
        // this.fetchDatapoints();
        this.datapointFilterObject = this.constructFilterObject();
        this.datapointsFilterService.updateFilter();
    }

    findItemInListAndDelete(item, list) {
        let searchedItem = list.find((listItem) => listItem.id === item.id);
        list.splice(list.indexOf(searchedItem), 1);
    }

    onProjectionChange(dataset, field) {
        //
        if (field.isProjected) {
            this.addProjectionField(dataset, field);
        } else {
            this.removeProjectionField(dataset, field);
        }
        this.sort = { datasetID: this.dataset.id, links: [], fields: [] };
        // this.fetchDatapoints();
        this.datapointFilterObject = this.constructFilterObject();
        this.datapointsFilterService.updateFilter();
    }

    dynamicProjectionMenuEmitter(_event) {
        this.onProjectionChange(_event.dataset, _event.field);
    }

    onNRIParentChange($event, dataset, field) {
        field.isParentSelected = $event;
        field.child.forEach((element) => {
            element.child.isProjected = $event;
        });
    }

    addProjectionField(dataset, field) {
        if (dataset.id !== this.dataset.id) {
            let existingLinks = this.projection.links.find(
                (link) => link.datasetID === dataset.id
            );
            if (!existingLinks) {
                let link: DatapointProjection = {
                    datasetID: dataset.id,
                    fields: [field.id],
                };
                this.projection.links.push(link);
            } else {
                let foundExistingLinkField = existingLinks.fields.some(
                    (fieldId) => fieldId === field.id
                );
                if (!foundExistingLinkField)
                    existingLinks.fields.push(field.id);
            }
        } else {
            let foundExistingField = this.projection.fields.some(
                (fieldId) => fieldId === field.id
            );
            if (!foundExistingField && dataset.geoWorkspace !== "ipcc")
                this.projection.fields.push(field.id);
        }
    }

    removeProjectionField(dataset, field) {
        if (dataset.id !== this.dataset.id) {
            let searchedProjectionLink = this.projection.links.find(
                (link) => link.datasetID === dataset.id
            );
            let index = this.projection.links.indexOf(searchedProjectionLink);
            searchedProjectionLink.fields.splice(
                searchedProjectionLink.fields.indexOf(field.id),
                1
            );
            if (searchedProjectionLink.fields.length < 1) {
                this.projection.links.splice(index, 1);
            }
        } else {
            let searchedField = this.projection.fields.find(
                (Field) => Field === field.id
            );
            this.projection.fields.splice(
                this.projection.fields.indexOf(searchedField),
                1
            );
        }
    }

    openDownloadDialog(): void {
        if (
            (this.tableComponent &&
                (this.tableComponent.selectAll === true ||
                    this.tableComponent.getSelectedDatapointIds().length >
                    0)) ||
            this.isMapView
        ) {
            const climateFilterAccountOverlays = this.prepareClimateOverlays();

            const dialogRef = this.dialog.open(DatasetDownloadComponent, {
                width: "450px",
                data: {
                    climateData: this.datapointObject.prepareClimateData(
                        climateFilterAccountOverlays,
                        true
                    )
                }
            });

            this.subscriptions.add(
                dialogRef.afterClosed().subscribe((response) => {
                    if (response) {
                        const {result, selectedClimateData} = response;
                        this.downloadDatapoints(result, selectedClimateData);
                    }
                })
            );
        }
    }

    downloadDatapoints(result, selectedClimateData) {
        const cloneObject = ObjectUtils.clone(this.getDefaultFilterObject());
        let geometryPrecision =
            this.dataset.geometryType !== DatasetGeometryType.NONE ? 25 : -1;
        let projection =
            result.projection !== "CURRENT_FIELDS"
                ? cloneObject.projection
                : ObjectUtils.clone(this.datapointFilterObject).projection;
        let formulaNames = projection.formulas && projection.formulas.length ? projection.formulas.map((field) => field.name) : [];
        let limit = this.datapointFilterObject.limit;
        let skip = this.datapointFilterObject.skip;
        let datapointIds = this.tableComponent ? this.tableComponent.getSelectedDatapointIds() : [];
        if (this.tableComponent) {
            limit = Number(
                this.tableComponent.gridApi.paginationGetCurrentPage() +
                1 +
                "0000"
            );
            skip =
                this.tableComponent.gridApi.paginationGetCurrentPage() == 0
                    ? 0
                    : Number(
                        this.tableComponent.gridApi.paginationGetCurrentPage() +
                        1 -
                        1 +
                        "0000"
                    );
            const selectedDownloadRequestData = this.tableComponent
                ? this.tableComponent.getSelectedDownloadRequestData()
                : { selectedRows: [], selectedColumns: [], overlayColumns: [] };
            projection.fields = formulaNames.length ? selectedDownloadRequestData.selectedColumns.filter(column => !formulaNames.includes(column)) : selectedDownloadRequestData.selectedColumns; // [...new Set(projection.fields)];
            datapointIds = selectedDownloadRequestData.selectedRows;
            projection.links = selectedDownloadRequestData.overlayColumns.length
                ? projection.links.filter((element) =>
                    selectedDownloadRequestData.overlayColumns.includes(
                        element.datasetID
                    )
                )
                : projection.links;

        } else if (this.isMapView) {
            limit = -1;
            skip = 0;
            projection.fields = [...new Set(projection.fields)];
        }
        // Create an object to store unique records based on datasetID
        const mergedObject: Record<string, any> = {};

        // Merge existing overlays expect climate into mergedObject while skipping duplicates
        for (const item of projection.links) {
            mergedObject[item.datasetID] = item;
        }

        // Merge climate overlays into mergedObject while skipping duplicates
        for (const item of this.prepareClimateDownloadLinks(selectedClimateData)) {
            if (!mergedObject[item.datasetID]) {
                mergedObject[item.datasetID] = item;
            }
        }

        projection.links = Object.values(mergedObject); // ObjectUtils.clone([]); // Object.values(mergedObject);
        let request: DownloadDatapointRequest = {
            datapointRequest: {
                limit: limit,
                projection: {
                    ...projection,
                    geometryPrecision: geometryPrecision,
                },
                skip: skip,
                sort:
                    result.sort === "CURRENT_SORT" && !this.isMapView
                        ? this.datapointFilterObject.sort
                        : this.datapointFilterObject.sort,
            },
            filter:
                result.filter === "CURRENT_FILTER"
                    ? this.datapointFilterObject.filter
                    : this.datapointFilterObject.filter,
            timezone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: result.fileType,
            datapointIds: datapointIds,
            // reportRequest: {},
        };
        const finalFileName = this.generateFileName(
            result.fileName.toString(),
            result.fileType.toLowerCase().toString()
        );
        this.subscriptions.add(
            this.downloadService
                .downloadDatapoints(this.dataset.id, request, result.fileName)
                .subscribe(
                    (response) => {
                        AttachmentUtils.downloadFileWithName(
                            response,
                            finalFileName
                        );
                    },
                    (error) =>
                        this.notifService.error(
                            "Something went wrong during download"
                        )
                )
        );
    }

    generateFileName(name: string, type: string): string {
        return name + "." + type;
    }

    applyDistanceFilter(
        dataset: Dataset,
        distance,
        distanceUnit: DistanceUnit
    ) {
        this.datapointsFilterService.applyOverlayDistance(
            dataset,
            distance,
            distanceUnit
        );
    }

    openDetails($event: MapDetails) {
        this.locationProfilePanel.previousSelectedValue = this.locationProfile.INTERNAL;
        this.mapComponent.isTensorflightFirstTimeFetched = true;
        this.mapComponent.areBoundsChanged = false;
        this.externalTensorFlightData = null;
        if ($event.location !== null) {
            this.datasetService.fetchTensorflight({
                lat: $event.location.y,
                lng: $event.location.x
            }, $event.datapointID).subscribe(response => {
                this.externalTensorFlightData = response;
            });
        }
        this.mapDatapointInfo = $event;
        if (!$event.refresh) {
            if ($event.dataset.id !== Crisis24Alert.getId()) {
                this.locationProfilePanel.onExpandedClick(true);
            } else if ($event.dataset.id == Crisis24Alert.getId()) {
                if (this.crises24DatapointsAgTablePanel) {
                    this.crises24DatapointsAgTablePanel.instance.closePanel();
                }
                this.sidePanelService.setRootViewContainerRef(
                    this.viewContainerRef
                );
                this.crises24DatapointsAgTablePanel =
                    this.sidePanelService.open<crises24DatapointsAgTableComponentType>(
                        SidePanels.CRISES24_DATAPOINTS_AGTABLE,
                        {
                            width: 459,
                            id: Crisis24Alert.getPanelId(),
                            panelTitle: "Crisis24 Alert",
                            resizeable: true,
                            panelIcon: "fa-atom",
                        },
                        {
                            datapointID: $event.datapointID,
                            dataset: $event.dataset,
                            location: $event.location,
                            version: $event.version,
                            radius: $event.radius,
                            isListCall: $event.isListCall,
                        }
                    );
                // this.locationProfilePanel.onExpandedClick(true);
            }
        }
        this.selectedLocationClone = ObjectUtils.clone($event.location); // we make a clone to be able to undo the changes
    }

    closeDetails(): void {
        this.mapDatapointInfo = undefined;
        this.selectedLocationClone = undefined;
        this.moveLocationModeEnabled = false;
        this.mapComponent.isTensorflightFirstTimeFetched = true;
        this.mapComponent.removeTensorflightPolygon();
    }

    private insertOverlay(
        overlay: Dataset,
        event: ActivateOverlayEvent,
        mapStateAction: MapStateActionEnum
    ) {
        if (event.renderingOptions) {
            let scaleType = event.renderingOptions.datasetStylingOptions.type;
            if (
                scaleType === ColorScaleType.FIXED ||
                scaleType === ColorScaleType.GRADIENT
            ) {
                let datasetID =
                    event.renderingOptions.converterOptions.datasetID;
                let fieldID = event.renderingOptions.converterOptions.fieldID;
                let datasetFields =
                    this.datapointsPageStateService.getDataset(
                        datasetID
                    ).fields;
                let colorizationField = datasetFields.find(
                    (field) => field.id === fieldID
                );
                let filter: DatapointFilter =
                    datasetID === this.dataset.id
                        ? {
                            datasetID: datasetID,
                            groups: this.getSelectedGroupsIds(),
                        }
                        : { datasetID: datasetID };
                return new Promise((resolve, reject) => {
                    this.subscriptions.add(
                        this.datapointAggregateService
                            .getDatapointsFieldStatistics(
                                datasetID,
                                colorizationField.id,
                                filter
                            )
                            .subscribe((statistics) => {
                                DatapointsComponent.setFieldStatistics(
                                    colorizationField,
                                    event.renderingOptions,
                                    statistics
                                );
                                this.putOverlayOnMap(
                                    mapStateAction,
                                    overlay.id,
                                    overlay,
                                    event
                                );
                                const field = this.dataset.fields.find(
                                    (field) => field.id === colorizationField.id
                                );
                                const name =
                                    colorizationField.displayName === null ||
                                        colorizationField.displayName === undefined
                                        ? colorizationField.name
                                        : colorizationField.displayName;
                                this.addLegend(
                                    overlay.id,
                                    LegendUtils.createIntervalsForLegend(
                                        event.renderingOptions,
                                        overlay.isGeoserver
                                    ),
                                    false,
                                    overlay.name,
                                    name,
                                    LegendUtils.createGradientForLegend(
                                        event.renderingOptions,
                                        overlay.isGeoserver
                                    )
                                );
                            })
                    );
                    resolve(true);
                });
            } else {
                // it is constant or interval. we don't need min and max
                return new Promise((resolve, reject) => {
                    if (scaleType === ColorScaleType.INTERVAL) {
                        if (
                            event.renderingOptions.visualizationOptions.type !==
                            VisualizationType.THEMATIC_MAP
                        ) {
                            let datasetID =
                                event.renderingOptions.converterOptions
                                    .datasetID;
                            let fieldID =
                                event.renderingOptions.converterOptions.fieldID;
                            let colorizationField =
                                this.datapointsPageStateService
                                    .getDataset(datasetID)
                                    .fields.find(
                                        (field) => field.id === fieldID
                                    );
                            DatapointsComponent.setFieldStatisticsForInterval(
                                colorizationField,
                                event.renderingOptions
                            );
                            const name =
                                colorizationField.displayName === null ||
                                    colorizationField.displayName === undefined
                                    ? colorizationField.name
                                    : colorizationField.displayName;
                            this.addLegend(
                                overlay.id,
                                LegendUtils.createIntervalsForLegend(
                                    event.renderingOptions,
                                    overlay.isGeoserver
                                ),
                                false,
                                overlay.name,
                                name,
                                LegendUtils.createGradientForLegend(
                                    event.renderingOptions,
                                    overlay.isGeoserver
                                )
                            );
                        }
                    }
                    if (scaleType === ColorScaleType.CONSTANT) {
                        this.legends = [];
                    }
                    this.putOverlayOnMap(
                        mapStateAction,
                        overlay.id,
                        overlay,
                        event
                    );
                    resolve(true);
                });
            }
        }
    }

    private putOverlayOnMap(
        mapStateAction: MapStateActionEnum,
        datasetID: string,
        overlay: Dataset,
        event: ActivateOverlayEvent
    ) {
        switch (mapStateAction) {
            case MapStateActionEnum.UPDATE_OVERLAY:
                this.mapStateService.updateOverlay(
                    datasetID,
                    this.generateOverlayLayer(overlay, event)
                );
                break;
            case MapStateActionEnum.INSERT_OVERLAY:
                let index = datasetID !== this.dataset.id ? 0 : undefined; // on first position if it's an overlay
                this.mapStateService.insertOverlay(
                    datasetID,
                    this.generateOverlayLayer(overlay, event),
                    index
                );
                break;
        }
    }

    openAnalyticsPanel() {
        this.showAnalyticsPanel();
        const { componentRef } = this.analyticsPanel.instance;
        if (componentRef) {
            componentRef.instance.removeAllReports();
            componentRef.instance.initialize();
        }
    }

    openClusterPanel() {
        if (this.clusterPanel) {
            this.clusterPanel.instance.showPanel();
        } else {
            this.sidePanelService.setRootViewContainerRef(
                this.viewContainerRef
            );
            this.clusterPanel =
                this.sidePanelService.open<DatapointsClusterComponentType>(
                    SidePanels.CLUSTER_PANEL,
                    {
                        width: 459,
                        id: "clusterPanel",
                        panelTitle: "Cluster",
                        panelIcon: "fa-atom",
                        resizeable: true,
                    },
                    {
                        dataset: this.dataset,
                        filter: this.filter,
                        initSettings: this.clusteringSettings,
                    }
                );

            this.datapointsServiceState.onDataPointSelected$.subscribe(
                (clusterPoint: ClusterDatapoint) =>
                    this.openLocationProfile(clusterPoint)
            );
            this.datapointsServiceState.onApplySettings$.subscribe(
                (settings: ClusteringRequest) => {
                    this.clusteringSettings = settings;
                }
            );
            this.datapointsServiceState.onCancelClustering$.subscribe(() => {
                this.clusteringSettings = undefined;
                this.closeClusterPanel();
            });
        }
    }

    showAnalyticsPanel() {
        if (this.analyticsPanel) {
            this.analyticsPanel.instance.showPanel();
        } else {
            this.sidePanelService.setRootViewContainerRef(
                this.viewContainerRef
            );
            this.analyticsPanel =
                this.sidePanelService.open<AnalitycsComponentType>(
                    SidePanels.ANALYTICS,
                    {
                        width: 459,
                        id: "analytics",
                        panelTitle: "Analytics",
                        resizeable: true,
                        panelIcon: 'icon-analitycs',
                    },
                    {
                        dataset: this.dataset,
                        nriFields: isUndefined(this.tessadataFieldsByDataset[this.dataset.id]) ? [] :
                            this.tessadataFieldsByDataset[this.dataset.id]
                                .nriFields,
                        _climateOverlays: this.prepareClimateOverlays()
                    }
                );
        }
    }

    updateAggregateChartPanel() {
        if (this.analyticsPanel) {
            let aggregateField = this.getAggregateFieldForThematicReport();
            let formulaIsUsed = false;
            if (this.thematicMapFormula) {
                formulaIsUsed = true;
            }
            const { componentRef } = this.analyticsPanel.instance;

            if (componentRef) {
                componentRef.instance.removeAllReports();
            }
            let currentLevelDataset =
                this.mapThematicOverlayService.currentConfig.dataset;
            if (currentLevelDataset) {
                let currentLevelDatasetThematicField =
                    currentLevelDataset.fields.find(
                        (field) =>
                            field.id ===
                            currentLevelDataset.thematicMapSettings
                                .mainThematicFieldId
                    );
                let breakdownFields = this.getBreakdownFields(
                    currentLevelDataset,
                    currentLevelDatasetThematicField
                );

                breakdownFields.forEach((breakdownField) => {
                    if (componentRef) {
                        componentRef.instance.addAggregateReport();

                        let aggregateReportComponent =
                            componentRef.instance.aggregateReportComponents.find(
                                (component) =>
                                    !component.getSelectedAggregateField() &&
                                    !component.getSelectedFormula()
                            );
                        aggregateReportComponent.includeThematicMapDatasets(
                            true
                        );

                        if (formulaIsUsed) {
                            aggregateReportComponent.formulas =
                                this.workspaceItemsForFormulas;
                            aggregateReportComponent.setSelectedFormula(
                                this.thematicMapFormula
                            );
                        } else {
                            aggregateReportComponent.setSelectedAggregateField(
                                aggregateField
                            );
                        }

                        aggregateReportComponent.setSelectedBreakdownFieldsByDataset(
                            breakdownField
                        );

                        let reportFilter =
                            this.getFilterForThematicInteraction();
                        aggregateReportComponent.setDatapointFilter(
                            reportFilter
                        );
                        aggregateReportComponent.generateReport();
                    }
                });
            }
        }
    }

    /**
     * The aggregate report needs an aggregate field and a <Dataset, DatasetField[]> map with the breakdown fields
     * For the demo, the aggregate field must be the Total Insured Value, for now we keep it hardcoded, but it will need to be defined in the dataset
     * For the thematic map interaction:
     *      - The breakdown fields map is as follows:
     *          - dataset: the current level dataset
     *          - fields[]: [the main thematic field of the current level dataset]
     *      - The datapoints filter must have a link to the current level dataset and in the link the main thematic field must be specified.
     */
    toggleAggregateChartPanel() {
        if (
            this.mapInteractionMode === MapInteractionMode.THEMATIC_MAP_ACTIVE
        ) {
            if (!this.analyticsPanel) {
                this.showAnalyticsPanel();

                this.datapointsServiceState.onAnalyticsInit$.subscribe(() => {
                    this.updateAggregateChartPanel();
                });
            } else if (
                this.analyticsPanel &&
                this.analyticsPanel.instance &&
                !this.analyticsPanel.instance.isOpen
            ) {
                this.showAnalyticsPanel();
                this.updateAggregateChartPanel();
            } else {
                this.analyticsPanel.instance.hidePanel();
            }
        }
    }

    private getBreakdownFields(
        currentLevelDataset: Dataset,
        currentLevelDatasetThematicField: DatasetField
    ) {
        let breakdownFields: Map<string, DatasetField[]>[] = [];

        let currentLevelBreakdownFieldsByDataset = new Map<
            string,
            DatasetField[]
        >();
        let fields = [currentLevelDatasetThematicField];
        let isLastLevelDataset =
            !this.mapThematicOverlayService.datasetsByParents.get(
                currentLevelDataset.id
            );
        if (isLastLevelDataset) {
            let additionalBreakdownField = currentLevelDataset.fields.find(
                (field) => field.name === "Postal Code"
            );
            if (additionalBreakdownField) {
                fields.push(additionalBreakdownField);
            }
            additionalBreakdownField = currentLevelDataset.fields.find(
                (field) => field.name === "Name"
            );
            if (additionalBreakdownField) {
                fields.push(additionalBreakdownField);
            }
        }
        currentLevelBreakdownFieldsByDataset.set(
            currentLevelDataset.id,
            fields
        );
        breakdownFields.push(currentLevelBreakdownFieldsByDataset);

        let occupancyBreakdownFieldsByDataset = new Map<
            string,
            DatasetField[]
        >();
        let occupancy = this.dataset.fields.find(
            (field) => field.name === "Occupancy"
        );
        if (occupancy) {
            occupancyBreakdownFieldsByDataset.set(this.dataset.id, [occupancy]);
            breakdownFields.push(occupancyBreakdownFieldsByDataset);
        }

        let constructionBreakdownFieldsByDataset = new Map<
            string,
            DatasetField[]
        >();
        let construction = this.dataset.fields.find(
            (field) => field.name === "Construction"
        );
        if (construction) {
            constructionBreakdownFieldsByDataset.set(this.dataset.id, [
                construction,
            ]);
            breakdownFields.push(constructionBreakdownFieldsByDataset);
        }

        return breakdownFields;
    }

    private getAggregateFieldForThematicReport(): DatasetField {
        return this.thematicMapMetric;
    }

    private getFilterForThematicInteraction() {
        let filter = JSON.parse(JSON.stringify(this.filter));
        let linkFilterToThematicLevel =
            this.mapThematicOverlayService.currentConfig.filter;
        if (linkFilterToThematicLevel) {
            const field: DatapointFilterField[] = [{id: 'link_' + this.mapThematicOverlayService.currentConfig.parentDatasetId, maxDateValue: null, minDateValue: null,maxNumberValue: null, minNumberValue: 0, searchValue: null, textValues:[this.mapThematicOverlayService.currentConfig.geometryID.geometryID], distanceUnit: null}];
            filter.fields = filter.fields.length > 0 ? [...filter.fields, ...field] : field;
        }
        return filter;
    }

    private getFloatingFieldsValues() {
        this.fetchLocationsCount();
        this.fetchValuesForFloatingFields();
    }

    fetchLocationsCount() {
        let currentLevelDataset =
            this.mapThematicOverlayService.currentConfig.dataset;
        let filter = this.getFilterForThematicInteraction();
        let projection = this.prepareProjectionForFloatingFields();
        projection.geometryPrecision = 25;
        let groups: AggregateGroupRequest[] = [];
        groups.push({
            datasetID: currentLevelDataset.id,
            fieldID:
                currentLevelDataset.thematicMapSettings.mainThematicFieldId,
        });

        let reportRequest: ReportRequest = {
            datasetID: this.dataset.id,
            aggregateFieldCodes: [{ aggregateFieldCode: "1", id: "count" }],
            groups: groups,
            aggregateFieldType: DatapointAggregateFieldType.FIELD,
        };
        this.subscriptions.add(
            this.datapointAggregateService
                .getDatapointsCount(this.dataset.id, filter, projection)
                .subscribe((result) => {
                    this.datapointsCount = result.count;
                })
        );
    }

    private fetchValuesForFloatingFields() {
        let currentLevelDataset =
            this.mapThematicOverlayService.currentConfig.dataset;
        this.floatingFieldsValuesByFields = new Map();

        let filter = this.getFilterForThematicInteraction();
        let projection = this.prepareProjectionForFloatingFields();

        this.datasetFloatingFields.forEach((field) => {
            let groups: AggregateGroupRequest[] = [];
            groups.push({
                datasetID: currentLevelDataset.id,
                fieldID:
                    currentLevelDataset.thematicMapSettings.mainThematicFieldId,
            });

            let formulaId = `${this.dataset.id}.${field.id}`;
            let reportRequest: ReportRequest = {
                datasetID: this.dataset.id,
                aggregateFieldCodes: [
                    { aggregateFieldCode: `VAR_${formulaId}`, id: formulaId },
                ],
                groups: groups,
                aggregateFieldType: DatapointAggregateFieldType.FIELD,
            };

            this.subscriptions.add(
                this.datapointAggregateService
                    .getDatapointsReport(
                        this.dataset.id,
                        filter,
                        reportRequest,
                        projection
                    )
                    .subscribe((result) => {
                        let totalValue = 0;
                        result.groupResults.forEach((groupResult) => {
                            if (
                                field.floatingOption ===
                                DatasetFieldFloatingOption.SUM
                            ) {
                                totalValue += groupResult.values[0].result;
                            } else {
                                totalValue +=
                                    groupResult.values[0].result /
                                    groupResult.values[0].count;
                            }
                        });
                        totalValue = Math.round(totalValue * 100) / 100;
                        this.floatingFieldsValuesByFields.set(
                            field,
                            totalValue
                        );
                    })
            );
        });

        this.fetchTotalForSelectedMetric(currentLevelDataset, filter);
    }

    private fetchTotalForSelectedMetric(currentLevelDataset: Dataset, filter) {
        // if (!this.thematicMapMetric) {
        //     return;
        // }
        let projection: DatapointProjection;

        let groups: AggregateGroupRequest[];
        let reportRequest: ReportRequest;
        if (isUndefined(this.thematicMapFormula)) {
            projection = {
                datasetID: this.dataset.id,
                fields: [this.thematicMapMetric.id],
                links: [
                    {
                        datasetID: currentLevelDataset.id,
                        fields: [
                            currentLevelDataset.thematicMapSettings
                                .mainThematicFieldId,
                        ],
                    },
                ],
            };
            groups = [
                {
                    datasetID: currentLevelDataset.id,
                    fieldID:
                        currentLevelDataset.thematicMapSettings
                            .mainThematicFieldId,
                },
            ];
            const formulaId = `${this.dataset.id}.${this.thematicMapMetric.id}`;
            reportRequest = {
                datasetID: this.dataset.id,
                aggregateFieldCodes: [
                    { aggregateFieldCode: `VAR_${formulaId}`, id: formulaId },
                ],
                groups: groups,
                aggregateFieldType: DatapointAggregateFieldType.FIELD,
            };
        } else {
            let currentLevelDatasetThematicField =
                currentLevelDataset.fields.find(
                    (field) =>
                        field.id ===
                        currentLevelDataset.thematicMapSettings
                            .mainThematicFieldId
                );
            let breakdownFields = this.getBreakdownFields(
                currentLevelDataset,
                currentLevelDatasetThematicField
            );
            const field = !isUndefined(
                breakdownFields[breakdownFields.length - 1].get(this.dataset.id)
            )
                ? breakdownFields[breakdownFields.length - 1].get(
                    this.dataset.id
                )[0].id
                : null;
            projection = {
                datasetID: this.dataset.id,
                fields: [field],
                links: [],
            };
            groups = [
                {
                    datasetID: this.dataset.id,
                    fieldID: field,
                },
            ];
            reportRequest = {
                datasetID: this.dataset.id,
                aggregateFieldFormulaJson: this.thematicMapFormula.data,
                groups: groups,
                aggregateFieldType: DatapointAggregateFieldType.FORMULA,
            };
        }

        this.subscriptions.add(
            this.datapointAggregateService
                .getDatapointsReport(
                    this.dataset.id,
                    filter,
                    reportRequest,
                    projection
                )
                .subscribe((result) => {
                    let totalValue = 0;
                    result.groupResults.forEach(
                        (groupResult) =>
                            (totalValue += groupResult.values[0].result)
                    );
                    totalValue = Math.round(totalValue * 100) / 100;
                    this.thematicMapTotal = totalValue;
                })
        );
    }

    private prepareProjectionForFloatingFields(isCountApiCall: boolean = false): DatapointProjection {
        let currentLevelDataset =
            this.mapThematicOverlayService.currentConfig.dataset;
        let fields = this.dataset.fields
            .filter((f) => f.isFloating)
            .map((f) => f.id);
        let projection = {
            datasetID: this.dataset.id,
            fields: fields,
            links: [],
        };
        let linkProjection: DatapointProjection = {
            datasetID: currentLevelDataset.id,
            fields: [
                currentLevelDataset.thematicMapSettings.mainThematicFieldId,
            ],
        };
        if (!isCountApiCall) {
            projection.links.push(linkProjection);
        }
        return projection;
    }

    toggleThematicMap(checked: boolean) {
        let mapMode = this.datapointsPageStateService.getMapInteractionStatus();

        switch (mapMode.mode) {
            case MapInteractionMode.DEFAULT:
                if (checked) {
                    // we activate thematic
                    // TODO don't need to do all this every time. make the separate call for the thematic overlays from backend.
                    this.mapThematicOverlayService.prepareThematicMapDatasets();

                    if (this.thematicMapPreconditionsAreMet()) {
                        this.datapointsPageStateService.updateMapMode({
                            mode: MapInteractionMode.THEMATIC_MAP_ACTIVE,
                            externalDatasetName: null,
                            externalDatasetId: null,
                        });
                        this.mapThematicOverlayService.enableThematicMap();
                    }
                }
                break;
            case MapInteractionMode.THEMATIC_MAP_ACTIVE:
                if (!checked) {
                    // we disabled it
                    this.removeThematicMapLegends();
                    this.datapointsPageStateService.updateMapMode(
                        defaultMapStatus
                    );
                    this.mapThematicOverlayService.disableThmeaticMap();
                    if (
                        this.analyticsPanel &&
                        this.analyticsPanel.instance.isOpen
                    ) {
                        const { componentRef } = this.analyticsPanel.instance;
                        componentRef.instance.removeAllReports();
                    }
                }
                break;
            case MapInteractionMode.INTERACTION_DATASET_ACTIVE:
                this.notifService.error(
                    "Can't activate. The FEMA flood mode is active."
                );
                break;
        }
    }

    thematicMapPreconditionsAreMet(): boolean {
        let firstFloatingField = this.dataset.fields.find(
            (field) => field.isFloating
        );
        if (!firstFloatingField) {
            this.notifService.error(
                "Thematic map needs at least one floating field"
            );
            this.thematicMapToggleElement.checked = false; // turn off toggle programmatically
            return false;
        }
        const parentOverlaySize =
            this.mapThematicOverlayService.datasetsByParents.size;
        if (parentOverlaySize !== NR_THEMATIC_MAP_LEVELS) {
            this.thematicMapToggleElement.checked = false; // turn off toggle programmatically
            this.notifService.error(
                `You should have access to ${NR_THEMATIC_MAP_LEVELS} thematic overlays (Found ${parentOverlaySize}). Please contact your administrator`
            );
            return;
        }

        return true;
    }

    onToggleImageOverlay(event: ActivateImageOverlayEvent) {
        if (event.active) {
            // should activate overlay
            event.interactive
                ? this.enableInteractiveImageOverlay(
                    event.overlay,
                    event.opacity
                )
                : this.enableImageOverlay(event.overlay, event.opacity);
        } else {
            // should remove overlay
            event.interactive
                ? this.disableInteractiveImageOverlay(event.overlay)
                : this.disableImageOverlay(event.overlay);
        }
    }

    private enableImageOverlay(overlay: ImageOverlay, opacity: number = 1) {
        let layer = new google.maps.ImageMapType({
            getTileUrl: (coord, zoom) =>
                DatapointsComponent.computeImageOverlayUrl(
                    overlay.url,
                    zoom,
                    coord.x,
                    coord.y,
                    overlay.apiKey,
                    overlay.id,
                    overlay.offset
                ),
            minZoom: 1,
            maxZoom: 15,
            name: overlay.id,
            opacity: opacity,
            tileSize: new google.maps.Size(
                defaultTileSize.width,
                defaultTileSize.height
            ),
        });

        this.mapStateService.insertImageOverlay(overlay, layer);
    }

    private enableInteractiveImageOverlay(
        overlay: ImageOverlay,
        opacity: number
    ) {
        this.mapStateService.insertInteractiveImageOverlay(overlay, opacity);
    }

    private disableInteractiveImageOverlay(overlay: ImageOverlay) {
        this.mapStateService.removeInteractiveImageOverlay(overlay);
    }

    private disableImageOverlay(overlay: ImageOverlay) {
        this.mapStateService.removeImageOverlay(overlay);
    }

    openEventNotificationsPanel() {
        if (this.notificationsPanel) {
            this.notificationsPanel.instance.showPanel();
        } else {
            this.notifPanelIsOpen = true;
            this.sidePanelService.setRootViewContainerRef(
                this.viewContainerRef
            );
            this.notificationsPanel =
                this.sidePanelService.open<EventNotificationComponentType>(
                    SidePanels.NOTIFICATIONS_PANEL,
                    {
                        width: 459,
                        id: "notificationsPanel",
                        panelTitle: "Notifications",
                        resizeable: true
                    },
                    {
                        dataset: this.dataset,
                        account: this.account,
                        groupsIds: this.groupsPanel.groupsIds,
                    }
                );
        }
    }

    openEditPanel($event: any) {
        if (this.alterDatapointsEnabled) {
            if (this.updateDatapointPanel) {
                this.updateDatapointPanel.instance.showPanel();
            } else {
                this.sidePanelService.setRootViewContainerRef(
                    this.viewContainerRef
                );
                this.updateDatapointPanel =
                    this.sidePanelService.open<UpdateDatapointComponentType>(
                        SidePanels.UPDATE_DATAPOINT,
                        {
                            width: 400,
                            id: "update-datapoint-panel",
                            panelTitle: "Edit",
                        },
                        {
                            dataset: this.dataset,
                        }
                    );

                this.datapointsServiceState.onUpdateDataPointInit$.subscribe(
                    () => {
                        const { componentRef } =
                            this.updateDatapointPanel.instance;
                        if (componentRef) {
                            componentRef.instance.setDataset($event);
                        }
                    }
                );

                this.datapointsServiceState.datapointUpdate$.subscribe(() =>
                    this.onUpdated()
                );
            }
        }
    }

    applyWorkspaceItemTableProjection(item: WorkspaceItem) {
        this.datapointsFilterService.updateArray([]);
        this.projection = JSON.parse(item.data).projection;
        this.filter = JSON.parse(item.data).filter;
        this.linkedAccountDatasets.forEach((dataset) => {
            dataset.fields.forEach((field) => (field.isProjected = false)); // set everything to false first

            if (dataset.id !== this.dataset.id) {
                this.projection.links.forEach((link) => {
                    if (link.datasetID === dataset.id) {
                        dataset.fields.forEach((field) => {
                            if (link.fields.includes(field.id)) {
                                field.isProjected = true;
                            }
                        });
                    }
                });
            } else {
                dataset.fields.forEach((field) => {
                    if (this.projection.fields.includes(field.id)) {
                        field.isProjected = true;
                    }
                });
            }
        });
        this.linkedAccountOverlays.forEach((dataset) => {
            dataset.fields.forEach((field) => (field.isProjected = false)); // set everything to false first

            this.projection.links.forEach((link) => {
                if (link.datasetID === dataset.id) {
                    dataset.fields.forEach((field) => {
                        if (link.fields.includes(field.id)) {
                            field.isProjected = true;
                        }
                    });
                }
            });
        });
        let fields = this.projection.fields;
        this.projection.fields = fields;
        if (this.workspaceItemsForFormulas) {
            this.workspaceItemsForFormulas.forEach((formula) => {
                formula.isProjected = false; // set everything to false
                if (this.projection.formulas) {
                    this.projection.formulas.forEach((projectedFormula) => {
                        if (projectedFormula.id === formula.id) {
                            formula.isProjected = true;
                        }
                    });
                }
            });
        }
        this.datapointsFilterService.updateArray(this.projection.fields);
        this.removeFilterBar();
        if (this.filter.fields.length) {
            this.createPopulatedFilterBarItemForTableView(this.dataset, this.filter.fields);
        }
        this.datapointFilterObject = this.constructFilterObject();
        if (this.projection.formulas && this.projection.formulas.length) {
            this.datapointsFilterService.updateFilter();
        }
        // this.datapointsFilterService.updateFilter();
        // setTimeout(() => {
        //     this.datapointFilterObject = this.constructFilterObject();
        //     this.datapointsFilterService.updateFilter();
        // }, 1000);
        // this.fetchDatapoints();
    }

    refreshWorkspaceItems(itemType: WorkspaceItemType) {
        this.fetchWorkspaceItems();
    }

    deleteWorkspaceItem(item: WorkspaceItem) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to delete "${item.name}"?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.workspaceItemsService
                        .deleteWorkspaceItem(item.id)
                        .subscribe(
                            (success) => {
                                this.fetchWorkspaceItems();
                                // update formulas projection
                                if (
                                    this.projection.formulas &&
                                    item.type === WorkspaceItemType.FORMULA &&
                                    item.isProjected
                                ) {
                                    this.findItemInListAndDelete(
                                        item,
                                        this.projection.formulas
                                    );
                                    this.fetchDatapoints();
                                }
                                // clear filters on delete one
                                if (item.type === WorkspaceItemType.FILTER) {
                                    this.removeFilterFromMap();
                                }
                                this.notifService.success(
                                    "Item was deleted successfully"
                                );
                            },
                            (error) =>
                                this.notifService.error(error.error.message)
                        );
                }
            });
    }

    removeFilterFromMap() {
        this.removeFilterBar();
        this.mapStateService.clearShapes(MapDrawType.FILTER);
        this.mapStateService.emitSelectedGeoRegion([]);
        this.mapStateService.clearFilteredGeoRegions(true);
    }

    /**
     * the filter on datapoints page and filter service will recreate itself when the filter bar items are parsed
     */
    applyWorkspaceItemFilter(item: WorkspaceItem) {
        let filter: DatapointFilter = JSON.parse(item.data);
        let statisticsNeedToBeFetched = false;

        // allow only one filter to project - Filter, Select, Zoom
        this.removeFilterFromMap();

        // populate filter bar items
        this.filterAccountDatasets.forEach((dataset) => {
            dataset.fields.forEach((field) => (field.selected = false)); // set everything to false first

            if (dataset.id === this.dataset.id) {
                filter.fields.forEach((filterField) => {
                    statisticsNeedToBeFetched = true;
                    this.statisticsFiltersFetched = true;
                    this.createPopulatedFilterBarItem(dataset, filterField);
                });
            } else {
                this.createFilterBarItemsFromFilterLinks(
                    filter,
                    dataset,
                    statisticsNeedToBeFetched
                );
            }
        });

        this.linkedAccountOverlays.forEach((dataset) => {
            dataset.fields.forEach((field) => (field.selected = false)); // set everything to false first
            this.createFilterBarItemsFromFilterLinks(
                filter,
                dataset,
                statisticsNeedToBeFetched
            );
        });

        // populate groups
        this.groupsPanel.markGroupsAsSelected(filter.groups);

        // populate shapes
        this.initializeShapesFromFilter(filter.geometryFilter);

        //populate Zoom region
        if (
            filter.geometryFilter &&
            filter.geometryFilter.regionIds &&
            filter.geometryFilter.regionIds.length > 0
        ) {
            this.initializeZoomRegionFromFilter(filter.geometryFilter);
        }
        if (statisticsNeedToBeFetched) {
            this.subscriptions.add(
                this.datapointsFilterService
                    .onFetchStatisticsReady()
                    .subscribe((ready) => {
                        if (
                            filter.fields.length > 0 &&
                            this.statisticsFiltersFetched
                        ) {
                            let filters = this.filterBarItems.filter((value) =>
                                filter.fields.find(
                                    (field) => field.id === value.id
                                )
                            );
                            if (filter.fields.length === filters.length) {
                                for (let i = 0; i < filters.length; i++) {
                                    if (
                                        filters[i].statisticValues !== null &&
                                        filter.fields[i].textValues.length > 0
                                    ) {
                                        filter.fields[i].textValues.forEach(
                                            (value) => {
                                                filters[i].statisticValues[
                                                    value
                                                ] = true;
                                                this.datapointsFilterService.populateFilterBarItemDisplayValues(
                                                    filters[i]
                                                );
                                            }
                                        );
                                    }
                                }
                            }
                            this.datapointsFilterService.initFilter(filter);
                            this.datapointsFilterService.emitFilterUpdate();
                            this.datapointFilterObject =
                                this.constructFilterObject();
                        }
                    })
            );
        } else {
            this.datapointsFilterService.initFilter(filter);
            this.datapointsFilterService.emitFilterUpdate();
            this.datapointFilterObject = this.constructFilterObject();
        }
    }

    private initializeZoomFromActiveFilter() {
        let geometryFilter =
            this.datapointsFilterService.getActiveFilter().geometryFilter;
        this.initializeZoomRegionFromFilter(geometryFilter);
    }

    initializeZoomRegionFromFilter = async (geometryFilter) => {
        if (
            geometryFilter &&
            geometryFilter.regionIds &&
            geometryFilter.regionIds.length > 0
        ) {
            let regionArray = [];
            from(geometryFilter.regionIds)
                .pipe(
                    mergeMap((param) =>
                        this.geoRegionsService.getRegion(+param)
                    )
                )
                .subscribe((val) => {
                    regionArray.push(val);
                    this.datapointsFilterService.emitzoomFromFiter(regionArray);
                    this.mapStateService.emitSelectedGeoRegion(regionArray);
                });
        }
    };

    private createFilterBarItemsFromFilterLinks(
        filter: DatapointFilter,
        dataset,
        statisticsNeedToBeFetched: boolean
    ) {
        filter.links.forEach((link) => {
            if (link.datasetID === dataset.id) {
                link.fields.forEach((linkField) => {
                    statisticsNeedToBeFetched = true;
                    this.createPopulatedFilterBarItem(dataset, linkField);
                });
                link.linkFields.forEach((linkField) => {
                    this.createPopulatedDistanceFilterBarItem(
                        dataset,
                        linkField
                    );
                });
            }
        });
    }

    private createPopulatedDistanceFilterBarItem(dataset, linkField) {
        let distanceField: DatasetField = {
            id: "distance",
            name: "Distance",
            type: DatasetFieldSpecificType.NUMBER_FIELD,
            baseType: DatasetFieldType.NUMBER,
        };
        let filterBarItem = this.datapointsFilterService.addFilterBarItem(
            distanceField,
            dataset,
            true
        );
        switch (linkField.distanceUnit) {
            case DistanceUnit.KM:
                filterBarItem.maxNumberValue =
                    ComputationUtils.getDistanceInUnit(
                        linkField.maxNumberValue,
                        DistanceUnit.KM
                    );
                break;
            case DistanceUnit.MILES:
                filterBarItem.maxNumberValue =
                    ComputationUtils.getDistanceInUnit(
                        linkField.maxNumberValue,
                        DistanceUnit.MILES
                    );
                break;
        }
        filterBarItem.minNumberValue = 0;
        filterBarItem.distanceUnit = linkField.distanceUnit;

        if (
            this.overlaysFilterDistance[dataset.id] !==
            filterBarItem.maxNumberValue
        ) {
            this.overlaysFilterDistance[dataset.id] =
                filterBarItem.maxNumberValue;
        }
    }

    private createPopulatedFilterBarItemForTableView(dataset, filterField) {
        const filterFieldIds = filterField.map((f) => f.id);
        let datasetFields = dataset.fields.filter(
            (ds) => filterFieldIds.includes(ds.id)
        );
        if (datasetFields.length) {
            let params : Map<string, any> = new Map();
            this.filter.fields.forEach(field => {
                params.set(
                    field.id,
                    field
                );
            })
            this.datapointsFilterService.addFilterBarItemForTableView(datasetFields, dataset, params);
        }
    }

    private createPopulatedFilterBarItem(dataset, filterField) {
        let datasetField = dataset.fields.find(
            (ds) => ds.id === filterField.id
        );
        if (datasetField) {
            datasetField.selected = true;
            if (filterField.textValues && filterField.textValues.length > 0) {
                this.datapointsFilterService.addFilterBarItem(
                    datasetField,
                    dataset,
                    false,
                    filterField.textValues
                );
            } else {
                let filterBarItem =
                    this.datapointsFilterService.addFilterBarItem(
                        datasetField,
                        dataset
                    );
                filterBarItem.maxNumberValue = filterField.maxNumberValue;
                filterBarItem.minNumberValue = filterField.minNumberValue;
                filterBarItem.maxDateValue = filterField.DateValue;
                filterBarItem.minDateValue = filterField.DateValue;
                filterBarItem.searchValue = filterField.searchValue;
            }
        }
    }

    get MapInteractionMode() {
        return MapInteractionMode;
    }

    get DistanceUnit() {
        return DistanceUnit;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get MapDrawType() {
        return MapDrawType;
    }

    get MapShape() {
        return MapShape;
    }

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    get WorkspaceItemType() {
        return WorkspaceItemType;
    }

    get JSON() {
        return JSON;
    }

    selectThematicMapMetric(field: DatasetField, $event: MatCheckboxChange) {
        this.customLegendsEnabled = false;
        this.mapThematicOverlayService.emitCustomizedLegends([]);
        this.legendLimitForm.reset();
        if ($event.checked) {
            this.thematicMapMetric = field;
            this.dataset.fields.forEach((f) => {
                f.selectedForTM = f.id === field.id;
            });
            this.thematicMapFormula = undefined;
            this.workspaceItemsForFormulas.forEach(
                (f) => (f.selectedForTM = false)
            );
        } else {
            this.thematicMapMetric = undefined;
        }
        if (this.isThematicMapActive) {
            this.mapThematicOverlayService.setActive(
                this.mapThematicOverlayService.currentConfig
            );
        }
    }

    selectThematicMapGrouping(item: string, $event: MatCheckboxChange) {
        if ($event.checked) {
            this.thematicMapGrouping = item;
        } else {
            this.thematicMapGrouping = "";
        }
        if (this.isThematicMapActive) {
            this.mapThematicOverlayService.setActive(
                this.mapThematicOverlayService.currentConfig
            );
        }
    }

    fetchExternalDataByCountry(datasetsByCountry: TessadataDatasetsByCountry) {
        let datasetsIds = [].concat
            .apply([], datasetsByCountry.datasets)
            .map((dataset) => dataset.datasetId);
        this.tableComponent.fetchExternalData([datasetsIds], false);
    }

    onCustomLocationDetailsSelected(location: Point) {
        if (this.addressLocationPanel) {
            this.addressLocationPanel.instance.hidePanel(null, true);
            this.addressLocationPanel.instance.showPanel();

            const { componentRef } = this.addressLocationPanel.instance;
            if (componentRef) {
                componentRef.instance.setLocation({
                    address: location,
                    externalPOIMenuItems: this.externalPOIMenuItems,
                    externalPOIDatasetsStructures:
                        this.externalPOIDatasetsStructures,
                });
                componentRef.instance.datasetID = this.dataset.id;
            }
        } else {
            this.sidePanelService.setRootViewContainerRef(
                this.viewContainerRef
            );
            this.addressLocationPanel =
                this.sidePanelService.open<AddressLocationProfileComponent>(
                    SidePanels.ADDRESS_LOCATION_PROFILE,
                    {
                        id: "addressLocationProfilePanel",
                        width: 459,
                        panelTitle: "Address Profile",
                        panelIcon: 'fa-map-marker-alt',
                        resizeable: true
                    }
                );

            this.datapointsServiceState.onAddressLocationProfileInit$.subscribe(
                () => {
                    if(this.addressLocationPanel){
                        const { componentRef } = this.addressLocationPanel.instance;
                        if (componentRef) {
                            componentRef.instance.datasetID = this.dataset.id;
                            componentRef.instance.setLocation({
                                address: location,
                                externalPOIMenuItems: this.externalPOIMenuItems,
                                externalPOIDatasetsStructures:
                                    this.externalPOIDatasetsStructures,
                            });
                        }
                    }
                }
            );

            this.sidePanelService.onHide$.subscribe((id: string) => {
                if (id === "addressLocationProfilePanel")
                    this.removeSearchResultMarker();
            });

            this.sidePanelService.onDropDownValueChanged$.subscribe(
                (value: string) => {
                    this.datapointsServiceState.emitDropDownValue(value);
                }
            );

            this.datapointsServiceState.onAddressLocationProfileDestroyed$.subscribe(
                () => {
                    this.removeSearchResultMarker();
                }
            );
        }
    }

    isNotAllDatasetSelected(
        fields: DatasetField[],
        isLocationCheckBox?: boolean
    ): boolean {
        return (
            fields.filter((field) => {
                if (
                    isLocationCheckBox &&
                    (field.scope === this.datasetFieldScope.NRI ||
                        field.scope === this.datasetFieldScope.EXTERNAL)
                ) {
                    return;
                }

                return !field.isProjected;
            }).length > 0
        );
    }

    isNotAllNRIDatasetSelected(
        fields: DatasetField[],
        isLocationCheckBox?: boolean
    ): boolean {
        let result = false;
        fields.forEach((element1) => {
            let count = 0;
            element1.child.forEach((element2) => {
                if (
                    element2.child.scope == this.datasetFieldScope.NRI &&
                    element2.child.isProjected == false
                ) {
                    element1.isParentSelected = false;
                    result = true;
                    return;
                } else if (
                    element2.child.scope == this.datasetFieldScope.NRI &&
                    element2.child.isProjected == true
                ) {
                    count += 1;
                }
            });

            if (count == element1.child.length) {
                element1.isParentSelected = true;
            }
        });
        return result;
    }

    onFilterAllClick(
        $event: MatCheckboxChange,
        fields: DatasetField[],
        dataset: Dataset,
        isLocationCheckBox?: boolean
    ) {
        fields.forEach((field) => {
            if (
                isLocationCheckBox &&
                (field.scope === this.datasetFieldScope.NRI ||
                    field.scope === this.datasetFieldScope.EXTERNAL)
            ) {
                return;
            }

            field.isProjected = $event.checked;
            this.onProjectionChange(dataset, field);
        });
    }

    onNRIFilterAllClick(
        $event: MatCheckboxChange,
        fields: DatasetField[],
        dataset: Dataset,
        isLocationCheckBox?: boolean
    ) {
        fields.forEach((element1) => {
            element1.child.forEach((element2) => {
                if (element2.child.scope == this.datasetFieldScope.NRI) {
                    element2.child.isProjected = $event.checked;
                    this.onProjectionChange(dataset, element2);
                }
            });
            element1.isParentSelected = $event.checked;
        });
    }

    closeClusterPanel() {
        this.clusterPanel.instance.hidePanel();
    }

    removeSearchResultMarker() {
        this.mapStateService.activeSearchResultMarker$.next(null);
        this.mapStateService.updateCurrentPinAddress(null);
        this.mapStateService.emitMarkerIsInitialized(false);
    }

    openPopup = (item) => {
        if (this.isThematicMapActive) {
            let currentActiveChecked =
                this.thematicMapFormula || this.thematicMapMetric;
            if (
                item.id === currentActiveChecked.id &&
                item.name === currentActiveChecked.name
            ) {
                this.customLegendsEnabled = true;
            }
        } else {
            this.notifService.error(
                "Please enable thematic Map first before customizing legends."
            );
        }
    };

    closePopup = () => {
        this.customLegendsEnabled = false;
        this.legendLimitForm.reset();
    };

    getLegends = () => {
        if (this.legendLimitForm.valid) {
            this.customLegendsEnabled = false;
            let legendArray = [];
            Object.keys(this.legendLimitForm.controls).forEach((key) => {
                legendArray.push(this.legendLimitForm.controls[key].value);
            });
            this.mapThematicOverlayService.emitCustomizedLegends(legendArray);
            this.mapThematicOverlayService.setActive(
                this.mapThematicOverlayService.currentConfig
            );
        } else {
            this.notifService.error("Please enter values for all fields.");
        }
    };

    groupByNriFields(nriFields) {
        const groupBy = (array, key) => {
            // Return the end result
            return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                let symbol = null;
                if (currentValue[key].trim().split("-").length > 1) {
                    symbol = "-";
                } else if (currentValue[key].trim().split("–").length > 1) {
                    symbol = "–";
                }
                if (isNullOrUndefined(symbol)) {
                    (result[currentValue[key].trim()] =
                        result[currentValue[key].trim()] || []).push({
                            name: currentValue[key].trim(),
                            child: currentValue,
                        });
                } else {
                    (result[currentValue[key].trim().split(symbol)[0].trim()] =
                        result[
                        currentValue[key].trim().split(symbol)[0].trim()
                        ] || []).push({
                            name: currentValue[key].trim().split(symbol)[1],
                            child: currentValue,
                        });
                }
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
            }, {}); // empty object is the initial value for result object
        };
        const groupByObjectResponse = groupBy(nriFields, "name");
        let tempArray = [];
        let nriSummaryArray = [
            { name: "NRI Summary", isParentSelected: true, child: [] },
        ];
        Object.keys(groupByObjectResponse).map(function (key) {
            let nameSplitValue = key.split(" ");
            groupByObjectResponse[key][0].child.isProjected = true;
            if (
                [
                    TessadataNriFields.SUMMARY_SOVI_RATING,
                    TessadataNriFields.SUMMARY_RESL_RATING,
                ].includes(groupByObjectResponse[key][0].child.id)
            ) {
                nriSummaryArray[0].child.push({
                    name:
                        nameSplitValue[1] + " " + nameSplitValue[2] + " Rating",
                    child: groupByObjectResponse[key][0].child,
                });
            } else if (
                groupByObjectResponse[key][0].child.id ==
                TessadataNriFields.SUMMARY_EAL_RATING
            ) {
                nriSummaryArray[0].child.push({
                    name: nameSplitValue[1] + " Rating",
                    child: groupByObjectResponse[key][0].child,
                });
            } else if (
                groupByObjectResponse[key][0].child.id ==
                TessadataNriFields.SUMMARY_RISK_RATING
            ) {
                nriSummaryArray[0].child.push({
                    name: "Overall " + nameSplitValue[1],
                    child: groupByObjectResponse[key][0].child,
                });
            } else {
                tempArray.push({
                    name: key,
                    isParentSelected: true,
                    child: groupByObjectResponse[key],
                });
                return tempArray;
            }
        });

        if (nriSummaryArray[0].child.length > 0) {
            tempArray = [...nriSummaryArray, ...tempArray];
        }
        return tempArray;
    }

    bindFilterIdRecurciveCall(parent, fieldIds, isFilterBarItemRemoveCall) {
        if (isUndefined(parent.children) && parent.length) {
            parent.forEach((element) => {
                if (!isUndefined(element.children) && element.children.length) {
                    this.bindFilterIdRecurciveCall(
                        element.children,
                        fieldIds,
                        isFilterBarItemRemoveCall
                    );
                    return;
                } else if (
                    isUndefined(element.children) &&
                    fieldIds.length > 0 &&
                    fieldIds.includes(element.id)
                ) {
                    element.selected = isFilterBarItemRemoveCall ? false : true;
                } else if (
                    isUndefined(element.children) &&
                    fieldIds.length > 0 &&
                    !fieldIds.includes(element.id)
                ) {
                    element.selected = false;
                } else if (
                    isUndefined(element.children) &&
                    fieldIds.length <= 0
                ) {
                    element.selected = false;
                }
            });
        }
    }

    bindFilterIds($event, isFilterBarItemRemoveCall: boolean = false) {
        if ($event.fieldIds.length > 0) {
            this.dataSource.data.forEach((element) => {
                if (!isUndefined(element.children) && element.children.length) {
                    this.bindFilterIdRecurciveCall(
                        element.children,
                        $event.fieldIds,
                        isFilterBarItemRemoveCall
                    );
                    return;
                }
            });
        } else if ($event.fieldIds.length <= 0 && isFilterBarItemRemoveCall) {
            this.dataSource.data.forEach((element) => {
                if (!isUndefined(element.children) && element.children.length) {
                    this.bindFilterIdRecurciveCall(
                        element.children,
                        $event.fieldIds,
                        isFilterBarItemRemoveCall
                    );
                    return;
                }
            });
        }
    }

    public openViewSaveAsModal() {
        // let agTableColumns = this.tableComponent.prepareColumnsForSaveAs();
        // agTableColumns = agTableColumns
        //     .filter((el) => el.split("_").length <= 1)
        //     .map((row) => row);
        //         let projection: DatapointProjection = this.projection;
        //         const selectedDownloadRequestData = this.tableComponent
        //               ? this.tableComponent.getSelectedDownloadRequestData()
        //               : { selectedRows: [], selectedColumns: [], overlayColumns: [] };
        //           projection.fields = selectedDownloadRequestData.selectedColumns;
        // if (agTableColumns.length) {
        //     projection.fields = agTableColumns.filter(
        //     (element) => element.split("_").length <= 1
        //     );
        // }
        let projection: DatapointProjection = this.projection;
        let formulaNames = projection.formulas.length ? projection.formulas.map((field) => field.name) : [];
        const selectedDownloadRequestData = this.tableComponent
            ? this.tableComponent.getSelectedDownloadRequestData()
            : { selectedRows: [], selectedColumns: [], overlayColumns: [] };
        projection.fields = formulaNames.length ? selectedDownloadRequestData.selectedColumns.filter(column => !formulaNames.includes(column)) : selectedDownloadRequestData.selectedColumns; // [...new Set(projection.fields)];
        this.workspaceIemDialog.open(
            { filter: this.filter, projection: projection },
            WorkspaceItemType.TABLE_PROJECTION,
            false
        );
    }

    public openViewSaveModal() {
        let projection: DatapointProjection = this.projection;
        let formulaNames = projection.formulas && projection.formulas.length ? projection.formulas.map((field) => field.name) : [];
        const selectedDownloadRequestData = this.tableComponent
            ? this.tableComponent.getSelectedDownloadRequestData()
            : { selectedRows: [], selectedColumns: [], overlayColumns: [] };
        projection.fields = formulaNames.length ? selectedDownloadRequestData.selectedColumns.filter(column => !formulaNames.includes(column)) : selectedDownloadRequestData.selectedColumns; // [...new Set(projection.fields)];
        this.workspaceIemDialog.open(
            { filter: this.filter, projection: projection },
            WorkspaceItemType.TABLE_PROJECTION,
            true
        );
    }

    public closeCrisis24Panel(datasetId) {
        if (
            this.crises24DatapointsAgTablePanel &&
            datasetId == Crisis24Alert.getId()
        ) {
            this.crises24DatapointsAgTablePanel.instance.closePanel();
            this.crises24DatapointsAgTablePanel = null;
        }
    }

    callFilterAction($event, node) {
        const params = node.params;
        if (["dataset", "tesadataGroup", "nriUSA"].includes(params.callType)) {
            this.onFilterMenuFieldClick(!$event, params.dataset, params.field);
        } else if (params.callType == "climate") {
            this.onFilterMenuFieldClick(!$event, params.overlay, params.field);
        }
    }

    distanceFilter(dataset: Dataset, distance, distanceUnit: DistanceUnit) {
        this.applyDistanceFilter(dataset, distance, distanceUnit);
    }

    getDisplayName(node) {
        if (!isUndefined(node?.params?.field)) {
            const field = node.params.field;
            return field.displayName == null || field.displayName == undefined
                ? field.name
                : field.displayName;
        }
        return node.name;
    }

    openImport(): void {
        this.uploadComponent.openComponent();
    }

    onUploadComplete() {
        this.notifService.success("Successfully uploaded datapoints");
        this.datapointsFilterService.emitFilterUpdate();
    }

    changeInSelectedLocationProfile($event) {
        this.currentSelectedProfile = $event.newSelectedValue;
        this.mapComponent.createTensorflightPolygon($event);
    }

    prepareClimateDownloadLinks(selectedClimateData) {
        const outputData: OutputItem[] = selectedClimateData.reduce((acc: OutputItem[], item: any) => {
            const overlay = item.params?.overlay;
            if (overlay) {
              const datasetID = overlay.id;
              const existingItem = acc.find((outputItem) => outputItem.datasetID === datasetID);

              if (existingItem) {
                existingItem.fields.push(item.id);
              } else {
                acc.push({ datasetID, fields: [item.id] });
              }
            }

            return acc;
          }, []);
         return outputData;
    }

    updateMap() {
        this.mapStateService.updateOverlay(
            this.dataset.id,
            this.generatePortfolioLayer(true)
        );
    }

    toggleDatasetList(event: Event) {
        event.stopPropagation();
        this.showDatasetList = !this.showDatasetList;
    }

    toggleFormulasMenu(): void {
    this.formulasMenuOpen = !this.formulasMenuOpen;
    }

    isFormulasMenuOpen(): boolean {
    return this.formulasMenuOpen;
    }

    toggleWorkspaceItemsProjectionMenu(): void {
    this.workspaceItemsProjectionMenuOpen = !this.workspaceItemsProjectionMenuOpen;
    }

    isWorkspaceItemsProjectionMenuOpen(): boolean {
    return this.workspaceItemsProjectionMenuOpen;
    }

    isThematicMapGroup: boolean = false;
    toggleThematicMapGroup(event: Event) {
        event.stopPropagation();
        this.isThematicMapGroup = !this.isThematicMapGroup;
    }

    isThematicMapValue: boolean = false;
    toggleThematicMapValue(event: Event) {
        event.stopPropagation();
        this.isThematicMapValue = !this.isThematicMapValue;
    }

    isThematicMapFormulas: boolean = false;
    toggleThematicMapFormulas(event: Event) {
        event.stopPropagation();
        this.isThematicMapFormulas = !this.isThematicMapFormulas;
    }

    prepareClimateOverlays() {
        return ObjectUtils.clone(
            this
                .filterAccountOverlays
        );
        // .filter(
        //     (params) =>
        //         ![
        //             "Risk Maps",
        //         ].includes(
        //             params.group
        //                 .name
        //         )
        // )
    }

    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

    displayNodeData(node: TreeStructure): void {
        node.expanded = !node.expanded;
        if (node.expanded && node.children) {
            this.expandAllChildren(node.children);
        }
    }

    expandAllChildren(nodes: TreeStructure[]): void {
        nodes.forEach((childNode) => {
            childNode.expanded = true;
            if (childNode.children) {
                this.expandAllChildren(childNode.children);
            }
        });
    }

    toggleExternalData(event: Event): void {
        event.stopPropagation();
        this.externalData = !this.externalData;
    }

    toggleReportList(event: Event) {
        event.stopPropagation();
        if(!this.reports.length){
            return;
        }
        this.showReportList = !this.showReportList;
    }

    getReports(datasetId, accountId) {
        this.showReportList = false;
        this.reportService.fetchReports(datasetId, accountId);
        this.subscriptions.add(
            this.reportService.reports$.subscribe(reports => {
                this.reports = reports;
            })
        );

        this.subscriptions.add(
            this.workspaceItemsService.isReportsCallClose$.subscribe(
                (response) => {
                    if (response) {
                        this.reportPanel.instance.closePanel();
                    }
                }
            )
        );
    }

    openReportPanelInCreateMode() {
        this.openReportPanel();
    }

    openReportPanelInEditMode(report) {
        this.openReportPanel(false, report)
    }

    openReportPanel(isCreateMode = true, report = null){
        this.sidePanelService.setRootViewContainerRef(
            this.viewContainerRef
        );
        this.reportPanel =
            this.sidePanelService.open<ReportPanelConfig>(
                SidePanels.REPORT_PANEL,
                {
                    width: 459,
                    id: REPORT_PANEL_ID,
                    panelTitle: "Reports",
                    resizeable: true,
                    panelIcon: 'icon-analitycs',
                },
                {
                    dataset: this.dataset,
                    isCreateMode: isCreateMode,
                    selectedReport: report,
                    tabsAccess: { FLOOD_FEMA: this.isFloodFEMAAssociated()}
                }
            );
    }

    isFloodFEMAAssociated(): boolean {
        let hasFloodFEMAAccess = false;

        if (this.externalDataMenuItems?.length) {
            this.externalDataMenuItems.some((country) => {
                if (country?.datasets?.length) {
                    const hasFlood = country.datasets.findIndex(dataset => dataset.datasetId === Constants.FLOOD_FEMA_DATASET_ID) !== -1;
                    if (hasFlood) {
                        hasFloodFEMAAccess = true;
                        return true;
                    }
                }
                return false;
            });
        }

        return hasFloodFEMAAccess;
    }

    downloadRenewalReport(report){
        this.reportService.downloadRenewalReport(report);
    }

    deleteRenewalReport(report) {
        this.reportService.deleteRenewalReport(report, this.dataset.id, this.dataset.accountID);
    }

    truncateName(name: string): string {
        if (name.length > 20) {
            return name.substring(0, 16) + '...';
        }
        return name;
    }
}
