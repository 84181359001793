<div class="report-box">
    <div>
        <div class="report-header">
            <div *ngIf="dataSource.data[0]?.children.length === 0">
                <span class="report-icon header-icon" matTooltip="Group by fields">
                    <!-- tasks-icon -->
                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004" />
                            <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" d="m18.665 15.167-2.083 2.085-1.25-1.25" />
                            <path d="M-.005-.005h24.01v24.01H-.005z" />
                        </g>
                    </svg>
                </span>
            </div>
            <div *ngIf="dataSource.data[0]?.children.length > 0">
                <div [matMenuTriggerFor]="menu" style="cursor: pointer" (mouseleave)="collapseAll()">

                        <span class="report-icon header-icon" matTooltip="Group by fields">
                            <!-- tasks-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004" />
                                    <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" d="m18.665 15.167-2.083 2.085-1.25-1.25" />
                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                </g>
                            </svg>
                        </span>

                    <span (click)="generateReport(); $event.stopPropagation()" *ngIf="selectedFieldsByDataset.size > 0"
                        class="report-submit-btn">
                        <!-- check-outline-icon -->
                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="position: relative;top:2px;">
                            <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" cx="12.005" cy="12.005" r="9.004"/>
                                <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="m8.448 12.344 2.167 2.168-.014-.014 4.891-4.891"/>
                                <path d="M0 0h24.01v24.01H0z"/>
                            </g>
                        </svg>

                    </span>
                </div>
                <mat-menu #menu="matMenu" (closed)="generateReport()" (mouseLeave)="collapseAll()" style="height: 360px !important;" class="analytics">
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="sub-menu-options-child">
                        <!-- This is the tree node template for leaf nodes -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="sub-menu-form-options-tree">

                            <li class="mat-tree-node sub-menu-checkbox-options" [matTooltip]="getDisplayName(node)" matTooltipPosition="right"
                                *ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
                                <button mat-icon-button disabled></button>
                                <mat-checkbox class="checklist-leaf-node" [checked]="node.selected"
                                    [disabled]="!node.selected &&  selectedFieldsCount == 2" [(ngModel)]="node.selected"
                                    (ngModelChange)="onFieldsMenuClick(node.selected, dataset, node.params?.field, node?.params?.overlay)">
                                    {{getDisplayName(node)}}</mat-checkbox>
                            </li>
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-nested-tree-node *matTreeNodeDef="let node; when: filterTreeStrcuture.hasChild"
                            class="sub-menu-options-parent external-sub-menu">
                            <li>
                                <hr *ngIf="dataSource.data.length > 1" class="menu-line-separator" />
                                <div class="mat-tree-node" [matTooltip]="node.name" matTooltipPosition="right">
                                    <span class="filter-inner-parent">{{node.name}}</span>
                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                        </mat-icon>
                                    </button>
                                </div>
                                <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </ul>
                            </li>
                        </mat-nested-tree-node>
                    </mat-tree>
                </mat-menu>

            </div>
            <div class="report-type-icons-container">
                <span (click)="setDisplayType(ReportDisplayType.TABLE)" class="report-icon"
                    [ngClass]="{'selected': reportSubType===ReportDisplayType.TABLE && dataIsReady, 'active' : dataIsReady}">
                    <i class="fal fa-table chart-icons" style="font-size: 14px; position: relative;bottom: 1px;"></i>
                </span>
                <span (click)="setDisplayType(ReportDisplayType.BAR_CHART)" class="report-icon"
                    [ngClass]="{'selected': reportSubType===ReportDisplayType.BAR_CHART && dataIsReady, 'active' : dataIsReady}">
                    <!-- chart-bar-icon -->
                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path
                                d="M16.5 3.5h3A1.5 1.5 0 0 1 21 5v14a1.5 1.5 0 0 1-1.5 1.5H15V5a1.5 1.5 0 0 1 1.5-1.5zM15 20.5H9v-10A1.5 1.5 0 0 1 10.5 9H15M4.5 15H9v5.5H4.5A1.5 1.5 0 0 1 3 19v-2.5A1.5 1.5 0 0 1 4.5 15z"
                                stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                class="chart-icons" />
                            <path d="M24 24H0V0h24z" />
                        </g>
                    </svg>
                </span>
                <span (click)="setDisplayType(ReportDisplayType.PIE_CHART)" class="report-icon"
                    [ngClass]="{'selected': reportSubType===ReportDisplayType.PIE_CHART && dataIsReady, 'active' : dataIsReady}">
                    <i class="fal fa-chart-pie chart-icons" style="font-size: 14px; position: relative;bottom: 1px;"></i>
                </span>
                <span (click)="closed.emit()" class="report-icon active">
                    <!-- cross-icon -->
                    <svg width="11" height="11" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"
                            stroke="#002d57" stroke-width="1.5" class="chart-icons">
                            <path d="m1 1 3.333 3.333L1 7.667" />
                            <path d="M7.333 1 4 4.333l3.333 3.334" />
                        </g>
                    </svg>
                </span>
                <span *ngIf="isLocationTypeApplication()" [matMenuTriggerFor]="actionsMenu" class="report-icon active">
                    <!-- dot-menu-icon -->
                    <svg width="13" height="13" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#002d57" fill-rule="evenodd">
                            <circle cx="4.5" cy="1" r="1" />
                            <circle cx="4.5" cy="4" r="1" />
                            <circle cx="4.5" cy="7" r="1" />
                        </g>
                    </svg>
                </span>
                <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu" backdropClass="mat-menu-header">
                    <hr *ngIf="dashboardWorkspaceItems.length" class="menu-line-separator" />
                    <div mat-menu-item *ngIf="dashboardWorkspaceItems.length"
                        (click)="saveWorkspaceItemToDashboard.emit()">
                        <span>
                            <!-- save-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                    <path
                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                    <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                    <path d="M0 0h24.01v24.01H0z" />
                                </g>
                            </svg>
                        </span>
                        {{getSaveButtonLabel()}}
                    </div>
                    <hr *ngIf="dashboardWorkspaceItems.length > 0" class="menu-line-separator" />
                    <div mat-menu-item (click)="saveAsWorkspaceItemToDashboard.emit()">
                        <span>
                            <!-- save-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                    <path
                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                    <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                    <path d="M0 0h24.01v24.01H0z" />
                                </g>
                            </svg>
                        </span>
                        {{getSaveAsButtonLabel()}}
                    </div>
                </mat-menu>
            </div>
        </div>

        <div *ngIf="!dataIsReady" class="report-instructions">
            Select from above the fields you want to count by
        </div>

        <div *ngIf="dataIsReady" class="report-content">
            <div class="report-table" *ngIf="reportSubType===ReportDisplayType.TABLE">
                <table mat-table matSort matSortDisableClear (matSortChange)="sortData($event, true)"
                    [matSortActive]="sort?.active" [matSortDirection]="sort?.direction" [dataSource]="reportData"
                    class="report-table">

                    <ng-container *ngFor="let dynamicColumn of dynamicColumns | keyvalue; let i = index"
                        matColumnDef="{{dynamicColumn.key}}">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{dynamicColumn.value}}</th>
                        <td mat-cell *matCellDef="let element ">
                            {{element.dynamicFieldValuesByIds.get(dynamicColumn.key)}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{i === 0 ? 'Total' : '' | commaFormat}}</td>
                    </ng-container>

                    <ng-container [matColumnDef]="PERCENTAGE_COLUMN_ID">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Total %</th>
                        <td mat-cell *matCellDef="let element"> {{element.percentage}}%</td>
                        <td mat-footer-cell *matFooterCellDef>100%</td>
                    </ng-container>

                    <ng-container [matColumnDef]="COUNT_COLUMN_ID">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Count</th>
                        <td mat-cell *matCellDef="let element"> {{element.count | commaFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef> {{totalCount | commaFormat}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                    <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
                </table>
            </div>
            <div *ngIf="reportSubType===ReportDisplayType.BAR_CHART">
                <div *ngIf="selectedFieldsCount > 2" class="report-instructions">
                    Unable to create a graph with more than two fields selected for grouping by
                </div>
                <canvas *ngIf="selectedFieldsCount <= 2" mdbChart chartType="bar" [datasets]="chartDatasets"
                    [labels]="chartLabels" [options]="chartOptions" [colors]="chartColors"
                    [legend]="selectedFieldsCount > 1">
                </canvas>
            </div>
            <div *ngIf="reportSubType===ReportDisplayType.PIE_CHART">
                <div *ngIf="selectedFieldsCount > 2" class="report-instructions">
                    Unable to create a graph with more than two fields selected for grouping by
                </div>
                <canvas *ngIf="selectedFieldsCount <= 2" mdbChart chartType="pie" [datasets]="chartDatasets"
                    [labels]="chartLabels" [options]="chartOptions" [colors]="chartColors"
                    [legend]="selectedFieldsCount > 1">
                </canvas>
            </div>
        </div>
    </div>
    <div class="report-title-container">
        <input class="report-bottom-title" placeholder="Count" [(ngModel)]="reportName">
    </div>
</div>

<!-- <app-workspace-item-dialog #workspaceItemDialog [accountId]="dataset?.accountID" [datasetId]="dataset?.id"
    (itemSubmitted)="fetchWorkspaceItems()"></app-workspace-item-dialog> -->
