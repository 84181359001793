import { TooltipComponent } from "@angular/material/tooltip";

export class ObjectUtils {
    private static hasFormattedHTMLTags = false;
    public static clone<T>(object: T): T {
        return object ? JSON.parse(JSON.stringify(object)) : object;
    }

    public formatStringWithHTMLtags() {
        if (ObjectUtils.hasFormattedHTMLTags) {
            return;
        }
        Object.defineProperty(TooltipComponent.prototype, "message", {
            set(v: any) {
                const el = document.querySelectorAll(".mat-tooltip");

                if (el) {
                    el[el.length - 1].innerHTML = v;
                }
            },
        });

        ObjectUtils.hasFormattedHTMLTags = true;
    }

    // find unique array object using id.
    public static filterUniqueObjectArray(data: any) {
        const seenIds: Record<string, boolean> = {};
        return data.filter(item => {
            if (!seenIds[item.id]) {
                seenIds[item.id] = true;
                return true;
            }
            return false;
        });
    }

}
