import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { DatapointsPageStateService } from "../../datapoints-page-state.service";
import { Dataset } from "../../../../model/dataset/dataset";
import { DatapointsAggregateService } from "../../../../data-access-layer/datapoints/datapoints-aggregate.service";
import { DatasetGeometryType } from "../../../../model/dataset/dataset-geometry-type";
import { DatasetField } from "../../../../model/dataset/field/dataset-field";
import { DatasetFieldType } from "../../../../model/dataset/dataset-field-type";
import { DatasetUtils } from "../../../../core/utils/dataset-utils";
import { ReportDisplayType } from "../../../../model/analytics/report-display-type";
import { DatapointFilter } from "../../../../model/datapoint/filter/datapoint-filter";
import { DatapointProjection } from "../../../../model/datapoint/projection/datapoint-projection";
import { ReportRequest } from "../../../../model/datapoint/report/report-request";
import { AggregateGroupRequest } from "../../../../model/datapoint/report/aggregate-group-request";
import { ReportResultResponse } from "../../../../model/datapoint/report/report-result-response";
import { ReportResultGroupResponse } from "../../../../model/datapoint/report/report-result-group-response";
import { Sort } from "@angular/material/sort";
import { ReportRow } from "../../../../model/datapoint/report/count/report-row";
import { ChartInfo } from "../../../../model/datapoint/report/chart-info";
import { ColorUtils } from "../../../../core/utils/color-utils";
import { ChartDataSets, ChartLegendLabelItem } from "chart.js";
import { DatapointsFilterService } from "../../datapoints-filter.service";
import { Subscription } from "rxjs";
import { UserStateService } from "../../../../auth/user-state-service";
import { DatasetFieldSpecificType } from "../../../../model/dataset/dataset-field-specific.type";
import {
    TableColumn,
    TableColumnType,
    TableColumnAlignment,
} from "../../../../model/upload/table/table-column";
import { TableRow } from "../../../../model/upload/table/table-row";
import { TableCell } from "../../../../model/upload/table/table-cell";
import { DownloadReportItem } from "../../../../model/download/item/download-report-item";
import { DownloadReportTableRequest } from "../../../../model/download/download-report-table-request";
import { ReportComponent } from "../report.component";
import {
    DownloadReportChartRequest,
    ValueKey,
} from "../../../../model/download/download-report-chart-request";
import { DownloadReportChartValueRequest } from "../../../../model/download/download-report-chart-value-request";
import { DownloadItemReportType } from "../../../../model/download/item/download-item-report-type";
import { DatapointAggregateFieldType } from "../../../../model/datapoint/report/datapoint-aggregate-field-type";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";
import { ActivatedRoute } from "@angular/router";
import { GroupWithOverlaysTreeNode } from "../../../../model/overlay/group/group-with-overlays-tree-node";
// import { ThrowStmt } from "@angular/compiler";
import * as _ from "agile";
import { isUndefined } from "src/app/core/utils/util-master";
import { ObjectUtils } from "src/app/core/utils/object-utils";
import { WorkspaceItemService } from "src/app/data-access-layer/workspace-item/workspace-item.service";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { ReportItem } from "src/app/model/analytics/report-item";
import { MatDialog } from "@angular/material/dialog";
import { WorkspaceItemDialogComponent } from "src/app/dataset/workspace-item/projection/workspace-item-dialog.component";
import { MaptycsApplication } from "src/app/model/account/maptycs-application";
import { StringUtils } from "src/app/core/utils/string-utils";
import { ReportType } from "src/app/model/analytics/report-type";
import { ChartDisplayType } from "src/app/model/analytics/chart-display-type";
import { MatMenu } from "@angular/material/menu";
import { TreeStructureUtils } from "src/app/core/utils/tree-structure-utils";
import { Datapoints } from "../../datapoints";

@Component({
    selector: "map-count-report",
    templateUrl: "./count-report.component.html",
    styleUrls: ["./count-report.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CountReportComponent
    implements OnInit, OnDestroy, ReportComponent {
    @ViewChild("workspaceItemDialog", { static: true })
    workspaceIemDialog: WorkspaceItemDialogComponent;
    MaptycsApplication = MaptycsApplication;

    private readonly subscriptions: Subscription = new Subscription();
    private LIMIT: number = 247;
    _nriFields: any;
    _climateOverlays: any;

    @Input() dataset: Dataset;
    @Input() uuid: string;
    @Output() closed = new EventEmitter();
    @Output() saveWorkspaceItemToDashboard = new EventEmitter();
    @Output() saveAsWorkspaceItemToDashboard = new EventEmitter();
    @Output() saveSorting = new EventEmitter();
    @Input() set nriFields(nriFields: any) {
        this._nriFields = JSON.parse(JSON.stringify(nriFields));
    };
    @Input() dashboardWorkspaceItems: WorkspaceItem[];
    @Input() isDashboardCall: boolean;
    get climateOverlays(): any {
        return this.climateOverlays;
    }
    @Input() set climateOverlays(climateOverlays: any) {
        this._climateOverlays = JSON.parse(JSON.stringify(climateOverlays));
    }

    selectedFieldsByDataset: Map<string, DatasetField[]> = new Map();
    selectedFieldsCount = 0;
    reportName: string;
    datasetsToChooseFrom: Dataset[];
    displayType: ReportDisplayType = ReportDisplayType.TABLE;
    reportType: string = ReportType.COUNT;
    reportSubType: ReportDisplayType = ReportDisplayType.TABLE;
    chartDisplayType: ChartDisplayType = ChartDisplayType.AGGREGATE;
    dataIsReady = false;
    datasetforCountReport: GroupWithOverlaysTreeNode[];
    filterFieldSearchString = "";
    filterFieldSearchFilter: (field: DatasetField) => boolean;
    datapointFilter: DatapointFilter;
    datapointProjection: DatapointProjection;
    tessadataFields: { externalFields: any[]; nriFields: any[], tensorflightFields: any[], munichreFields: any[] };
    tessadataGroupedFields: any[];

    /** TABLE  */
    dynamicColumns: Map<string, string>; // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
    columnsToDisplay: string[];
    totalCount: number;
    reportData: ReportRow[];
    downloadReportData: ReportRow[];
    parentOverlay: any;

    /** CHART  */
    chartDatasets: any[];
    chartLabels: string[];
    chartColors: any[];
    chartOptions: any = {
        responsive: true,
        legend: {
            labels: {
                generateLabels: (chart) => {
                    let legendItems: ChartLegendLabelItem[] = [];

                    chart.data.datasets.forEach((dataset) => {
                        legendItems.push({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor[0], // because all values os the secondary dataset have the same color
                        });
                    });
                    if (legendItems.length <= 10) {
                        // not to overload the page
                        return legendItems;
                    } else {
                        return [];
                    }
                },
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    var name = data.labels[tooltipItem.index];
                    const roundedUpValue = typeof value === 'number' ? value.toFixed(2) : value;
                    return name + ': ' + StringUtils.numberWithCommas(roundedUpValue);
                }
            } // end callbacks:
        },
    };

    reportItems: ReportItem[] = [];

    readonly COUNT_COLUMN_ID = "count";
    readonly PERCENTAGE_COLUMN_ID = "percentage";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";

    private static compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    sort: Sort = {
        active: '',
        direction: ''
    };
    showDropdownMenu: boolean = false;
    showTessadataMenus: boolean = false;
    @ViewChild('locationsFieldsMenu') locationsMenu!: MatMenu;
    toggleDropdownMenu() {
        this.showDropdownMenu = !this.showDropdownMenu;
    }

    openDropdownOnHover() {
        this.showDropdownMenu = true;
    }

    public toggleTessadataMenu(key?: string) {
        // Logic to update the showTessadataMenus object based on the key
        if (key) {
            this.showTessadataMenus[key] = !this.showTessadataMenus[key];
        }
    }
    filterTreeStrcuture = new TreeStructureUtils();
    treeControl = this.filterTreeStrcuture.getTreeControl();
    dataSource = this.filterTreeStrcuture.getDataSource();
    datapointObject = new Datapoints();
    constructor(
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly aggregateService: DatapointsAggregateService,
        private readonly userStateService: UserStateService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly overlayService: OverlaysService,
        private readonly route: ActivatedRoute,
        private readonly workspaceItemService: WorkspaceItemService,
        private dialog: MatDialog
    ) {
        this.filterFieldSearchFilter = (field: DatasetField) => {
            return field.name
                .toLowerCase()
                .includes(this.filterFieldSearchString.toLowerCase());
        };

    }

    ngOnInit() {
        // this.fetchDashboardWorkspaceItems();
        this.resetNRISelectedFlag();
        let accountId = +this.route.snapshot.paramMap.get("accountId");
        this.dataset = JSON.parse(JSON.stringify(this.dataset));

        this.dataset.fields = this.sortFields(this.dataset.fields);
        // this.dataset.fields = this.dataset.fields.sort(this.sortByProperty("name"));
        if ([MaptycsApplication.CLAIMS, MaptycsApplication.POLICIES].includes(this.dataset.application)) {
            this._climateOverlays = this.datapointObject.filterAndDelete(this._climateOverlays, {isTextTypeCheck: true});
        }
        if (this._climateOverlays.length) {
            this.datasetforCountReport = this._climateOverlays;
            this.datasetforCountReport = this.setFieldsSelected(this.datasetforCountReport);
        }

        this.datapointFilter = this.datapointsFilterService.getActiveFilter();
        this.subscriptions.add(
            this.datapointsFilterService
                .onFilterChange()
                .subscribe((newFilter) => {
                    this.datapointFilter = newFilter;
                    if (this.dataIsReady) {
                        this.generateReportData();
                    }
                })
        );

        let datasetsToGroupBy = this.datapointsPageStateService
            .getLinkedAccountDatasets()
            .concat(
                this.datapointsPageStateService
                    .getLinkedAccountOverlays()
                    .filter(
                        (overlay) =>
                            overlay.geometryType === DatasetGeometryType.COMPLEX
                    )
            );

        if (
            !datasetsToGroupBy.find((dataset) => dataset.id === this.dataset.id)
        ) {
            datasetsToGroupBy.splice(0, 0, this.dataset);
        }

        this.tessadataFields = JSON.parse(
            JSON.stringify(
                this.datapointsPageStateService.getActiveTessadataFields()
            )
        );
        this.tessadataFields.externalFields.forEach(
            (field) => (field.selected = false)
        );
        if (
            this.tessadataFields.externalFields &&
            this.tessadataFields.externalFields.length > 0
        ) {
            let externalDatasets = this.sortFields(
                this.tessadataFields.externalFields
            );
            this.tessadataGroupedFields = externalDatasets.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
        }
        this.tessadataFields.tensorflightFields.forEach(
            (field) => (field.selected = false)
        );
        if (
            this.tessadataFields.tensorflightFields &&
            this.tessadataFields.tensorflightFields.length > 0
        ) {
            let tensorflightDatasets = this.sortFields(
                this.tessadataFields.tensorflightFields
            );
            let tensorflightGroupFields = tensorflightDatasets.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            this.tessadataGroupedFields = !isUndefined(this.tessadataGroupedFields) && Object.keys(this.tessadataGroupedFields).length ? { ...this.tessadataGroupedFields, ...tensorflightGroupFields } : tensorflightGroupFields;
        }
        this.tessadataFields.munichreFields.forEach(
            (field) => (field.selected = false)
        );
        if (
            this.tessadataFields.munichreFields &&
            this.tessadataFields.munichreFields.length > 0
        ) {
            let munichreDatasets = this.sortFields(
                this.tessadataFields.munichreFields
            );
            let munichreGroupFields = munichreDatasets.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            this.tessadataGroupedFields = !isUndefined(this.tessadataGroupedFields) && Object.keys(this.tessadataGroupedFields).length ? { ...this.tessadataGroupedFields, ...munichreGroupFields } : munichreGroupFields;
        }
        this.datasetsToChooseFrom = datasetsToGroupBy;
        let tessadataFieldsByDataset = {};
        tessadataFieldsByDataset[this.dataset.id] = { nriFields: [] };
        tessadataFieldsByDataset[this.dataset.id].nriFields = this._nriFields;
        if (this.datasetforCountReport !== undefined) {
            this.dataSource.data = [
                ...this.datapointObject.prepareDataset([this.dataset], this.dataset),
                ...this.datapointObject.prepareTesadata(this.tessadataGroupedFields, this.dataset),
                ...this.datapointObject.prepareNRIFields(
                    [this.dataset],
                    tessadataFieldsByDataset,
                    true,
                    this.dataset
                ),
                ...this.datapointObject.filterAndDelete([...this.datapointObject.prepareClimateData(this.datasetforCountReport, false, false, false)], {isTextTypeCheck: true, geometryTypes: [DatasetGeometryType.COMPLEX]})
            ];
            this.dataSource.data = this.datapointObject.filterAndDelete(this.dataSource.data, {isTextTypeCheck: true});
        } else {
                this.dataSource.data = [
                    ...this.datapointObject.prepareDataset([this.dataset], this.dataset)
                ];
        }
        this.collapseAll();
    }

    recursiveOperation(
        groupNode: GroupWithOverlaysTreeNode,
        operation: (node: GroupWithOverlaysTreeNode) => void
    ) {
        operation(groupNode);
        groupNode.children.forEach((child) => {
            // Sort the Overlay Of children of each Parent
            if (child.overlays) {
                child.overlays = this.sortFields(child.overlays);
            }
            this.recursiveOperation(child, operation);
        });
    }

    onFieldsMenuClick(
        fieldSelected: boolean,
        dataset: Dataset,
        field: DatasetField,
        overlay?: Dataset
    ) {
        const validDataset = overlay || dataset;
        let datasetFields = this.selectedFieldsByDataset.get(validDataset.id);
        if (!datasetFields) {
            datasetFields = [];
        }
        if (fieldSelected) {
            datasetFields.push(field);
            this.selectedFieldsCount++;
        } else {
            datasetFields = datasetFields.filter((f) => f.id !== field.id);
            this.selectedFieldsCount--;
        }
        if (datasetFields.length === 0) {
            this.selectedFieldsByDataset.delete(validDataset.id);
        } else {
            this.selectedFieldsByDataset.set(validDataset.id, datasetFields);
        }
    }

    generateReport() {
        if (this.selectedFieldsByDataset.size > 0) {
            this.generateReportData();
        } else {
            this.dataIsReady = false;
        }
    }

    generateReportData() {
        let reportRequest = this.createReportRequest();
        this.populateTableColumnsList();
        this.prepareProjection();
        let tempDatapointFilter: DatapointFilter = { datasetID: this.dataset.id };
        const datapointFilters = isUndefined(this.datapointFilter) ? tempDatapointFilter : this.datapointFilter;
        this.subscriptions.add(
            this.aggregateService
                .getDatapointsReport(
                    this.dataset.id,
                    datapointFilters,
                    reportRequest,
                    this.datapointProjection
                )
                .subscribe((success) => {
                    this.computeTotalCount(success.groupResults);
                    this.convertDataToTableFormat(success.groupResults);
                    this.populateTableColumnsList();
                    if (this.sort && !isUndefined(this.sort)) {
                        this.sortData(this.sort);
                    }
                    this.populateChartData();

                    this.dataIsReady = true;
                    this.changeDetector.detectChanges();
                })
        );
    }

    private createReportRequest(): ReportRequest {
        let groups: AggregateGroupRequest[] = [];
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                groups.push({
                    datasetID: datasetId,
                    fieldID: field.id,
                });
            });
        });
        return {
            datasetID: this.dataset.id,
            aggregateFieldCodes: [{ aggregateFieldCode: "1", id: "count" }],
            groups: groups,
            aggregateFieldType: DatapointAggregateFieldType.FIELD,
        };
    }

    private populateTableColumnsList() {
        this.dynamicColumns = new Map();
        this.columnsToDisplay = [];

        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    field.id
                );
                this.dynamicColumns.set(key, field.name);
                this.columnsToDisplay.push(key);
            });
        });

        this.columnsToDisplay.push(this.PERCENTAGE_COLUMN_ID);
        this.columnsToDisplay.push(this.COUNT_COLUMN_ID);
    }

    private computeTotalCount(groupResults: ReportResultResponse[]) {
        this.totalCount = 0;
        groupResults.forEach((groupResult) => {
            this.totalCount += groupResult.values[0].count;
        });
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get ReportDisplayType() {
        return ReportDisplayType;
    }

    setDisplayType(reportSubType: ReportDisplayType) {
        if (this.selectedFieldsByDataset.size > 0) {
            this.reportSubType = reportSubType;
        }

        this.chartOptions.scales = reportSubType === ReportDisplayType.BAR_CHART ? {
            yAxes: [
                {
                    ticks: {
                        callback: (value, index, values) => {
                            return StringUtils.numberWithCommas(value);
                        },
                    },
                },
            ],
        } : null;

        this.populateChartData();
    }

    getSelectedFieldsByDataset() {
        return this.selectedFieldsByDataset;
    }

    getSort() {
        return this.sort;
    }

    sortData(sort: Sort, isSortCall: boolean = false) {
        const isAsc = sort.direction === "asc";
        const fieldId = sort.active;
        if (isUndefined(this.sort)) {
            this.sort = {
                active: '',
                direction: ''
            };
        }
        this.sort.active = fieldId;
        this.sort.direction = sort.direction;
        let sortedData = this.reportData.sort((a, b) => {
            switch (fieldId) {
                case this.COUNT_COLUMN_ID:
                    return CountReportComponent.compare(
                        a.count,
                        b.count,
                        isAsc
                    );
                case this.PERCENTAGE_COLUMN_ID:
                    return CountReportComponent.compare(
                        a.percentage,
                        b.percentage,
                        isAsc
                    );
                default: {
                    let aValue = a.dynamicFieldValuesByIds.get(fieldId);
                    let bValue = b.dynamicFieldValuesByIds.get(fieldId);
                    return CountReportComponent.compare(aValue, bValue, isAsc);
                }
            }
        });
        this.reportData = [...sortedData];
        this.downloadReportData = this.reportData;
        if (isSortCall) {
            this.saveSorting.emit();
        }
    }

    private convertDataToTableFormat(groupResults: ReportResultResponse[]) {
        this.reportData = [];
        this.downloadReportData = [];
        groupResults.forEach((groupResult) => {
            let percentage: number =
                this.totalCount > 0
                    ? (groupResult.values[0].count / this.totalCount) * 100
                    : 0;
            let tableEntry: ReportRow = {
                count: groupResult.values[0].count,
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    groupResult.buckets
                ),
                percentage: Math.round(percentage * 100) / 100,
            };
            this.downloadReportData.push(tableEntry);
        });
        this.reportData = this.downloadReportData.slice(0, this.LIMIT);
    }

    getDynamicFieldValuesByIds(
        groupResultBuckets: ReportResultGroupResponse[]
    ): Map<string, string> {
        let tableEntries: Map<string, string> = new Map<string, string>();
        groupResultBuckets.forEach((groupResultBucket) => {
            let value = groupResultBucket.value
                ? groupResultBucket.value
                : "N/A";
            tableEntries.set(
                DatasetUtils.createUniqueIdentifierForDatasetField(
                    groupResultBucket.datasetID,
                    groupResultBucket.fieldID
                ),
                value
            );
        });
        return tableEntries;
    }

    /**
     * We need a structure like: Map<String, chartDataset[]>
     * The key if the main field value
     * The value is the chartDataset list for each of the other field's values (one chart dataset per second field value).
     * Each chart dataset array will contain the count values for the pair (mainField, secondField)
     */
    private populateChartData() {
        if (this.selectedFieldsCount > 2) {
            return; // cannot create chart with more than 2 group by fields
        }
        if (this.reportData.length === 0) {
            return;
        }
        this.initializeChartData();

        let chartColors: ChartInfo[] = [];
        let mainDatasetFieldValues = []; // chart labels
        let chartDatasets: ChartDataSets[] = [];

        if (this.selectedFieldsCount <= 1) {
            let chartDataset = {
                data: [],
            };
            let chartColor = {
                backgroundColor: [],
            };
            chartDatasets.push(chartDataset);
            chartColors.push(chartColor);

            this.reportData.forEach((entry) => {
                mainDatasetFieldValues.push(
                    entry.dynamicFieldValuesByIds.values().next().value
                );
                chartDataset.data.push(entry.count);
                chartColor.backgroundColor.push(
                    ColorUtils.generateRandomHexColor()
                );
            });
        } else {
            let mainField = this.reportData[0].dynamicFieldValuesByIds
                .keys()
                .next().value; // we will use this as the main field in the chart
            this.reportData.forEach((row) => {
                let mainFieldValue = row.dynamicFieldValuesByIds.get(mainField);
                if (!mainDatasetFieldValues.includes(mainFieldValue)) {
                    mainDatasetFieldValues.push(mainFieldValue);
                }
            });

            this.reportData.forEach((row) => {
                let mainDatasetFieldValue =
                    row.dynamicFieldValuesByIds.get(mainField);
                let indexInDatasetArray = mainDatasetFieldValues.indexOf(
                    mainDatasetFieldValue
                ); // because the labels indexes must match the values indexes
                row.dynamicFieldValuesByIds.forEach((value, datasetFieldId) => {
                    if (datasetFieldId !== mainField) {
                        let chartDataset = chartDatasets.find(
                            (cd) => cd.label === value
                        );
                        let backgroundColor = chartColors.find(
                            (bg) => bg.label === value
                        );
                        if (!chartDataset) {
                            chartDataset = {
                                data: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(0),
                                label: value,
                            };
                            chartDatasets.push(chartDataset);
                        }
                        if (!backgroundColor) {
                            backgroundColor = {
                                label: value,
                                backgroundColor: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(ColorUtils.generateRandomHexColor()),
                            };
                            chartColors.push(backgroundColor);
                        }
                        chartDataset.data[indexInDatasetArray] = row.count;
                    }
                });
            });
        }
        this.chartDatasets = chartDatasets;
        this.chartLabels = mainDatasetFieldValues;
        this.chartColors = chartColors;
    }

    private initializeChartData() {
        this.chartDatasets = [];
        this.chartLabels = [];
        this.chartColors = [];
    }

    private prepareProjection() {
        if (!this.datapointProjection) {
            this.datapointProjection = {
                datasetID: this.dataset.id,
                fields: [],
                links: [],
            };
        }
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            if (datasetId === this.dataset.id) {
                this.datapointProjection.fields = fields.map(
                    (field) => field.id
                );
            } else {
                let linkProjection: DatapointProjection = {
                    datasetID: datasetId,
                    fields: fields.map((field) => field.id),
                };
                this.datapointProjection.links.push(linkProjection);
            }
        });
    }

    getTableReportHeader(): TableColumn[] {
        let columns: TableColumn[] = [];
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        field.id
                    ),
                    name: field.name,
                    type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                    horizontalAlignment: TableColumnAlignment.LEFT,
                });
            });
        });
        columns.push({
            id: this.PERCENTAGE_COLUMN_ID,
            name: "Total %",
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });
        columns.push({
            id: this.COUNT_COLUMN_ID,
            name: "Count",
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        return columns;
    }

    getTableReportFooter(): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        this.dynamicColumns.forEach((key, value) =>
            cells.push({ id: this.BLANK_COLUMN_ID, value: "" })
        );
        cells.splice(cells.length - 1, 1); // we need to add only N-1 empty spaces
        cells.push({ id: this.PERCENTAGE_COLUMN_ID, value: "100%" });
        cells.push({ id: this.COUNT_COLUMN_ID, value: this.totalCount });
        return { cells: cells };
    }

    getTableReportRows(): TableRow[] {
        let rows: TableRow[] = [];
        this.downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            this.dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });
            columns.push({
                id: this.PERCENTAGE_COLUMN_ID,
                value: row.percentage.toString() + "%",
            });
            columns.push({ id: this.COUNT_COLUMN_ID, value: row.count });
            rows.push({ cells: columns });
        });
        return rows;
    }

    getTableReportDownloadRequest(): DownloadReportItem {
        if (this.dataIsReady) {
            let reportHeader = this.getTableReportHeader();
            let reportRows = this.getTableReportRows();
            let reportFooter = this.getTableReportFooter();
            let title = this.reportName || "Count";
            return new DownloadReportTableRequest(
                title,
                reportHeader,
                reportRows,
                reportFooter
            );
        } else {
            return null;
        }
    }

    getDisplayType(): ReportDisplayType {
        return this.reportSubType;
    }

    getChartReportDownloadRequest(): DownloadReportItem {
        let breakdownFieldsNames: string[] = [];
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                breakdownFieldsNames.push(field.name);
            });
        });
        let request: DownloadReportChartRequest = {
            title: this.reportName || "Count",
            valueKey: ValueKey.COUNT,
            type: undefined,
            columns: {
                value: "Count",
                categories: breakdownFieldsNames,
            },
            values: [],
        };

        this.chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                let chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [this.chartLabels[valueIndex]],
                    colors: [
                        this.chartColors[datasetIndex].backgroundColor[
                        valueIndex
                        ],
                    ],
                    count: value,
                    value: value,
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });

        if (this.reportSubType === ReportDisplayType.PIE_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_PIE_CHART;
        } else if (this.reportSubType === ReportDisplayType.BAR_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_BAR_CHART;
        }
        return request;
    }

    getReportDownloadRequest(): DownloadReportItem {
        if (this.getDisplayType() === ReportDisplayType.TABLE) {
            return this.getTableReportDownloadRequest();
        } else if (
            this.getDisplayType() === ReportDisplayType.BAR_CHART ||
            this.getDisplayType() === ReportDisplayType.PIE_CHART
        ) {
            return this.getChartReportDownloadRequest();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    isTwoDimensionReport(): boolean {
        let selectedFieldNo = 0;
        this.selectedFieldsByDataset.forEach(
            (fields, datasetId) => (selectedFieldNo += fields.length)
        );
        return selectedFieldNo === 2;
    }

    setSelectedFieldsByDataset(
        selectedFieldsByDataset: Map<string, DatasetField[]>
    ) {
        // the following is necessary for checking the selected field in the breakdown dropdown
        selectedFieldsByDataset.forEach((fields, datasetId) => {
            this.selectedFieldsCount += fields.length;
            let selectedFields: DatasetField[] = [];
            let selectedDataset = this.datasetsToChooseFrom.find(
                (d) => d.id === datasetId
            );
            if (selectedDataset) {
                selectedDataset.fields.forEach((selectionField) => {
                    let fieldIsSelected = fields.find(
                        (f) => f.id === selectionField.id
                    );
                    if (fieldIsSelected) {
                        selectionField.selected = true;
                        this.datapointObject.markSelected(this.dataSource.data, selectionField.id);
                        selectedFields.push(selectionField);
                    }
                });
                this.selectedFieldsByDataset.set(
                    selectedDataset.id,
                    selectedFields
                );
            }
        });
    }
    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    sortFields(fields) {
        fields.sort((item1, item2) => {
            if (item1 && item1.name && item2 && item2.name) {
                return item1.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(item2.name.trim().toLowerCase());
            } else {
                return item1.datasetLabel
                    .toLowerCase()
                    .localeCompare(item2.datasetLabel.toLowerCase());
            }
        });
        return fields;
    }

    resetNRISelectedFlag() {
        this._nriFields.forEach((outerElement) => {

            outerElement.child.forEach((element) => {
                element.child.selected = false;
            });
        });
    }

    dynamicFilterMenuEmitter($event) {
        this.onFieldsMenuClick($event.event, $event.dataset, $event.field);
    }

    prepareFilterAcccountOverlays(overlays) {
        overlays.forEach((element, key) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            } else if (element.children.length > 0) {
                this.recursiveFilterAccountOverlay(element);
            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            }
        });
        return overlays;
    }

    recursiveFilterAccountOverlay(element) {
        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (
                    sub_element.children.length <= 0 &&
                    sub_element.overlays.length <= 0
                ) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(
                        (params) => !groupIds.includes(params.group.id)
                    );
                    this.recursiveFilterAccountOverlay(sub_element);
                    return;
                }
            });

            if (groupIds.length) {
                element.children = element.children.filter(
                    (params) => !groupIds.includes(params.group.id)
                );
                this.recursiveFilterAccountOverlay(this.parentOverlay);
            }
        }
    }

    getSaveButtonLabel() {
        return this.isDashboardCall ? 'Save' : 'Save to Dashboard';
    }

    getSaveAsButtonLabel() {
        return this.isDashboardCall ? 'Save as' : 'Save as Dashboard';
    }

    isTensorFlight(key: string) {
        return key.toUpperCase() === "TENSORFLIGHT";
    }

    setFieldsSelected(datasetforCountReport): GroupWithOverlaysTreeNode[] {
        for (const [key, value] of this.selectedFieldsByDataset) {
            this.datasetforCountReport.forEach((dataset) => {
                if (dataset && dataset.overlays && dataset.overlays.length) {
                    let selectedOverlay = this.findSelectedOverlay(dataset.overlays, key);

                    if (!selectedOverlay) {
                        selectedOverlay = this.findSelectedOverlayFromChildren(dataset.children, key);
                    }

                    if (selectedOverlay && selectedOverlay.fields) {
                        for (const field of value) {
                            let matchingField = selectedOverlay.fields.find(
                                (overlayField) => overlayField.id === field.id
                            );
                            if (matchingField) {
                                this.datapointObject.markSelected(this.dataSource.data, matchingField.id);
                                matchingField.selected = true;
                            }
                        }
                    }
                }
            })
        }
        return datasetforCountReport;
    }

    findSelectedOverlayFromChildren(children: GroupWithOverlaysTreeNode[], key: string): any {
        for (const element of children || []) {
            const foundOverlay = this.findSelectedOverlay(element.overlays, key);

            if (foundOverlay) {
                return foundOverlay;
            }

            if (element.children && element.children.length > 0) {
                const overlayFromChildren = this.findSelectedOverlayFromChildren(element.children, key);
                if (overlayFromChildren) {
                    return overlayFromChildren;
                }
            }
        }

        return null;
    }


    findSelectedOverlay(overlays, key) {
        const selectedOverlay = overlays.find(
            (overlay) => overlay.id === key
        );

        return selectedOverlay
    }

    getDisplayName(node) {
        return this.datapointObject.getDisplayName(node);
    }
    collapseAll(): void {
        this.treeControl.collapseAll();
    }

    isLocationTypeApplication() {
        return this.dataset.application === MaptycsApplication.LOCATIONS;
    }
}
