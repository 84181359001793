import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    EventEmitter, HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewContainerRef,
    PipeTransform
} from '@angular/core';
import { Dataset } from '../../../model/dataset/dataset';
import { DatapointsService } from '../../../data-access-layer/datapoints/datapoints.service';
import { DatasetFieldSpecificType } from '../../../model/dataset/dataset-field-specific.type';
import { MaptycsApplication } from '../../../model/account/maptycs-application';
import { SidePanelComponent } from '../../../core/side-panel/side-panel.component';
import { isEnabled } from '../../../../environments/environment';
import { Datapoint } from '../../../model/datapoint/datapoint';
import { DatasetField } from '../../../model/dataset/field/dataset-field';
import { Functionalities } from '../../../../environments/app-functionalities';
import { UserStateService } from '../../../auth/user-state-service';
import { DatapointsPageStateService } from '../datapoints-page-state.service';
import { TessadataDatasetStructure } from '../../../core/tessadata/tessadata-dataset-structure';
import { TessadataDataset } from '../../../core/tessadata/tessadata-dataset';
import { SidePanelService } from '../../../shared/services/side-panel.service';
import { DataPointsServiceState } from '../../../shared/services/datapoints-service-state';
import { SidePanels } from '../../../shared/services/side-panel.helper';
import {DownloadService} from '../../../data-access-layer/download/download.service';
import {AttachmentUtils} from '../../../core/utils/attachment-utils';
import {PoiClosestFilter} from 'src/app/model/datapoint/filter/poi-closest-filter';
import {fromEvent, Subscription} from 'rxjs';
import { throttleTime} from 'rxjs/operators';
import {NotifService} from '../../../core/notification/notif.service';
import {DownloadDatapointRequest} from '../../../model/download/download-datapoint-request';
import {Constants} from '../../../constants';
import {DownloadFileType} from '../../../model/download/download-file-type';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocationProfile, dropdownMenuList } from 'src/app/model/datapoint/location-profile/location-profile';
import { DatapointsClimateChartService } from '../climate-charts/datapoints-climate-chart.service';
import { Climate } from 'src/app/core/utils/climate';
import { DatasetFieldType } from 'src/app/model/dataset/dataset-field-type';
import { DatePipe } from 'src/app/core/pipes/date.pipe';
import { DecimalPipe } from '@angular/common';
import { DatapointField } from 'src/app/model/datapoint/datapoint-field';
import { OpenWeather } from 'src/app/model/datapoint/open-weather';

type UpdateDataPointComponentType = {
    dataset: Dataset;
}

@Component({
    selector: 'map-datapoints-profile-panel',
    templateUrl: './datapoints-profile-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./datapoints-profile-panel.component.scss']
})
export class DatapointsProfilePanelComponent implements OnInit, OnChanges, OnDestroy {
    // @Input() externalTensorFlightData: any;
    @Input() externalPOIDatasets: TessadataDataset[] = [];
    @Input() externalPOIDatasetsStructures: TessadataDatasetStructure[] = [];

    @Output() closed = new EventEmitter();
    @Output() datapointDeleted = new EventEmitter();
    @Output() updateSelectedDatapoint: EventEmitter<any> = new EventEmitter();
    @Output() onMoveModeEnabled: EventEmitter<Datapoint> = new EventEmitter();
    moveModeEnabled = false;

    updateDatapointPanel: ComponentRef<SidePanelComponent>;

    isExpanded = false;
    isOverlay = false;
    details: { datapointID: string, dataset: Dataset };
    fields = [];
    updating: boolean;
    datapoint: Datapoint;
    dataset: Dataset;
    datasetFields: { [key: string]: DatasetField; } = {}; // here we should have a map ID - field

    alterDatapointsEnabled = isEnabled(Functionalities.ALTER_DATAPOINTS);
    downloadDatapointsEnabled = isEnabled(Functionalities.DOWNLOAD_DATAPOINTS);

    hasWriteAccess = false;
    locationHasUploadedAttachments = false;
    poiClosestSubmittedFilter: PoiClosestFilter;

    // expand panel
    oldX = 0;
    grabber = false;
    subscription = new Subscription();
    PANEL_WIDTH = 459;
    width: number;
    dragWidth: number;
    isAppliedWrap: boolean;
    isAccountAdmin: boolean;
    previousSelectedValue: any;

    @Input('mapDatapointInfo')
    set setMapDatapointInfo(details: { datapointID: string, dataset: Dataset }) {
        this.datapoint = null;
        this.details = details;
        if (!details) {

            return;
        }
        this.dataset = details.dataset;
        this.isOverlay = details.dataset.application === null;
        details.dataset.fields.forEach((field) => {
            this.datasetFields[field.id] = field;
        });
    }

    selectedGroup: UntypedFormGroup;
    selectedValue = new UntypedFormControl('INTERNAL');
    menuList: any;
    locationProfile = new LocationProfile();


    get DatasetFieldType() {
        return DatasetFieldType;
    }

    constructor(private readonly dpService: DatapointsService, private readonly changeDetector: ChangeDetectorRef,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly userStateService: UserStateService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly sidePanelService: SidePanelService,
        private readonly datapointsServiceState: DataPointsServiceState,
        private readonly downloadService: DownloadService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly notifService: NotifService,
        private datapointsClimateChartService: DatapointsClimateChartService,
        private fb: UntypedFormBuilder,
        private maptycsDatePipe: DatePipe,
        private decimalPipe: DecimalPipe
        ) {
    }

    ngOnInit() {
        this.selectedGroup = this.fb.group({
            defaultSelected: ['INTERNAL']
        });
        this.menuList = Object.keys(dropdownMenuList).map(function(personNamedIndex){
            let menu =  [personNamedIndex, dropdownMenuList[personNamedIndex]];
            return menu;
        });
        this.hasWriteAccess = this.userStateService.hasWriteAccess(this.datapointsPageStateService.activeAccountId);
        const activeAccount = this.userStateService.principal.membership.find(params => params.accountID == this.datapointsPageStateService.activeAccountId);
        this.isAccountAdmin = activeAccount !== undefined && activeAccount.isAccountAdmin ? true : false;
        this.width = this.PANEL_WIDTH;
        this.dragWidth =  this.width;
    }

    selectionChange($event) {
        this.selectedValue.setValue($event.value);
        this.updateSelectedDatapoint.emit({ newSelectedValue: $event.value, prevSelectedValue: this.previousSelectedValue});
        if($event.value !== this.locationProfile.CLIMATE) {
            Climate.resetGraphTypes(this.datapointsClimateChartService);
        }
        this.applyChangeDetection();
        this.previousSelectedValue = $event.value;
    }

    onExpandedClick(expanded?: boolean) {
        this.isExpanded = (expanded !== undefined) ? expanded : !this.isExpanded;
        this.selectedGroup.controls.defaultSelected.reset();
        this.selectedGroup.controls.defaultSelected.setValue('INTERNAL');
        this.selectedValue = new UntypedFormControl('INTERNAL');
        if (!expanded) {
            this.closed.emit();
        }
        this.width = this.PANEL_WIDTH;
        this.dragWidth = this.width;
        this.isAppliedWrap = false;
        if (this.dataset?.id === OpenWeather.getId()) {
            // this.mapStateService.setRemoveOpenWeatherPolygon(true);
        }
    }

    ngOnChanges() {
        if (!this.details || !this.details.datapointID || !this.details.dataset) {
            return;
        }
        this.updating = false;
        this.fetchDatapoint();
        if (this.updateDatapointPanel) this.updateDatapointPanel.instance.hidePanel();
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    enableEditMode(): void {
        if (this.alterDatapointsEnabled) {
            this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
            if (this.updateDatapointPanel) {
                this.updateDatapointPanel.instance.hidePanel();
            }
            this.updating = true;
            this.updateDatapointPanel = this.sidePanelService.open<UpdateDataPointComponentType>(SidePanels.DATAPOINT_PROFILE,
                {
                    id: "update-datapoint-panel",
                    width: 459,
                    panelTitle: "Edit Datapoint",
                    panelIcon: "icon-pencil"
                },
                {
                    dataset: this.details.dataset
                });

            this.datapointsServiceState.onUpdateDataPointInit$.subscribe(() => {
                const {componentRef} = this.updateDatapointPanel.instance;
                if (componentRef) {
                    componentRef.instance.setDataset(this.details);
                }
            });
            this.datapointsServiceState.datapointUpdate$.subscribe((value) => {
                if (value === true) {
                    this.onUpdated();
                    this.updateDatapointPanel.instance.closePanel();
                } else {
                    this.notUpdated();
                }
            });
        }
    }

    onUpdated() {
        this.updating = false;
        this.changeDetector.detectChanges();
        this.fetchDatapoint();
        if (this.updateDatapointPanel) this.updateDatapointPanel.instance.hidePanel();
    }

    notUpdated() {
        this.updating = false;
        this.changeDetector.detectChanges();
        if (this.updateDatapointPanel) this.updateDatapointPanel.instance.hidePanel();
    }

    private fetchDatapoint() {
        this.dpService.getDatapoint(this.details.datapointID, this.details.dataset.id).subscribe((datapoint) => {
            datapoint.fields = this.getFilteredDatapointFields(datapoint.fields)
            this.datapoint = datapoint;
            this.fields = [];
            let idFieldValues = {};
            datapoint.fields.forEach((field) => {
                let value = field.textValue || field.numberValue || field.datetimeValue;
                if (value && !this.datasetFields[field.id].isGenerated) {
                    if (this.datasetFields[field.id].name !== 'id') {
                        let currentField = {
                            value,
                            name: this.datasetFields[field.id].displayName !== undefined && this.datasetFields[field.id].displayName !== null ? this.datasetFields[field.id].displayName : this.datasetFields[field.id].name,
                            type: this.datasetFields[field.id].type,
                            isSummary: this.datasetFields[field.id].isSummary
                        };
                        this.fields.push(currentField);
                    } else {
                        idFieldValues = {
                            value,
                            name: this.datasetFields[field.id].displayName !== undefined && this.datasetFields[field.id].displayName !== null ? this.datasetFields[field.id].displayName : this.datasetFields[field.id].name,
                            type: this.datasetFields[field.id].type,
                            isSummary: this.datasetFields[field.id].isSummary
                        };
                    }
                }
            });
            this.fields.push(idFieldValues);
            this.changeDetector.detectChanges();
        });
    }

    getFilteredDatapointFields(datapointFields): DatapointField[] {
        let fields: DatapointField[] = [];
        const datasetFieldIds = new Set(this.details.dataset.fields.map(field => field.id));

        if(datapointFields && datapointFields.length){
            datapointFields.forEach(element => {
                if(datasetFieldIds.has(element.id)){
                    fields.push(element)
                }
            });
        } else {
            fields = datapointFields;
        }

        return fields
    }

    ngOnDestroy() {
        if (this.updateDatapointPanel) {
            this.updateDatapointPanel.instance.closePanel();
            this.updateDatapointPanel = null;
        }
        this.subscription.unsubscribe();
    }

    downloadLocationProfile($event) {
        $event.preventDefault();
        this.downloadService.downloadLocationProfile(this.datapoint.id, this.dataset.id, this.poiClosestSubmittedFilter)
            .subscribe(response => AttachmentUtils.downloadFile(response),
                error => this.notifService.error('Something went wrong during download'));
    }

    downloadOverlayProfile($event) {
        $event.preventDefault();
        let fileName = 'overlay-profile.xlsx';
        let datapointIds = [];
        datapointIds.push(this.datapoint.id);
        let request: DownloadDatapointRequest = {
            datapointRequest: {
                limit: 0,
                projection: {
                    datasetID: this.dataset.id,
                    geometryPrecision: 25,
                    fields: this.dataset.fields.map(f => f.id)
                },
                skip: 0,
                sort: null
            },
            filter: { datasetID: this.dataset.id, groups: this.datapointsPageStateService.activeGroups },
            timezone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: DownloadFileType.XLSX,
            datapointIds: datapointIds
        };
        this.downloadService.downloadDatapointsByIds(this.dataset.id, request, fileName).subscribe(
            response => {
                AttachmentUtils.downloadFileWithName(response, fileName);
            }, error => this.notifService.error('Something went wrong during download'));
    }

    // resize panel
    @HostListener('document:mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        if (this.width) {
            this.dragWidth = Math.min(Math.max(this.dragWidth, this.width), window.innerWidth);
        }

        this.grabber = false;
        this.subscription.unsubscribe();
        if(this.dragWidth === this.PANEL_WIDTH) {
            this.isAppliedWrap = false;
        }
    }

    resizer(offsetX: number) {
        this.dragWidth -= offsetX;
    }

    onMouseDown(event: MouseEvent) {
        this.grabber = true;
        this.oldX = event.clientX;
        this.subscription.unsubscribe();
        const mousemoveGlobal = fromEvent<MouseEvent>(document, 'mousemove');
        this.subscription = mousemoveGlobal
            .pipe(throttleTime(10))
            .subscribe((e) => this.onMouseMove(e));
    }

    onMouseMove(event: MouseEvent) {
        if (!this.grabber) {
            return;
        }
        this.resizer(event.clientX - this.oldX);
        this.oldX = event.clientX;
        this.applyChangeDetection();
        this.isAppliedWrap = true;

    }

    downloadOverlayProfileChart() {
        const processedFields = this.processDatapointFields(this.datapoint.fields);
        Climate.downloadOverlayProfileChart(this.datapointsClimateChartService, this.notifService, 'locationProfileClimateChart', processedFields);
    }

    applyChangeDetection() {
        this.changeDetectorRef.detectChanges();
    }

    processDatapointFields(fields: any[]): any[] {
        const processedFields = [];

        for (const field of fields) {
            if (this.datasetFields[field.id].isSummary && field.isPresent) {
                const processedField = {
                    name: this.datasetFields[field.id].name,
                    value: ''
                };

                if (this.datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME) {
                    processedField.value = this.maptycsDatePipe.transform(field.datetimeValue);
                } else if (this.datasetFields[field.id].baseType === DatasetFieldType.NUMBER) {
                    processedField.value = this.decimalPipe.transform(field.numberValue);
                } else if (this.datasetFields[field.id].baseType === DatasetFieldType.TEXT) {
                    processedField.value = field.textValue;
                }

                processedFields.push(processedField);
            }
        }

        // Adding latitude and longitude
        processedFields.push({
            name: 'Latitude',
            value: this.datapoint.location.y.toString()
        })
        processedFields.push({
            name: 'Longitude',
            value: this.datapoint.location.x.toString()
        })

        return processedFields;
    }

    deleteDatapoint() {
        this.dpService.deleteDatapoint(this.datapoint.datasetID, this.datapoint.id).subscribe((response) => {
            if (response === null) {
                this.notifService.error('Datapoint is deleted successfully')
                this.onExpandedClick(false);
                this.datapointDeleted.emit();
            }
        })
    }

    getClass() {
        return this.dataset?.id === OpenWeather.getId() ? "open-weather-detail-row" : "detail-row";
    }
}
