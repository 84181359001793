import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { DatapointsPageStateService } from "src/app/dataset/datapoints/datapoints-page-state.service";
import { DatapointFilter } from "src/app/model/datapoint/filter/datapoint-filter";
import { AggregateGroupRequest } from "src/app/model/datapoint/report/aggregate-group-request";
import { ReportRow } from "src/app/model/datapoint/report/count/report-row";
import { DatapointAggregateFieldType } from "src/app/model/datapoint/report/datapoint-aggregate-field-type";
import { ReportRequest } from "src/app/model/datapoint/report/report-request";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetGeometryType } from "src/app/model/dataset/dataset-geometry-type";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { DatasetUtils } from "../utils/dataset-utils";
import { DatapointProjection } from "src/app/model/datapoint/projection/datapoint-projection";
import { ReportResultResponse } from "src/app/model/datapoint/report/report-result-response";
import { ReportResultGroupResponse } from "src/app/model/datapoint/report/report-result-group-response";
import { ChartDataSets, ChartLegendLabelItem } from "chart.js";
import { ChartInfo } from "src/app/model/datapoint/report/chart-info";
import { ColorUtils } from "../utils/color-utils";
import { ChartDisplayType } from "src/app/model/analytics/chart-display-type";
import { ReportDisplayType } from "src/app/model/analytics/report-display-type";
import { ReportType } from "src/app/model/analytics/report-type";
import { Sort } from "@angular/material/sort";
import { DownloadReportItem } from "src/app/model/download/item/download-report-item";
import {
    DownloadReportChartRequest,
    ValueKey,
} from "src/app/model/download/download-report-chart-request";
import {
    TableColumn,
    TableColumnAlignment,
    TableColumnType,
} from "src/app/model/upload/table/table-column";
import { TableRow } from "src/app/model/upload/table/table-row";
import { TableCell } from "src/app/model/upload/table/table-cell";
import { DownloadReportTableRequest } from "src/app/model/download/download-report-table-request";
import { DownloadReportChartValueRequest } from "src/app/model/download/download-report-chart-value-request";
import { DownloadItemReportType } from "src/app/model/download/item/download-item-report-type";
import { ObjectUtils } from "../utils/object-utils";
import { isUndefined } from "../utils/util-master";
import { StringUtils } from "../utils/string-utils";
import { TreeStructureUtils } from "../utils/tree-structure-utils";
import { Datapoints } from "src/app/dataset/datapoints/datapoints";
import { DatasetFieldSpecificType } from "src/app/model/dataset/dataset-field-specific.type";
import { GroupWithOverlaysTreeNode } from "src/app/model/overlay/group/group-with-overlays-tree-node";
import * as _ from "agile";

export class Aggregate {

    datapointObject = new Datapoints();
    parentOverlay: any;
    constructor(
        public readonly datapointsPageStateService: DatapointsPageStateService,
        public readonly aggregateService: DatapointsAggregateService
    ) { }
    sort: Sort = {
        active: null,
        direction: null
    };

    aggregateData: {
        id: number;
        reportName: string;
        reportType: string;
        reportSubType: ReportDisplayType;
        sequence: number;
        selectedAggregateName: string;
        selectedBreakdownFieldsCount: number;
        selectedAggregateField: DatasetField;
        selectedFormula: WorkspaceItem;
        selectedBreakdownFieldsByDataset: Map<string, DatasetField[]>;
        table: {
            dynamicColumns: Map<string, string>; // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
            columnsToDisplay: string[];
            totalCount: number;
            totalAggregate: number;
            reportData: ReportRow[];
            downloadReportData: ReportRow[];
        };
        chart: {
            chartDatasets: ChartDataSets[];
            chartLabels: string[];
            chartColors: any[];
            chartOptions: any;
        };
        chartDisplayType: ChartDisplayType;
        aggregateDatasetFields: DatasetField[];
        formulas: WorkspaceItem[];
        breakdownDatasetFields: DatasetField[];
        tessadataFields: {
            nriFields: DatasetField[];
            externalFields: DatasetField[];
        };
        tessadataGroupedFields: any[];
        tableDownloadRequest: DownloadReportItem;
        chartDownloadRequest: DownloadReportChartRequest;
        isTwoDimensionReport: boolean;
        sort: Sort,
        aggregateTreeStrcuture: any,
        aggregateTreeControl: any,
        aggregateDataSource: any,
        breakdownTreeStrcuture: any,
        breakdownTreeControl: any,
        breakdownDataSource: any,
        globalOverlays: any;
    }[] = [
            {
                id: 0,
                reportName: "Aggregate",
                reportType: ReportType.AGGREGATE,
                reportSubType: ReportDisplayType.TABLE,
                sequence: 0,
                selectedAggregateName: "",
                selectedBreakdownFieldsCount: 0,
                selectedAggregateField: undefined,
                selectedFormula: undefined,
                selectedBreakdownFieldsByDataset: new Map(),
                table: {
                    dynamicColumns: new Map(), // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
                    columnsToDisplay: [],
                    totalCount: 0,
                    totalAggregate: 0,
                    reportData: [],
                    downloadReportData: [],
                },
                chart: {
                    chartDatasets: [],
                    chartLabels: [],
                    chartColors: [],
                    chartOptions: {},
                },
                chartDisplayType: ChartDisplayType.NONE,
                aggregateDatasetFields: [],
                formulas: [],
                breakdownDatasetFields: [],
                tessadataFields: { nriFields: [], externalFields: [] },
                tessadataGroupedFields: [],
                tableDownloadRequest: null,
                chartDownloadRequest: null,
                isTwoDimensionReport: false,
                sort: {
                    active: null,
                    direction: null
                },
                aggregateTreeStrcuture: null,
                aggregateTreeControl: null,
                aggregateDataSource: null,
                breakdownTreeStrcuture: null,
                breakdownTreeControl: null,
                breakdownDataSource: null,
                globalOverlays: []
            },
        ];

    readonly COUNT_COLUMN_ID = "count";
    readonly AGGREGATE_COLUMN_ID = "aggregate";
    readonly PERCENTAGE_COLUMN_ID = "percentage";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";
    readonly AVERAGE_COLUMN_ID = "average";

    private LIMIT: number = 247;
    linkedOverlays: any;

    public process(
        dataset: Dataset,
        aggregateCount: number,
        analytic,
        data,
        datasetFields: DatasetField[],
        formulas: WorkspaceItem[],
        groupsIds: any,
        aggregateFieldsData: any,
        overlays: any,
        linkedOverlays: any
    ) {
        this.linkedOverlays = linkedOverlays;
        this.aggregateData[aggregateCount] = {
            id: 0,
            reportName: analytic.name,
            reportType: ReportType.AGGREGATE,
            reportSubType: analytic.reportSubType,
            sequence: analytic.sequence,
            selectedAggregateName: "",
            selectedBreakdownFieldsCount: 0,
            selectedAggregateField: undefined,
            selectedFormula: undefined,
            selectedBreakdownFieldsByDataset: new Map(),
            table: {
                dynamicColumns: new Map(), // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
                columnsToDisplay: [],
                totalCount: 0,
                totalAggregate: 0,
                reportData: [],
                downloadReportData: [],
            },
            chart: {
                chartDatasets: [],
                chartLabels: [],
                chartColors: [],
                chartOptions: {},
            },
            chartDisplayType: analytic.chartDisplayType,
            aggregateDatasetFields: [],
            formulas: [],
            breakdownDatasetFields: [],
            tessadataFields: { nriFields: [], externalFields: [] },
            tessadataGroupedFields: [],
            tableDownloadRequest: null,
            chartDownloadRequest: null,
            isTwoDimensionReport: this.isTwoDimensionReport(
                new Map(Object.entries(data.selectedBreakdownFieldsByDataset || {}))
            ),
            sort: isUndefined(data.sort) ? this.sort : data.sort,
            aggregateTreeStrcuture: null,
            aggregateTreeControl: null,
            aggregateDataSource: null,
            breakdownTreeStrcuture: null,
            breakdownTreeControl: null,
            breakdownDataSource: null,
            globalOverlays: []
        };
        this.aggregateData[aggregateCount].aggregateTreeStrcuture = new TreeStructureUtils();
        this.aggregateData[aggregateCount].aggregateTreeControl = this.aggregateData[aggregateCount].aggregateTreeStrcuture.getTreeControl();
        this.aggregateData[aggregateCount].aggregateDataSource =this.aggregateData[aggregateCount].aggregateTreeStrcuture.getDataSource();
        this.aggregateData[aggregateCount].breakdownTreeStrcuture = new TreeStructureUtils();
        this.aggregateData[aggregateCount].breakdownTreeControl = this.aggregateData[aggregateCount].breakdownTreeStrcuture.getTreeControl();
        this.aggregateData[aggregateCount].breakdownDataSource =this.aggregateData[aggregateCount].breakdownTreeStrcuture.getDataSource();
        this.aggregateData[aggregateCount].aggregateDataSource.data = [
            ...this.datapointObject.prepareDataset([dataset], dataset, {type: DatasetFieldSpecificType.NUMBER_FIELD}),
            ...this.datapointObject.prepareFormulas(formulas)
        ]
        this.aggregateData[aggregateCount].aggregateDatasetFields =
            datasetFields;
        if (data.selectedAggregateField) {
            this.aggregateData[aggregateCount].aggregateDatasetFields.forEach(
                (field) => {
                    if (field.id === data.selectedAggregateField.id) {
                        field.selected = true;
                        this.aggregateData[aggregateCount].aggregateDataSource.data = this.datapointObject.marksSelected(this.aggregateData[aggregateCount].aggregateDataSource.data, field.id);
                        this.aggregateData[
                            aggregateCount
                        ].selectedAggregateField = field;
                    }
                }
            );
        }

        this.aggregateData[aggregateCount].formulas = formulas;
        if (data.selectedFormula) {
            let selectedFormula = formulas.find(
                (f) => f.id === data.selectedFormula.id
            );
            this.aggregateData[aggregateCount].selectedFormula =
                selectedFormula;

            this.aggregateData[aggregateCount].formulas.forEach(
                (formula) => {
                    if (formula.id === data.selectedFormula.id) {
                        this.aggregateData[aggregateCount].aggregateDataSource.data = this.datapointObject.marksSelected(this.aggregateData[aggregateCount].aggregateDataSource.data, data.selectedFormula.id);
                        formula.selected = true;
                    }
                }
            )
        }

        let selectedFieldIds;
        if(data.selectedBreakdownFieldsByDataset[dataset.id]){
            selectedFieldIds = data.selectedBreakdownFieldsByDataset[dataset.id].map(function (
                result
            ) {
                return result["id"];
            })
        }

        this.aggregateData[aggregateCount].tessadataFields = aggregateFieldsData.tessadataFields;
        this.aggregateData[aggregateCount].tessadataGroupedFields = aggregateFieldsData.tessadataGroupedFields;
        this.markSelectedFields(this.aggregateData[aggregateCount].tessadataFields.nriFields, selectedFieldIds);
        this.markSelectedFields(this.aggregateData[aggregateCount].tessadataFields.externalFields, selectedFieldIds);
        if (this.aggregateData[aggregateCount].tessadataGroupedFields !== undefined && Object.keys(this.aggregateData[aggregateCount].tessadataGroupedFields).length) {
            for (const key in this.aggregateData[aggregateCount].tessadataGroupedFields) {
                if (Object.prototype.hasOwnProperty.call(this.aggregateData[aggregateCount].tessadataGroupedFields, key)) {
                    const element = this.aggregateData[aggregateCount].tessadataGroupedFields[key];
                    this.markSelectedFields(element, ObjectUtils.clone(selectedFieldIds));
                }
            }
        }

        this.aggregateData[aggregateCount].globalOverlays = this.getOverlays(overlays);

        let tessadataFieldsByDataset = {};
        tessadataFieldsByDataset[dataset.id] = {nriFields: []};
        tessadataFieldsByDataset[dataset.id].nriFields = this.aggregateData[aggregateCount].tessadataFields.nriFields;
        this.aggregateData[aggregateCount].breakdownDataSource.data = [
            ...this.datapointObject.prepareDataset([dataset], dataset),
            ...this.datapointObject.prepareTesadata(this.aggregateData[aggregateCount].tessadataGroupedFields, dataset),
            ...this.datapointObject.prepareNRIFields(
                [dataset],
                tessadataFieldsByDataset,
                true,
                dataset
            ),
            ...this.datapointObject.filterAndDelete([...this.datapointObject.prepareClimateData(this.aggregateData[aggregateCount].globalOverlays, false, false, false)], {isTextTypeCheck: true, geometryTypes: [DatasetGeometryType.COMPLEX]})
        ];
        this.aggregateData[aggregateCount].breakdownDataSource.data = this.datapointObject.filterAndDelete(this.aggregateData[aggregateCount].breakdownDataSource.data, {isTextTypeCheck: true});
        if (data.selectedBreakdownFieldsByDataset) {
            this.aggregateData[aggregateCount].selectedBreakdownFieldsCount = 0;
            let selectedBreakdownFieldsByDataset = new Map<
                string,
                DatasetField[]
            >();
            for (let k of Object.keys(data.selectedBreakdownFieldsByDataset)) {
                selectedBreakdownFieldsByDataset.set(
                    k,
                    data.selectedBreakdownFieldsByDataset[k]
                );
            }
            selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
                this.aggregateData[
                    aggregateCount
                ].selectedBreakdownFieldsCount += fields.length;
                let selectedFields: DatasetField[] = [];
                let selectedDataset = this.collectLinkedDatasetChooseToForm(
                    dataset
                ).find((d) => d.id === datasetId);

                if (selectedDataset) {
                    selectedDataset.fields.forEach((selectionField) => {
                        let fieldIsSelected = fields.find(
                            (f) => f.id === selectionField.id
                        );
                        if (fieldIsSelected) {
                            selectionField.selected = true;
                            this.aggregateData[aggregateCount].breakdownDataSource.data = this.datapointObject.marksSelected(this.aggregateData[aggregateCount].breakdownDataSource.data, selectionField.id);
                            selectedFields.push(selectionField);
                        }
                    });
                    this.aggregateData[
                        aggregateCount
                    ].selectedBreakdownFieldsByDataset.set(
                        selectedDataset.id,
                        selectedFields
                    );
                }
            });
        }

        this.aggregateData[aggregateCount].breakdownDatasetFields =
            datasetFields.map((field) => {
                let isSelected = false;
                if(data.selectedBreakdownFieldsByDataset[
                    dataset.id
                ]){
                    isSelected = data.selectedBreakdownFieldsByDataset[
                        dataset.id
                    ].find((f) => f.id === field.id);
                }
                if (isSelected) {
                    field.selected = true;
                }
                return field;
        });



        if (
            (data.selectedAggregateField || data.selectedFormula) &&
            data.selectedBreakdownFieldsByDataset
        ) {
            return this.generateReport(
                this.aggregateData[aggregateCount],
                dataset,
                groupsIds
            );
        }
    }


    getOverlays(overlays) {
        let res = overlays;
        let datasetforCountReport: GroupWithOverlaysTreeNode[];
        const information = res.filter(
            (element) =>
                element.group.name.toLowerCase() ===
                "information"
        );
        res.forEach((rootGroup) => {
            this.recursiveOperation(rootGroup, (group) => {
                // Sort Overlays inside Group
                group.overlays = this.sortFields(
                    group.overlays
                );
                // Sort Children Of Group
                if (group.children) {
                    group.children = _.orderBy(
                        group.children,
                        "group.name"
                    );
                }
            });
        });
        res.sort((a, b) =>
            a.group.name > b.group.name ? -1 : 1
        );
        let index = res.findIndex(
            (group) =>
                group.group.name.toLowerCase() === "information"
        );
        if (index !== -1) {
            res.splice(index, 1);
        }
        datasetforCountReport = res;

        datasetforCountReport = !isUndefined(information)
            ? [...datasetforCountReport, ...information]
            : datasetforCountReport;
        datasetforCountReport = ObjectUtils.clone(
            datasetforCountReport
        ).filter(
            (params) =>
                !["Risk Maps"].includes(params.group.name)
        );
        datasetforCountReport =
            this.prepareFilterAcccountOverlays(
                datasetforCountReport
            );

        // datasetforCountReport = this.setFieldsSelected(datasetforCountReport, selectedFieldsByDataset);

        console.log(datasetforCountReport)
        return datasetforCountReport;
    }

    prepareFilterAcccountOverlays(overlays) {
        overlays.forEach((element, key) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            } else if (element.children.length > 0) {
                this.recursiveFilterAccountOverlay(element);
            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            }
        });
        return overlays;
    }

    recursiveFilterAccountOverlay(element) {
        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (
                    sub_element.children.length <= 0 &&
                    sub_element.overlays.length <= 0
                ) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(
                        (params) => !groupIds.includes(params.group.id)
                    );
                    this.recursiveFilterAccountOverlay(sub_element);
                    return;
                }
            });

            if (groupIds.length) {
                element.children = element.children.filter(
                    (params) => !groupIds.includes(params.group.id)
                );
                this.recursiveFilterAccountOverlay(this.parentOverlay);
            }
        }
    }

    recursiveOperation(
        groupNode: GroupWithOverlaysTreeNode,
        operation: (node: GroupWithOverlaysTreeNode) => void
    ) {
        operation(groupNode);
        groupNode.children.forEach((child) => {
            // Sort the Overlay Of children of each Parent
            if (child.overlays) {
                child.overlays = this.sortFields(child.overlays);
            }
            this.recursiveOperation(child, operation);
        });
    }

    sortFields(fields) {
        fields.sort((item1, item2) => {
            if (item1 && item1.name && item2 && item2.name) {
                return item1.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(item2.name.trim().toLowerCase());
            } else {
                return item1.datasetLabel
                    .toLowerCase()
                    .localeCompare(item2.datasetLabel.toLowerCase());
            }
        });
        return fields;
    }

    collectLinkedDatasetChooseToForm(dataset: Dataset) {
        let datasetsToGroupBy = this.datapointsPageStateService
            .getLinkedAccountDatasets()
            .concat(
                this.linkedOverlays.filter(
                        (overlay) =>
                            overlay.geometryType === DatasetGeometryType.COMPLEX
                    )
            );

        datasetsToGroupBy = [...[dataset], ...datasetsToGroupBy];
        if (!datasetsToGroupBy.find((dataset) => dataset.id === dataset.id)) {
            datasetsToGroupBy.splice(0, 0, dataset);
        }

        return JSON.parse(JSON.stringify(datasetsToGroupBy));
    }

    generateReport(report: any, dataset: Dataset, groupsIds) {
        let reportRequest = this.createReportRequest(
            report.selectedBreakdownFieldsByDataset,
            report.selectedFormula,
            report.selectedAggregateField,
            dataset
        );
        this.populateTableColumnsList(report);
        let datapointFilter: DatapointFilter = {
            datasetID: dataset.id,
            groups: groupsIds,
            fields: [],
            links: [],
        };
        report.selectedAggregateName = report.selectedFormula
            ? report.selectedFormula.name
            : report.selectedAggregateField.name;
        this.aggregateService
            .getDatapointsReport(
                dataset.id,
                datapointFilter,
                reportRequest,
                this.prepareProjection(
                    report.selectedBreakdownFieldsByDataset,
                    report.selectedAggregateField,
                    dataset
                )
            )
            .subscribe((success) => {
                const computatedAggregate = this.computeTotalCountAndAggregate(
                    success.groupResults
                );
                report.table.totalAggregate =
                    computatedAggregate.totalAggregateValue;
                report.table.totalCount = computatedAggregate.totalCountValue;

                report.table.reportData = this.convertDataToTableFormat(
                    success.groupResults,
                    report.table.totalAggregate
                );
                if (report.sort && !isUndefined(report.sort)) {
                    report = this.sortData({
                        active: report.sort.active,
                        direction: report.sort.direction
                    }, report, dataset);
                } else {
                    this.populateChartData(report);
                    report.tableDownloadRequest =
                        this.getTableReportDownloadRequest(report, dataset);
                    report.chartDownloadRequest =
                        this.getChartReportDownloadRequest(report, dataset);
                }

                report.breakdownDatasetFields = report.aggregateDatasetFields.map((field) => {
                    // let isSelected = report.selectedBreakdownFieldsByDataset.get(dataset.id).find((f) => f.id === field.id);
                    // if (isSelected) {
                    //     field.selected = true;
                    // }
                    return field;
                });
            });
        return report;
    }

    public populateChartData(report: any) {
        if (report.selectedBreakdownFieldsCount > 2) {
            return; // cannot create chart with more than 2 group by fields
        }
        if (report.table.reportData.length === 0) {
            return;
        }
        let chartDatasetsInfo: ChartInfo[] = [];
        let mainDatasetFieldValues = []; // chart labels
        let chartDatasets: ChartDataSets[] = [];

        if (report.selectedBreakdownFieldsCount <= 1) {
            let chartDataset = {
                data: [],
            };
            let chartDatasetInfo = {
                backgroundColor: [],
                count: [], // this is only a shortcut for download
            };
            chartDatasets.push(chartDataset);
            chartDatasetsInfo.push(chartDatasetInfo);

            report.table.reportData.forEach((entry) => {
                mainDatasetFieldValues.push(
                    entry.dynamicFieldValuesByIds.values().next().value
                );
                chartDatasetInfo.backgroundColor.push(
                    ColorUtils.generateRandomHexColor()
                );
                chartDatasetInfo.count.push(entry.count);
                switch (report.chartDisplayType) {
                    case ChartDisplayType.AGGREGATE:
                        chartDataset.data.push(entry.aggregate);
                        break;
                    case ChartDisplayType.COUNT:
                        chartDataset.data.push(entry.count);
                        break;
                    case ChartDisplayType.AVERAGE:
                        chartDataset.data.push(entry.average);
                        break;
                }
            });
        } else {
            let mainField = report.table.reportData[0].dynamicFieldValuesByIds
                .keys()
                .next().value; // we will use this as the main field in the chart
            report.table.reportData.forEach((row) => {
                let mainFieldValue = row.dynamicFieldValuesByIds.get(mainField);
                if (!mainDatasetFieldValues.includes(mainFieldValue)) {
                    mainDatasetFieldValues.push(mainFieldValue);
                }
            });

            report.table.reportData.forEach((row) => {
                let mainDatasetFieldValue =
                    row.dynamicFieldValuesByIds.get(mainField);
                let indexInDatasetArray = mainDatasetFieldValues.indexOf(
                    mainDatasetFieldValue
                ); // because the labels indexes must match the values indexes
                row.dynamicFieldValuesByIds.forEach((value, datasetFieldId) => {
                    if (datasetFieldId !== mainField) {
                        let chartDataset = chartDatasets.find(
                            (cd) => cd.label === value
                        );
                        let chartDatasetInfo = chartDatasetsInfo.find(
                            (bg) => bg.label === value
                        );
                        if (!chartDataset) {
                            chartDataset = {
                                data: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(0),
                                label: value,
                            };
                            chartDatasets.push(chartDataset);
                        }
                        if (!chartDatasetInfo) {
                            chartDatasetInfo = {
                                label: value,
                                backgroundColor: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(ColorUtils.generateRandomHexColor()),
                                count: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(0),
                            };
                            chartDatasetsInfo.push(chartDatasetInfo);
                        }
                        switch (report.chartDisplayType) {
                            case ChartDisplayType.AGGREGATE:
                                chartDataset.data[indexInDatasetArray] =
                                    row.aggregate;
                                break;
                            case ChartDisplayType.COUNT:
                                chartDataset.data[indexInDatasetArray] =
                                    row.count;
                                break;
                            case ChartDisplayType.AVERAGE:
                                chartDataset.data[indexInDatasetArray] =
                                    row.average;
                                break;
                        }
                        chartDatasetInfo.count[indexInDatasetArray] = row.count;
                    }
                });
            });
        }
        report.chart.chartDatasets = chartDatasets;
        report.chart.chartLabels = mainDatasetFieldValues;
        report.chart.chartColors = chartDatasetsInfo;
        report.chart.chartOptions = {
            responsive: true,
            legend: {
                labels: {
                    generateLabels: (chart) => {
                        let legendItems: ChartLegendLabelItem[] = [];
                        if (chart.data.datasets.length === 1) {
                            const data = chart.data.datasets[0].data;
                            if (data) {
                                data.forEach((value, i) => {
                                    legendItems.push({
                                        text: report.chart.chartLabels[i],
                                        fillStyle: report.chart.chartColors[0].backgroundColor[i], // because all values os the secondary dataset have the same color
                                    });
                                });
                            }
                        } else {
                            chart.data.datasets.forEach((dataset, i) => {
                                legendItems.push({
                                    text: dataset.label,
                                    fillStyle: dataset.backgroundColor[0],
                                });
                            });
                        }

                        if (legendItems.length <= 40) {
                            return legendItems;
                        } else {
                            return [];
                        }
                    },
                },
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        var name = data.labels[tooltipItem.index];
                        const roundedUpValue = typeof value === 'number' ? value.toFixed(2) : value;
                        return  name + ': ' + StringUtils.numberWithCommas(roundedUpValue);
                    }
                } // end callbacks:
            },
            scales: report.reportSubType === ReportDisplayType.BAR_CHART ? {
                yAxes: [
                    {
                        ticks: {
                            callback: (value, index, values) => {
                                return StringUtils.numberWithCommas(value);
                            },
                        },
                    },
                ],
            } : null
        };
        return report;
    }

    private createReportRequest(
        selectedBreakdownFieldsByDataset: Dataset,
        selectedFormula: WorkspaceItem,
        selectedAggregateField: DatasetField,
        dataset: Dataset
    ): ReportRequest {
        let groups: AggregateGroupRequest[] = [];
        selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                groups.push({
                    datasetID: datasetId,
                    fieldID: field.id,
                });
            });
        });

        let aggregateFieldType;
        let aggregateFieldCodes;
        let aggregateFieldFormula;

        if (selectedFormula) {
            aggregateFieldType = DatapointAggregateFieldType.FORMULA;
            aggregateFieldFormula = selectedFormula.data;
        } else {
            aggregateFieldType = DatapointAggregateFieldType.FIELD;
            let formulaId = `${dataset.id}.${selectedAggregateField.id}`;
            aggregateFieldCodes = [
                { aggregateFieldCode: `VAR_${formulaId}`, id: formulaId },
            ];
        }

        return {
            datasetID: dataset.id,
            groups: groups,
            aggregateFieldCodes: aggregateFieldCodes,
            aggregateFieldType: aggregateFieldType,
            aggregateFieldFormulaJson: aggregateFieldFormula,
        };
    }

    private populateTableColumnsList(report: any) {
        report.table.dynamicColumns = new Map();
        report.table.columnsToDisplay = [];

        report.selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    field.id
                );
                if (key !== "undefined") {
                    report.table.dynamicColumns.set(key, field.name);
                    report.table.columnsToDisplay.push(key);
                }
            });
        });

        report.table.columnsToDisplay.push(this.AGGREGATE_COLUMN_ID);
        report.table.columnsToDisplay.push(this.PERCENTAGE_COLUMN_ID);
        report.table.columnsToDisplay.push(this.COUNT_COLUMN_ID);
        report.table.columnsToDisplay.push(this.AVERAGE_COLUMN_ID);
    }

    private prepareProjection(
        selectedBreakdownFieldsByDataset: Dataset,
        selectedAggregateField: DatasetField,
        dataset: Dataset
    ): DatapointProjection {
        let datapointProjection: DatapointProjection = {
            datasetID: dataset.id,
            fields: [],
            links: [],
        };

        selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            if (datasetId === dataset.id) {
                datapointProjection.fields = fields.map((field) => field.id);
            } else {
                let linkProjection: DatapointProjection = {
                    datasetID: datasetId,
                    fields: fields.map((field) => field.id),
                };
                datapointProjection.links.push(linkProjection);
            }
        });
        if (selectedAggregateField) {
            datapointProjection.fields.push(selectedAggregateField.id);
        }
        return datapointProjection;
    }

    private computeTotalCountAndAggregate(
        groupResults: ReportResultResponse[]
    ) {
        let totalCount: number = 0;
        let totalAggregate = 0;
        groupResults.forEach((groupResult) => {
            totalCount += groupResult.values[0].count;
            totalAggregate += groupResult.values[0].result;
        });

        totalAggregate = Math.round(totalAggregate * 100) / 100;
        return {
            totalAggregateValue: totalAggregate,
            totalCountValue: totalCount,
        };
    }

    private convertDataToTableFormat(
        groupResults: ReportResultResponse[],
        totalAggregate: number
    ) {
        let reportData = [];
        let downloadReportData = [];

        groupResults.forEach((groupResult) => {
            let percentage: number =
                totalAggregate > 0
                    ? (groupResult.values[0].result / totalAggregate) * 100
                    : 0;
            let tableEntry: ReportRow = {
                count: groupResult.values[0].count,
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    groupResult.buckets
                ),
                percentage: Math.round(percentage * 100) / 100,
                aggregate: groupResult.values[0].result,
                average:
                    groupResult.values[0].result / groupResult.values[0].count,
            };
            downloadReportData.push(tableEntry);
        });

        reportData = downloadReportData.slice(0, this.LIMIT);
        return reportData;
    }

    getDynamicFieldValuesByIds(
        groupResultBuckets: ReportResultGroupResponse[]
    ): Map<string, string> {
        let tableEntries: Map<string, string> = new Map<string, string>();
        groupResultBuckets.forEach((groupResultBucket) => {
            let value = groupResultBucket.value
                ? groupResultBucket.value
                : "N/A";
            tableEntries.set(
                DatasetUtils.createUniqueIdentifierForDatasetField(
                    groupResultBucket.datasetID,
                    groupResultBucket.fieldID
                ),
                value
            );
        });
        return tableEntries;
    }

    sortData(sort: Sort, report: any, dataset: Dataset) {
        const isAsc = sort.direction === "asc";
        const fieldId = sort.active;
        report.sort.direction = sort.direction;
        report.sort.active = fieldId;
        let sortedData = report.table.reportData.sort((a, b) => {
            switch (fieldId) {
                case this.COUNT_COLUMN_ID:
                    return this.compare(a.count, b.count, isAsc);
                case this.PERCENTAGE_COLUMN_ID:
                    return this.compare(a.percentage, b.percentage, isAsc);
                case this.AGGREGATE_COLUMN_ID:
                    return this.compare(a.aggregate, b.aggregate, isAsc);
                case this.AVERAGE_COLUMN_ID:
                    return this.compare(a.average, b.average, isAsc);
                default: {
                    let aValue = a.dynamicFieldValuesByIds.get(fieldId);
                    let bValue = b.dynamicFieldValuesByIds.get(fieldId);
                    return this.compare(aValue, bValue, isAsc);
                }
            }
        });

        report.table.reportData = [...sortedData];
        report.table.downloadReportData = [...sortedData];
        this.populateChartData(report);
        report.tableDownloadRequest =
            this.getTableReportDownloadRequest(report, dataset);
        report.chartDownloadRequest =
            this.getChartReportDownloadRequest(report, dataset);
        return report;
    }

    private compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    getTableReportHeader(report, dataset): TableColumn[] {
        const {
            selectedBreakdownFieldsByDataset,
            datasetId,
            selectedAggregateField,
            selectedFormula
        } = this.getDownloadRequestParams(report, dataset);

        let columns: TableColumn[] = [];
        selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        field.id
                    ),
                    name: field.name,
                    type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                    horizontalAlignment: TableColumnAlignment.LEFT,
                });
            });
        });

        if (selectedFormula) {
            columns.push({
                id: DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    selectedFormula.id
                ),
                name: selectedFormula.name,
                type: TableColumnType.TEXT,
                horizontalAlignment: TableColumnAlignment.LEFT,
            });
        }

        if (selectedAggregateField !== undefined) {
            columns.push({
                id: DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    selectedAggregateField.id
                ),
                type: TableColumnType.DECIMAL,
                name: selectedAggregateField.name,
                horizontalAlignment: TableColumnAlignment.RIGHT,
            });
        }
        columns.push({
            id: this.PERCENTAGE_COLUMN_ID,
            name: "Total %",
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });
        columns.push({
            id: this.COUNT_COLUMN_ID,
            name: "Count",
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        columns.push({
            id: this.AVERAGE_COLUMN_ID,
            name: "Average",
            type: TableColumnType.DECIMAL,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        return columns;
    }

    getTableReportFooter(report, dataset): TableRow {
        const {
            dynamicColumns,
            datasetId,
            selectedAggregateField,
            totalAggregate,
            totalCount,
            selectedFormula
        } = this.getDownloadRequestParams(report, dataset);

        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        dynamicColumns.forEach((key, value) =>
            cells.push({ id: this.BLANK_COLUMN_ID, value: "" })
        );
        cells.splice(cells.length - 1, 1); // we need to add only N-1 empty spaces

        if (selectedFormula) {
            cells.push({
                id: DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    selectedFormula.id
                ),
                value: Math.round(totalAggregate),
            });
        }

        if (selectedAggregateField !== undefined) {
            cells.push({
                id: DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    selectedAggregateField.id
                ),
                value: Math.round(totalAggregate),
            });
        }
        cells.push({ id: this.PERCENTAGE_COLUMN_ID, value: "100%" });
        cells.push({
            id: this.COUNT_COLUMN_ID,
            value: totalCount.toString(),
        });
        cells.push({
            id: this.AVERAGE_COLUMN_ID,
            value: Math.round(totalAggregate / totalCount),
        });

        return { cells: cells };
    }

    getTableReportRows(report, dataset): TableRow[] {
        const {
            downloadReportData,
            dynamicColumns,
            datasetId,
            selectedAggregateField,
            selectedFormula
        } = this.getDownloadRequestParams(report, dataset);

        let rows: TableRow[] = [];
        downloadReportData.map((row) => {
            let columns: TableCell[] = [];

            dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });

            if (selectedFormula) {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        selectedFormula.id
                    ),
                    value: row.aggregate,
                });
            }

            if (selectedAggregateField !== undefined) {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        selectedAggregateField.id
                    ),
                    value: row.aggregate,
                });
            }

            columns.push({
                id: this.PERCENTAGE_COLUMN_ID,
                value: row.percentage.toString() + "%",
            });

            columns.push({ id: this.COUNT_COLUMN_ID, value: row.count });

            columns.push({
                id: this.AVERAGE_COLUMN_ID,
                value: Math.round(row.average),
            });

            rows.push({ cells: columns });
        });

        return rows;
    }

    getTableReportDownloadRequest(report, dataset): DownloadReportItem {
        let reportHeader = this.getTableReportHeader(report, dataset);
        let reportRows = this.getTableReportRows(report, dataset);
        let reportFooter = this.getTableReportFooter(report, dataset);
        let title = report.reportName || "Aggregate";
        return new DownloadReportTableRequest(
            title,
            reportHeader,
            reportRows,
            reportFooter
        );
    }

    getChartReportDownloadRequest(report, dataset): DownloadReportItem {
        const {
            selectedBreakdownFieldsByDataset,
            chartDisplayType,
            chartDatasets,
            chartColors,
            chartLabels,
        } = this.getDownloadRequestParams(report, dataset);

        let breakdownFieldsNames: string[] = [];
        selectedBreakdownFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                breakdownFieldsNames.push(field.name);
            });
        });
        let request: DownloadReportChartRequest = {
            title: report.reportName || "Aggregate",
            valueKey:
                (chartDisplayType === ChartDisplayType.AGGREGATE &&
                    ValueKey.VALUE) ||
                (chartDisplayType === ChartDisplayType.COUNT &&
                    ValueKey.COUNT) ||
                (chartDisplayType === ChartDisplayType.AVERAGE &&
                    ValueKey.AVERAGE),
            type: undefined,
            columns: {
                value: chartDisplayType,
                categories: breakdownFieldsNames,
            },
            values: [],
        };

        chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                const count = chartColors[datasetIndex].count[valueIndex];
                const chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [chartLabels[valueIndex]],
                    colors: [
                        chartColors[datasetIndex].backgroundColor[valueIndex],
                    ],
                    count: count,
                    value: value,
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });

        // Setting default request type as PIE CHART
        request.type = DownloadItemReportType.AGGREGATE_PIE_CHART;

        return request;
    }

    isTwoDimensionReport(selectedBreakdownFieldsByDataset): boolean {
        let selectedFieldNo = 0;
        selectedBreakdownFieldsByDataset.forEach(
            (fields, datasetId) => (selectedFieldNo += fields.length)
        );
        return selectedFieldNo === 2;
    }

    getDownloadRequestParams(report, dataset) {
        return {
            datasetId: dataset.id,
            selectedBreakdownFieldsByDataset:
                report.selectedBreakdownFieldsByDataset,
            selectedAggregateField: report.selectedAggregateField,
            totalAggregate: report.table.totalAggregate,
            dynamicColumns: report.table.dynamicColumns,
            totalCount: report.table.totalCount,
            downloadReportData: report.table.reportData,
            reportName: report.reportName,
            chartDatasets: report.chart.chartDatasets,
            chartColors: report.chart.chartColors,
            chartLabels: report.chart.chartLabels,
            chartDisplayType: report.chartDisplayType,
            reportSubType: report.reportSubType,
            selectedFormula: report.selectedFormula
        };
    }

    markSelectedFields(array: any[], selectedIds: string[]): void {
        for (const item of array) {
            if (item.child && Array.isArray(item.child)) {
                this.markSelectedFields(item.child, selectedIds);
            } else if (item.child && selectedIds.includes(item.child.id)) {
                item.child.selected = true;
            } else if (selectedIds && selectedIds.includes(item.id)) {
                item.selected = true;
            }
        }
    }

}
