import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter, HostListener,
    Input, OnDestroy, OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { SidePanelService } from 'src/app/shared/services/side-panel.service';
import componentsMapping, { ISidePanel, SidePanels } from '../../shared/services/side-panel.helper';
import {DataPointsServiceState} from '../../shared/services/datapoints-service-state';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MUNICHRE_ARRAY, TENSORFLIGHT_ARRAY, addressProfileDropdownMenuList } from 'src/app/model/datapoint/location-profile/location-profile';
import { UserStateService } from 'src/app/auth/user-state-service';
import { DatapointsPageStateService } from 'src/app/dataset/datapoints/datapoints-page-state.service';
import { isUndefined } from '../utils/util-master';
import { AccountService } from 'src/app/data-access-layer/account/account.service';
import { TessadataService } from 'src/app/data-access-layer/tessadata.service';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-side-panel',
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidePanelComponent implements OnInit, OnDestroy, ISidePanel {
    @Input() id: string;
    @Input() size: string;
    @Input() width: number;
    @Input() panelTitle: string;
    @Input() panelIcon: string;
    @Input() backdrop: boolean;
    @Input() resizeable: boolean;
    @Input() component: SidePanels;
    @Input() componentConfigs: any;
    @Input() zIndex: number;
    @Output() showChange = new EventEmitter<boolean>();
    @Output() closed = new EventEmitter();

    isOpen: boolean;
    show: boolean;
    hasAccess: any;

    // Resize
    grabber = false;
    dragWidth: number;
    x = 100;
    oldX = 0;
    subscription = new Subscription();
    closeModal$: Subject<boolean>;
    hideModal$: Subject<SidePanelComponent> = new Subject();
    onPanelFirst$: Subject<SidePanelComponent> = new Subject<SidePanelComponent>();
    destroy$: Subject<boolean>;
    componentRef: ComponentRef<any>;
    dropdownValueChanged$: Subject<string> = new Subject<string>();

    @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

    selectedGroup: UntypedFormGroup;
    selectedValue = new UntypedFormControl('EXTERNAL');
    menuList: any;
    readonly ADDRESS_LOCATION_PROFILE = 'addressLocationProfilePanel';

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly userStateService: UserStateService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly componentFactoryResolver: ComponentFactoryResolver, private readonly dataPointServiceState: DataPointsServiceState, private fb: UntypedFormBuilder,
        private readonly accountService: AccountService,
        private readonly tessadataService: TessadataService,
        private readonly route: ActivatedRoute,
        public dataPointsServiceState: DataPointsServiceState,
        ) {
        this.resizeable = false;
        this.closeModal$ = new Subject();
        // this.hideModal$ = new Subject();
        this.destroy$ = new Subject();
    }

    getPanelIcon() {
        if (this.isFontAwesomeIcon()) {
            return 'fa ' + this.panelIcon;
        } else {
            return '../../../assets/icons/newIcons/panelIcons/' + this.panelIcon + ".svg";
        }
    }

    isFontAwesomeIcon() {
        return this.panelIcon.startsWith('fa-');
    }

    ngOnInit(): void {
        if (this.component) {
            this.selectedGroup = this.fb.group({
                defaultSelected: ['EXTERNAL']
            });
            this.menuList = Object.keys(addressProfileDropdownMenuList).map(function(personNamedIndex){
                let menu =  [personNamedIndex, addressProfileDropdownMenuList[personNamedIndex]];
                return menu;
            });
            if(this.isAddressLocationProfileCall()){
                this.checkModuleAccess();
            }
            const component = componentsMapping.get(this.component);
            const factory = this.componentFactoryResolver.resolveComponentFactory(component);
            this.componentRef = this.container.createComponent(factory);

            if (this.componentConfigs) {
                Object.keys(this.componentConfigs).map((value: string) => {
                    this.componentRef.instance[value] = this.componentConfigs[value];
                });
            }

            this.show = true;
            this.isOpen = true;
            this.dragWidth = this.width;
        }
    }

    private checkModuleAccess() {
        let accountId = +this.route.snapshot.paramMap.get("accountId");
        this.tessadataService.checkAccountAccess(accountId)
            .subscribe((data) => {
                this.hasAccess = data;
                if (this.hasAccess.tensorflight)
                    this.menuList.push(TENSORFLIGHT_ARRAY);
                if (this.hasAccess.munichre_hazards)
                    this.menuList.push(MUNICHRE_ARRAY);
            });
    }

    showPanel() {
        this.show = true;
        this.isOpen = true;
        if (this.dragWidth === 0)
            this.dragWidth = this.width;
        this.applyChangeDetection();
    }

    setPanelFirst() {
        this.onPanelFirst$.next(this);
    }

    closePanel(evt?: MouseEvent) {
        if (evt) evt.stopPropagation();
        this.show = false;
        this.isOpen = false;
        this.dragWidth = 0;
        this.closeModal$.next(true);
    }

    hidePanel(evt?: MouseEvent, doNotEmit?: boolean) {
        if (this.isAddressLocationProfileCall()) {
            this.selectedGroup.controls.defaultSelected.reset();
            this.selectedGroup.controls.defaultSelected.setValue('EXTERNAL');
            this.dataPointsServiceState.emitOnTensorflightAddressProfilePolygonFetch("");
        }
        if (evt) evt.stopPropagation();
        this.show = false;
        this.isOpen = false;
        this.dragWidth = 0;
        if (!doNotEmit) this.hideModal$.next(this);
        this.applyChangeDetection();
    }

    onClick() {
        this.dataPointServiceState.emitDatapointUpdate(false);
    }

    applyChangeDetection() {
        this.changeDetectorRef.detectChanges();
    }

    get sizeClass() {
        return 'size-panel-' + this.size;
    }

    onMouseMove(event: MouseEvent) {
        if (!this.grabber) {
            return;
        }

        this.resizer(event.clientX - this.oldX);
        this.oldX = event.clientX;
        this.applyChangeDetection();
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        if (this.width) {
            this.dragWidth = Math.min(Math.max(this.dragWidth, this.width), window.innerWidth);
        }

        this.grabber = false;
        this.subscription.unsubscribe();
    }

    resizer(offsetX: number) {
        this.dragWidth -= offsetX;
    }

    onMouseDown(event: MouseEvent) {
        this.grabber = true;
        this.oldX = event.clientX;

        this.subscription.unsubscribe();
        const mousemoveGlobal = fromEvent<MouseEvent>(document, 'mousemove');
        this.subscription = mousemoveGlobal
            .pipe(throttleTime(10))
            .subscribe((e) => this.onMouseMove(e));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    selectionChange($event) {
        this.applyChangeDetection();
        this.selectedValue.setValue($event.value);
        this.dropdownValueChanged$.next($event.value);
        this.dataPointServiceState.emitOnExternalOverlaySelected($event.value);
        this.dataPointServiceState.externalCallCount = 0;
    }

    isAddressLocationProfileCall() {
        return this.id === this.ADDRESS_LOCATION_PROFILE;
    }
}
