import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DatapointFilter} from '../model/datapoint/filter/datapoint-filter';
import {TessadataDatasetsByCountry} from '../core/tessadata/tessadata-datasets-by-country';
import {TessadataDatasetType} from '../core/tessadata/tessadata-dataset-type';
import {TessadataLocationDetails} from '../core/tessadata/tessadata-location-details';
import {TessadataDatasetStructure} from '../core/tessadata/tessadata-dataset-structure';
import {PoiClosestFilter} from '../model/datapoint/filter/poi-closest-filter';

@Injectable({
    providedIn: 'root'
})
export class TessadataService {


    constructor(private readonly http: HttpClient) {
    }

    /**
     * return type is geoJSON
     */
    getZoneGeometry(longitude: number, latitude: number, externalDatasetId: string, accountId:number): Observable<any> {
        return this.http.get(`/tessadata/${longitude}/${latitude}/dataset/${externalDatasetId}/accountId/${accountId}/geometry`);
    }

    fetchDatasets(type: TessadataDatasetType, accountId: number): Observable<TessadataDatasetsByCountry[]> {
        return this.http.get<TessadataDatasetsByCountry[]>(`/tessadata/datasets/${accountId}?type=${type}`);
    }

    fetchDatasetsStructures(datasetID: string, externalDatasetsIds: string[]): Observable<TessadataDatasetStructure[]> {
        return this.http.get<TessadataDatasetStructure[]>(`/tessadata/${datasetID}/datasets/fields?datasetsIds=${externalDatasetsIds}`);
    }

    fetchClosestPOIDetails(filter: PoiClosestFilter): Observable<TessadataLocationDetails> {
        return this.http.get<TessadataLocationDetails>(
            `/tessadata/${filter.longitude}/${filter.latitude}/fetch-poi?externalDatasetId=${filter.externalDatasetId}&distance=${filter.distanceInMeters}&limit=${filter.limit}`
        );
    }

    fetchExternalData(projection, datasetId, externalDatasetIds: string[], datapointIds: string[], filter: DatapointFilter, refresh: boolean, accountId?: number): Observable<void> {
        const url = `/tessadata/fetch-data/dataset/${datasetId}/${accountId}/by-ids?externalDatasetsIds=${externalDatasetIds}`;
        return this.http.put<void>(url, datapointIds, {params: {filter: JSON.stringify(filter), projection: JSON.stringify(projection), refresh : refresh}});
    }

    fetchExternalDataByFilter(projection, datasetId, externalDatasetIds: string[], filter: DatapointFilter, refresh: boolean, accountId?: number): Observable<void> {
        const url = `/tessadata/fetch-data/dataset/${datasetId}/${accountId}/by-filter?externalDatasetsIds=${externalDatasetIds}`;
        return this.http.put<void>(url, null, {params: {filter: JSON.stringify(filter), projection: JSON.stringify(projection), refresh : refresh}});
    }

    fetchLocationProfile(latitude: number, longitude: number): Observable<TessadataLocationDetails> {
        let url = `/tessadata/${longitude}/${latitude}/fetch`;
        return this.http.get<TessadataLocationDetails>(url);
    }

    checkAccountAccess(accountId:number): Observable<any>{
        return this.http.get(`/tessadata/${accountId}/hasaccess`);
    }

    getLoggedData(latitude: number, longitude: number, externalDatasetId:String): Observable<TessadataLocationDetails>{
        return this.http.get<TessadataLocationDetails>(`/tessadata/${longitude}/${latitude}/get-logged-data?datasetId=${externalDatasetId}`);
    }

    fetchExternalLogData(accountId: number, latitude: number, longitude: number, externalDatasetId:string, address: string, isRefresh = false): Observable<TessadataLocationDetails>{
        return this.http.get<TessadataLocationDetails>(`/tessadata/${accountId}/${longitude}/${latitude}/fetch-see-info?datasetId=${externalDatasetId}&address=${address}&refresh=${isRefresh}`);
    }

}
