<map-drafts-upload #uploadComponent *ngIf="dataset" [dataset]="dataset" [IsInDraft]="false"
	(uploadCompleted)="onUploadComplete()"></map-drafts-upload>
<map-header>
	<div class="header-option">
		<button [matMenuTriggerFor]="actionsMenu">
			Actions
		</button>
		<mat-menu #actionsMenu="matMenu" class="custom-menu nested-menu header-menu" backdropClass="mat-menu-header">
			<hr class="menu-line-separator" *ngIf="downloadDatapointsEnabled && !isMapView" />
			<div mat-menu-item *ngIf="downloadDatapointsEnabled && !isMapView" (click)="openDownloadDialog()">
				<span>
					<!-- download-arrow-icon -->
					<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<g fill="none" fill-rule="evenodd">
							<path stroke="#0044f1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
								d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
							<path d="M-.005-.005h24.01v24.01H-.005z" />
						</g>
					</svg>
				</span>
				Download
			</div>
			<hr class="menu-line-separator" *ngIf="hasWritePermission && !isMapView && alterDatapointsEnabled" />
			<div mat-menu-item *ngIf="hasWritePermission && !isMapView && alterDatapointsEnabled"
				(click)="deleteSelected()">
				<span>
					<!-- Trash-Icon -->
					<svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg">
						<g fill="none" fill-rule="evenodd">
							<path
								d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
								stroke="#0044f1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
								class="icon-actions" />
							<path d="M0 0h24v24H0z" />
						</g>
					</svg>
				</span>
				Delete
			</div>
			<hr class="menu-line-separator"
				*ngIf="(isAccountAdmin || userStateService.isSuperadmin)&& !dataset?.isGeoserver" />
			<div mat-menu-item *ngIf="(isAccountAdmin || userStateService.isSuperadmin)&& !dataset?.isGeoserver"
				(click)="openImport()">
				<span>
					<!-- upload-arrow-icon -->
					<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
						style="transform: rotate(180deg);">
						<g fill="none" fill-rule="evenodd">
							<path stroke="#0044f1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
								d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
							<path d="M-.005-.005h24.01v24.01H-.005z" />
						</g>
					</svg>
				</span>
				Upload
			</div>
			<hr class="menu-line-separator"
				*ngIf="eventNotificationsEnabled && !userStateService.isSuperadmin && ![DatasetsNames.CLAIMS, DatasetsNames.POLICIES].includes(dataset?.name)" />
			<div mat-menu-item
				*ngIf="eventNotificationsEnabled && !userStateService.isSuperadmin && ![DatasetsNames.CLAIMS, DatasetsNames.POLICIES].includes(dataset?.name)"
				(click)="openEventNotificationsPanel()">
				<span><mat-icon fontIcon="fa-bell" fontSet="far"></mat-icon></span>
				Alerts
			</div>
			<hr class="menu-line-separator"
				*ngIf="hasWritePermission && tessadataEnabled && !isMapView && dataset?.geometryType === DatasetGeometryType.POINT" />
			<div mat-menu-item class="nested-menu header-option" (click)="toggleExternalData($event)"
				*ngIf="hasWritePermission && tessadataEnabled && !isMapView && dataset?.geometryType === DatasetGeometryType.POINT">
				<span style="width: 100%;">
					<div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
						<div style="display: flex; align-items: center;">
							<div>
								<!-- tasks-icon -->
								<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
									style="position: relative;top: 4px;">
									<g fill="none" fill-rule="evenodd">
										<path stroke="#0044f1" stroke-width="2" stroke-linecap="round"
											stroke-linejoin="round"
											d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004" />
										<path stroke="#0044f1" stroke-width="2" stroke-linecap="round"
											stroke-linejoin="round" d="m18.665 15.167-2.083 2.085-1.25-1.25" />
										<path d="M-.005-.005h24.01v24.01H-.005z" />
									</g>
								</svg>
							</div>
							<div style="margin-left: 3px;">
								External Data
							</div>
						</div>
						<div>
							<mat-icon style="width: 16px; font-size: 16px;margin-right: 6px !important;">
								{{ externalData ? 'expand_less' : 'expand_more' }}
							</mat-icon>
						</div>
					</div>
				</span>
				<hr class="menu-line-separator" />
			</div>
			<div *ngIf="externalData">
				<mat-tree [dataSource]="externalDatadataSource" [treeControl]="externalDatatreeControl"
					*ngIf="hasWritePermission && tessadataEnabled && !isMapView && dataset?.geometryType === DatasetGeometryType.POINT">
					>
					<mat-tree-node *matTreeNodeDef="let node" class="sub-menu-options-child">
						<li class="mat-tree-node">
							<hr class="menu-line-separator" />
							<div class="external-overlay-link external-node-overlay-link">
								<span [matTooltip]="node.name" matTooltipPosition="left" class="external-node-title">{{
									node.name }}</span>
								<div class="fetch-actions">
									<mat-icon class="first-icon" matTooltip="Fetch" matTooltipPosition="above"
										(click)="fetchExternalData(node, $event, false)"
										style="color: #0044f1 !important; font-size: 16px !important;">task_alt</mat-icon>
									<mat-icon matTooltip="Refresh" matTooltipPosition="above"
										(click)="fetchExternalData(node, $event, true)"
										style="color: #0044f1 !important; font-size: 16px !important;">autorenew</mat-icon>
								</div>
							</div>
						</li>
					</mat-tree-node>
					<mat-nested-tree-node *matTreeNodeDef="let node; when: externalDataTreeStrcuture.hasChild"
						class="sub-menu-options-parent external-sub-menu"
						[class.example-tree-invisible]="!node.expanded">
						<li>
							<hr class="menu-line-separator" />
							<div class="mat-tree-node">
								<span [matTooltip]="node.name" matTooltipPosition="right">{{ node.name }}</span>
								<button mat-icon-button matTreeNodeToggle (click)="displayNodeData(node)">
									<mat-icon class="mat-icon-rtl-mirror"
										style="width: 16px; font-size: 16px; color: #0044f1;">
										{{ node.expanded ? 'expand_more' : 'chevron_right' }}
									</mat-icon>
								</button>
							</div>
							<ul>
								<ng-container *ngIf="node.expanded">
									<ng-container matTreeNodeOutlet></ng-container>
								</ng-container>
							</ul>
						</li>
					</mat-nested-tree-node>
				</mat-tree>
			</div>
			<hr class="menu-line-separator" />

			<div mat-menu-item (click)="toggleDatasetList($event)" mat-menu-icon="display:none"
				style="padding-left: 6px !important;">

				<span style="width: 100%;">
					<div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
						<div style="display: flex; align-items: center;">
							<div>
								<i class="fal fa-toggle-on"
									style="color: #0044f1; width: 20px; height: 20px;position: relative;top: 2px;"></i>
							</div>
							<div style="margin-left: 3px;">
								Switch to
							</div>
						</div>
						<div>
							<mat-icon style="width: 16px; font-size: 16px;">
								{{ showDatasetList ? 'expand_less' : 'expand_more' }}
							</mat-icon>
						</div>
					</div>
				</span>
				<hr class="menu-line-separator" />
			</div>
			<div class="nested-menu header-menu" style="margin-top: 4px !important;" color="accent"
				[class.tree-invisible]="!showDatasetList">
				<hr class="menu-line-separator" />
				<div class="nested-menu header-menu" style="margin-top: 4px !important;" color="accent"
					[class.tree-invisible]="!showDatasetList">
					<ng-container
						*ngFor="let currentDataset of userStateService.availableDatasetsInAccount(account?.id); let last = last">
						<div [class.list-item-active]="currentDataset?.id === dataset?.id"
							(click)="switchApplication(currentDataset?.id)" mat-menu-item>
							{{currentDataset?.name}}
						</div>
						<hr *ngIf="!last" class="menu-line-separator">
					</ng-container>
				</div>
				<hr *ngIf="draftsEnabled && (isAccountAdmin || userStateService.isSuperadmin)"
					class="menu-line-separator" />
				<ng-container *ngIf="draftsEnabled && (isAccountAdmin || userStateService.isSuperadmin)">
					<div (click)="navigateToDrafts()" mat-menu-item>
						Drafts
					</div>
				</ng-container>
			</div>
		</mat-menu>
	</div>
	<div class="header-option">
		<ng-container *ngIf="!isMapView && dataset">
			<button [matMenuTriggerFor]="viewMenu">
				View
				<mat-icon fontIcon="fa-angle-down" fontSet="far"></mat-icon>
			</button>
			<mat-menu #viewMenu="matMenu" class="custom-menu nested-menu header-menu">
				<hr class="menu-line-separator" />

				<div class="nested-menu" (click)="$event.stopPropagation()">
					<div mat-menu-item (click)="toggleFormulasMenu()"
						style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
						<div>
							<span>
								<!-- funvtion-icon -->
								<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
									style="position: relative; top:4px;">
									<g fill="none" fill-rule="evenodd">
										<path d="M24 24H0V0h24z" />
										<path
											d="M8.48 16.48c.333.333.784.52 1.255.52h0c.882 0 1.63-.647 1.757-1.519l1.016-6.962A1.775 1.775 0 0 1 14.265 7h0c.47 0 .922.187 1.255.52M15.264 11.551h-6"
											stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
										<path d="M3 8v8a5 5 0 0 0 5 5h8a5 5 0 0 0 5-5V8a5 5 0 0 0-5-5H8a5 5 0 0 0-5 5z"
											stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</g>
								</svg>
							</span>
							Formula
						</div>
						<div>
							<mat-icon *ngIf="isFormulasMenuOpen()"
								style="color: #0044f1; font-size: 16px !important;">expand_less</mat-icon>
							<mat-icon *ngIf="!isFormulasMenuOpen()"
								style="color: #0044f1; font-size: 16px !important;">expand_more</mat-icon>
						</div>
					</div>
					<ul *ngIf="isFormulasMenuOpen()" class="action-formula-dropdown">
						<li *ngFor="let item of workspaceItemsForFormulas; let i = index">
							<div (click)="$event.stopPropagation()" class="action-formula-inner-wrapper">
								<mat-checkbox mat-checkbox [(ngModel)]="item.isProjected"
									(ngModelChange)="onFormulaProjectionChange(item)" class="formula-name"
									style="width: 25px !important; bottom: 4px;"></mat-checkbox>
								<span class="action-formula-title" [matTooltip]="item.name"
									matTooltipPosition="right">{{ item.name }}</span>
							</div>
						</li>
					</ul>
				</div>
				<hr *ngIf="workspaceItemsForTableProjection.length" class="menu-line-separator" />
				<div *ngIf="workspaceItemsForTableProjection.length" mat-menu-item (click)="openViewSaveModal()">
					<span>
						<!-- save-icon -->
						<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
								<path
									d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
									stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path
									d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
									stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
								<path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
									d="m18.907 16.808-1.75 1.752-1.05-1.052" />
								<path d="M0 0h24.01v24.01H0z" />
							</g>
						</svg>
					</span>
					Save
				</div>
				<hr class="menu-line-separator" />
				<div mat-menu-item (click)="openViewSaveAsModal()">
					<span>
						<!-- save-icon -->
						<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
								<path
									d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
									stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path
									d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
									stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
								<path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
									d="m18.907 16.808-1.75 1.752-1.05-1.052" />
								<path d="M0 0h24.01v24.01H0z" />
							</g>
						</svg>
					</span>
					Save as
				</div>
				<hr class="menu-line-separator" />
				<div class="nested-menu" (click)="$event.stopPropagation()">
					<div (click)="toggleWorkspaceItemsProjectionMenu()" style="display: flex;
					align-items: center;
					font-size: 0.75rem;
					justify-content: space-between;
					padding: 0 0 0 8px;
					height: 32px;">
						<div>
							<!-- binacular-icon -->
							<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
								style="position: relative; top:2px;">
								<g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
									<path
										d="M10.598 14.672v-6.16a1.508 1.508 0 0 0-1.507-1.51h0a1.51 1.51 0 0 0-1.46 1.13l-1.558 6.013"
										stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M9.926 13.083a2.301 2.301 0 1 1-3.25 3.258 2.301 2.301 0 0 1 3.25-3.258"
										stroke="#0044f1" stroke-width="1.5" />
									<path
										d="M17.937 14.145 16.38 8.132a1.51 1.51 0 0 0-1.46-1.13h0a1.508 1.508 0 0 0-1.508 1.51v6.16"
										stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M14.084 13.083a2.301 2.301 0 1 1 3.258 3.25 2.301 2.301 0 0 1-3.258-3.25"
										stroke="#0044f1" stroke-width="1.5" />
									<path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" d="M10.599 9.504h2.812M10.599 13.506h2.812" />
									<rect stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" x="3.001" y="3.001" width="18.008" height="18.008"
										rx="4" />
									<path d="M0 0h24.01v24.01H0z" />
								</g>
							</svg>
							Open
						</div>

						<span>
							<mat-icon *ngIf="isWorkspaceItemsProjectionMenuOpen()"
								style="color: #0044f1; font-size: 16px;">expand_less</mat-icon>
							<mat-icon *ngIf="!isWorkspaceItemsProjectionMenuOpen()"
								style="color: #0044f1; font-size: 16px;">expand_more</mat-icon>
						</span>
					</div>

					<ul *ngIf="isWorkspaceItemsProjectionMenuOpen()" class="action-formula-dropdown">
						<li *ngFor="let item of workspaceItemsForTableProjection" class="action-formula-inner-wrapper">
							<div style="display: flex; justify-content: space-between; width: 100%;">
								<div (click)="applyWorkspaceItemTableProjection(item)" class="workspace-item-menu-option">
									{{ item.name }}
								</div>
								<div>
									<span *ngIf="item.isDeletable" class="workspace-item-menu-button"
										(click)="deleteWorkspaceItem(item); $event.stopPropagation()"
										class="action-formula-title">
										<!-- Trash-Icon -->
										<svg width="16" height="16" viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg">
											<g fill="none" fill-rule="evenodd">
												<path
													d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
													stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" class="icon-actions" />
												<path d="M0 0h24v24H0z" />
											</g>
										</svg>
									</span>
								</div>
							</div>
						</li>
					</ul>
				</div>

			</mat-menu>
		</ng-container>

		<mat-menu #workspaceItemsFilterMenu="matMenu" class="nested-menu">
			<div *ngFor="let item of workspaceItemsForFilter">
				<span mat-menu-item (click)="applyWorkspaceItemFilter(item)" [matTooltip]="item.name"
					matTooltipPosition="right" class="workspace-item-menu-option">
					{{item.name}}
					<span *ngIf="item.isDeletable" class="workspace-item-menu-button"
						(click)="deleteWorkspaceItem(item); $event.stopPropagation()">
						<!-- Trash-Icon -->
						<svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg">
							<g fill="none" fill-rule="evenodd">
								<path
									d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
									stroke="#0044f1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
									class="icon-actions" />
								<path d="M0 0h24v24H0z" />
							</g>
						</svg>
					</span>
				</span>
			</div>
		</mat-menu>
	</div>

	<div class="header-option" *ngIf="isMapView">
		<button [matMenuTriggerFor]="shapeFilterMenu">
			Select
		</button>
		<mat-menu #shapeFilterMenu="matMenu" class="custom-menu nested-menu header-menu">
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="drawShapes(MapShape.CIRCLE, MapDrawType.FILTER)">
				<span><mat-icon fontIcon="fa-circle" fontSet="fas"></mat-icon></span>
				Circle
			</div>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="drawShapes(MapShape.POLYGON, MapDrawType.FILTER)">
				<span><mat-icon style="width: 15px" fontIcon="fa-hexagon" fontSet="fas"></mat-icon></span>
				Polygon
			</div>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="drawShapes(MapShape.RECTANGLE, MapDrawType.FILTER)">
				<span><mat-icon fontIcon="fa-square" fontSet="fas"></mat-icon></span>
				Rectangle
			</div>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="removeShapes(MapDrawType.FILTER)">
				<span><mat-icon fontIcon="fa-times-circle" fontSet="fas"></mat-icon></span>
				Cancel
			</div>
		</mat-menu>
	</div>

	<div class="header-option" *ngIf="isMapView">
		<button [matMenuTriggerFor]="geographicalFilterMenu">
			Zoom
		</button>
		<mat-menu #geographicalFilterMenu="matMenu" class="custom-menu nested-menu header-menu">
			<map-geographical-filter></map-geographical-filter>
		</mat-menu>
	</div>
	<div class="header-option">
		<button [matMenuTriggerFor]="filterMenu1">Filter</button>
		<mat-menu #filterMenu1="matMenu" class="custom-menu nested-menu">
			<hr *ngIf="workspaceItemsForFilter.length" class="menu-line-separator" />
			<div *ngIf="workspaceItemsForFilter.length" mat-menu-item style="display: flex; align-items: center;"
				(click)="workspaceItemDialog.open(filter,WorkspaceItemType.FILTER, true)">
				<span>
					<!-- save-icon -->
					<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
						style="margin-right: 4px; position: relative;top: 4px;">
						<g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
							<path
								d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
								stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path
								d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
								stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<circle stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
								cx="17.507" cy="17.507" r="4.502" />
							<path stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
								d="m18.907 16.808-1.75 1.752-1.05-1.052" />
							<path d="M0 0h24.01v24.01H0z" />
						</g>
					</svg>
				</span>
				<div>
					Save
				</div>
			</div>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="workspaceItemDialog.open(filter,WorkspaceItemType.FILTER, false)"
				style="display: flex; align-items: center;height: 25px;">
				<div style="display: flex;">
					<!-- save-icon -->
					<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
						style="margin-right: 4px;">
						<g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
							<path
								d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
								stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path
								d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
								stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<circle stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
								cx="17.507" cy="17.507" r="4.502" />
							<path stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
								d="m18.907 16.808-1.75 1.752-1.05-1.052" />
							<path d="M0 0h24.01v24.01H0z" />
						</g>
					</svg>
				</div>
				<div>
					Save as
				</div>
			</div>
			<hr *ngIf="saveFilterDataSource.data[0]?.children.length > 0"  class="menu-line-separator" />
			<ng-container *ngIf="saveFilterDataSource.data[0]?.children.length > 0">
				<hr class="menu-line-separator" />
				<mat-tree [dataSource]="saveFilterDataSource" [treeControl]="saveFilterTreeControl"
					class="sub-menu-options-child">
					<!-- This is the tree node template for leaf nodes -->
					<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="sub-menu-form-options-tree">
						<li class="mat-tree-node" style="width: 100%; min-height: 25px !important;">
							<span style="flex: 1;" mat-menu-item (click)="applyWorkspaceItemFilter(node)"
								class="workspace-item-menu-option" [matTooltip]="node.name.length > 20 ? node.name : ''" matTooltipPosition="left">
								{{ truncateName(node.name) }}
								<span *ngIf="node.isDeletable" class="workspace-item-menu-button"
									(click)="deleteWorkspaceItem(node); $event.stopPropagation()">
									<!-- Trash-Icon -->
									<svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg">
										<g fill="none" fill-rule="evenodd">
											<path
												d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
												stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" class="icon-actions" />
											<path d="M0 0h24v24H0z" />
										</g>
									</svg>
								</span>
							</span>
						</li>
					</mat-tree-node>
					<!-- This is the tree node template for expandable nodes -->
					<mat-nested-tree-node *matTreeNodeDef="let node; when: showSaveFilterTreeStrcuture.hasChild"
						class="sub-menu-options-parent external-sub-menu">
						<li>
							<!-- <hr class="menu-line-separator" /> -->
							<div class="mat-tree-node">
								<span class="filter-inner-parent">{{node.name}}</span>
								<button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
									<mat-icon class="mat-icon-rtl-mirror">
										{{saveFilterTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
									</mat-icon>
								</button>
							</div>
							<ul [class.tree-invisible]="!saveFilterTreeControl.isExpanded(node)">
								<ng-container matTreeNodeOutlet></ng-container>
							</ul>
						</li>
					</mat-nested-tree-node>
				</mat-tree>
			</ng-container>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="removeFilterFromMap()"
				style="display: flex; align-items: center; height: 25px;">
				<div style="display: flex;">
					<!-- undo-icon -->
					<svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
						style="margin-right: 4px;">
						<g fill="none" fill-rule="evenodd">
							<g stroke-linecap="round" stroke-linejoin="round" stroke="#002d57" stroke-width="1.5">
								<path d="M8 5 5 8l3 3" />
								<path d="M5 8h9c3.314 0 6 2.462 6 5.5h0c0 3.038-2.686 5.5-6 5.5H6" />
							</g>
							<path d="M0 0h24v24H0z" />
						</g>
					</svg>
				</div>
				<div>
					Remove
				</div>
			</div>
			<ng-container *ngIf="dataSource.data[0]?.children.length > 0">
				<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="sub-menu-options-child">
					<!-- This is the tree node template for leaf nodes -->
					<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="sub-menu-form-options-tree"
						style="flex-direction: column !important;">
						<li class="mat-tree-node sub-menu-form-options" style="padding: 8px 0 !important;"
							*ngIf="node?.geometryType === DatasetGeometryType.POINT || node.params?.overlay?.geometryType === DatasetGeometryType.POINT && node.params?.key == 0">
							<!-- use a disabled button to provide padding for tree leaf -->
							<div>
								<div style="margin: 8px 0;">
									<input class="link-distance-input" #myInput placeholder="Distance"
										[(ngModel)]="overlaysFilterDistance[node.params?.overlay?.id]"
										(keydown)="$event.stopPropagation()">
									<mat-select #distanceUnit [(value)]="defaultDistanceUnit"
										class="location-address-select">
										<mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
										<mat-option [value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
									</mat-select>
								</div>
								<div class="apply-button-wrapper">
									<span class="apply-distance-btn"
										(click)="distanceFilter(node.params?.overlay, myInput.value, distanceUnit.value)">Apply</span>
								</div>
							</div>
						</li>
						<li class="mat-tree-node sub-menu-checkbox-options" [matTooltip]="getDisplayName(node)"
							*ngIf="node.params?.overlay?.geometryType === DatasetGeometryType.POINT && node.params?.showCheckBox">
							<button mat-icon-button disabled></button>
							<mat-checkbox [disabled]="!overlaysFilterDistance[node.params?.overlay?.id]"
								class="checklist-leaf-node" (ngModelChange)="callFilterAction(node.selected, node)"
								[(ngModel)]="node.selected">{{getDisplayName(node)}}</mat-checkbox>
						</li>

						<li class="mat-tree-node sub-menu-checkbox-options child-inner-tooltip"
							[matTooltip]="getDisplayName(node)" matTooltipPosition="right"
							*ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
							<button mat-icon-button disabled></button>
							<mat-checkbox class="checklist-leaf-node"
								(ngModelChange)="callFilterAction(node.selected, node)"
								[(ngModel)]="node.selected">{{getDisplayName(node)}}</mat-checkbox>
						</li>

					</mat-tree-node>
					<!-- This is the tree node template for expandable nodes -->
					<mat-nested-tree-node *matTreeNodeDef="let node; when: filterTreeStrcuture.hasChild"
						class="sub-menu-options-parent external-sub-menu">
						<li>
							<hr class="menu-line-separator" />
							<div class="mat-tree-node"
								style="display: flex !important; flex-direction: row !important; justify-content: space-between !important;"
								[matTooltip]="node.name" matTooltipPosition="right">

								<span class="filter-inner-parent">{{node.name}}</span>
								<button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
									<mat-icon class="mat-icon-rtl-mirror">
										{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
									</mat-icon>
								</button>
							</div>
							<!-- <hr class="menu-line-separator" /> -->
							<ul [class.tree-invisible]="!treeControl.isExpanded(node)">
								<ng-container matTreeNodeOutlet></ng-container>
							</ul>
						</li>
						<!-- <hr *ngIf="node && node.children && node.children.length > 0" class="menu-line-separator" /> -->
					</mat-nested-tree-node>
				</mat-tree>
			</ng-container>
		</mat-menu>
	</div>
	<div class="header-option" *ngIf="isMapView">
		<button [matMenuTriggerFor]="measurementsMenu">
			Measure
		</button>
		<mat-menu #measurementsMenu="matMenu" class="custom-menu nested-menu header-menu">
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="drawShapes(MapShape.CIRCLE, MapDrawType.MEASURE)">
				<span><mat-icon fontIcon="fa-circle" fontSet="far"></mat-icon></span>
				Circle
			</div>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="drawShapes(MapShape.POLYGON, MapDrawType.MEASURE)">
				<span><mat-icon style="width: 15px" fontIcon="fa-hexagon" fontSet="far"></mat-icon></span>
				Polygon
			</div>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="drawShapes(MapShape.RECTANGLE, MapDrawType.MEASURE)">
				<span><mat-icon fontIcon="fa-square" fontSet="far"></mat-icon></span>
				Rectangle
			</div>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="drawShapes(MapShape.POLYLINE, MapDrawType.MEASURE)">
				<span><mat-icon fontIcon="fa-ruler-triangle" fontSet="far"></mat-icon></span>
				Distance
			</div>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="removeShapes(MapDrawType.MEASURE)">
				<span><mat-icon fontIcon="fa-times-circle" fontSet="fas"></mat-icon></span>
				Cancel
			</div>
		</mat-menu>
	</div>

	<div *ngIf="analyticsComponentEnabled && !isAnalyticsDisabled" class="header-option">
		<button [disabled]="isAnalyticsDisabled" (click)="openAnalyticsPanel()">Analytics</button>
	</div>

	<div class="header-option">
		<button *ngIf="groupsComponentEnabled" (click)="groupsPanel.open()">Groups</button>
		<map-group-panel #groupsPanel [dataset]="dataset" (groupsReady)="onGroupsReady($event)"></map-group-panel>
	</div>
	<div class="header-option" *ngIf="isMapView">
		<button (click)="openClusterPanel()">Cluster</button>
	</div>

    <div class="header-option" *ngIf="!userStateService.isSuperadmin">
		<button [matMenuTriggerFor]="measurementsMenu">
			Reports
		</button>
		<mat-menu #measurementsMenu="matMenu" class="custom-menu nested-menu header-menu">
			<hr class="menu-line-separator" />
			<!-- <div mat-menu-item>
                Configure report
				<span style="margin-left: 10px;"><mat-icon style="margin-right: 0 !important" fontIcon="fa-cog" fontSet="far"></mat-icon></span>
			</div> -->
            <div mat-menu-item  mat-menu-icon="display:none"
				style="padding-left: 6px !important;">
				<span style="width: 100%;">
					<div (click)="openReportPanelInCreateMode()" style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
						<div style="display: flex; align-items: center;">
                            Configure new report
						</div>
						<div>
                            <span style="margin-left: 10px"><mat-icon fontIcon="fa-cog" fontSet="far"></mat-icon></span>
						</div>
					</div>
				</span>
			</div>
			<hr class="menu-line-separator" />
			<div mat-menu-item (click)="toggleReportList($event)" mat-menu-icon="display:none"
				style="padding-left: 6px !important;" [disabled]="!reports.length">

				<span style="width: 100%;">
					<div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
						<div style="display: flex; align-items: center;">
                            Renewal Report
						</div>
						<div>
							<mat-icon style="margin-left: 10px !important; margin-right: 0 !important; width: 16px; font-size: 16px;">
								{{ showDatasetList ? 'expand_less' : 'expand_more' }}
							</mat-icon>
						</div>
					</div>
				</span>
				<hr class="menu-line-separator" />
			</div>
			<div class="nested-menu header-menu" style="margin-top: 4px !important;" color="accent"
				[class.tree-invisible]="!showReportList">
				<hr class="menu-line-separator" />
				<div class="nested-menu header-menu" style="margin-top: 4px !important;" color="accent"
					[class.tree-invisible]="!showReportList">
					<ng-container
						*ngFor="let report of reports; let last = last">
						<div mat-menu-item class="report-menu-item">
                            <span [matTooltip]="report.name" [matTooltipPosition]="'left'">
                                {{ report.name.length > 10 ? (report.name | slice:0:10) + '...' : report.name }}
                            </span>
                            <div class="report-action-butons">
                                <button class="icon-menu-button" (click)="downloadRenewalReport(report)">
                                    <mat-icon fontIcon="fa-arrow-to-bottom" fontSet="far"></mat-icon>
                                </button>
                                <button *ngIf="report.isDeletable" class="icon-menu-button mt-5" (click)="deleteRenewalReport(report)">
                                    <span>
                                        <!-- trash-icon -->
                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <path
                                                    d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                    stroke="#0044f1" stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path d="M0 0h24v24H0z" />
                                            </g>
                                        </svg>
                                    </span>
                                </button>
                                <button *ngIf="report.isDeletable" class="icon-menu-button" (click)="openReportPanelInEditMode(report)">
                                    <mat-icon fontIcon="fa-cog" fontSet="far"></mat-icon>
                                </button>
                            </div>
						</div>
						<hr *ngIf="!last" class="menu-line-separator">
					</ng-container>
				</div>
			</div>
		</mat-menu>
	</div>
</map-header>
<div class="page-root">
	<div class="page" style=" position:relative;" [class.isMapView]="isMapView">

		<map-overlays-panel #datapointsOverlaysComponent
			*ngIf="account && dataset && isMapView && dataset.geometryType !== DatasetGeometryType.NONE"
			(overlayToggled)="onToggleOverlay($event)" (imageOverlayToggled)="onToggleImageOverlay($event)"
			(overlaySettingsUpdated)="onUpdateOverlaySettings($event)" (overlaySelected)="onSelectedOverlay($event)"
			[account]="account" [dataset]="dataset" [isMapView]="isMapView" [accountOverlays]="accountOverlays"
			[externalDatasets]="externalDataMenuItems">
		</map-overlays-panel>

		<map-datapoints-profile-panel #locationProfilePanel [mapDatapointInfo]="mapDatapointInfo" [style.z-index]="100"
			[externalPOIDatasets]="externalPOIMenuItems" [externalPOIDatasetsStructures]="externalPOIDatasetsStructures"
			(onMoveModeEnabled)="moveLocationModeEnabled = true; selectedDatapointForEdit = $event"
			(updateSelectedDatapoint)="changeInSelectedLocationProfile($event)" (closed)="closeDetails()"
			(datapointDeleted)="updateMap()">
		</map-datapoints-profile-panel>

		<div class="flex-top-bar">
			<div *ngIf="filterBarItems.length" class="filters-container filters">
				<div class="flex filters">
					<div class="filter-count">
						<div>Locations Filters ({{filterBarItems.length}})</div>
					</div>
					<div *ngFor="let filterItem of filterBarItems; let i = index; trackBy: trackByIndexFunction"
						class=" filter-item">
						<div [matTooltip]="filterItem.displayedTitle" [matMenuTriggerFor]="filterMenu"
							class="filter-item-content" [tabindex]="0">
							<div class="filter-title">
								{{filterItem.datasetField.displayName == null || filterItem.datasetField.displayName == undefined ? filterItem.datasetField.name : filterItem.datasetField.displayName}}&nbsp;({{filterItem.dataset.name}})
							</div>
							<div class="filter-value">
								{{filterItem.displayedSearchValue || '-'}}
							</div>
							<div class="filter-close"
								(click)="$event.stopPropagation(); removeFilterBarItem(filterItem)">
								<mat-icon fontIcon="fa-times" fontSet="far"></mat-icon>
							</div>
						</div>

						<mat-menu xPosition="before" yPosition="below" #filterMenu="matMenu"
							class="nested-menu nested-menu-leaf">
							<div (click)="$event.stopPropagation()" [ngSwitch]="filterItem.datasetField.baseType">

								<div *ngSwitchCase="DatasetFieldType.DATE_TIME">
									<div class="dateMenuContent">
										<mat-form-field class="date-form-field">
											<mat-label>Choose a starting date</mat-label>
											<input [(ngModel)]="filterItem.minDateValue" [matDatepicker]="startAt"
												autocomplete="off" matInput
												matTooltip="Statistical Minimum Date: {{!filterItem.statistics.minDateValue ? '-' : filterItem.statistics.minDateValue | date }}"
												matTooltipPosition="right" [max]="filterItem.statistics.maxDateValue"
												[min]="filterItem.statistics.minDateValue">
											<mat-datepicker-toggle [for]="startAt" matSuffix></mat-datepicker-toggle>
											<mat-datepicker #startAt></mat-datepicker>
										</mat-form-field>
										<mat-form-field class="date-form-field">
											<mat-label>Choose an ending date</mat-label>
											<input [(ngModel)]="filterItem.maxDateValue" [matDatepicker]="endAt"
												autocomplete="off" matInput
												matTooltip="Statistical Maximum Date: {{!filterItem.statistics.maxDateValue ? '-' : filterItem.statistics.maxDateValue | date }}"
												matTooltipPosition="right" [max]="filterItem.statistics.maxDateValue"
												[min]="filterItem.statistics.minDateValue">
											<mat-datepicker-toggle [for]="endAt" matSuffix></mat-datepicker-toggle>
											<mat-datepicker #endAt></mat-datepicker>
										</mat-form-field>
										<button (click)="applyFilters(); filterMenu.closed.emit()" mat-flat-button
											color="accent" class="apply-filter-button date-apply-button small">
											Apply
										</button>
									</div>
								</div>

								<div class="number-field-content" (click)="$event.stopPropagation()"
									*ngSwitchCase="DatasetFieldType.NUMBER">
									<mat-form-field *ngIf="filterItem.id !== 'distance'" class="full-width">
										<mat-label>Min</mat-label>
										<input [(ngModel)]="filterItem.minNumberValue" autocomplete="off" matInput
											matTooltip="Statistical Minimum: {{filterItem.statistics.minValue || '-' | commaFormat}}"
											matTooltipPosition="right" placeholder="Min Value" type="number">
									</mat-form-field>
									<div class="display-flex column" [class.distance]="filterItem.isDistanceItem">
										<mat-form-field
											[ngClass]="{'partial-width': filterItem.isDistanceItem, 'full-width': !filterItem.isDistanceItem}">
											<mat-label>Max</mat-label>
											<input [(ngModel)]="filterItem.maxNumberValue" autocomplete="off"
												matTooltip="Statistical Maximum: {{filterItem.statistics.maxValue || '-' | commaFormat}}"
												matTooltipPosition="right" matInput placeholder="Max Value"
												type="number">
										</mat-form-field>
										<mat-form-field *ngIf="filterItem.isDistanceItem" floatLabel="always">
											<mat-select [(ngModel)]="filterItem.distanceUnit" class="dropdown-medium">
												<mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
												<mat-option
													[value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<button class="apply-filter-button" mat-flat-button color="accent"
										(click)="applyFilters(); filterMenu.closed.emit()">Apply
									</button>
								</div>

								<div class="filter-bar-item-text-field ng-switch-short-text-field"
									[class.highCardinality]="filterItem.datasetField.isHighCardinality"
									(click)="$event.stopPropagation()" *ngSwitchCase="DatasetFieldType.TEXT"
									style="overflow: hidden !important;">

									<!-- text field low cardinality-->
									<ng-container *ngIf="!filterItem.datasetField.isHighCardinality else newDeb">
										<ng-container *ngFor="let value of filterItem.statistics.values | sortList">
											<div (click)="$event.stopPropagation()" mat-menu-item>
												<mat-checkbox [(ngModel)]="filterItem.statisticValues[value]"
													(ngModelChange)="applyFilters()" [matTooltip]="value"  matTooltipPosition="left">
													<span>{{value}}</span>
												</mat-checkbox>

											</div>
										</ng-container>
										<div *ngIf="!filterItem.statistics.values.length"
											style="padding:  4px 14px; font-size: 12px;">
											There are no options for this field
										</div>
									</ng-container>
									<!-- text field low cardinality-->


									<!-- text field high cardinality-->
									<ng-template #newDeb>
										<mat-form-field class="full-width">
											<mat-label>Enter value</mat-label>
											<input [(ngModel)]="filterItem.searchValue" matInput
												placeholder="Search contains..." autocomplete="off">
										</mat-form-field>
										<button class="apply-filter-button small" mat-flat-button color="accent"
											(click)="applyFilters(); filterMenu.closed.emit()" mat-raised-button>Apply
										</button>
									</ng-template>
									<!-- text field high cardinality-->
								</div>

								<span (click)="$event.stopPropagation()" *ngSwitchDefault>
									<mat-form-field class="full-width" appearance="outline" floatLabel="always">
										<mat-label>Search a text value</mat-label>
										<input matInput placeholder="Search contains..." autocomplete="off">
									</mat-form-field>
									<button>default</button>
								</span>
							</div>
						</mat-menu>

					</div>
				</div>
				<button style="display: block; margin-left: auto;" class="remove-filter-bar-button"
					(click)="removeFilterBar()" mat-icon-button>
					<span class="material-icons">
						clear
					</span>
				</button>
			</div>
		</div>

		<map-datapoints-overlay-filter *ngIf="isMapView" #overlaysFilter (filterUpdated)="applyOverlayFilters($event)"
			class="flex-top-bar">
		</map-datapoints-overlay-filter>

		<div class="confirmation-container">
			<div class="confirmation-popup" *ngIf="moveLocationModeEnabled">
				<div class="popup-btn" (click)="confirmCoordinatesChange()">Confirm</div>
				<div class="popup-btn" (click)="cancelCoordinatesChange()">Discard</div>
			</div>
		</div>

		<div class="page-container">

			<map-map #mapComponent *ngIf="isMapView" (mapReady)="onMapReady()"
				[activeMarker]="mapDatapointInfo ? mapDatapointInfo.location : undefined"
				[activeMarkerEditMode]="moveLocationModeEnabled" [externalTensorFlightData]="externalTensorFlightData"
				(DetailsOpen)="openDetails($event)"
				(onCustomLocationDetailsSelected)="onCustomLocationDetailsSelected($event);"
				[isThematicMapEnabled]="isThematicMapActive">
			</map-map>

			<map-ag-datapoint-table class="table-container" #tableComponent (Update)="openEditPanel($event)"
				(bindFilterIds)="bindFilterIds($event)" [tessadataFieldsByDataset]="tessadataFieldsByDataset"
				[formulas]="workspaceItemsForFormulas"
				*ngIf="datapointFilterObject && !isMapView && isFilterAttributesPrepared" [dataset]="dataset"
				[filterItems]="filterBarItems" [filter]="datapointFilterObject" [filterAttributes]="filterAttributes">
			</map-ag-datapoint-table>
		</div>

		<div [formGroup]="legendLimitForm">
			<div class="confirmation-popup-container" *ngIf="customLegendsEnabled">
				<div class="confirmation-popup">
					<div class="popup-title">Update percentiles for legend buckets</div>
					<div class="popup-body">
						<div class="custom-input-wrapper">
							<label>For Green</label>
							<input type="number" name="forGreen" formControlName="forGreen" />
						</div>
						<div class="custom-input-wrapper">
							<label>For Yellow</label>
							<input type="number" name="forYellow" formControlName="forYellow" />
						</div>
						<div class="custom-input-wrapper">
							<label>For Orange</label>
							<input type="number" name="forOrange" formControlName="forOrange" />
						</div>
					</div>
					<div class="popup-buttons">
						<div class="popup-btn" (click)="getLegends()">Confirm</div>
						<div class="popup-btn discard-btn" (click)="closePopup()">Discard</div>
					</div>
				</div>
			</div>
		</div>


		<div class="footer-actions">
			<div class="footer-group thematic-map-section">
				<ng-container *ngIf="thematicMapFeatureEnabled">
					<span *ngIf="isMapView" class="display-flex full-width">
						<div (click)="toggleAggregateChartPanel()" class="report-icon footer"
							[ngClass]="{'selected' : analyticsPanel && analyticsPanel.instance.isOpen && isThematicMapActive, 'selectable': isThematicMapActive}">
							<!-- Pie-Chart-Icon -->
							<svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
								style="width: 42px;">
								<g fill="none" fill-rule="evenodd">
									<path d="M12 17.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11" stroke="#002d57"
										stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									<rect stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" x="2.5" y="2.5" width="19" height="19" rx="9.5" />
									<path stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" d="M15.89 15.889 12 11.999V6.51" />
									<path d="M0 0h24v24H0z" />
								</g>
							</svg>
						</div>

						<div class="footer-thematic-map-container">
							<span class="footer-label">
								Thematic Map
							</span>
							<span [matMenuTriggerFor]="thematicMapConfigMenu" class="config-icon">
								<!-- Burger-Icon -->
								<svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<g fill="none" fill-rule="evenodd">
										<path stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round"
											d="M5.498 12h13.005M5.498 16.002h13.005M5.497 7.998h13.005" />
										<path d="M-.005-.005h24.01v24.01H-.005z" />
									</g>
								</svg>
							</span>
							<mat-menu #thematicMapConfigMenu="matMenu" class="nested-menu">
								<hr class="menu-line-separator" />
								<div>
									<div class="nested-menu nested-menu-leaf"
										[ngClass]="{'colored': thematicMapFormula}"
										(click)="toggleThematicMapFormulas($event)">
										Formulas
										<mat-icon *ngIf="!isThematicMapFormulas">expand_more</mat-icon>
										<mat-icon *ngIf="isThematicMapFormulas">expand_less</mat-icon>

									</div>
									<div class="nested-menu" *ngIf="isThematicMapFormulas">
										<div *ngFor="let item of workspaceItemsForFormulas" class="d-flex">
											<div (click)="$event.stopPropagation()">
												<mat-checkbox (change)="selectThematicMapFormula(item, $event)"
													[checked]="item.selectedForTM">
													<span
														[ngClass]="{'colored': thematicMapFormula?.id === item.id}">{{item.name}}</span>
												</mat-checkbox>
											</div>
										</div>
									</div>
								</div>
								<div>
									<hr class="menu-line-separator" />
									<div class="nested-menu nested-menu-leaf" [ngClass]="{'colored': thematicMapMetric}"
										(click)="toggleThematicMapValue($event)">
										Value
										<mat-icon *ngIf="!isThematicMapValue">expand_more</mat-icon>
										<mat-icon *ngIf="isThematicMapValue">expand_less</mat-icon>
									</div>
									<div class="nested-menu" *ngIf="isThematicMapValue">
										<div *ngFor="let item of dataset?.fields" class="d-flex">
											<div *ngIf="!item.isGenerated && item.baseType === DatasetFieldType.NUMBER"
												(click)="$event.stopPropagation()">
												<mat-checkbox (change)="selectThematicMapMetric(item, $event)"
													[checked]="item.selectedForTM">
													<span
														[ngClass]="{'colored': thematicMapMetric?.id === item.id}">{{item.name}}</span>
												</mat-checkbox>
											</div>
										</div>
									</div>
								</div>
								<div>
									<hr class="menu-line-separator" />
									<div class="nested-menu nested-menu-leaf"
										[ngClass]="{'colored': thematicMapGrouping}"
										(click)="toggleThematicMapGroup($event)">
										Grouping
										<mat-icon *ngIf="!isThematicMapGroup">expand_more</mat-icon>
										<mat-icon *ngIf="isThematicMapGroup">expand_less</mat-icon>
									</div>
									<div class="nested-menu grouping-menu" *ngIf="isThematicMapGroup">
										<ng-container *ngFor="let item of groupingOptions">
											<ng-container *ngIf="item !== groupingEnum.COUNT">
												<div (click)="$event.stopPropagation()">
													<mat-checkbox (change)="selectThematicMapGrouping(item, $event)"
														[checked]="item === thematicMapGrouping">
														<span
															[ngClass]="{'colored': thematicMapGrouping === item}">{{item
															| titlecase}}</span>
													</mat-checkbox>
												</div>
											</ng-container>
										</ng-container>
									</div>
								</div>
							</mat-menu>
							<div class="footer-slider"
								[matTooltip]="!isThematicMapActive && mapInteractionMode !== MapInteractionMode.DEFAULT ? 'Cannot be activated until the other modes are disabled' : null">
								<mat-slide-toggle #thematicMapToggleElement color="accent" class="full-width"
									(change)="toggleThematicMap($event.checked)" [checked]="isThematicMapActive">
									<span *ngIf="isThematicMapActive">ON</span>
									<span *ngIf="!isThematicMapActive">OFF</span>
								</mat-slide-toggle>
							</div>
						</div>

						<div *ngIf="account" class="footer-floating-fields-container">
							<ng-container *ngIf="isThematicMapActive">
								<div *ngIf="thematicMapMetric" class="footer-label floating-field">Colorized by
									{{thematicMapMetric.name}}: {{thematicMapTotal | commaFormat}}</div>
								<div *ngIf="thematicMapFormula" class="footer-label floating-field">Colorized by
									{{thematicMapFormula.name}}: {{thematicMapTotal | commaFormat}}</div>
								<div class="footer-label floating-field">
									Count: {{datapointsCount | commaFormat}}
								</div>
								<div *ngFor="let field of datasetFloatingFields" class="footer-label floating-field">
									{{ field.name }}: {{floatingFieldsValuesByFields?.get(field) | commaFormat }}
								</div>
							</ng-container>
						</div>
					</span>
				</ng-container>
			</div>
			<div class="footer-group footer-group-custom">
				<div class="footer-option">
					<button [matMenuTriggerFor]="formulaMenu">Formula</button>
					<mat-menu #formulaMenu="matMenu" class="nested-menu">
						<div mat-menu-item (click)="workspaceItemDialog.open(filter,WorkspaceItemType.FORMULA, false)"
							style="height: 40px;">
							<span><mat-icon fontIcon="fa-plus" fontSet="fas"></mat-icon></span>
							Add
						</div>
						<div (click)="$event.stopPropagation()" class="nested-menu-search">
							<input placeholder="Search..." [(ngModel)]="workspaceItemSearchString"
								(keydown)="$event.stopPropagation()">
						</div>
						<div
							*ngFor="let item of workspaceItemsForFormulas | callback: workspaceItemSearchFilter; let i = index">
							<span mat-menu-item (click)="applyWorkspaceItemFilter(item)" [matTooltip]="item.name"
								matTooltipPosition="left" class="workspace-item-menu-option">
								{{item.name}}
								<span>
									<span *ngIf="!item.isDeletable" class="workspace-item-menu-button"
										(click)="workspaceItemDialog.open(item,WorkspaceItemType.FORMULA, true, true)"><i
											class="fas fa-eye"></i>
									</span>
									<span *ngIf="item.isDeletable" class="workspace-item-menu-button"
										(click)="workspaceItemDialog.open(item,WorkspaceItemType.FORMULA, true)">
										<!-- <i class="fas fa-pencil-alt"></i> -->
										<svg width="16" height="16" viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg">
											<g stroke="#323232" stroke-width="1.5" fill="none" fill-rule="evenodd"
												stroke-linecap="round" stroke-linejoin="round">
												<path
													d="m8.972 12.2 4.76-4.76a1.5 1.5 0 0 1 2.122 0l.707.706a1.5 1.5 0 0 1 0 2.122l-4.761 4.76a2.5 2.5 0 0 1-1.414.708l-1.815.259a.5.5 0 0 1-.566-.566l.26-1.815a2.5 2.5 0 0 1 .707-1.414zM15.209 11.62 12.38 8.791" />
												<rect transform="rotate(180 12 12)" x="3" y="3" width="18" height="18"
													rx="5" />
											</g>
										</svg>
									</span>
									<span *ngIf="item.isDeletable" class="workspace-item-menu-button"
										(click)="deleteWorkspaceItem(item)">
										<!-- Trash-Icon -->
										<svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg">
											<g fill="none" fill-rule="evenodd">
												<path
													d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
													stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" class="icon-actions" />
												<path d="M0 0h24v24H0z" />
											</g>
										</svg>
									</span>
								</span>
							</span>
						</div>
					</mat-menu>
				</div>
				<span *ngIf="dataset && dataset.geometryType !== 'NONE'" (click)="switchView('dashboard')"
					style="padding: 6px;" class="footer-button">
					<!-- Dashboard-icon -->
					<div class="icon-container-wrap">
						<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
							class="dashboard-icon">
							<g fill="none" fill-rule="evenodd">
								<path d="M24 24H0V0h24z" />
								<path d="M0 0h24v24H0z" />
								<path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round"
									d="M7.5 17H8M16 17h.5M12 17l2.5-2.5M12 13v-.5M9.172 14.172l-.354-.354M18 21H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3zM3 9h18" />
								<path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round"
									d="M11 5.995 10.995 6l.005.005.005-.005L11 5.995M8.5 5.995 8.495 6l.005.005L8.505 6 8.5 5.995M6 5.995 5.995 6 6 6.005 6.005 6 6 5.995" />
								<path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round"
									d="M11 5.995 10.995 6l.005.005.005-.005L11 5.995M8.5 5.995 8.495 6l.005.005L8.505 6 8.5 5.995M6 5.995 5.995 6 6 6.005 6.005 6 6 5.995" />
							</g>
						</svg>
					</div>
				</span>
				<span (click)="switchView('table')" [ngClass]="{'footer-button-active': !isMapView}"
					style="padding: 6px;" class="footer-button">
					<!-- burger-icon -->
					<div class="icon-container-wrap">
						<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
							class="dashboard-icon">
							<g fill="none" fill-rule="evenodd">
								<path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round"
									d="M5.498 12h13.005M5.498 16.002h13.005M5.497 7.998h13.005" />
								<path d="M-.005-.005h24.01v24.01H-.005z" />
							</g>
						</svg>
					</div>
				</span>
				<span *ngIf="dataset && dataset.geometryType !== 'NONE'" (click)="switchView('map')"
					[ngClass]="{'footer-button-active': isMapView}" style="padding: 6px;" class="footer-button">
					<!-- travel-map-icon -->
					<div class="icon-container-wrap">
						<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
							class="dashboard-icon">
							<g fill="none" fill-rule="evenodd">
								<path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round"
									d="M16 18.457V13.25M8 18.463V13.25M5.083 8l-1.597.959A1 1 0 0 0 3 9.816v9.668a1 1 0 0 0 1.514.857l2.91-1.745a1 1 0 0 1 1.114.058L11.4 20.8a1 1 0 0 0 1.2 0l2.862-2.147a1 1 0 0 1 1.115-.057l2.909 1.745A1 1 0 0 0 21 19.484V9.816a1 1 0 0 0-.486-.857L18.917 8M12 15.25v5.748M8 6h0a4 4 0 0 1 4-4h0a4 4 0 0 1 4 4h0" />
								<path class="icon-primary"
									d="M8 6c0 1.807 1.993 3.735 3.166 4.713.49.382 1.177.383 1.667 0C14.006 9.736 16 7.808 16 6"
									stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path class="icon-primary" d="M12.001 5.639a.25.25 0 1 1-.002.5.25.25 0 0 1 .002-.5"
									stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M0 0h24v24H0z" />
							</g>
						</svg>
					</div>
				</span>
			</div>
		</div>
	</div>
</div>

<div *ngIf="legends.length > 0" class="legends-container"
	[style.margin-left]="datapointsOverlaysComponent?.isExpanded ? '360px' : null"
	[style.max-width]="datapointsOverlaysComponent?.isExpanded ? 'calc(100% - 360px)' : '100%'">
	<map-datapoint-map-legend *ngFor="let legend of legends" style="height: calc(100% - 20px);"
		[intervalOptions]="legend.intervals" [datasetName]="legend.datasetName" [fieldLabel]="legend.fieldLabel"
		[gradientDetails]="legend.gradientDetails" [datasetId]="legend.datasetId"
		(removeLegend)="removeLegend($event)"></map-datapoint-map-legend>
</div>

<app-workspace-item-dialog #workspaceItemDialog [accountId]="account?.id" [datasetId]="dataset?.id"
	(itemSubmitted)="refreshWorkspaceItems($event)"></app-workspace-item-dialog>
