import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { APP_INITIALIZER, Injectable, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AccountModule } from "./account/account.module";
import { JwtModule } from "@auth0/angular-jwt";
import { HttpClientModule } from "@angular/common/http";
import { AuthModule } from "./auth/auth.module";
import { CoreModule } from "./core/core.module";
import { environment } from "../environments/environment";
import { DatasetModule } from "./dataset/dataset.module";
import { FieldsModule } from "./fields/fields.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from "@agm/core";
import { GroupPermissionLoginComponent } from "./auth/group-permission-login/group-permission-login.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "./shared/shared.module";
import { GeographicalRegionsComponent } from "./geographical-regions/geographical-regions.component";
import { DialogUpdateRegionComponent } from "./geographical-regions/dialog-update-region/dialog-update-region.component";
import { ExternalOverlaysComponent } from "./external-overlays/external-overlays.component";
import { EditExternalOverlayTreeComponent } from "./external-overlays/edit-external-overlay-tree/edit-external-overlay-tree.component";
import { LicenseManager } from "ag-grid-enterprise";
import { OverlayComponent } from "./overlay/overlay.component";
import { OverlayModule } from "./overlay/overlay.module";
import { NgxEchartsModule } from "ngx-echarts";
import { CommonModule } from "@angular/common";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { GoogleAPIKeyService } from "./core/services/google-api-key.service";
import { GoogleMapsInitializer } from "./core/services/google-maps-initializer.service";

LicenseManager.setLicenseKey(environment.agGridLicense);

export function echartImport(): any {
    return import("echarts");
}

export function tokenGetter(): string {
    const token = localStorage.getItem("access_token");
    return token || "invalid";
}

@NgModule({
    declarations: [
        AppComponent,
        GroupPermissionLoginComponent,
        GeographicalRegionsComponent,
        DialogUpdateRegionComponent,
        ExternalOverlaysComponent,
        EditExternalOverlayTreeComponent,
    ],
    imports: [
        CoreModule,
        SharedModule,
        AccountModule,
        AuthModule,
        FieldsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule,
        OverlayModule,
        MatProgressSpinnerModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                throwNoTokenError: true,
                authScheme: "",
                whitelistedDomains: environment.whitelist,
            },
        }),
        AgmCoreModule.forRoot({
            apiKey: process.env.GOOGLE_API_KEY,
            libraries: ["drawing", "places"],
        }),
        NgxEchartsModule.forRoot({
            echarts: echartImport,
        }),
        DatasetModule,
        InfiniteScrollModule,
    ],
    bootstrap: [AppComponent],
})

export class AppModule {}
