import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { DatapointsPageStateService } from "../../datapoints-page-state.service";
import { Dataset } from "../../../../model/dataset/dataset";
import { DatasetField } from "../../../../model/dataset/field/dataset-field";
import { DatapointFilter } from "../../../../model/datapoint/filter/datapoint-filter";
import { DatapointProjection } from "../../../../model/datapoint/projection/datapoint-projection";
import { ReportRow } from "../../../../model/datapoint/report/count/report-row";
import { Sort } from "@angular/material/sort";
import { ReportDisplayType } from "../../../../model/analytics/report-display-type";
import { DatasetFieldType } from "../../../../model/dataset/dataset-field-type";
import { DatapointsService } from "../../../../data-access-layer/datapoints/datapoints.service";
import { ProjectedDatapoint } from "../../../../model/datapoint/projected-datapoint";
import { DatapointFilterObject } from "../../../../model/datapoint/datapoint-filter-object";
import { DatasetUtils } from "../../../../core/utils/dataset-utils";
import { SortOrder } from "../../../../model/filter/draft-filter-sort";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DatapointsFilterService } from "../../datapoints-filter.service";
import { Subscription } from "rxjs";
import { DatasetFieldSpecificType } from "../../../../model/dataset/dataset-field-specific.type";
import {
    TableColumn,
    TableColumnType,
    TableColumnAlignment,
} from "../../../../model/upload/table/table-column";
import { TableRow } from "../../../../model/upload/table/table-row";
import { TableCell } from "../../../../model/upload/table/table-cell";
import { DownloadReportItem } from "../../../../model/download/item/download-report-item";
import { DownloadReportTableRequest } from "../../../../model/download/download-report-table-request";
import { ReportComponent } from "../report.component";
import { DateUtils } from "../../../../core/utils/date-utils";
import { NumberUtils } from "../../../../core/utils/number-utils";
import { WorkspaceItem } from "../../../../model/workspace/workspace-item";
import { DatasetFieldScope } from "../../../../model/dataset/dataset-field-scope";
import { NotifService } from "../../../../core/notification/notif.service";
import { isUndefined } from "src/app/core/utils/util-master";
import { ReportType } from "src/app/model/analytics/report-type";
import { ChartDisplayType } from "src/app/model/analytics/chart-display-type";
import { TreeStructureUtils } from "src/app/core/utils/tree-structure-utils";
import { Datapoints } from "../../datapoints";
import { DatasetGeometryType } from "src/app/model/dataset/dataset-geometry-type";
import { MaptycsApplication } from "src/app/model/account/maptycs-application";

@Component({
    selector: "map-ranking-report",
    templateUrl: "./ranking-report.component.html",
    styleUrls: ["./ranking-report.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingReportComponent
    implements OnInit, OnDestroy, ReportComponent
{
    @Input() uuid: string;
    @Output() closed = new EventEmitter();
    @Output() saveWorkspaceItemToDashboard = new EventEmitter();
    @Output() saveAsWorkspaceItemToDashboard = new EventEmitter();
    @Output() saveSorting = new EventEmitter();
    @Input() set dataset(dataset: Dataset) {
        this._dataset = JSON.parse(JSON.stringify(dataset));
        this._dataset.fields.forEach((field) => (field.selected = false));
    }
    @Input() set formulas(formulas: WorkspaceItem[]) {
        this._formulas = formulas;
    }
    @Input() set nriFields(nriFields: any) {
        this._nriFields = JSON.parse(JSON.stringify(nriFields));
    }

    @Input() dashboardWorkspaceItems: WorkspaceItem[];
    @Input() isDashboardCall: boolean;

    private LIMIT: number = 247;

    _dataset: Dataset;
    _nriFields: any;
    selectedRankingField: DatasetField;
    selectedFields: DatasetField[] = [];
    noOfItems: number;
    reportName: string;
    datasetFields: DatasetField[];
    reportType: string = ReportType.RANKING;
    reportSubType: ReportDisplayType = ReportDisplayType.TABLE;
    chartDisplayType: ChartDisplayType = ChartDisplayType.NONE;
    dataIsReady = false;
    datapointFilter: DatapointFilter;
    datapointProjection: DatapointProjection;
    tessadataFields: {
        nriFields: DatasetField[];
        externalFields: DatasetField[];
        tensorflightFields: DatasetField[];
        munichreFields: DatasetField[]
    };
    tessadataGroupedFields: any[];
    _formulas: WorkspaceItem[] = [];
    selectedFormula: WorkspaceItem;

    /** TABLE  */
    dynamicColumns: Map<string, string>; // key of the map is the fieldId, value is field value
    columnsToDisplay: string[];
    sumsByFieldId: Map<string, number> = new Map<string, number>();
    reportData: ReportRow[];
    downloadReportData: ReportRow[];

    readonly RANKING_COLUMN_ID = "ranking";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";
    private readonly subscriptions: Subscription = new Subscription();
    private static compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    sort: Sort= {
        active: '',
        direction: ''
    };
    rankingTreeStrcuture = new TreeStructureUtils();
    rankingTreeControl = this.rankingTreeStrcuture.getTreeControl();
    rankingDataSource = this.rankingTreeStrcuture.getDataSource();
    
    columnsToDisplayTreeStrcuture = new TreeStructureUtils();
    columnsToDisplayTreeControl = this.columnsToDisplayTreeStrcuture.getTreeControl();
    columnsToDisplayDataSource = this.columnsToDisplayTreeStrcuture.getDataSource();
    
    datapointObject = new Datapoints();

    constructor(
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly datapointService: DatapointsService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly notif: NotifService
    ) {}

    ngOnInit() {
        this.resetNRISelectedFlag();
        this._dataset.fields = this.sortFields(
            JSON.parse(JSON.stringify(this._dataset.fields))
        );
        this.datasetFields = this.sortFields(
            JSON.parse(JSON.stringify(this._dataset.fields))
        );
        this.tessadataFields = JSON.parse(
            JSON.stringify(
                this.datapointsPageStateService.getActiveTessadataFields()
            )
        );
        this.tessadataFields.externalFields.forEach(
            (field) => (field.selected = false)
        );
        this.tessadataFields.nriFields = this.sortFields(
            this.tessadataFields.nriFields
        );
        if (
            this.tessadataFields.externalFields &&
            this.tessadataFields.externalFields.length > 0
        ) {
            let externalDataset = this.sortFields(
                this.tessadataFields.externalFields
            );
            this.tessadataGroupedFields = externalDataset.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            for (const key in this.tessadataGroupedFields) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        this.tessadataGroupedFields,
                        key
                    )
                ) {
                    this.tessadataGroupedFields[key] = this.sortFields(
                        this.tessadataGroupedFields[key]
                    );
                }
            }
        }
        this.tessadataFields.tensorflightFields.forEach(
            (field) => (field.selected = false)
        );
        if (
            this.tessadataFields.tensorflightFields &&
            this.tessadataFields.tensorflightFields.length > 0
        ) {
            let tensorflightDatasets = this.sortFields(
                this.tessadataFields.tensorflightFields
            );
            let tensorflightGroupFields = tensorflightDatasets.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            this.tessadataGroupedFields = !isUndefined(this.tessadataGroupedFields) && Object.keys(this.tessadataGroupedFields).length ? {...this.tessadataGroupedFields, ...tensorflightGroupFields} : tensorflightGroupFields;
        }
        this.tessadataFields.munichreFields.forEach(
            (field) => (field.selected = false)
        );
        if (
            this.tessadataFields.munichreFields &&
            this.tessadataFields.munichreFields.length > 0
        ) {
            let munichreDatasets = this.sortFields(
                this.tessadataFields.munichreFields
            );
            let munichreGroupFields = munichreDatasets.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            this.tessadataGroupedFields = !isUndefined(this.tessadataGroupedFields) && Object.keys(this.tessadataGroupedFields).length ? {...this.tessadataGroupedFields, ...munichreGroupFields} : munichreGroupFields;
        }
        this._formulas = JSON.parse(JSON.stringify(this._formulas));
        this.datapointFilter = this.datapointsFilterService.getActiveFilter();
        this.subscriptions.add(
            this.datapointsFilterService
                .onFilterChange()
                .subscribe((newFilter) => {
                    this.datapointFilter = newFilter;
                    if (this.dataIsReady) {
                        this.generateReportData();
                    }
                })
        );
        this.rankingDataSource.data = [
            ...this.datapointObject.prepareDataset([this._dataset], this._dataset, {type: DatasetFieldSpecificType.NUMBER_FIELD})
        ];
        let tessadataFieldsByDataset = {};
        tessadataFieldsByDataset[this._dataset.id] = {nriFields: []};
        tessadataFieldsByDataset[this._dataset.id].nriFields = this,this.nriFields;
        this.columnsToDisplayDataSource.data = [
            ...this.datapointObject.prepareDataset([this._dataset], this._dataset),
            ...this.datapointObject.prepareTesadata(this.tessadataGroupedFields, this._dataset),
            ...this.datapointObject.prepareNRIFields(
                [this._dataset],
                tessadataFieldsByDataset,
                true,
                this._dataset
            )
        ]
        this.columnsToDisplayDataSource.data = this.datapointObject.filterAndDelete(this.columnsToDisplayDataSource.data, {isBothTypeCheck: true});
    }

    onFilterAllClick(
        $event: MatCheckboxChange,
        fields: DatasetField[],
        isDatasetAllCheckboxSelected?: boolean,
        isLocations?: boolean
    ) {
        fields.forEach((field) => {
            if (
                (isLocations && field.scope !== this.datasetScope.INTERNAL) ||
                field.id === "created_on" ||
                field.id === "updated_on"
            ) {
                return;
            }
            if (
                this.selectedRankingField &&
                this.selectedRankingField.id === field.id
            ) {
                return;
            }
            if (field.selected && $event.checked === true) {
                let findExistingField = this.selectedFields.find(
                    (f) => f.id === field.id
                );
                if (findExistingField) {
                    let index = this.selectedFields.indexOf(findExistingField);
                    this.selectedFields.splice(index, 1);
                }
            }
            field.selected = $event.checked;
            this.onFieldsMenuClick($event.checked, field);
        });
    }

    isNotAllDatasetSelected(
        fields: DatasetField[],
        isLocations?: boolean
    ): boolean {
        if (isLocations) {
            return (
                fields.filter(
                    (field) =>
                        !field.selected &&
                        field.scope === this.datasetScope.INTERNAL &&
                        field.id !== "created_on" &&
                        field.id !== "updated_on"
                ).length > 0
            );
        } else {
            return fields.filter((field) => !field.selected).length > 0;
        }
    }

    onFieldsMenuClick(fieldSelected: boolean, field: DatasetField) {
        if (fieldSelected) {
            this.selectedFields.push(field);
        } else {
            this.selectedFields.splice(this.selectedFields.indexOf(field), 1);
        }
    }

    get datasetScope() {
        return DatasetFieldScope;
    }

    generateReport() {
        if (
            this.selectedFields.length > 0 &&
            (this.selectedRankingField || this.selectedFormula) &&
            this.noOfItems
        ) {
            this.generateReportData();
        } else {
            this.dataIsReady = false;
        }
    }

    getSelectedRankingField() {
        return this.selectedRankingField;
    }

    generateReportData() {
        // if there is selected a formula but not a numeric field
        if (this.selectedFormula && !this.selectedRankingField) {
            this.notif.error(
                "For a selected formula - Please select a Locations field too."
            );
            return;
        }

        this.prepareProjection();
        this.populateTableColumnsList();

        let datapointsRequest = this.createDatapointRequest();

        this.subscriptions.add(
            this.datapointService
                .getDatapointsByFilter(datapointsRequest)
                .subscribe((datapoints) => {
                    this.computeTotalSums(datapoints);
                    this.convertDataToTableFormat(datapoints);
                    this.populateTableColumnsList();
                    if (this.sort && !isUndefined(this.sort)) {
                        this.sortData(this.sort);
                    }

                    this.dataIsReady = true;
                    this.changeDetector.detectChanges();
                })
        );
    }

    private createDatapointRequest(): DatapointFilterObject {
        let tempDatapointFilter: DatapointFilter = {
            datasetID: this._dataset.id,
        };
        const datapointFilters = isUndefined(this.datapointFilter)
            ? tempDatapointFilter
            : this.datapointFilter;
        return {
            filter: datapointFilters,
            limit: this.noOfItems ? this.noOfItems : 0,
            skip: 0,
            projection: this.datapointProjection,
            sort: {
                datasetID: this._dataset.id,
                fields: [
                    {
                        id: this.selectedRankingField.id,
                        sortOrder: SortOrder.DESCENDANT,
                    },
                ],
            },
        };
    }

    private populateTableColumnsList() {
        this.dynamicColumns = new Map();
        this.columnsToDisplay = [this.RANKING_COLUMN_ID];

        this.selectedFields.forEach((field) => {
            this.dynamicColumns.set(field.id, field.name);
            this.columnsToDisplay.push(field.id);
        });
        if (this.selectedRankingField) {
            this.dynamicColumns.set(
                this.selectedRankingField.id,
                this.selectedRankingField.name
            );
            this.columnsToDisplay.push(this.selectedRankingField.id);
        }
        if (this.selectedFormula) {
            this.dynamicColumns.set(
                this.selectedFormula.id.toString(),
                this.selectedFormula.name
            );
            this.columnsToDisplay.push(this.selectedFormula.id.toString());
        }
    }

    private computeTotalSums(datapoints: ProjectedDatapoint[]) {
        this.sumsByFieldId = new Map<string, number>();
        datapoints.forEach((datapoint) => {
            datapoint.fields.forEach((field) => {
                if (field.numberValue) {
                    if (!this.sumsByFieldId.get(field.id)) {
                        this.sumsByFieldId.set(field.id, field.numberValue);
                    } else {
                        this.sumsByFieldId.set(
                            field.id,
                            this.sumsByFieldId.get(field.id) + field.numberValue
                        );
                    }
                }
            });
            if (datapoint.formulaResults) {
                if (
                    !this.sumsByFieldId.get(this.selectedFormula.id.toString())
                ) {
                    this.sumsByFieldId.set(
                        this.selectedFormula.id.toString(),
                        datapoint.formulaResults[0]
                    );
                } else {
                    this.sumsByFieldId.set(
                        this.selectedFormula.id.toString(),
                        this.sumsByFieldId.get(
                            this.selectedFormula.id.toString()
                        ) + datapoint.formulaResults[0]
                    );
                }
            }
        });
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get ReportDisplayType() {
        return ReportDisplayType;
    }

    getSort(): Sort {
        return this.sort;
    }

    sortData(sort: Sort, isSortCall: boolean = false) {
        const isAsc = sort.direction === "asc";
        const fieldId = sort.active;
        if (isUndefined(this.sort)) {
            this.sort = {
                active: '',
                direction: ''
            };
        }
        this.sort.active = fieldId;
        this.sort.direction = sort.direction;

        let sortedData = this.reportData.sort((a, b) => {
            switch (fieldId) {
                case this.RANKING_COLUMN_ID:
                    return RankingReportComponent.compare(
                        a.ranking,
                        b.ranking,
                        isAsc
                    );
                default: {
                    let sanatizeValue = (item: any) => {
                        let value = item.dynamicFieldValuesByIds.get(fieldId);
                        return value === 'N/A' ? "" : value;
                    };

                    let aValue = sanatizeValue(a);
                    let bValue = sanatizeValue(b);

                    return RankingReportComponent.compare(
                        aValue,
                        bValue,
                        isAsc
                    );
                }
            }
        });

        this.reportData = [...sortedData];
        this.downloadReportData = this.reportData;
        if (isSortCall) {
            this.saveSorting.emit();
        }
    }

    private convertDataToTableFormat(datapoints: ProjectedDatapoint[]) {
        this.reportData = [];
        this.downloadReportData = [];
        let index = 1;
        datapoints.forEach((datapoint) => {
            let tableEntry: ReportRow = {
                dynamicFieldValuesByIds:
                    this.getDynamicFieldValuesByIds(datapoint),
                ranking: index,
            };
            index++;
            this.downloadReportData.push(tableEntry);
        });
        this.reportData = this.downloadReportData.slice(0, this.LIMIT);
    }

    getDynamicFieldValuesByIds(
        datapoint: ProjectedDatapoint
    ): Map<string, string> {
        let tableEntries: Map<string, string> = new Map<string, string>();
        datapoint.fields.forEach((field) => {
            let value;
            if (field.numberValue) {
                value = field.numberValue;
            }
            if (field.textValue) {
                value = field.textValue;
            }
            if (field.datetimeValue) {
                value = DateUtils.parseDate(field.datetimeValue);
            }
            if (field.textArrayValue) {
                value = field.textArrayValue;
            }
            if (field.numberArrayValue) {
                value = NumberUtils.formatNumber(field.numberArrayValue);
            }
            if (field.datetimeArrayValue) {
                value = DateUtils.parseDate(field.datetimeArrayValue);
            }
            if (!value) {
                value = "N/A";
            }

            tableEntries.set(field.id, value);
        });
        if (datapoint.formulaResults) {
            let value;
            value = datapoint.formulaResults[0];
            if (!value) {
                value = "N/A";
            }
            tableEntries.set(this.selectedFormula.id.toString(), value);
        }
        return tableEntries;
    }

    private prepareProjection() {
        this.datapointProjection = {
            datasetID: this._dataset.id,
            fields: [],
            geometryPrecision: 25,
            formulas: [],
        };

        this.selectedFields.forEach((field) => {
            this.datapointProjection.fields.push(field.id);
        });
        if (this.selectedRankingField) {
            this.datapointProjection.fields.push(this.selectedRankingField.id);
        }
        if (this.selectedFormula) {
            this.datapointProjection.formulas.push(this.selectedFormula);
        }
    }

    onRankingFieldSelection(fieldSelected: boolean, field: DatasetField) {
        if (fieldSelected) {
            this.selectedRankingField = field;
            let foundIndex = this.findFieldIndex(this._dataset.fields, field);
            this._dataset.fields[foundIndex].selected = true;
            let findExistingField = this.selectedFields.find(
                (f) => f.id === this.selectedRankingField.id
            );
            if (findExistingField) {
                let index = this.findFieldIndex(this.selectedFields, field);
                this.selectedFields.splice(index, 1);
            }
        } else {
            this.selectedRankingField = undefined;
            let foundIndex = this.findFieldIndex(this._dataset.fields, field);
            this._dataset.fields[foundIndex].selected = false;
        }
        this.changeDetector.detectChanges();
    }

    findFieldIndex(array: DatasetField[], field: DatasetField): number {
        return array.findIndex((x) => x.id === field.id);
    }

    onFormulaSelected(
        $event: MatCheckboxChange,
        selectedFormula: WorkspaceItem
    ) {
        if ($event.checked) {
            this.selectedFormula = selectedFormula;
        } else {
            this.selectedFormula = undefined;
        }
        this.changeDetector.detectChanges();
    }

    setSelectedFormula(formula: WorkspaceItem) {
        let selectedFormula = this._formulas.find((f) => f.id === formula.id);
        this.selectedFormula = selectedFormula;
        selectedFormula.selected = true;
    }

    getSelectedFormula(): WorkspaceItem {
        return this.selectedFormula;
    }

    isTwoDimensionReport(): boolean {
        return false;
    }

    getTableReportHeader(): TableColumn[] {
        let columns: TableColumn[] = [];
        columns.push({
            id: this.RANKING_COLUMN_ID,
            name: "Ranking",
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });
        this.selectedFields.forEach((field) => {
            columns.push({
                id: field.id,
                name: field.name,
                type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                horizontalAlignment: TableColumnAlignment.RIGHT,
            });
        });
        columns.push({
            id: this.selectedRankingField.id,
            type: TableColumnType.DECIMAL,
            name: this.selectedRankingField.name,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        if (this.selectedFormula) {
            columns.push({
                id: this.selectedFormula.id.toString(),
                type: TableColumnType.DECIMAL,
                name: this.selectedFormula.name,
                horizontalAlignment: TableColumnAlignment.RIGHT,
            });
        }
        return columns;
    }

    getTableReportFooter(): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        this.selectedFields.forEach((field) => {
            if (
                DatasetUtils.getDatasetFieldType(field) ===
                DatasetFieldType.NUMBER
            ) {
                cells.push({
                    id: field.id,
                    value: this.sumsByFieldId.get(field.id),
                });
            } else {
                cells.push({ id: this.BLANK_COLUMN_ID, value: "" });
            }
        });
        cells.push({
            id: this.selectedRankingField.id,
            value: this.sumsByFieldId.get(this.selectedRankingField.id),
        });
        if (this.selectedFormula) {
            cells.push({
                id: this.selectedFormula.id.toString(),
                value: this.sumsByFieldId.get(
                    this.selectedFormula.id.toString()
                ),
            });
        }
        // cells.splice(cells.length - 1, 1); // we need to add only N-1 empty spaces

        return { cells: cells };
    }

    getTableReportRows(): TableRow[] {
        let rows: TableRow[] = [];
        this.downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            columns.push({
                id: this.RANKING_COLUMN_ID,
                value: row.ranking,
            });
            this.dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });
            rows.push({ cells: columns });
        });

        return rows;
    }

    getTableReportDownloadRequest(): DownloadReportItem {
        if (this.dataIsReady) {
            let reportHeader = this.getTableReportHeader();
            let reportRows = this.getTableReportRows();
            let reportFooter = this.getTableReportFooter();
            let title = this.reportName || "Ranking";

            return new DownloadReportTableRequest(
                title,
                reportHeader,
                reportRows,
                reportFooter
            );
        } else {
            return null;
        }
    }

    getDisplayType(): ReportDisplayType {
        return this.reportSubType;
    }

    getChartReportDownloadRequest(): DownloadReportItem {
        return undefined;
    }

    getReportDownloadRequest(): DownloadReportItem {
        if (this.getDisplayType() === ReportDisplayType.TABLE) {
            return this.getTableReportDownloadRequest();
        } else if (
            this.getDisplayType() === ReportDisplayType.BAR_CHART ||
            this.getDisplayType() === ReportDisplayType.PIE_CHART
        ) {
            return this.getChartReportDownloadRequest();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setSelectedRankingField(selectedRankingField: DatasetField) {
        let selectedField = this.datasetFields.find(
            (f) => f.id === selectedRankingField.id
        );
        this.selectedRankingField = selectedField;
        selectedField.selected = true;
        this.datapointObject.markSelected(this.rankingDataSource.data, selectedRankingField.id);
    }

    setSelectedFields(selectedFields: DatasetField[]) {
        selectedFields.forEach((field) => {
            let datasetField = this.datasetFields.find(
                (f) => f.id === field.id
            );
            if (datasetField) {
                datasetField.selected = true;
                this.datapointObject.markSelected(this.columnsToDisplayDataSource.data, datasetField.id);
                this.selectedFields.push(datasetField);
            }
        });
    }

    sortFields(fields: DatasetField[]) {
        fields.sort((item1, item2) => {
            if (item1 && item1.name && item2 && item2.name) {
                return item1.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(item2.name.trim().toLowerCase());
            }
        });
        return fields;
    }

    resetNRISelectedFlag() {
        this._nriFields.forEach((outerElement) => {
            outerElement.child.forEach((element) => {
                element.child.selected = false;
            });
        });
    }

    getSaveButtonLabel() {
        return this.isDashboardCall ? "Save" : "Save to Dashboard";
    }

    getSaveAsButtonLabel() {
        return this.isDashboardCall ? "Save as" : "Save as Dashboard";
    }

    isTensorFlight(key: string) {
        return key.toUpperCase() === "TENSORFLIGHT";
    }

    getDisplayName(node) {
        return this.datapointObject.getDisplayName(node);
    }
    
    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    collapseAll(): void {
        this.rankingTreeControl.collapseAll();
        this.columnsToDisplayTreeControl.collapseAll();
    }

    isLocationTypeApplication() {
        return this._dataset.application === MaptycsApplication.LOCATIONS;
    }
}
