<div class="container location-right-panel-container">
    <ng-container *ngIf="_datapoint && datasetFields">
        <div class="boxed-section location-summary-section-container">
            <ng-container *ngFor="let field of _datapoint.fields">
                <div class="detail-row detail-row-new1" *ngIf="datasetFields[field.id].isSummary && field.isPresent">
                    <span>{{ datasetFields[field.id].name }}</span>
                    <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{ field.datetimeValue
                        | maptycsDate: true}}</span>
                    <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{ field.numberValue |
                        number}}</span>
                    <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{ field.textValue}}</span>
                </div>
            </ng-container>
            <div class="detail-row detail-row-new1">
                <span>Latitude</span>
                <span>{{ this._datapoint.location.y }}</span>
            </div>
            <div class="detail-row detail-row-new1">
                <span>Longitude</span>
                <span>{{ this._datapoint.location.x }}</span>
            </div>
        </div>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.INTERNAL" class="location-card">
            <mat-card-header>
                <mat-card-title>Internal Data</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" style="padding: 8px 8px;">
                    <ng-container *ngFor="let field of _datapoint.fields">
                        <div class="detail-row detail-row-new1"
                            *ngIf="!datasetFields[field.id].isSummary && field.scope === DatasetFieldScope.INTERNAL && field.isPresent && isFieldValueValid(field)">
                            <span>{{ datasetFields[field.id].name }}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{
                                field.datetimeValue | maptycsDate: true}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{
                                field.numberValue | number}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{
                                field.textValue}}</span>

                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.EXTERNAL && tessadataEnabled">
            <mat-card-header>
                <mat-card-title>External Data</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div *ngIf="externalFields?.length" class="boxed-section internal-data-container">
                    <ng-container *ngFor="let field of externalFields">
                        <div class="detail-row detail-row-new1" *ngIf="isFieldValueValid(field)">
                            <span>{{ datasetFields[field.id].name }}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{
                                field.datetimeValue | maptycsDate: true}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{
                                field.numberValue | number}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{
                                field.textValue}}</span>
                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
        <div>
            <mat-card *ngIf="_dropdownSelectedValue == locationProfile.CLIMATE">
                <mat-card-header>
                    <mat-card-title>Climate Data</mat-card-title>
                </mat-card-header>
                <mat-card-actions>
                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isMaxTemperatureEnabled">
                        <div class="headline">
                            <div class="title">Maximum Temperature</div>
                        </div>
                        <div echarts [options]="initMaxTempOption" (chartInit)="onMaxTempChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isMeanTemperatureEnabled">
                        <div class="headline">
                            <div class="title">Mean Temperature</div>
                        </div>
                        <div echarts [options]="initMeanTempOption" (chartInit)="onMeanTempChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isMinTemperatureEnabled">
                        <div class="headline">
                            <div class="title">Minimum Temperature</div>
                        </div>
                        <div echarts [options]="initMinTempOption" (chartInit)="onMinTempChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isSnowFallEnabled">
                        <div class="headline">
                            <div class="title">Snowfall</div>
                        </div>
                        <div echarts [options]="initSnowfallOption" (chartInit)="onSnowfallChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isPrecipitationEnabled">
                        <div class="headline">
                            <div class="title">Precipitation</div>
                        </div>
                        <div echarts [options]="initPrecipitationOption" (chartInit)="onPrecipitationChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isSurfaceWindEnabled">
                        <div class="headline">
                            <div class="title">Surface Wind</div>
                        </div>
                        <div echarts [options]="initSurfaceWindOption" (chartInit)="onSurfaceWindChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isPopulationDensityEnabled">
                        <div class="headline">
                            <div class="title">Population Density</div>
                        </div>
                        <div echarts [options]="initPopulationDensityOption"
                            (chartInit)="onPopulationDensityCharInit($event)" [theme]="maptycsTheme" class="demo-chart">
                        </div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isSeaLevelRiseEnabled">
                        <div class="headline">
                            <div class="title">Sea Level Rise</div>
                        </div>
                        <div echarts [options]="initSeaLevelRiseOption" (chartInit)="onSeaLevelRiseChartInit($event)"
                            [theme]="maptycsTheme" class="demo-chart"></div>
                    </div>

                    <div class="tab-content-container" *ngIf="climateOverlayPermission.isSeaLevelRiseWarmingEnabled">
                        <div class="headline">
                            <div class="title">Sea Level Rise Warming</div>
                        </div>
                        <div echarts [options]="initSeaLevelRiseWarmingOption"
                            (chartInit)="onSeaLevelRiseWarmingChartInit($event)" [theme]="maptycsTheme"
                            class="demo-chart">
                        </div>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.APG && tessadataEnabled">
            <mat-card-header>
                <mat-card-title>Precisely Wildfire</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div *ngIf="apgFields?.length" class="boxed-section internal-data-container" style="padding: 8px 16px;">
                    <ng-container *ngFor="let field of apgFields">
                        <div class="detail-row detail-row-new1" *ngIf="isFieldValueValid(field)">
                            <span>{{ datasetFields[field.id].name }}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{
                                field.datetimeValue | maptycsDate: true}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{
                                field.numberValue | number}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{
                                field.textValue}}</span>
                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.NRI && tessadataEnabled">
            <mat-card-header>
                <mat-card-title>National Risk Index</mat-card-title>
                <mat-card-subtitle class="subtitle">NRI</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
                <div class="card" *ngIf="summaryCard.visible">
                    <div class="content">
                        <div class="title">{{summaryCard.title}}</div>
                        <div class="indexes-list">
                            <div *ngFor="let property of summaryCard.properties">{{property.label}}: {{property.value}}
                            </div>
                        </div>
                    </div>
                    <div class="index-container" *ngIf="summaryCard.warnSignColor">
                        <mat-icon class="icon" [style.color]="summaryCard.warnSignColor"
                            fontIcon="fa-exclamation-triangle" fontSet="fas"></mat-icon>
                    </div>
                </div>
                <ng-container *ngFor="let card of nriCards">
                    <div class="card" *ngIf="card.visible">
                        <div class="icon-container"><img class="icon" [src]="'assets/icons/' + card.icon"
                                alt="Card Icon"></div>
                        <div class="content">
                            <div class="title">{{card.title}}</div>
                            <div class="indexes-list">
                                <div *ngFor="let property of card.properties">{{property.label}}: {{property.value}}
                                </div>
                            </div>
                        </div>
                        <div class="index-container" *ngIf="card.warnSignColor">
                            <mat-icon class="icon" [style.color]="card.warnSignColor" fontIcon="fa-exclamation-triangle"
                                fontSet="fas"></mat-icon>
                        </div>
                    </div>
                </ng-container>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.POI && tessadataEnabled">
            <mat-card-header>
                <mat-card-title>Points of Interest</mat-card-title>
                <mat-card-subtitle class="subtitle">POI</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" style="padding: 8px 16px;">
                    <div class="section-item">
                        <div class="section-item-title">Point of Interest</div>
                        <div>
                            <mat-form-field color="accent">
                                <mat-select [(ngModel)]="tessadataPOIDataset" class="location-address-select">
                                    <mat-option *ngFor="let ds of tessadataPOIDatasets" [value]="ds">
                                        {{ ds.datasetLabel }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="section-item-title">Distance</div>
                        <div style="display: flex; align-items: flex-end">
                            <mat-form-field [floatLabel]="'never'" color="accent" class="section-item-input">
                                <input #distanceInput placeholder="Distance" [(ngModel)]="tessadataPOIDistance" matInput
                                    (keydown)="$event.stopPropagation()" style="bottom: 0 !important;">
                            </mat-form-field>
                            <mat-form-field color="accent" style="width: 60px">
                                <mat-select #distUnit [(value)]="tessadataPOIDistanceUnit" class="location-address-select">
                                    <mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
                                    <mat-option [value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="section-item-title">Limit</div>
                        <mat-form-field [floatLabel]="'never'" color="accent" class="section-item-input">
                            <input type="number" [(ngModel)]="tessadataPOILimit" matInput style="bottom: 0 !important;" />
                        </mat-form-field>
                    </div>
                    <div class="section-button" style="margin-top: 8px;">
                        <button color="accent" mat-flat-button (click)="fetchClosestPOI()">
                            Submit
                        </button>
                    </div>
                </div>
                <div *ngIf="tessadataPOIDetails" class="boxed-section internal-data-container"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let poi of tessadataPOIDetails">
                        <ng-container *ngFor="let poiField of poi.fields">
                            <div class="detail-row detail-row-new1">
                                <span>{{ poiField.label }}</span>
                                <span *ngIf="poiField.type === 'NUMBER'; else notNumber">{{ poiField.value | number}}
                                    <span
                                        *ngIf="poiField.label === 'Distance'">{{tessadataPOISubmittedDistanceUnit}}</span></span>
                            </div>
                            <ng-template #notNumber>
                                <span>{{ poiField.value }}</span>
                            </ng-template>
                        </ng-container>
                        <div *ngIf="poi.fields.length" class="inner-section-delimiter">
                            <div style="border-bottom: 1px solid #8080805e;"></div>
                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.CONDITIONS">
            <mat-card-header>
                <mat-card-title>Xweather conditions</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="meteorologicalConditionsData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of meteorologicalConditionsData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="meteorologicalConditionsData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.PLACES">
            <mat-card-header>
                <mat-card-title>Xweather places</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="boxed-section internal-data-container" *ngIf="placesData.length > 0"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let ad of placesData">
                        <div class="detail-row detail-row-new1">
                            <span [style.font-weight]="ad.value === '' ? '900' : 'normal' "
                                [style.font-size]="ad.value === '' ? '16px' : '11px' ">{{ad.text}}</span>
                            <span>{{ad.value}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="placesData.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.TENSORFLIGHT">
            <mat-card-header>
                <mat-card-title>TensorFlight Data</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div *ngIf="tensorflightFields?.length" class="boxed-section internal-data-container"
                    style="padding: 8px 16px;">
                    <ng-container *ngFor="let field of tensorflightFields">
                        <div class="detail-row detail-row-new1" *ngIf="isFieldValueValid(field)">
                            <span>{{ datasetFields[field.id].name }}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.DATE_TIME">{{
                                field.datetimeValue | maptycsDate: true}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.NUMBER">{{
                                field.numberValue | number}}</span>
                            <span *ngIf="datasetFields[field.id].baseType === DatasetFieldType.TEXT">{{
                                field.textValue}}</span>
                        </div>
                    </ng-container>
                </div>
                <p *ngIf="tensorflightFields?.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="_dropdownSelectedValue == locationProfile.MUNICHRE">
            <mat-card-header *ngIf="munichReFields.length === 0">
                <mat-card-title>Munich Re</mat-card-title>
            </mat-card-header>
            <mat-card-actions>
                <div class="form-container" *ngIf="munichReFields.length > 0">
                    <div class="nathan-header-wrapper">
                        <div class="nathan-wrapper">Nathan Score</div>
                        <div style="display: flex; align-items: center;">
                            <div>Generate Report</div>
                            <div (click)="downloadMunichReport()">
                                <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer; margin-left:4px">
                                    <g fill="none" fill-rule="evenodd">
                                        <path stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
                                        <path d="M-.005-.005h24.01v24.01H-.005z" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 8px;">
                        <div class="hazard-wrapper">Risk Scores</div>
                        <div class="scrollable-table">
                            <table mat-table [dataSource]="riskDataSource" class="mat-elevation-z8"
                                style="width: 100%; font-size: 0.75rem;">

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="hazard-header"></th>
                                    <td mat-cell *matCellDef="let element" style="width: 30%;">
                                        <div
                                            style="display: flex;align-items: center;flex-direction: row;width: 100%;">
                                            <div style="display: flex;">
                                                <img [src]="element.imgSrc"
                                                    style="height: 16px; width: 20px; stroke: #08080d;margin-right: 4px;"
                                                    alt="icon-overall">
                                            </div>
                                            <div class="risk-hazard-title">
                                                {{element.name}}
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="none">
                                    <th mat-header-cell *matHeaderCellDef style="padding: 0 8px;text-align: center;"> None </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="padding: 0 8px; text-align: center;width: 10%;">
                                        <i class="fal fa-info-circle"></i>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="low">
                                    <th mat-header-cell *matHeaderCellDef style="width: 100%;">
                                        <div
                                            style="display: flex; align-items: center; justify-content: space-between;">
                                            <div>Low</div>
                                            <div>Extreme</div>
                                        </div>
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="width: 60%;">
                                        <div class="color-rectangle">
                                            <div class="pointer"
                                                [style.left.%]="getPointerPosition(element)"
                                                [matTooltip]="getTooltipText(element)"
                                                matTooltipPosition="above">
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedRiskColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedRiskColumns;"></tr>
                            </table>

                        </div>
                    </div>
                    <div>
                        <div class="hazard-wrapper">Hazard Score</div>
                        <div class="scrollable-table">
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                                style="width: 100%; font-size: 0.75rem;padding: 0 4px !important;padding: 0px 4px;">

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="hazard-header"></th>
                                    <td mat-cell *matCellDef="let element" style="width: 30%;">
                                        <span class="hazard-title">{{element.name}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="none">
                                    <th mat-header-cell *matHeaderCellDef style="width: 40px;
                                        padding: 0 8px;text-align: center;"> None </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: center;padding: 0 8px;width: 10%;">
                                        <div class="progress" style="width: 40px;">
                                            <div [class]="element.values[0].none.class"
                                                [style]="element.values[0].none.style"
                                                [matTooltip]="element.values[0].none.tooltip"
                                                matTooltipPosition="above">
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="low">
                                    <th mat-header-cell *matHeaderCellDef style="width: 100%;">
                                        <div
                                            style="display: flex; align-items: center; justify-content: space-between;">
                                            <div>Low</div>
                                            <div>High</div>
                                        </div>
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="width: 60%;">
                                        <div class="progress" style="max-width: 100%">
                                            <div [class]="element.values[0].value1.class"
                                                [style]="element.values[0].value1.style"
                                                [matTooltip]="element.values[0].value1.tooltip"
                                                matTooltipPosition="above">
                                            </div>
                                            <div [class]="element.values[0].value2.class"
                                                [style]="element.values[0].value2.style"
                                                [matTooltip]="element.values[0].value2.tooltip"
                                                matTooltipPosition="above">
                                            </div>
                                            <div [class]="element.values[0].value3.class"
                                                [style]="element.values[0].value3.style"
                                                [matTooltip]="element.values[0].value3.tooltip"
                                                matTooltipPosition="above">
                                            </div>
                                            <div *ngIf="element.values[0].value4"
                                                [class]="element.values[0].value4.class"
                                                [style]="element.values[0].value4.style"
                                                [matTooltip]="element.values[0].value4.tooltip"
                                                matTooltipPosition="above">
                                            </div>
                                            <div *ngIf="element.values[0].value5"
                                                [class]="element.values[0].value5.class"
                                                [style]="element.values[0].value5.style"
                                                [matTooltip]="element.values[0].value5.tooltip"
                                                matTooltipPosition="above">
                                            </div>
                                            <div *ngIf="element.values[0].value6"
                                                [class]="element.values[0].value6.class"
                                                [style]="element.values[0].value6.style"
                                                [matTooltip]="element.values[0].value6.tooltip"
                                                matTooltipPosition="above">
                                            </div>
                                            <div *ngIf="element.values[0].value7"
                                                [class]="element.values[0].value7.class"
                                                [style]="element.values[0].value7.style"
                                                [matTooltip]="element.values[0].value7.tooltip"
                                                matTooltipPosition="above">
                                            </div>
                                        </div>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </div>
                </div>
                <p *ngIf="munichReFields.length === 0">
                    No data for this location.
                </p>
            </mat-card-actions>
        </mat-card>
    </ng-container>
</div>