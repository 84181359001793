<agm-map #map (mapClick)="onMapClick($event, mapInfoBox);" (mapReady)="onMapReady($event)"
    (mapRightClick)="onMapRightClick($event)" [disableDefaultUI]="mapSettings.disableDefaultUI" [mapTypeId]="mapType.type"
    [maxZoom]="mapSettings.maxZoom" [minZoom]="mapSettings.minZoom" [tilt]="mapSettings.tilt"
    [restriction]="mapSettings.restriction" [streetViewControl]="mapSettings.streetViewControl"
    [zoom]="mapSettings.zoom" [fitBounds]="(searchResultMarker$ | async)?.fitBounds"
    (boundsChange)="onBoundsChange($event)" (zoomChange)="onZoomChange($event)" [styles]="customMapStyles">

    <ng-container *ngIf="mapStatus.mode === DatapointsMapMode.INTERACTION_DATASET_ACTIVE && interactiveOverlayShapes">
        <agm-data-layer [geoJson]="geometry.geojson" [style]="geometry.style"
            *ngFor="let geometry of interactiveOverlayShapes" (layerClick)="onGeojsonClick(geometry, $event)">
            <agm-info-window [latitude]="geometry.clickedLocation.lat" [longitude]="geometry.clickedLocation.lng"
                [isOpen]="geometry.showInfo" (infoWindowClose)="geometry.showInfo = false">
                {{mapStatus.externalDatasetName}}
                <div *ngFor="let property of (geometry?.geojson?.properties?.fields)">{{property.id}}:
                    {{property.value}}</div>
            </agm-info-window>
        </agm-data-layer>
    </ng-container>

    <ng-container *ngIf="regionsShapes.length > 0">
        <agm-data-layer [geoJson]="geometry.geojson" [style]="geometry.style" *ngFor="let geometry of regionsShapes">
        </agm-data-layer>
    </ng-container>

    <map-info-box #mapInfoBox (DetailsOpen)="openDetails($event)"></map-info-box>
    <!-- MEASURE    -->
    <agm-polygon
        (polyPathsChange)="updatePolygonPaths(polygon, $event, i, MapShape.POLYGON, MapDrawType.MEASURE); info.close()"
        *ngFor="let polygon of polygons.measure; let i = index" [clickable]="drawingOptions.polygon.clickable"
        [editable]="drawingOptions.polygon.editable" [fillColor]="shapeMeasurementColor"
        [geodesic]="drawingOptions.polygon.geodesic" [paths]="polygon.polygon.getPath()"
        [polyDraggable]="drawingOptions.polygon.draggable" [strokeColor]="shapeMeasurementColor"
        [strokeWeight]="drawingOptions.polygon.strokeWeight" (polyClick)="info.open()" (polyDragEnd)="info.open()">
        <agm-info-window #info [latitude]="polygon.polygon.getPath().getArray()[0].lat()"
            [longitude]="polygon.polygon.getPath().getArray()[0].lng()" isOpen="true" class="info-window">
            <div class="info-box-content">
                <div class="tooltip-subtitle">Area</div>
                <div class="tooltip-value font-12">{{squareMToSquareKm(computePolygonArea(polygon.polygon.getPath())) |
                    number:
                    '1.0-2'}}&nbsp;km.<sup>2</sup></div>
                <div class="tooltip-value font-12">{{squareMToSquareMi(computePolygonArea(polygon.polygon.getPath())) |
                    number:
                    '1.0-2'}}&nbsp;mi.<sup>2</sup></div>
                <div class="tooltip-subtitle">Risk Info</div>
                <ng-container *ngIf="polygon.isReceivingAllData">
                    <div class="container-risk-info">
                        <div *ngFor="let item of polygon.polygonData | keyvalue">
                            <div *ngIf="item.value.length > 0" [title]="item.value[0].description"
                                class="tooltip-value aeris-label margin-bottom"
                                (click)="showCircleInfoByLabel(item.value[0])"
                                [ngClass]="!item.value[0].showData ? 'light-bg-aeris-label' : 'dark-bg-aeris-label'">
                                {{item.key}}
                            </div>
                        </div>
                        <div *ngIf="polygon.emptyResponsesCounter === numberOfAerisRequestsWithinShapes"
                            class="bold-400">No Data Recorded Here</div>
                    </div>
                </ng-container>
                <div *ngIf="!polygon.isReceivingAllData" class="flex-centered">
                    <mat-spinner diameter="20"></mat-spinner>
                </div>

                <!--            aeris data without coords-->
                <ng-container *ngFor="let data of polygon.polygonData | keyvalue">
                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.ALERTS_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div *ngFor="let property of data.value[0].properties;let index=index">
                                    <div class="property-name">Alert {{index + 1}}</div>
                                    <div *ngFor="let alert of property">
                                        <span class="aeris-property">{{alert[0]}}</span>: {{alert[1] || '-'}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.LIGHTNING_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div class="property-name">Range</div>
                                <div *ngFor="let property of data.value[0].range; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                                <div class="property-name">Pulse</div>
                                <div *ngFor="let property of data.value[0].pulse; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </agm-info-window>

        <!--aeris data with coords-->
        <ng-container *ngFor="let data of polygon.polygonData | keyvalue">
            <ng-container *ngIf="data.key !== AerisType.AIR_QUALITY && data.key !== AerisType.RIVERS_GAUGES && data.key!== AerisType.STORMCELLS && data.key !== AerisType.TROPICALCYCLONES &&
                             data.key !== AerisType.ALERTS_SUMMARY && data.key !== AerisType.LIGHTNING_SUMMARY">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.AIR_QUALITY || data.key === AerisType.RIVERS_GAUGES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div *ngFor="let trait of item.traitList; let index=index;">
                                        <div class="property-name">{{item.traitLabel}} {{index + 1}}:</div>
                                        <div *ngFor="let property of trait"><span
                                                class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                        </div>
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.STORMCELLS || data.key === AerisType.TROPICALCYCLONES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div class="property-name">{{item.traitLabel}}</div>
                                    <div *ngFor="let trait of item.traitList">
                                        <span class="aeris-property">{{trait[0]}}</span>: {{trait[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </agm-polygon>
    <agm-polyline (polyPathChange)="info.close();shapeUpdateDebouncer.next({ event: $event.newArr, index:i })"
        *ngFor="let polyline of polylines.measure; let i = index" [clickable]="drawingOptions.polyline.clickable"
        [editable]="false" [geodesic]="drawingOptions.polyline.geodesic"
        [polylineDraggable]="drawingOptions.polyline.draggable" [strokeColor]="drawingOptions.polyline.strokeColor"
        [strokeWeight]="drawingOptions.polyline.strokeWeight" (lineDragStart)="info.close()"
        (lineDragEnd)="getLastCoordsForInfobox($event, i, info)">
        <agm-polyline-point *ngFor="let point of polyline.polyline" [latitude]="point.lat()" [longitude]="point.lng()">
        </agm-polyline-point>
        <agm-info-window #info [latitude]="polyline.latPolylineInfobox" [longitude]="polyline.lngPolylineInfobox"
            isOpen="true">
            <div class="tooltip-subtitle">Distance</div>
            <div class="tooltip-value">{{mToKm(computePolylineDistance(polyline.polyline)) | number: '1.0-2'}}&nbsp;km.
            </div>
            <div class="tooltip-value">{{mToMi(computePolylineDistance(polyline.polyline)) | number: '1.0-2'}}&nbsp;mi.
            </div>
            <div class="tooltip-value">{{mToFeet(computePolylineDistance(polyline.polyline)) | number:
                '1.0-2'}}&nbsp;ft.</div>
            <div class="tooltip-value">{{mToMeter(computePolylineDistance(polyline.polyline)) | number:
                '1.0-2'}}&nbsp;m.</div>
        </agm-info-window>
    </agm-polyline>
    <agm-circle *ngFor="let cluster of clusters" [circleDraggable]="false" [clickable]="true" [editable]="false"
        [fillOpacity]="cluster.opacity"
        (mouseOver)="cluster.opacity = 0.8; cluster.strokeWeight = 2; cluster.showInfo = true;"
        (mouseOut)="cluster.opacity = 0.6; cluster.strokeWeight = 0.5; cluster.showInfo = false"
        (circleClick)="onClusterClick(cluster); clusterInfoWindow.open()" [fillColor]="cluster.color"
        [latitude]="cluster.center.y" [longitude]="cluster.center.x"
        [radius]="cluster.radius ? cluster.radius : clusterSettings.radius * 1000" [strokeColor]="'grey'"
        [strokeWeight]="cluster.strokeWeight" [zIndex]="10">
        <agm-info-window #clusterInfoWindow [latitude]="cluster.center.y" [longitude]="cluster.center.x"
            [isOpen]="cluster.showInfo">
            <div>{{clusteringField?.name}}: {{cluster?.value | commaFormat}}</div>
            <div>Count: {{cluster?.datapointsCount}}</div>
            <div style="height: 6px; width: 100%; margin-top: 8px; border-radius: 2px; opacity: 0.8;"
                [style.background]="cluster.color"></div>
        </agm-info-window>
    </agm-circle>
    <agm-circle
        (centerChange)="shapeUpdateDebouncer.next({event: {latitude: $event.lat, longitude: $event.lng, radius: circle.radius}, index:i, type: MapShape.CIRCLE, action: MapDrawType.MEASURE}); info.close()"
        (radiusChange)="shapeUpdateDebouncer.next({event: {latitude: circle.latitude, longitude: circle.longitude, radius: $event}, index:i, type: MapShape.CIRCLE, action: MapDrawType.MEASURE }); info.close()"
        *ngFor="let circle of circles.measure; let i = index" [circleDraggable]="drawingOptions.circle.draggable"
        [clickable]="drawingOptions.circle.clickable" [editable]="drawingOptions.circle.editable"
        [fillColor]="shapeMeasurementColor" [latitude]="circle.latitude" [longitude]="circle.longitude"
        [radius]="circle.radius" [strokeColor]="shapeMeasurementColor"
        [strokeWeight]="drawingOptions.circle.strokeWeight" (circleClick)="info.open()" (dragEnd)="circleDragEnd(info)">
        <agm-info-window #info [latitude]="circle.latitude" [longitude]="circle.longitude" isOpen="true">
            <div class="info-box-content">
                <div class="tooltip-subtitle">Area</div>
                <div class="tooltip-value font-12">{{squareMToSquareKm(computeCircleArea(circle.radius)) | number:
                    '1.0-2'}}&nbsp;km.<sup>2</sup></div>
                <div class="tooltip-value font-12">{{squareMToSquareMi(computeCircleArea(circle.radius)) | number:
                    '1.0-2'}}&nbsp;mi.<sup>2</sup></div>
                <div class="tooltip-subtitle">Radius</div>
                <div class="tooltip-value">{{mToKm(circle.radius) | number: '1.0-2'}}&nbsp;km.</div>
                <div class="tooltip-value">{{mToMi(circle.radius) | number: '1.0-2'}}&nbsp;mi.</div>
                <div class="tooltip-value">{{mToFeet(circle.radius) | number: '1.0-2'}}&nbsp;ft.</div>
                <div class="tooltip-value">{{mToMeter(circle.radius) | number: '1.0-2'}}&nbsp;m.</div>
                <div class="tooltip-subtitle">Risk Info</div>
                <ng-container *ngIf="circle.isReceivingAllData">
                    <div class="container-risk-info">
                        <div *ngFor="let item of circle.circleData | keyvalue">
                            <div *ngIf="item.value.length > 0" [title]="item.value[0].description"
                                class="tooltip-value aeris-label margin-bottom"
                                (click)="showCircleInfoByLabel(item.value[0])"
                                [ngClass]="!item.value[0].showData ? 'light-bg-aeris-label' : 'dark-bg-aeris-label'">
                                {{item.key}}
                            </div>
                        </div>
                        <div *ngIf="circle.emptyResponsesCounter === numberOfAerisRequestsWithinShapes"
                            class="bold-400">No Data Recorded Here</div>
                    </div>
                </ng-container>
                <div *ngIf="!circle.isReceivingAllData" class="flex-centered">
                    <mat-spinner diameter="20"></mat-spinner>
                </div>
                <!--            aeris data without coords-->
                <ng-container *ngFor="let data of circle.circleData | keyvalue">
                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.ALERTS_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div *ngFor="let property of data.value[0].properties;let index=index">
                                    <div class="property-name">Alert {{index + 1}}</div>
                                    <div *ngFor="let alert of property">
                                        <span class="aeris-property">{{alert[0]}}</span>: {{alert[1] || '-'}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.LIGHTNING_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div class="property-name">Range</div>
                                <div *ngFor="let property of data.value[0].range; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                                <div class="property-name">Pulse</div>
                                <div *ngFor="let property of data.value[0].pulse; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </agm-info-window>
        <!--aeris data with coords-->
        <ng-container *ngFor="let data of circle.circleData | keyvalue">
            <ng-container *ngIf="data.key !== AerisType.AIR_QUALITY && data.key !== AerisType.RIVERS_GAUGES && data.key!== AerisType.STORMCELLS && data.key !== AerisType.TROPICALCYCLONES &&
                             data.key !== AerisType.ALERTS_SUMMARY && data.key !== AerisType.LIGHTNING_SUMMARY">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.AIR_QUALITY || data.key === AerisType.RIVERS_GAUGES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div *ngFor="let trait of item.traitList; let index=index;">
                                        <div class="property-name">{{item.traitLabel}} {{index + 1}}:</div>
                                        <div *ngFor="let property of trait"><span
                                                class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                        </div>
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.STORMCELLS || data.key === AerisType.TROPICALCYCLONES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div class="property-name">{{item.traitLabel}}</div>
                                    <div *ngFor="let trait of item.traitList">
                                        <span class="aeris-property">{{trait[0]}}</span>: {{trait[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

    </agm-circle>
    <agm-rectangle (boundsChange)="shapeUpdateDebouncer.next({event: $event, index:i, action: MapDrawType.MEASURE})"
        *ngFor="let rectangle of rectangles.measure; let i = index" [east]="rectangle.east"
        [editable]="drawingOptions.rectangle.editable" [fillColor]="shapeMeasurementColor" [north]="rectangle.north"
        [rectangleDraggable]="drawingOptions.rectangle.draggable" [south]="rectangle.south"
        [strokeColor]="shapeMeasurementColor" [strokePosition]="drawingOptions.strokePosition"
        [strokeWeight]="drawingOptions.rectangle.strokeWeight" [west]="rectangle.west" (rectangleClick)="info.open()">
        <agm-info-window #info [latitude]="rectangle.north" [longitude]="rectangle.east" isOpen="true">
            <div class="info-box-content">
                <div class="tooltip-subtitle">Area</div>
                <div class="tooltip-value font-12">{{squareMToSquareKm(computeRectangleArea(rectangle)) | number:
                    '1.0-2'}}&nbsp;km.<sup>2</sup></div>
                <div class="tooltip-value font-12">{{squareMToSquareMi(computeRectangleArea(rectangle)) | number:
                    '1.0-2'}}&nbsp;mi.<sup>2</sup></div>
                <div class="tooltip-subtitle">Risk Info</div>
                <ng-container *ngIf="rectangle.isReceivingAllData">
                    <div class="container-risk-info">
                        <div *ngFor="let item of rectangle.rectangleData| keyvalue">
                            <div *ngIf="item.value.length > 0" [title]="item.value[0].description"
                                class="tooltip-value aeris-label margin-bottom"
                                (click)="showCircleInfoByLabel(item.value[0])"
                                [ngClass]="!item.value[0].showData ? 'light-bg-aeris-label' : 'dark-bg-aeris-label'">
                                {{item.key}}
                            </div>
                        </div>
                        <div *ngIf="rectangle.emptyResponsesCounter === numberOfAerisRequestsWithinShapes"
                            class="bold-400">No Data Recorded Here</div>
                    </div>
                </ng-container>
                <div *ngIf="!rectangle.isReceivingAllData" class="flex-centered">
                    <mat-spinner diameter="20"></mat-spinner>
                </div>
                <!--            aeris data without coords-->
                <ng-container *ngFor="let data of rectangle.rectangleData | keyvalue">
                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.ALERTS_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div *ngFor="let property of data.value[0].properties;let index=index">
                                    <div class="property-name">Alert {{index + 1}}</div>
                                    <div *ngFor="let alert of property">
                                        <span class="aeris-property">{{alert[0]}}</span>: {{alert[1] || '-'}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.LIGHTNING_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div class="property-name">Range</div>
                                <div *ngFor="let property of data.value[0].range; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                                <div class="property-name">Pulse</div>
                                <div *ngFor="let property of data.value[0].pulse; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </agm-info-window>
        <!--aeris data with coords-->
        <ng-container *ngFor="let data of rectangle.rectangleData | keyvalue">
            <ng-container *ngIf="data.key !== AerisType.AIR_QUALITY && data.key !== AerisType.RIVERS_GAUGES && data.key!== AerisType.STORMCELLS && data.key !== AerisType.TROPICALCYCLONES &&
                             data.key !== AerisType.ALERTS_SUMMARY && data.key !== AerisType.LIGHTNING_SUMMARY">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.AIR_QUALITY || data.key === AerisType.RIVERS_GAUGES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div *ngFor="let trait of item.traitList; let index=index;">
                                        <div class="property-name">{{item.traitLabel}} {{index + 1}}:</div>
                                        <div *ngFor="let property of trait"><span
                                                class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                        </div>
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.STORMCELLS || data.key === AerisType.TROPICALCYCLONES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div class="property-name">{{item.traitLabel}}</div>
                                    <div *ngFor="let trait of item.traitList">
                                        <span class="aeris-property">{{trait[0]}}</span>: {{trait[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </agm-rectangle>

    <!-- FILTER    -->
    <agm-polygon
        (polyPathsChange)="updatePolygonPaths(polygon, $event, i, MapShape.POLYGON, MapDrawType.FILTER); info.close()"
        *ngFor="let polygon of polygons.select; let i = index" [clickable]="drawingOptions.polygon.clickable"
        [editable]="drawingOptions.polygon.editable" [fillColor]="shapeSelectColor"
        [geodesic]="drawingOptions.polygon.geodesic" [paths]="polygon.polygon.getPath()"
        [polyDraggable]="drawingOptions.polygon.draggable" [strokeColor]="shapeSelectColor"
        [strokeWeight]="drawingOptions.polygon.strokeWeight" (polyClick)="info.open()" (polyDragEnd)="info.open()">
        <agm-info-window #info [latitude]="polygon.polygon.getPath().getArray()[0].lat()"
            [longitude]="polygon.polygon.getPath().getArray()[0].lng()" isOpen="true">
            <div class="info-box-content">
                <div class="tooltip-subtitle">Area</div>
                <div class="tooltip-value font-12">{{squareMToSquareKm(computePolygonArea(polygon.polygon.getPath())) |
                    number:'1.0-2'}}&nbsp;km.<sup>2</sup>
                </div>
                <div class="tooltip-value font-12">{{squareMToSquareMi(computePolygonArea(polygon.polygon.getPath())) |
                    number:'1.0-2'}}&nbsp;mi.<sup>2</sup>
                </div>
                <div class="tooltip-subtitle">Risk Info</div>
                <ng-container *ngIf="polygon.isReceivingAllData">
                    <div class="container-risk-info">
                        <div *ngFor="let item of polygon.polygonData | keyvalue">
                            <div *ngIf="item.value.length > 0" [title]="item.value[0].description"
                                class="tooltip-value aeris-label margin-bottom"
                                (click)="showCircleInfoByLabel(item.value[0])"
                                [ngClass]="!item.value[0].showData ? 'light-bg-aeris-label' : 'dark-bg-aeris-label'">
                                {{item.key}}
                            </div>
                        </div>
                        <div *ngIf="polygon.emptyResponsesCounter === numberOfAerisRequestsWithinShapes"
                            class="bold-400">No Data Recorded Here</div>
                    </div>
                </ng-container>
                <div *ngIf="!polygon.isReceivingAllData" class="flex-centered">
                    <mat-spinner diameter="20"></mat-spinner>
                </div>
                <!--            aeris data without coords-->
                <ng-container *ngFor="let data of polygon.polygonData | keyvalue">
                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.ALERTS_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div *ngFor="let property of data.value[0].properties;let index=index">
                                    <div class="property-name">Alert {{index + 1}}</div>
                                    <div *ngFor="let alert of property">
                                        <span class="aeris-property">{{alert[0]}}</span>: {{alert[1] || '-'}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.LIGHTNING_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div class="property-name">Range</div>
                                <div *ngFor="let property of data.value[0].range; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                                <div class="property-name">Pulse</div>
                                <div *ngFor="let property of data.value[0].pulse; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </agm-info-window>

        <!--aeris data with coords-->
        <ng-container *ngFor="let data of polygon.polygonData | keyvalue">
            <ng-container *ngIf="data.key !== AerisType.AIR_QUALITY && data.key !== AerisType.RIVERS_GAUGES && data.key!== AerisType.STORMCELLS && data.key !== AerisType.TROPICALCYCLONES &&
                             data.key !== AerisType.ALERTS_SUMMARY && data.key !== AerisType.LIGHTNING_SUMMARY">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.AIR_QUALITY || data.key === AerisType.RIVERS_GAUGES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div *ngFor="let trait of item.traitList; let index=index;">
                                        <div class="property-name">{{item.traitLabel}} {{index + 1}}:</div>
                                        <div *ngFor="let property of trait"><span
                                                class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                        </div>
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.STORMCELLS || data.key === AerisType.TROPICALCYCLONES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div class="property-name">{{item.traitLabel}}</div>
                                    <div *ngFor="let trait of item.traitList">
                                        <span class="aeris-property">{{trait[0]}}</span>: {{trait[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

    </agm-polygon>
    <agm-circle
        (centerChange)="shapeUpdateDebouncer.next({event: {latitude: $event.lat, longitude: $event.lng, radius: circle.radius}, index:i, type: MapShape.CIRCLE, action: MapDrawType.FILTER}); info.close()"
        (radiusChange)="shapeUpdateDebouncer.next({event: {latitude: circle.latitude, longitude: circle.longitude, radius: $event}, index:i, type: MapShape.CIRCLE, action: MapDrawType.FILTER}); info.close()"
        *ngFor="let circle of circles.select; let i = index" [circleDraggable]="drawingOptions.circle.draggable"
        [clickable]="drawingOptions.circle.clickable" [editable]="drawingOptions.circle.editable"
        [fillColor]="shapeSelectColor" [latitude]="circle.latitude" [longitude]="circle.longitude"
        [radius]="circle.radius" [strokeColor]="shapeSelectColor" [strokeWeight]="drawingOptions.circle.strokeWeight"
        (circleClick)="info.open()" (dragEnd)="circleDragEnd(info)">
        <agm-info-window #info [latitude]="circle.latitude" [longitude]="circle.longitude" isOpen="true">
            <div class="info-box-content">
                <div class="tooltip-subtitle">Area</div>
                <div class="tooltip-value font-12">{{squareMToSquareKm(computeCircleArea(circle.radius)) |
                    number:'1.0-2'}}&nbsp;km.<sup>2</sup>
                </div>
                <div class="tooltip-value font-12">{{squareMToSquareMi(computeCircleArea(circle.radius)) |
                    number:'1.0-2'}}&nbsp;mi.<sup>2</sup>
                </div>
                <div class="tooltip-subtitle">Radius</div>
                <div class="tooltip-value">{{mToKm(circle.radius) | number: '1.0-2'}}&nbsp;km.</div>
                <div class="tooltip-value">{{mToMi(circle.radius) | number: '1.0-2'}}&nbsp;mi.</div>
                <div class="tooltip-value">{{mToFeet(circle.radius) | number: '1.0-2'}}&nbsp;ft.</div>
                <div class="tooltip-value">{{mToMeter(circle.radius) | number: '1.0-2'}}&nbsp;m.</div>
                <div class="tooltip-subtitle">Risk Info</div>
                <ng-container *ngIf="circle.isReceivingAllData">
                    <div class="container-risk-info">
                        <div *ngFor="let item of circle.circleData | keyvalue">
                            <div *ngIf="item.value.length > 0" [title]="item.value[0].description"
                                class="tooltip-value aeris-label margin-bottom"
                                (click)="showCircleInfoByLabel(item.value[0])"
                                [ngClass]="!item.value[0].showData ? 'light-bg-aeris-label' : 'dark-bg-aeris-label'">
                                {{item.key}}
                            </div>
                        </div>
                        <div *ngIf="circle.emptyResponsesCounter === numberOfAerisRequestsWithinShapes"
                            class="bold-400">No Data Recorded Here</div>
                    </div>
                </ng-container>
                <div *ngIf="!circle.isReceivingAllData" class="flex-centered">
                    <mat-spinner diameter="20"></mat-spinner>
                </div>
                <!--            aeris data without coords-->
                <ng-container *ngFor="let data of circle.circleData | keyvalue">
                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.ALERTS_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div *ngFor="let property of data.value[0].properties;let index=index">
                                    <div class="property-name">Alert {{index + 1}}</div>
                                    <div *ngFor="let alert of property">
                                        <span class="aeris-property">{{alert[0]}}</span>: {{alert[1] || '-'}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.LIGHTNING_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div class="property-name">Range</div>
                                <div *ngFor="let property of data.value[0].range; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                                <div class="property-name">Pulse</div>
                                <div *ngFor="let property of data.value[0].pulse; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </agm-info-window>
        <!--aeris data with coords-->
        <ng-container *ngFor="let data of circle.circleData | keyvalue">
            <ng-container *ngIf="data.key !== AerisType.AIR_QUALITY && data.key !== AerisType.RIVERS_GAUGES && data.key!== AerisType.STORMCELLS && data.key !== AerisType.TROPICALCYCLONES &&
                             data.key !== AerisType.ALERTS_SUMMARY && data.key !== AerisType.LIGHTNING_SUMMARY">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.AIR_QUALITY || data.key === AerisType.RIVERS_GAUGES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div *ngFor="let trait of item.traitList; let index=index;">
                                        <div class="property-name">{{item.traitLabel}} {{index + 1}}:</div>
                                        <div *ngFor="let property of trait"><span
                                                class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                        </div>
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.STORMCELLS || data.key === AerisType.TROPICALCYCLONES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div class="property-name">{{item.traitLabel}}</div>
                                    <div *ngFor="let trait of item.traitList">
                                        <span class="aeris-property">{{trait[0]}}</span>: {{trait[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

    </agm-circle>

    <agm-rectangle (boundsChange)="shapeUpdateDebouncer.next({event: $event, index:i, action: MapDrawType.FILTER});"
        (dragEnd)="info.open()" *ngFor="let rectangle of rectangles.select; let i = index" [east]="rectangle.east"
        [editable]="drawingOptions.rectangle.editable" [fillColor]="shapeSelectColor" [north]="rectangle.north"
        [rectangleDraggable]="drawingOptions.rectangle.draggable" [south]="rectangle.south"
        [strokeColor]="shapeSelectColor" [strokePosition]="drawingOptions.strokePosition"
        [strokeWeight]="drawingOptions.rectangle.strokeWeight" [west]="rectangle.west" (rectangleClick)="info.open()">
        <agm-info-window #info [latitude]="rectangle.north" [longitude]="rectangle.east" isOpen="true">
            <div class="info-box-content">
                <div class="tooltip-subtitle">Area</div>
                <div class="tooltip-value font-12">{{squareMToSquareKm(computeRectangleArea(rectangle)) |
                    number:'1.0-2'}}&nbsp;km.<sup>2</sup>
                </div>
                <div class="tooltip-value font-12">{{squareMToSquareMi(computeRectangleArea(rectangle)) |
                    number:'1.0-2'}}&nbsp;mi.<sup>2</sup>
                </div>
                <div class="tooltip-subtitle">Risk Info</div>
                <ng-container *ngIf="rectangle.isReceivingAllData">
                    <div class="container-risk-info">
                        <div *ngFor="let item of rectangle.rectangleData| keyvalue">
                            <div *ngIf="item.value.length > 0" [title]="item.value[0].description"
                                class="tooltip-value aeris-label margin-bottom"
                                (click)="showCircleInfoByLabel(item.value[0])"
                                [ngClass]="!item.value[0].showData ? 'light-bg-aeris-label' : 'dark-bg-aeris-label'">
                                {{item.key}}
                            </div>
                        </div>
                        <div *ngIf="rectangle.emptyResponsesCounter === numberOfAerisRequestsWithinShapes"
                            class="bold-400">No Data Recorded Here</div>
                    </div>
                </ng-container>
                <div *ngIf="!rectangle.isReceivingAllData" class="flex-centered">
                    <mat-spinner diameter="20"></mat-spinner>
                </div>
                <!--            aeris data without coords-->
                <ng-container *ngFor="let data of rectangle.rectangleData | keyvalue">
                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.ALERTS_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div *ngFor="let property of data.value[0].properties;let index=index">
                                    <div class="property-name">Alert {{index + 1}}</div>
                                    <div *ngFor="let alert of property">
                                        <span class="aeris-property">{{alert[0]}}</span>: {{alert[1] || '-'}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="data.value.length > 0">
                        <ng-container *ngIf="data.key === AerisType.LIGHTNING_SUMMARY && data.value[0].showData">
                            <div class="no-locations-aeris-container">
                                <div class="poi-name">{{data.key}}</div>
                                <div class="property-name">Range</div>
                                <div *ngFor="let property of data.value[0].range; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                                <div class="property-name">Pulse</div>
                                <div *ngFor="let property of data.value[0].pulse; let index=index">
                                    <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </agm-info-window>
        <!--aeris data with coords-->
        <ng-container *ngFor="let data of rectangle.rectangleData | keyvalue">
            <ng-container *ngIf="data.key !== AerisType.AIR_QUALITY && data.key !== AerisType.RIVERS_GAUGES && data.key!== AerisType.STORMCELLS && data.key !== AerisType.TROPICALCYCLONES &&
                             data.key !== AerisType.ALERTS_SUMMARY && data.key !== AerisType.LIGHTNING_SUMMARY">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.AIR_QUALITY || data.key === AerisType.RIVERS_GAUGES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div *ngFor="let trait of item.traitList; let index=index;">
                                        <div class="property-name">{{item.traitLabel}} {{index + 1}}:</div>
                                        <div *ngFor="let property of trait"><span
                                                class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                        </div>
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.key === AerisType.STORMCELLS || data.key === AerisType.TROPICALCYCLONES">
                <ng-container *ngIf="data.value.length > 0">
                    <ng-container *ngIf="data.value[0].showData">
                        <agm-marker *ngFor="let item of data.value" [latitude]="item.loc.lat"
                            [longitude]="item.loc.long">
                            <agm-info-window>
                                <div class="locations-aeris-container">
                                    <div class="poi-name">{{item.type}}</div>
                                    <div *ngFor="let property of item.properties">
                                        <span class="aeris-property">{{property[0]}}</span>: {{property[1] || '-'}}
                                    </div>
                                    <div class="property-name">{{item.traitLabel}}</div>
                                    <div *ngFor="let trait of item.traitList">
                                        <span class="aeris-property">{{trait[0]}}</span>: {{trait[1] || '-'}}
                                    </div>
                                </div>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

    </agm-rectangle>

    <agm-marker *ngIf="activeMarker" [latitude]="activeMarker.y" [longitude]="activeMarker.x"
        [markerDraggable]="activeMarkerEditMode" (dragStart)="markerAnimation = null"
        (dragEnd)="markerAnimation = 'BOUNCE'; activeMarker.x = $event.coords.lng; activeMarker.y = $event.coords.lat"
        [animation]="markerAnimation">
    </agm-marker>

    <agm-marker *ngIf="searchResultMarker$ | async as marker" [latitude]="marker.latitude"
        [longitude]="marker.longitude" [markerDraggable]="true"
        [iconUrl]="getDefaultIconUrl()"
        (dragEnd)="marker.latitude = $event.coords.lat; marker.longitude = $event.coords.lng; updateAddressOnDragEnd($event.coords.lat,$event.coords.lng)">
    </agm-marker>
    <agm-polygon #polygonElement *ngIf="tensorFlightPolygonData" [clickable]="drawingOptions.polygon.clickable"
        [editable]="false" [fillColor]="shapeSelectColor" [geodesic]="drawingOptions.polygon.geodesic"
        [paths]="tensorFlightPolygonData.paths" [polyDraggable]="false" [strokeColor]="shapeSelectColor"
        [strokeWeight]="drawingOptions.polygon.strokeWeight">
    </agm-polygon>
</agm-map>
<div class="map-custom-actions">
    <div class="flex google-search-wrapper">
        <div>
            <mat-icon fontIcon="fa-search" fontSet="fas" class="search-icon"></mat-icon>
        </div>
        <div>
            <input matGoogleMapsAutocomplete placeholder="Enter an address.." [value]="currentPinAddress | async"
                [(ngModel)]="address" (onAutocompleteSelected)="onSearchAutocompleteSelected($event)"
                (onLocationSelected)="onSearchLocationSelected($event)">
        </div>
        <div class="info-icon-wrap" (click)="seeAddressDetails()">
            <mat-icon fontIcon="fa-info-square" fontSet="fal" class="info-icon"></mat-icon>
        </div>
    </div>
    <div class="map-btns-group">
        <mat-button-toggle-group [value]="mapType">
            <mat-button-toggle *ngFor="let mapOption of mapOption" [value]="mapOption" class="map-button">
                <div class="toggle-btn-content">
                    <img [src]="mapOption.iconSource" class="map-image" alt="maps" (click)="onIconClick(mapOption.type)">
                </div>
                <div class="toggle-btn-text">
                    {{ mapOption.type | uppercase }}
                </div>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>

</div>

<div class="map-play-button" *ngIf="showPlayButton">
    <div>
        <button mat-fab class="play-button" color="primary" (click)="toggleInteractiveMapAnimation()">
            <mat-icon fontIcon="fa-play" fontSet="fas"></mat-icon>
        </button>

        <button mat-fab color="primary" (click)="toggleInteractiveMapAnimation()">
            <mat-icon fontIcon="fa-stop" fontSet="fas"></mat-icon>
        </button>
    </div>
</div>
