import { Component, Input, ViewChild } from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import {
    CONDITIONAL_REPORT_TABS,
    EXCESS_VALUE_LIMIT,
    META_INTERNAL_FIELDS,
    REPORT_TABS,
    REPORT_TYPES,
} from "./reports.constants";
import { Dataset } from "src/app/model/dataset/dataset";
import { GroupService } from "src/app/data-access-layer/groups/group.service";
import { Group } from "src/app/model/group/group";
import { GroupTreeNode } from "src/app/model/overlay/group/group-with-overlays-tree-node";
import { DatasetFieldScope } from "src/app/model/dataset/dataset-field-scope";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { WorkspaceItemType } from "src/app/model/workspace/workspace-item-type";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { WorkspaceItemDialogComponent } from "../../workspace-item/projection/workspace-item-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NotifService } from "src/app/core/notification/notif.service";
import { ReportService } from "src/app/shared/services/renewal-report-service";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { sortArrayByName } from "src/app/core/utils/util-master";
@Component({
    selector: "map-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent {
    @ViewChild("workspaceItemDialog", { static: true })
    workspaceIemDialog: WorkspaceItemDialogComponent;

    @Input() dataset: Dataset;
    @Input() isCreateMode: boolean;
    @Input() selectedReport: WorkspaceItem;
    @Input() tabsAccess: {
        FLOOD_FEMA: boolean;
    };

    readonly excessValuesLimit = EXCESS_VALUE_LIMIT;
    readonly constantReportTabs = REPORT_TABS;
    readonly reportTypes = Object.values(REPORT_TYPES);
    readonly constantReportTabKeys = Object.keys(REPORT_TABS);

    selectedReportTabs: string[] = [];
    reportForm: FormGroup;
    eligibleGroups: Group[];
    internalFields: DatasetField[];
    isSubmitted: boolean = false;
    renewalReports: WorkspaceItem[];
    activeReport: WorkspaceItem;

    constructor(
        private formBuilder: FormBuilder,
        private readonly groupService: GroupService,
        public readonly dialog: MatDialog,
        public readonly notifService: NotifService,
        private readonly reportService: ReportService
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.getGroups();
        this.filterInternalFields();
        this.activeReport = this.selectedReport;
    }

    filterInternalFields() {
        this.internalFields = sortArrayByName(
            this.dataset?.fields?.filter(
                (field) =>
                    field.scope === DatasetFieldScope.INTERNAL &&
                    field.baseType === DatasetFieldType.NUMBER &&
                    !META_INTERNAL_FIELDS.includes(field.id)
            )
        );
    }

    getGroups() {
        this.groupService
            .getGroupsAsTree(this.dataset.id, this.dataset.accountID)
            .subscribe((groups) => {
                this.eligibleGroups = this.findLastChildNodes(groups);

                if (!this.isCreateMode) {
                    this.prepopulateForm();
                }
            });
    }

    findLastChildNodes(nodes: GroupTreeNode[]): Group[] {
        let eligibleGroups: Group[] = [];

        for (const node of nodes) {
            if (!node.children.length) {
                eligibleGroups.push(node.value);
            }
            if (node.children.length > 0) {
                eligibleGroups = eligibleGroups.concat(
                    this.findLastChildNodes(node.children)
                );
            }
        }

        return eligibleGroups;
    }

    setActiveReport(report) {
        this.activeReport = { ...report, isDeletable: true };
        this.isCreateMode = false;
        this.reportService.fetchReports(
            this.dataset.id,
            this.dataset.accountID
        );
    }

    initForm() {
        this.reportForm = this.formBuilder.group({
            reportType: [this.reportTypes[0], Validators.required],
            portfolio: ["", Validators.required],
            compareWith: [""],
            referenceValue: ["", Validators.required],
            excessValues: this.formBuilder.array([]),
        });

        if (this.isCreateMode) {
            this.addExcessValue();
        }
    }

    prepopulateForm() {
        const parsedData = JSON.parse(this.activeReport.data.trim());
        if (parsedData) {
            this.reportForm.patchValue({
                reportType: this.activeReport.type,
                portfolio: parsedData.portfolio,
                compareWith: parsedData.compareWith,
                referenceValue: parsedData.referenceValue,
            });

            this.selectedReportTabs = parsedData.renewalReportTabs || [];

            if (!parsedData.defineExcessValues?.length) {
                this.addExcessValue();
            } else {
                parsedData.defineExcessValues.forEach((excessValue) => {
                    this.populateExcessValue(excessValue);
                });
            }
        }
    }

    get excessValues() {
        return this.reportForm.get("excessValues") as FormArray;
    }

    populateExcessValue(value) {
        this.excessValues.push(
            this.formBuilder.control(value, [
                Validators.max(999),
                Validators.min(1),
            ])
        );
    }

    addExcessValue() {
        this.excessValues.push(
            this.formBuilder.control("", [
                Validators.max(999),
                Validators.min(1),
            ])
        );
    }

    removeExcessValue(index: number) {
        this.excessValues.removeAt(index);
    }

    onSubmit(isUpdateCall: boolean = true) {
        this.isSubmitted = true;
        if (!this.removeEmptyControlsOfExcessValues()) {
            this.addExcessValue();
        }
        if (this.reportForm.valid && this.selectedReportTabs.length) {
            this.saveReport(isUpdateCall);
        } else {
            this.markFormGroupAndArraysTouched(this.reportForm);
        }
    }

    markFormGroupAndArraysTouched(formGroup: FormGroup | FormArray) {
        Object.values(formGroup.controls).forEach((control) => {
            if (control instanceof FormControl) {
                control.markAsTouched();
            } else if (control instanceof FormGroup) {
                this.markFormGroupAndArraysTouched(control);
            } else if (control instanceof FormArray) {
                control.controls.forEach((arrayControl) => {
                    arrayControl.markAsTouched();
                });
            }
        });
    }

    toggleSelection(key: string) {
        if (this.selectedReportTabs.includes(key)) {
            this.selectedReportTabs = this.selectedReportTabs.filter(
                (item) => item !== key
            );
        } else {
            this.isSubmitted = false;
            this.selectedReportTabs.push(key);
        }
    }

    saveReport(isUpdateCall) {
        const report = this.prepareReport();

        this.workspaceIemDialog.open(
            [report],
            WorkspaceItemType.RENEWAL_REPORT,
            isUpdateCall
        );
    }

    removeEmptyControlsOfExcessValues() {
        const formArray = this.excessValues;
        for (let i = formArray.length - 1; i >= 0; i--) {
            const control = formArray.at(i);
            if (
                control.value === null ||
                control.value === "" ||
                control.value === undefined
            ) {
                formArray.removeAt(i);
            }
        }

        return formArray.length;
    }

    prepareReport() {
        const reportFormValues = this.reportForm.value;
        return {
            portfolio: reportFormValues.portfolio,
            compareWith: reportFormValues.compareWith,
            referenceValue: reportFormValues.referenceValue,
            renewalReportTabs: this.selectedReportTabs,
            defineExcessValues: reportFormValues.excessValues.filter(
                (value) => value
            ),
            groups: this.eligibleGroups.map((group) => {
                return group.id;
            }),
        };
    }

    deleteRenewalReport(report) {
        this.reportService.deleteRenewalReport(
            report,
            this.dataset.id,
            this.dataset.accountID
        );
    }

    downloadRenewalReport(report) {
        this.reportService.downloadRenewalReport(report);
    }

    closeReportPanel() {
        this.reportService.closeRenewalReportPanel();
    }

    hasTabAccess(key) {
        if (CONDITIONAL_REPORT_TABS.includes(key)) {
            return this.tabsAccess[key];
        }

        return true;
    }
}
