import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { DownloadDatapointRequest } from "../../model/download/download-datapoint-request";
import { DownloadDatapointDetailsRequest } from "../../model/download/download-datapoint-details-request";
import { Point } from "../../model/geometry/point";
import { PoiClosestFilter } from "src/app/model/datapoint/filter/poi-closest-filter";
import { DatapointsClimateChartService } from "src/app/dataset/datapoints/climate-charts/datapoints-climate-chart.service";

@Injectable({
    providedIn: "root",
})
export class DownloadService {
    constructor(
        private readonly http: HttpClient,
        private datapointsClimateChartService: DatapointsClimateChartService
    ) {}

    downloadDrafts(
        datasetID: string,
        request: any,
        fileName: string
    ): Observable<any> {
        return this.http.post(`/drafts/dataset/${datasetID}/file`, request, {
            params: { fileName: fileName },
            responseType: "blob",
        });
    }

    // POST /v2/datapoints/dataset/{datasetID}/file
    downloadDatapoints(
        datasetID: string,
        request: DownloadDatapointRequest,
        fileName: string
    ): Observable<any> {
        return this.http.post(
            `/datapoints/dataset/${datasetID}/file`,
            request,
            {
                params: { fileName: fileName },
                responseType: "blob",
            }
        );
    }

    downloadDatapointsByIds(
        datasetID: string,
        request: DownloadDatapointRequest,
        fileName: string
    ): Observable<any> {
        return this.http.post(
            `/datapoints/dataset/${datasetID}/file/by-ids`,
            request,
            {
                params: { fileName: fileName },
                responseType: "blob",
            }
        );
    }

    downloadDatasetFields(
        datasetID: string,
        fileName: string
    ): Observable<any> {
        return this.http.post(`/fields/dataset/${datasetID}/file`, null, {
            params: { fileName },
            responseType: "blob",
        });
    }

    downloadExternalDataForAddress(
        point: Point,
        address: string,
        datasetIds: any,
        poiClosestFilter: PoiClosestFilter
    ): Observable<any> {
        const params = new HttpParams()
            .append("datasetIds", datasetIds.join(", "))
            .set("address", address)
            .set("longitude", poiClosestFilter.longitude as any)
            .set("latitude", poiClosestFilter.latitude as any)
            .set("externalDatasetId", poiClosestFilter.externalDatasetId)
            .set("distanceInMeters", poiClosestFilter.distanceInMeters as any)
            .set("distanceUnit", poiClosestFilter.distanceUnit)
            .set("limit", poiClosestFilter.limit as any)
            .set(
                "climateData",
                JSON.stringify(
                    this.datapointsClimateChartService.downloadRequestPayload
                ) as any
            );
        const url = `/external/${point.x}/${point.y}/download`;
        return this.http.post(url, null, {
            responseType: "blob",
            params,
        });
    }

    downloadLocationProfile(
        datapointId: string,
        datasetId: string,
        poiClosestFilter: PoiClosestFilter
    ): Observable<any> {
        const params = new HttpParams()
            .set("datapointId", datapointId)
            .set("datasetId", datasetId)
            .set("longitude", poiClosestFilter.longitude as any)
            .set("latitude", poiClosestFilter.latitude as any)
            .set("externalDatasetId", poiClosestFilter.externalDatasetId)
            .set("distanceInMeters", poiClosestFilter.distanceInMeters as any)
            .set("distanceUnit", poiClosestFilter.distanceUnit)
            .set("limit", poiClosestFilter.limit as any)
            .set(
                "climateData",
                JSON.stringify(
                    this.datapointsClimateChartService.downloadRequestPayload
                ) as any
            );

        return this.http.post("/download/location-profile", null, {
            responseType: "blob",
            observe: "response",
            params,
        });
    }

    downloadDashboardPDFFile(
        datasetID: string,
        request: DownloadDatapointRequest,
        fileName: string
    ): Observable<any> {
        return this.http.post(
            `/datapoints/dataset/${datasetID}/file`,
            request,
            {
                params: { fileName: fileName },
                responseType: "blob",
            }
        );
    }

    downloadMunichReReport(
        datasetID: string,
        datapointID: string
    ): Observable<any> {
        return this.http.post(
            `/munichre/download-report/${datasetID}/${datapointID}`,{}
        );
    }

    downloadMunichReReportForSeeInfo(
        latitude: number,
        longitude : number,
        datasetID: string,
        address: string
    ): Observable<any> {
        return this.http.post(
            `/munichre/download-report/${latitude}/${longitude}/${datasetID}/${address}`,{});
    }

    downloadRenewalReport(
        reportId: string,
    ): Observable<any> {
        return this.http.post(
            `/reports/download/${reportId}`,
            {},
            {
                responseType: "blob",
            }
        );
    }
}
