<div class="renewal-report-container">
    <div class="boxed-section">
        <h3 class="report-form-heading" *ngIf="!isCreateMode">{{ activeReport.name }}</h3>
        <form [formGroup]="reportForm" (ngSubmit)="onSubmit()" class="renewal-report-form">

            <div class="flex-form-field">
                <mat-label>Report Type</mat-label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="reportType" required>
                        <ng-container *ngFor="let reportType of reportTypes">
                            <mat-option [value]="reportType">{{reportType | titlecase}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error *ngIf="reportForm.get('reportType').invalid && reportForm.get('reportType').touched">
                        Report Type is required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="flex-form-field">
                <mat-label>Portfolio</mat-label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="portfolio">
                        <ng-container *ngFor="let group of eligibleGroups">
                            <mat-option [value]="group.id"
                                *ngIf="reportForm.get('compareWith').value !== group.id">{{group.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error *ngIf="reportForm.get('portfolio').invalid && reportForm.get('portfolio').touched">
                        Portfolio is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex-form-field">
                <mat-label>Compare With</mat-label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="compareWith">
                        <ng-container *ngFor="let group of eligibleGroups">
                            <mat-option [value]="group.id"
                                *ngIf="reportForm.get('portfolio').value !== group.id">{{group.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex-form-field">
                <mat-label>Reference Value</mat-label>
                <mat-form-field appearance="outline">
                    <mat-select formControlName="referenceValue">
                        <ng-container *ngFor="let internalField of internalFields">
                            <mat-option [value]="internalField.id">{{internalField.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error
                        *ngIf="reportForm.get('referenceValue').invalid && reportForm.get('referenceValue').touched">
                        Reference Value is required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="report-tabs-conatiner">
                <mat-label>Select Report Tabs</mat-label>
                <div class="report-tabs-checkbox-container">
                    <ng-container *ngFor="let key of constantReportTabKeys">
                        <mat-checkbox *ngIf="hasTabAccess(key)"
                            [checked]="selectedReportTabs.includes(key)" (change)="toggleSelection(key)"
                            [disabled]="constantReportTabs[key].isComparison && !reportForm.get('compareWith').value">
                            {{ constantReportTabs[key].value }}
                        </mat-checkbox>
                    </ng-container>
                </div>

                <mat-error *ngIf="isSubmitted && !selectedReportTabs.length">
                    Select atleast one report tab
                </mat-error>
            </div>

            <div class="excess-fields-conatiner">
                <mat-label>Define Excess Value(s)</mat-label>
                <div formArrayName="excessValues" class="excess-values-fields-conatiner">
                    <div *ngFor="let value of excessValues.controls; let i = index; let last = last;">
                        <div class="excess-field">
                            <mat-form-field appearance="outline">
                                <input matInput type="number" [formControlName]="i">
                                <mat-error *ngIf="excessValues.controls[i].hasError('max')"
                                    class="excess-value-error">Max limit is 3</mat-error>
                                <mat-error *ngIf="excessValues.controls[i].hasError('min')"
                                    class="excess-value-error">Min limit is 1</mat-error>
                            </mat-form-field>
                            <label for="{{ i }}">M</label>
                            <button class="excess-field-action-button" *ngIf="last; else deleteExcessField"
                                type="button" [disabled]="i >= excessValuesLimit - 1" (click)="addExcessValue()">
                                <mat-icon>add</mat-icon>
                            </button>

                            <ng-template #deleteExcessField>
                                <button class="excess-field-action-button" type="button" (click)="removeExcessValue(i)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="report-action-buttons">
                <button type="submit" mat-flat-button type="submit" color="accent" class="map-button">
                    Save
                </button>
                <button mat-flat-button type="button" color="accent" class="map-button" (click)="onSubmit(false)">
                    Save As
                </button>
                <button mat-flat-button type="button" color="primary" class="map-button"
                    [disabled]="isCreateMode || !activeReport?.isDeletable" (click)="deleteRenewalReport(activeReport)">
                    Delete
                </button>
                <button mat-flat-button type="button" color="primary" class="map-button" (click)="closeReportPanel()">
                    Cancel
                </button>
                <button mat-flat-button type="button" color="primary" class="map-button" [disabled]="isCreateMode"
                    (click)="downloadRenewalReport(activeReport)">
                    Download
                </button>
            </div>
        </form>
    </div>
</div>

<app-workspace-item-dialog #workspaceItemDialog [accountId]="dataset?.accountID" [datasetId]="dataset?.id"
    (itemSubmitted)="setActiveReport($event)"></app-workspace-item-dialog>
