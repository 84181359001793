import { EditAccountOverlaysComponent } from 'src/app/account/edit-account-overlays/edit-account-overlays.component';
import { Crises24DatapointsAgtableComponent } from 'src/app/dataset/datapoints/datapoints-table/crises24-datapoints-agtable/crises24-datapoints-agtable.component';
import { CreateExternalOverlayGroupComponent } from 'src/app/external-overlays/create-external-overlay-group/create-external-overlay-group.component';
import { EditExternalOverlayAccountComponent } from 'src/app/external-overlays/edit-external-overlay-account/edit-external-overlay-account.component';
import { EditExternalOverlayGroupComponent } from 'src/app/external-overlays/edit-external-overlay-group/edit-external-overlay-group.component';
import { ExternalOverlayCreateComponent } from 'src/app/external-overlays/external-overlay-create/external-overlay-create.component';
import { AccountCreateGroupsPanelComponent } from '../../account/account-groups/account-create-groups-panel/account-create-groups-panel.component';
import { AccountUpdateGroupsPanelComponent } from '../../account/account-groups/account-update-groups-panel/account-update-groups-panel.component';
import { AccountPanelComponent } from '../../account/account-panel/account-panel.component';
import { EditAccountComponent } from '../../account/edit-account/edit-account.component';
import { CreateMemberComponent } from '../../account/members/create-member-panel/create-member/create-member.component';
import { AddressLocationProfileComponent } from '../../dataset/datapoints/address-location-profile/address-location-profile.component';
import { DatapointsAnalyticsComponent } from '../../dataset/datapoints/datapoints-analytics/datapoints-analytics.component';
import { DatapointsClusterComponent } from '../../dataset/datapoints/datapoints-cluster/datapoints-cluster.component';
import { UpdateDatapointComponent } from '../../dataset/datapoints/datapoints-table/update-datapoint/update-datapoint.component';
import { DraftsCreateComponent } from '../../dataset/drafts/drafts-create/drafts-create.component';
import { DraftsUpdateComponent } from '../../dataset/drafts/drafts-update/drafts-update.component';
import { EventNotificationsComponent } from '../../dataset/event-notifications/event-notifications.component';
import { CreateIntegratedClientComponent } from '../../integration/create-integrated-client/create-integrated-client.component';
import { EditIntegratedClientComponent } from '../../integration/edit-integrated-client/edit-integrated-client.component';
import { CreateOverlayGroupComponent } from '../../overlays/create-overlay-group/create-overlay-group.component';
import { EditOverlayGroupComponent } from '../../overlays/edit-overlay-group/edit-overlay-group.component';
import { EditOverlayAccountsComponent } from '../../overlays/overlay-edit-accounts/edit-overlay-accounts.component';
import { ReportsComponent } from 'src/app/dataset/datapoints/reports/reports.component';

export interface ISidePanel {
    id?: string;
    size?: string;
    width: number;
    panelTitle: string;
    panelIcon?: string;
    backdrop?: boolean;
    resizeable?: boolean;
    component?: SidePanels;
}

export enum SidePanels {
    EDIT_ACCOUNT = 'EDIT_ACCOUNT',
    CREATE_ACCOUNT = 'CREATE_ACOUNT',
    CREATE_GROUP = 'CREATE_GROUP',
    UPDATE_GROUPS = 'UPDATE_GROUPS',
    CREATE_MEMBER = 'CREATE_MEMBER',
    ANALYTICS = 'ANALYTICS',
    NOTIFICATIONS_PANEL = 'NOTIFICATIONS_PANEL',
    UPDATE_DATAPOINT = 'UPDATE_DATAPOINT',
    CLUSTER_PANEL = 'CLUSTER_PANEL',
    ADDRESS_LOCATION_PROFILE = 'ADDRESS_LOCATION_PROFILE',
    DATAPOINT_PROFILE = 'DATAPOINT_PROFILE',
    UPDATE_DRAFTS = 'UPDATE_DRAFTS',
    CREATE_DRAFT = 'CREATE_DRAFT',
    EDIT_INTEGRATED_CLIENT = 'EDIT_INTEGRATED_CLIENT',
    CREATE_INTEGRATED_CLIENT = 'CREATE_INTEGRATED_CLIENT',
    EDIT_OVERLAYS_ACCOUNT = 'EDIT_OVERLAYS_ACCOUNT',
    CREATE_GROUP_OVERLAY = 'CREATE_GROUP_OVERLAY',
    EDIT_ACCOUNT_OVERLAYS = 'EDIT_ACCOUNT_OVERLAYS',
    EDIT_GROUP_OVERLAY = 'EDIT_GROUP_OVERLAY',
    EDIT_EXTERNAL_GROUP_OVERLAY= 'EDIT_EXTERNAL_GROUP_OVERLAY',
    CREATE_EXTERNAL_GROUP_OVERLAY = 'CREATE_EXTERNAL_GROUP_OVERLAY',
    EDIT_EXTERNAL_OVERLAYS_ACCOUNT = 'EDIT_EXTERNAL_OVERLAY_ACCOUNT',
    CREATE_EXTERNAL_OVERLAY = 'CREATE_EXTERNAL_OVERLAY',
    CRISES24_DATAPOINTS_AGTABLE = 'CRISES24_DATAPOINTS_AGTABLE',
    REPORT_PANEL = 'REPORT_PANEL'
}

const componentsMapping = new Map<string, any>();
componentsMapping.set(SidePanels.EDIT_ACCOUNT, EditAccountComponent);
componentsMapping.set(SidePanels.CREATE_ACCOUNT, AccountPanelComponent);
componentsMapping.set(SidePanels.CREATE_GROUP, AccountCreateGroupsPanelComponent);
componentsMapping.set(SidePanels.UPDATE_GROUPS, AccountUpdateGroupsPanelComponent);
componentsMapping.set(SidePanels.CREATE_MEMBER, CreateMemberComponent);
componentsMapping.set(SidePanels.ANALYTICS, DatapointsAnalyticsComponent);
componentsMapping.set(SidePanels.NOTIFICATIONS_PANEL, EventNotificationsComponent);
componentsMapping.set(SidePanels.UPDATE_DATAPOINT, UpdateDatapointComponent);
componentsMapping.set(SidePanels.CLUSTER_PANEL, DatapointsClusterComponent);
componentsMapping.set(SidePanels.ADDRESS_LOCATION_PROFILE, AddressLocationProfileComponent);
componentsMapping.set(SidePanels.DATAPOINT_PROFILE, UpdateDatapointComponent);
componentsMapping.set(SidePanels.UPDATE_DRAFTS, DraftsUpdateComponent);
componentsMapping.set(SidePanels.CREATE_DRAFT, DraftsCreateComponent);
componentsMapping.set(SidePanels.EDIT_INTEGRATED_CLIENT, EditIntegratedClientComponent);
componentsMapping.set(SidePanels.CREATE_INTEGRATED_CLIENT, CreateIntegratedClientComponent);
componentsMapping.set(SidePanels.EDIT_OVERLAYS_ACCOUNT, EditOverlayAccountsComponent);
componentsMapping.set(SidePanels.CREATE_GROUP_OVERLAY, CreateOverlayGroupComponent);
componentsMapping.set(SidePanels.EDIT_GROUP_OVERLAY, EditOverlayGroupComponent);
componentsMapping.set(SidePanels.CREATE_EXTERNAL_GROUP_OVERLAY, CreateExternalOverlayGroupComponent);
componentsMapping.set(SidePanels.EDIT_EXTERNAL_GROUP_OVERLAY, EditExternalOverlayGroupComponent);
componentsMapping.set(SidePanels.EDIT_EXTERNAL_OVERLAYS_ACCOUNT, EditExternalOverlayAccountComponent);
componentsMapping.set(SidePanels.CREATE_EXTERNAL_OVERLAY, ExternalOverlayCreateComponent);
componentsMapping.set(SidePanels.EDIT_ACCOUNT_OVERLAYS, EditAccountOverlaysComponent);
componentsMapping.set(SidePanels.CRISES24_DATAPOINTS_AGTABLE, Crises24DatapointsAgtableComponent);
componentsMapping.set(SidePanels.REPORT_PANEL, ReportsComponent);
export default componentsMapping;
