<ul [ngClass]="ulClass">
    <ng-container *ngTemplateOutlet="recursiveList; context: { items: items, level: 0 }"></ng-container>
</ul>

<ng-template #recursiveList let-items="items" let-level="level">
    <li *ngFor="let item of items" [style.margin-left.px]="level * 10">
        <div class="parent-group-container">
            <div class="toggle-icon" [ngClass]="{'invisble': !item?.children?.length}"
                (click)="toggleItemExpansion(item)">
                <mat-icon class="small-icon">{{ item.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
                    }}</mat-icon>
            </div>
            <ng-container *ngIf="lastItemDisable; else enabledCheckbox">
                <mat-checkbox [(ngModel)]="item.selected" [disabled]="isDisabled ? isDisabled : isLastSelectedDisabled(items, item)" [indeterminate]="isIndeterminate(item)"
                    (change)="toggleItem(item)" [matTooltip]="isDisabled ? isDisabled : isLastSelectedDisabled(items, item) ? 'Atleast one group is mandatory' : ''">
                    {{ item.name }}
                </mat-checkbox>
            </ng-container>

            <ng-template #enabledCheckbox>
                <mat-checkbox [(ngModel)]="item.selected" [indeterminate]="isIndeterminate(item)"
                    (change)="toggleItem(item)">
                    {{ item.name }}
                </mat-checkbox>
            </ng-template>


        </div>
        <ng-container *ngIf="item.children && item.expanded">
            <ng-container
                *ngTemplateOutlet="recursiveList; context: { items: item.children, level: level + 1 }"></ng-container>
        </ng-container>
    </li>
</ng-template>
